angular.module('cerberus.admin')
    .controller('WidgetsImportsRulesCreateCtrl', function WidgetsImportsRulesCreateCtrl($scope, $location, widgetId,
                                                                                        ImportService, ImportListService, WidgetsSettingsService) {
        $scope.widgetId = widgetId;
        $scope.tempImportSettings = {
            map:[],
            name:'',
            description:'',
            type:'csv',
            ignoreExtraColumns: true
        };
        $scope.origImportSettings = angular.copy($scope.tempImportSettings);
        $scope.forms = [];
        $scope.types = ImportListService.getConfigTypes();
        $scope.typeColumns = ImportListService.getTypeColumns() || {};
        $scope.input = {
            fieldMap:null,
            colHeader:null
        };

        $scope.setPrimaryKey = function(importSetArray,index){
            if(importSetArray[index].primaryKey){
                for(var i = 0; i < importSetArray.length; i++){
                    if(i != index){
                        importSetArray[i].primaryKey = false;
                    }
                }
            }
        };

        $scope.setAppendUpdate = function(importSetArray,index){
            if(importSetArray[index].appendUpdateKey){
                for(var i = 0; i < importSetArray.length; i++){
                    if(i != index){
                        importSetArray[i].appendUpdateKey = false;
                    }
                }
            }
        };

        $scope.onTypeChange = function(importSettings){
            if(importSettings.type === 'files'){
                importSettings.map[0] = {};
                importSettings.map[1] = {
                    modelId: 'sys_created_date',
                    colHeader:null
                };
                importSettings.map[2] = {
                    modelId:'client_file_name',
                    colHeader:null
                };
                importSettings.map[3] = {
                    modelId:'sys_created_by',
                    colHeader:null
                };
            }
            else{
                importSettings.map = [];
            }
        };

        $scope.addFieldMap = function(fieldMap,hdr){
            if(!$scope.tempImportSettings.map){
                $scope.tempImportSettings.map = [];
            }

            if($scope.tempImportSettings.map.map(function(e) { return e.colHeader; }).indexOf(hdr) === -1){
                var d = fieldMap;
                d.colHeader = hdr;
                $scope.tempImportSettings.map.push(d);
            }
            $scope.input = {
                fieldMap:null,
                colHeader:null
            };
        };

        $scope.isEqual = function(){
            return angular.equals($scope.tempImportSettings, $scope.origImportSettings);
        };

        $scope.removeFieldMap = function(index){
            $scope.tempImportSettings.map.splice(index,1);
        };

        $scope.cancel = function () {
            $location.url('/settings/widgets/' + $scope.widgetId + '/imports');
        };
        $scope.submit = function(isValid, data){
            if(isValid){
                ImportService.create($scope.widgetId,data).then(function(){
                    $location.url('/settings/widgets/' + $scope.widgetId + '/imports');
                });
            }
        };

        _init();

        function _init(){
            WidgetsSettingsService.getFormFields(widgetId).then(function (d) {
                $scope.forms = WidgetsSettingsService.parseFormObjects(d.display);
            });
        }
    })
;