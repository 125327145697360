angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name NimFormObjectService
     * @alias cerberus/core:nimFromObjectService
     * @description Provides functionality for nimFormObjects
     */
    .factory('NimFormObjectService', function NimFormObjectService(_, moment, kendo, apiPath, mapboxConfig, $http, $q, $log, $rootScope, $sanitize, $sce, $timeout, $window,
                                                                   nimUtilityService, OdataUtilityService, RulesService){
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';
        return {
            textEditorOptions: textEditorOptions,
            getValue: getValue,
            getLocationAutoComplete: getLocationAutoComplete,
            setLocationData: setLocationData,
            setEmptyLocation: setEmptyLocation,
            watchAfterDateField: watchAfterDateField,
            watchBeforeDateField: watchBeforeDateField,
            createODataLookUp: createODataLookUp,
            compileStaticText: compileStaticText,
            enforceRule: enforceRule
        };

        /**
         * Builds options for textarea form object
         * @returns {Object}
         */
        function textEditorOptions(){
            return {
                encoded: false,
                tools: [
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    'justifyLeft',
                    'justifyCenter',
                    'justifyRight',
                    'justifyFull',
                    'insertUnorderedList',
                    'insertOrderedList',
                    'indent',
                    'outdent',
                    'createLink',
                    'unlink',
                    'insertImage',
                    'subscript',
                    'superscript',
                    'createTable',
                    'addRowAbove',
                    'addRowBelow',
                    'addColumnLeft',
                    'addColumnRight',
                    'deleteRow',
                    'deleteColumn',
                    'viewHtml',
                    'formatting',
                    'cleanFormatting',
                    'fontName',
                    'fontSize',
                    'foreColor',
                    'backColor'
                ],
                paste: function (e) {
                    e.html = $sanitize(e.html);
                }
            };
        }

        /**
         * getValue
         * @param viewId
         * @param col
         * @param instanceId
         * @returns {*|r.promise|Function|promise|Document.promise}
         */

        function getValue(viewId, col, instanceId) {
            var deferred = $q.defer();
            $http.get(apiPath + 'view/'+viewId+'/column/'+col+'/instance/'+instanceId)
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function getLocationAutoComplete(inputString) { 
            return $http.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + inputString + '.json', {
                params: {
                    access_token: mapboxConfig.token
                }
            }).then(function (response) {
                return response.data.features;
            });
        }

        function setLocationData(scope, model) {
            var location = formatAddress(model);

            scope.dataSet[scope.nimFormObject.config.modelId] = location;
            scope.validateFormObject(location, scope.isRequired);
            
            // Auto-populate fields
            if (scope.nimFormObject.param.fields) {
                _.forEach(scope.nimFormObject.param.fields, function (v, k) {
                    scope.dataSet[k] = _.get(location.properties, v.value, '');
                });
            }
        }

        /**
         * Formats mapbox data to match current model
         * @param rawData - mapbox raw response data
         * @returns {Object} - formatted location data
         */
        function formatAddress(rawData) {
            var properties = {
                administrative_area_level_1_long_name: '',
                administrative_area_level_1_short_name: '',
                administrative_area_level_2_long_name: '',
                administrative_area_level_2_short_name: '',
                country_long_name: '',
                country_short_name: '',
                formatted_address: rawData['place_name'],
                locality_long_name: '',
                locality_short_name: '',
                neighborhood_long_name: '',
                neighborhood_short_name: '',
                postal_address: '',
                postal_code_long_name: '',
                postal_code_short_name: '',
                postal_code_suffix_long_name: '',
                postal_code_suffix_short_name: '',
                route_long_name: '',
                route_short_name: '',
                street_address: '',
                street_number_long_name: '',
                street_number_short_name: ''
            };
            
            _.forEach(rawData.context, function (context) {
                var type = _.head(context.id.split('.'));

                switch (type) {
                    case 'region':
                        properties.administrative_area_level_1_long_name = context.text;

                        if(context.short_code) {
                            properties.administrative_area_level_1_short_name = _.last(context.short_code.split('-'));
                        }
                        break;
                    case 'country':
                        properties.country_long_name = context.text;

                        if(context.short_code) {
                            properties.country_short_name = context.short_code;
                        }
                        break;
                    case 'place':
                        if(context.text && properties.locality_long_name) {
                            properties.locality_long_name = properties.locality_long_name + ', ' + context.text;
                        }else {
                            properties.locality_long_name = context.text;
                        }
                        break;
                    case 'locality':
                        if(context.text && properties.locality_long_name) {
                            properties.locality_long_name = context.text + ', ' + properties.locality_long_name;
                        }else {
                            properties.locality_long_name = context.text;
                        }
                        break;
                    case 'neighborhood':
                        properties.neighborhood_long_name = context.text;
                        break;
                    case 'postcode':
                        properties.postal_code_short_name = context.text;
                        break;
                }
            });

            var type = _.head(rawData.id.split('.'));

            if (type === 'address') {
                properties.route_long_name = rawData.text || '';
                properties.street_number_long_name = rawData.address || '';
                
                if (rawData.text && rawData.address){
                    properties.street_address = rawData.address + ' ' + rawData.text;
                    properties.postal_address = rawData.address + ' ' + rawData.text + '\n';
                } else if (rawData.text) {
                    properties.postal_address = rawData.text + '\n';
                }
                
                if (properties.country_long_name === 'United States'){
                    properties.postal_address = properties.postal_address + properties.locality_long_name + ', ' + properties.administrative_area_level_1_short_name + ' ' + properties.postal_code_short_name;
                }else {
                    properties.postal_address = properties.postal_address + properties.locality_long_name + ', ' + properties.administrative_area_level_1_long_name + ' ' + properties.postal_code_short_name + '\n';
                    properties.postal_address = properties.postal_address + properties.country_long_name;
                }
                //Conversion to Uppercase style
                //properties.postal_address = properties.postal_address.toUpperCase();
            }
            else {
                properties.postal_address = properties.formatted_address;
            }

            return {
                geometry: rawData['geometry'],
                properties: properties
            };
        }
        
        // OLD STYLE - OBSOLETE AND REPLACED WITH NEW ONE
        // function formatAddress(rawData){
        //     return {
        //         geometry: {
        //             type: 'Point',
        //             coordinates: [
        //                 rawData.metadata['longitude'],
        //                 rawData.metadata['latitude']
        //             ]
        //         },
        //         properties: {
        //             administrative_area_level_1_long_name: rawData.components['state_abbreviation'],
        //             administrative_area_level_1_short_name: rawData.components['state_abbreviation'],
        //             administrative_area_level_2_long_name: rawData.metadata['county_name'],
        //             administrative_area_level_2_short_name: rawData.metadata['county_name'],
        //             country_long_name: 'United States',
        //             country_short_name: 'US',
        //             formatted_address: rawData['delivery_line_1'] + ' ' + rawData['last_line'],
        //             locality_long_name: rawData.components['city_name'],
        //             locality_short_name: rawData.components['city_name'],
        //             neighborhood_long_name: '',
        //             neighborhood_short_name: '',
        //             postal_code_long_name: rawData.components['zipcode'],
        //             postal_code_short_name: rawData.components['zipcode'],
        //             postal_code_suffix_long_name: rawData.components['plus4_code'],
        //             postal_code_suffix_short_name: rawData.components['plus4_code'],
        //             route_long_name: rawData.components['street_name'] + ' ' + rawData.components['street_suffix'],
        //             route_short_name: rawData.components['street_name'] + ' ' + rawData.components['street_suffix'],
        //             street_address: rawData['delivery_line_1'],
        //             street_number_long_name: rawData.components['primary_number'],
        //             street_number_short_name: rawData.components['primary_number']
        //         }
        //     };
        // }

        /**
         * Sets location form object value to empty object when user types and changes the
         * value of the autocomplete. Not called when location is selected from type-ahead
         * @param scope
         * @returns {Function} - ngChange handler
         */
        function setEmptyLocation(scope){
            return function(){
                scope.dataSet[scope.nimFormObject.config.modelId] = {};

                if(scope.nimFormObject.param.fields){
                    _.forEach(scope.nimFormObject.param.fields,function(v, k){
                        scope.dataSet[k] = '';
                    });
                }
            };
        }

        /**
         * Sets watcher for date form object's selected 'after' field
         * @param scope
         * @param after - form object 'after' configuration
         * @param dateField - name of kendo date-picker scope variable
         */
        function watchAfterDateField(scope, after, dateField){
            scope.$watch(function(){
                return scope.dataSet[after.modelId];
            }, function(min){
                $timeout(function(){
                    var nimDateFormObject = scope[dateField];
                    if(nimDateFormObject) {
                        var current = scope.dataSet[scope.nimFormObject.config.modelId];
                        nimDateFormObject.min(min);
                        if (!current || moment(current).isBefore(min)) {
                            scope.dataSet[scope.nimFormObject.config.modelId] = min;
                            nimDateFormObject.value(min);
                            nimDateFormObject.trigger('change');
                        }
                        else {
                            nimDateFormObject.value(current);
                        }
                    }
                });
            });
        }

        /**
         * Sets watcher for date form object's selected 'before' field
         * @param scope
         * @param before - form object 'before' configuration
         * @param dateField - name of kendo date-picker scope variable
         */
        function watchBeforeDateField(scope, before, dateField){
            scope.$watch(function(){
                return scope.dataSet[before.modelId];
            }, function(max){
                $timeout(function(){
                    var nimDateFormObject = scope[dateField];
                    if(nimDateFormObject) {
                        var current = scope.dataSet[scope.nimFormObject.config.modelId];
                        nimDateFormObject.max(max);
                        if (!current || moment(current).isAfter(max)) {
                            scope.dataSet[scope.nimFormObject.config.modelId] = max;
                            nimDateFormObject.value(max);
                            nimDateFormObject.trigger('change');
                        }
                        else {
                            nimDateFormObject.value(current);
                        }
                    }
                });
            });
        }

        function createODataLookUp(scope){
            var option = scope.nimFormObject.option,
                param = scope.nimFormObject.param,
                lookupDisplay = option.column;

            var sort = {
                field: option.orderCol || 'display',
                dir: option.orderBy || 'asc'
            };

            if(param.sortBy){
                sort = param.sortBy;
            }
            else if(option.orderBy === 'oldest'){
                sort = {
                    field: 'id',
                    dir: 'asc'
                };
            }
            else if(option.orderBy === 'newest'){
                sort = {
                    field: 'id',
                    dir: 'desc'
                };
            }

            if(option.columnType === 'location'){
                lookupDisplay += '_formatted_address';
            }

            var url = '/server/rest/v1/' + option.type + '/' + option.id + '/odata.svc';
            if(option.type === 'views'){
                url = url + '?$lookupDisplay=' + lookupDisplay;
            }

            return new kendo.data.DataSource({
                type:'odata',
                serverFiltering: true,
                serverPaging: true,
                serverSorting: true,
                pageSize: option.returnNum || 20,
                requestEnd: function(e) {
                    var currentValue = _.get(scope, 'dataSet.' + scope.nimFormObject.config.modelId);
                    var data = oDataToArray(e.response);

                    if (scope.nimFormObject.config.type == 'multiselect') {
                        transformMultiSelectValues(scope, data);
                    }

                    if(_.has(currentValue, 'display') && option.type === 'views'){
                        var exists = !!(_.find(data, 'id', currentValue.id));

                        if (!exists) {
                            data.push(currentValue);
                        }
                    }

                    if(data.length === 0){
                        data.push({ display: '(No Results)', id: -1 });
                    }
                    e.response = arrayToOData(data);
                },
                change: function (e) {
                    // If only one item comes down, __count is left in when the data is added to the data source
                    if(e.sender.total() == 1){
                        var dataItem = e.sender.data()[0];

                        // Removes __count
                        if(_.has(dataItem, '__count')){
                            delete dataItem.__count;
                        }

                        // Sometimes, if only one item comes down, datasource strips out all
                        // properties preceded by "__" (like __instanceId or __widgetId).
                        if (scope.nimFormObject.config.type == 'multiselect' && !dataItem.__instanceId && !dataItem.__widgetId) {
                            transformMultiSelectValues(scope, e.sender.data());
                        }    
                    }
                },
                transport: {
                    read: function (options) {

                        // Check if required parent filters are set
                        var cascadeFilters = _.get(param, 'cascadeFilter.filters');
                        if (!_.isEmpty(cascadeFilters)){
                            var requiredParentFiltersSet = _.every(cascadeFilters, function(filter){
                                if (filter.required) {
                                    var filterValue = scope.filters[filter.value];
                                    return !!_.get(filterValue, 'id');
                                }
                                return true;
                            });

                            // If required filters are not set, return no results                            
                            if (!requiredParentFiltersSet){
                                options.success({
                                    d: {
                                        id: -1,
                                        display: '(No Results)',
                                        __count: 1
                                    }
                                });
                                // Return without allowing the oData call                                
                                return;
                            }
                        }
                        
                        var urlParams = {};

                        // Set $filter parameter
                        var filter = oDataLookUpFilter(scope, param);

                        if (filter) {
                            urlParams.$filter = OdataUtilityService.toOdataFilter(filter);
                        }

                        // Set $top parameter                        
                        if (options.data.pageSize) {
                            urlParams.$top = options.data.pageSize;
                        }

                        // Set $orderBy parameter                        
                        if (!_.isEmpty(sort)) {
                            var sortStrings = [];
                            _.forEach(sort, function (sortItem) {
                                if (sortItem.field) {
                                    var dir = sortItem.dir || 'asc';
                                    sortStrings.push(sortItem.field + ' ' + dir);
                                }
                            });
                            if (sortStrings.length) {
                                urlParams.$orderBy = sortStrings.join(',');
                            }    
                        }

                        $http.get(url, {
                                headers: {
                                    Authentication: 'Bearer ' + $rootScope.authToken,
                                    WSID: $rootScope.userData.currentWorkspace.id
                                },
                                params: urlParams
                            })
                            .then(function (result) {
                                options.success(result.data);
                            },function (reason) {
                                options.error(reason);
                            });
                    }
                },
                sort: sort
            });
        }

        function oDataToArray(oDataResult) {
            var data = [];

            if (_.has(oDataResult, 'd')) {
                if (_.isArray(oDataResult.d.results)) {
                    data = oDataResult.d.results;
                }
                else {
                    data.push(_.omit(oDataResult.d, '__count'));
                }
            }

            return data;
        }

        function arrayToOData(arr) {
            var odata = {
                d: {
                    __count: arr.length
                }
            };

            if (arr.length == 1) {
                _.assign(odata.d, arr[0]);
            }
            else {
                odata.d.results = arr;
            }

            return odata;
        }

        function transformMultiSelectValues(scope, data) {
            var param = scope.nimFormObject.param;
            var dataSet = scope.dataSet[scope.nimFormObject.config.modelId];
            _.forEach(data, function (dataItem) {
                if (dataItem.id != -1) {
                    var matchingRecorditem = _.find(dataSet, function (item) {
                        return _.get(item, ['__nimData', param.lookup, 'id']) == dataItem.id;
                    });
                    if (matchingRecorditem) {
                        _.set(matchingRecorditem, ['__nimData', param.lookup, 'display'], dataItem.display);
                        _.set(matchingRecorditem, [param.lookup, 'display'], dataItem.display);
                        matchingRecorditem.id = dataItem.id;
                        matchingRecorditem.display = dataItem.display;

                        _.assign(dataItem, matchingRecorditem);
                    }
                    else {
                        var nimData = {};

                        dataItem.__widgetId = param.widgetId;
                        dataItem.__optionId = param.optionId;
                        dataItem.__column = param.column;
                        dataItem.__modelId = param.fk;

                        nimData[param.lookup] = {
                            id: dataItem.id,
                            display: dataItem.display
                        };
                    
                        if (scope.nimInstanceAction === 'read') {
                            var displayField = '',
                                display = 'Auto';
                            
                            if (param.column) {
                                displayField = param.column.split('_')[1];
                                display = _.get(scope.dataSet, displayField, 'Auto');
                            }

                            nimData[param.fk] = {
                                id: parseInt(scope.$eval('vm.instanceId')),
                                display: display
                            };
                        }
                        else {
                            nimData[param.fk] = {
                                display: 'Auto'
                            };
                        }
                        dataItem.__nimData = nimData;
                    }
                }    
            });

            _.forEach(dataSet, function (item) {
                if (item) {
                    if (!item.id) {
                        item.id = _.get(item, ['__nimData', param.lookup, 'id']);
                        item.display = _.get(item, ['__nimData', param.lookup, 'display']);
                    }

                    if (!_.find(data, 'id', item.id)) {
                        data.push(item);
                    }
                }    
            });
        }

        function oDataLookUpFilter(scope, param){
            var filters = [];

            var defaultFilter = param.defaultFilter;

            if(defaultFilter && defaultFilter.filters.length){
                if(defaultFilter.filters.length === 1){
                    filters.push(defaultFilter.filters[0]);
                }
                else {
                    filters.push(defaultFilter);
                }
            }

            // Cascade filter
            if(param.cascadeFilter && !_.isEmpty(param.cascadeFilter.filters)){
                var cascadeFilters = [];

                _.forEach(param.cascadeFilter.filters, function(parentFilter){
                    var from = parentFilter.value;
                    var fromField = parentFilter.field;

                    if(from && fromField){
                        var filterValue = scope.filters[from];
                        if(filterValue){
                            if(filterValue.id && filterValue.id != "-1" && filterValue.id != -1) {
                                cascadeFilters.push({
                                    field: fromField.replace('_display', ''),
                                    operator: 'eq',
                                    value: filterValue.id
                                });
                            }
                            else if(typeof filterValue === 'string' || typeof filterValue === 'number'){
                                cascadeFilters.push({
                                    field: fromField,
                                    operator: parentFilter.operator,
                                    value: filterValue
                                });
                            }
                        }
                    }
                });

                if(cascadeFilters.length) {
                    filters.push({
                        logic: param.cascadeFilter.logic,
                        filters: cascadeFilters
                    });
                }
                else if(cascadeFilters.length === 1){
                    filters.push(cascadeFilters[0]);
                }
            }

            // Search input filter
            if(scope.filterInput){
                filters.push({
                    field: scope.filterInput.field,
                    operator: scope.filterInput.operator,
                    value: scope.filterInput.value
                });
            }


            /*if(filters.length === 1){
                return filters[0];
            }
            else */if(filters.length > 0) {
                return {
                    logic: 'and',
                    filters: filters
                };
            }

            return null;
        }

        function compileStaticText(scope/*, element*/){
            if (scope.nimFormObject.param.template) {
                var stateOnly = true;
                var dataSetString = 'dataSet';

                if(scope.$eval('vm.instanceId')){
                    stateOnly = false;
                    dataSetString = 'vm.temp';
                }

                // var origSizeY = 0;

                // if (scope.gridsterItem) {
                //     origSizeY = scope.gridsterItem.sizeY;
                // }

                var watchedData = [{}, {}];

                scope.$watch(function () {
                    watchedData[0] = scope.$eval(dataSetString);
                    watchedData[1] = scope.$eval('autoFillData');

                    return watchedData;
                }, function (newData) {
                    // $timeout(function() {
                    var dataSet = newData[0],
                        autoFillData = newData[1];

                    var flatDataSet = {},
                        userData = {
                            firstName: $rootScope.userData.firstName,
                            lastName: $rootScope.userData.lastName,
                            fullName: $rootScope.userData.firstName + $rootScope.userData.lastName
                        },
                        printData = {
                            pageCurrent: scope.pageCurrent || '#',
                            pageTotal: scope.pageTotal || '#'
                        };

                    if (!stateOnly) {
                        _.forEach(dataSet, function (state) {
                            _.assign(flatDataSet, state);
                        });
                    }
                    else {
                        flatDataSet = dataSet;
                    }

                    flatDataSet = angular.copy(flatDataSet);

                    // 
                    _.forEach(autoFillData, function (dataItem, modelId) {
                        flatDataSet[modelId] = flatDataSet[modelId] || {};

                        _.forEach(dataItem, function (value, key) { 
                            flatDataSet[modelId][key] = value;
                        });
                    });

                    var templateData = {
                        data: defaultPropsToBlank(flatDataSet),
                        user: userData,
                        print: printData,
                        nim: nimUtilityService,
                        instance: {
                            action: scope.nimInstanceAction
                        }
                    };

                    try {
                        var template = kendo.template(scope.nimFormObject.param.template.replace(/&lt;/g, '<').replace(/&gt;/g, '>'));
                        var compiledHtml = template(templateData);

                        scope.compiledStaticText = $sce.trustAsHtml(compiledHtml);
                    }
                    catch (e) {
                        $log.error('Error building HTML:', e, scope.nimFormObject.param.template, templateData);
                        scope.compiledStaticText = $sce.trustAsHtml(scope.nimFormObject.param.text);
                    }
                    // });

                    // $timeout(function () {
                    //     // Resize template gridster item
                    //     if (scope.gridsterItem && scope.gridsterItem.gridster) {
                    //         var height = 0;
                    //         element.find('.nim-static-text').children().each(function (i, element) {
                    //             height += angular.element(element).outerHeight(true);
                    //         });

                    //         var rows = Math.max(Math.ceil(height / 8), origSizeY);
                    //         scope.gridsterItem.setSizeY(rows);
                    //     }
                    // }, 1);
                }, true);
            }
            else {
                $timeout(function(){
                    scope.compiledStaticText = $sce.trustAsHtml(scope.nimFormObject.param.text);
                });
            }
        }

        /**
         * Sets null values in dataSet to empty string
         * @param dataSet - Flattened instance dataSet
         * @returns {Object}
         */
        function defaultPropsToBlank(dataSet){
            var newDataSet = {};

            _.forEach(dataSet, function(value, field){
                if(value == null){
                    newDataSet[field] = '';
                }
                else {
                    newDataSet[field] = value;
                }
            });

            return newDataSet;
        }

        /**
         * Creates watcher that enforces a rule when the required parameters have been set
         * @param scope
         * @param ruleConfig - Form Object's rule configuration
         * @param ruleConfig.ruleId - ID of rule being enforced
         * @param ruleConfig.parameterMap - Maps form fields to rule parameters
         * @param ruleConfig.returnMap - Maps form fields to rule return data values
         * @param dataSet
         * @param ruleModelId
         */
        function enforceRule(scope, ruleConfig, dataSet, ruleModelId){
            if(ruleConfig.ruleId){
                // Make sure a form field's modelId has been mapped to each required field
                var requiredFieldsMapped = true,
                    paramIds = _.keys(ruleConfig.parameterMap),
                    params = [];

                _.forEach(paramIds, function(name){
                    var p = ruleConfig.parameterMap[name];
                    if(p.modelId){
                        params.push('dataSet.' + p.modelId);
                    }
                    else if(!p.req){
                        params.push(_.constant(null));
                    }
                    else {
                        requiredFieldsMapped = false;
                        return false;
                    }
                });

                if(requiredFieldsMapped){
                    // Watches all parameters
                    scope.$watchGroup(params, function(newParams, oldParams){
                        if(!angular.equals(newParams, oldParams)){
                            // Make sure each required parameter has been set
                            var requiredFieldsSet = true,
                                paramStruct = {};
                            _.forEach(paramIds, function(name, index){
                                var p = ruleConfig.parameterMap[name],
                                    val = newParams[index];

                                if(_.isObject(val) && !_.isDate(val)){
                                    if(_.isEmpty(val)){
                                        val = null;
                                    }
                                    else {
                                        val = _.get(val, 'display');
                                    }
                                }

                                // Zero counts as a valid value
                                if(p.req && !val && val !== 0 && val !== false){
                                    requiredFieldsSet = false;
                                    return false; // Breaks from forEach function
                                }
                                else {
                                    paramStruct[name] = val;
                                }
                            });

                            if(requiredFieldsSet){
                                RulesService.getResult(ruleConfig.ruleId, paramStruct).then(function (result) {
                                    dataSet[ruleModelId] = result || {};
                                }, function () {
                                    dataSet[ruleModelId] = {};
                                });
                            }
                            else {
                                dataSet[ruleModelId] = {};
                            }
                        }
                    });
                }
            }
        }
    })
;