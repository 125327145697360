angular.module('cerberus.ui-util')
    /**
     * @ngdoc service
     * @name ExportFileWriteService
     * @alias cerberus/ui-util:ExportFileWriteService
     * @description Provides functions for handling form data exports and save content to file on the main host server.
     */
    .factory('ExportFileWriteService', function ExportFileWriteService(_, $q, $http, apiPath) {
        var service = {
            writeFile: writeFile,
        };

        return service;

        /*******************************************************/

        /**
         * Auxiliary tool to generate Base64 URI string into PDF file
         * Takes Base64 encoded string of encoded file and saves it on the server, and returns its location details
         * @param base64 {URI base 64 encoded string}
         * @param fileAttributes - Optional option may be used to specify filename and file extension)
         * @returns {deferred.promise|{then}} / (Generated filename with it's path is propagated in the 'return.data').
         */
        function writeFile(base64, fileAttributes) {
            var deferred = $q.defer();
            var _base64FMT = base64.substr(base64.indexOf('base64,') + 7); // Strip ['data:application/pdf;base64,'] from beginning of the string, leave remaining part. // Option 2: base64.split(',')[1];
            var _fileAttrFMT = {
                'filename': _.get(fileAttributes, 'filename', null),  // ['filename.pdf']
                'extension': _.get(fileAttributes, 'extension', null) // ['pdf']
            };

            $http.post(apiPath + 'utilities/filewrite', {base64String: _base64FMT, fileAttributes: _fileAttrFMT})
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });

            return deferred.promise;
        }
    })
;