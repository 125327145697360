angular.module('cerberus.admin')
    .controller('WorkspacesDateTimeCtrl', function WorkspaceDateTimeCtrl($scope, $location, workspaceSettingsFactory) {
        $scope.datetimeMaster = {};
        $scope.datetimeUser = {};
        $scope.datetimeLookup = {
            shortDate: [
                {format: 'y', display:'November, 2000'},
                {format: 'm', display:'November 06'}
            ],
            longDate: [
                {format: 'D', display:'Monday, November 06, 2000'},
                {format: 'd', display:'11/6/2000'}
            ],
            shortTime: [
                {format: 'hh:mm tt', display:'12:00 AM'},
                {format: 'HH:mmZ', display:'00:00Z'}
            ],
            longTime: [
                {format: 'hh:mm:ss tt', display:'12:00:00 AM'},
                {format: 'HH:mm:ss"Z"', display:'00:00:00Z'}
            ],
            shortDateTime: [
                {format: 'g', display:'11/6/2000 12:00 AM'},
                {format: 'G', display:'11/6/2000 12:00:00 AM'}
            ],
            longDateTime: [
                {format: 'F', display:'Monday, November 06, 2000 12:00:00 AM'},
                {format: 'u', display:'2000-11-06 00:00:00Z'}
            ],
            culture:[
                {format:'en-AU', display:"Australia"},
                {format:'en-CA', display:"Canada"},
                {format:'en-GB', display:"United Kingdom"},
                {format:'en-US', display:"United States of America"}
            ]
        };

        $scope.isUnchanged = _isUnchanged;
        $scope.save = _save;
        $scope.reset = _reset;

        _init();

        function _init(){
            workspaceSettingsFactory.getSettings('dateTimeShortDate,dateTimeLongDate,dateTimeShortTime,dateTimeLongTime,dateTimeShortDateTime,dateTimeLongDateTime,dateTimeCulture').then(function(settingsModel){
                $scope.datetimeMaster = angular.copy(settingsModel);
                $scope.datetimeUser = angular.copy($scope.datetimeMaster);
            });
        }

        function _isUnchanged(user){
            return angular.equals(user, $scope.datetimeMaster);
        }

        function _save(user){
            workspaceSettingsFactory.putSettings({settings:user}).then(function(){
                $location.url('/settings');
            });
        }

        function _reset(){
            $location.replace().url('/settings');
        }
    })
;