angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizChartAreaService
     * @alias cerberus/core:VizChartAreaService
     * @description Provides functions for configuring area charts
     */
    .factory('VizChartAreaService', function VizChartAreaService(){
        return {
            //configArea: configArea
        };

        /**
         * This function dynamically adjusts certain parameters for the chart settings
         * @function configArea
         * @param {object} data
         * @param {object} options
         * function configArea(data, options){}
         */

    })
;