angular.module('cerberus.core')
    .controller('nimVizScheduleGanttCtrl', function(_, moment, $scope, $timeout, VizScheduleGanttService, VizUtilityService, $firebaseObject, fbUtil) {
        var vm = this;
        $scope.mainPageObject.vizCtrl = vm;

        vm.exportICS = _.partial(VizScheduleGanttService.exportICS, $scope);

        //Firebase
        var initializing = true,
            debouncedRefresh = _.debounce(function () { _.result(vm, 'dataSource.read'); }, 5000, { leading: true, trailing: true }),
            unwatchFb = $firebaseObject(fbUtil.ref('queries/' + $scope.pageObject.viz.settings.dataSource.nim_viewId)).$watch(function(){
                if (initializing) {
                    //Stops first firing of the event
                    $timeout(function() { initializing = false; });
                } else {
                    debouncedRefresh();
                }
            });
        
        init();

        // Event Bindings
        var removeGanttSizeWatcher = angular.noop;
        $scope.$on('kendoWidgetCreated', function (event, widget) {
            if (widget === $scope.schedule) {
                event.preventDefault();
                event.stopPropagation();

                var list = widget.element.find('.k-gantt-treelist');
                
                if (!list.width() && widget.options.listWidth) {
                    list.width(widget.options.listWidth);
                }

                var debouncedResizeFunc = _.debounce(function () {
                    widget.resize();
                }, 400, { leading: true, trailing: true });
                removeGanttSizeWatcher = $scope.$watch(function(){
                    return widget.wrapper.width();
                }, debouncedResizeFunc);
            }
        });

        $scope.$on('nim-viz-reload', function (event) {
            event.preventDefault();
            debouncedRefresh();
        });

        $scope.$on('$destroy', function () {
            unwatchFb();     // Remove this watcher so it stops firing after the user has left the page
        });

        $scope.$on('nim-remove-page-objects', function () {
            if ($scope.schedule) {
                $scope.schedule.destroy();
                $scope.schedule = null;
            }

            removeGanttSizeWatcher();
        });

        function init() {
            var options = angular.copy($scope.pageObject.viz.settings);
            options.autoBind = false;
            options.columns = angular.copy(_.reject(options.columns, 'hidden'));

            var titleCol = _.find(options.columns, 'field', $scope.pageObject.viz.settings.dataSource.schema.model.title);

            if (titleCol) {
                titleCol.field = 'title';
            }

            vm.dataSource = VizScheduleGanttService.buildDataSource($scope, $scope.pageObject, options);
            options.dataSource = vm.dataSource;
            options.change = selectHandler;
            options.dataBound = dataBoundHandler;
            options.resizable = true;
            options.date = moment().startOf('week').toDate();

            options.tooltip = {
                template: '<div class="#=styles.taskDetails#">' +
                '<strong>#=task.title#</strong>' +
                '<div class="#=styles.taskDetailsPercent#">#=kendo.toString(task.percentComplete, "p0")#</div>' +
                '<ul class="#=styles.reset#">' +
                '<li>#=messages.start#: #=kendo.toString(task.start, "ddd, MMM d")#</li>' +
                '<li>#=messages.end#: #=kendo.toString(task.end, "ddd, MMM d")#</li>' +
                '</ul>' +
                '</div>'
            };

            // Expose Options to Scope
            vm.options = options;

            VizUtilityService.subscribeToPageFilters($scope.pageObject.filterMap, $scope, vm.dataSource);
        }

        function selectHandler(e) {
            var selected = e.sender.select();
            if($scope.pageObject.params.openOnSelect && selected.length){
                var uid = selected.data('uid');
                var dataItem = e.sender.dataSource.getByUid(uid);

                VizScheduleGanttService.viewRecord(dataItem.id/*, navFunctions*/);
            }
        }

        function dataBoundHandler(e) {
            resizeGantt(e.sender);
        }

        function resizeGantt(gantt) {
            if (gantt) {
                var list = gantt.element.find('.k-gantt-treelist'),
                    timeline = gantt.element.find('.k-gantt-timeline'),
                    splitbar = gantt.element.find('.k-splitbar');
                
                if (!gantt.options.height) {
                    gantt.element.css('height', '').find('.k-gantt-layout, .k-grid-content').css('height', '');

                    var listHeight = list.height();
                    var timelineHeight = timeline.height();
            
                    splitbar.css('height', Math.max(listHeight, timelineHeight));
                }

                if ($scope.resizeGridsterItem) {
                    $timeout(function () {
                        $scope.resizeGridsterItem();
                    }, 10);
                }
            }    
        }
    })
;