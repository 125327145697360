/**
 * EXAMPLE
 ***Controller:
 *
 ***Template
 *
 *
 */
angular.module('cerberus.ui')
    .controller('NimRecurrenceCtrl', function NimRecurrenceCtrl(_, RRule, $scope, $rootScope, $timeout, $attrs, RecurrencesService) {
        var vm = this;

        // Changed $scope.model to instance instead of just recurrence
        vm.recurrence = angular.copy($scope.model.recurrence);
        vm.instanceId = $scope.$eval($attrs.nimRecurrenceId);
        vm.monthRepeatBy = '';
        vm.weekdays = RecurrencesService.weekdays();
        vm.frequencies = RecurrencesService.frequencies();

        $scope.$watch('vm.recurrence.rrule', function(newVal, oldVal){
            if(newVal != oldVal){
                var rule = new RRule(angular.copy(vm.recurrence.rrule));
                vm.recurrence.summary = rule.toText();
            }
        },true);

        vm.toggleWeekDaysSelect = function(day){
            var idx =  vm.recurrence.rrule.byweekday.map(function(e) { return e.weekday; }).indexOf(day.value.weekday);
            if(idx > -1){
                day.selected = false;
                vm.recurrence.rrule.byweekday.splice(idx,1);
            }
            else{
                day.selected = true;
                vm.recurrence.rrule.byweekday.push(day.value);
            }
        };
        vm.toggleMonthRepeatBy = function(val){
            if(val === 'dayMonth'){
                vm.recurrence.rrule.byweekday = [];
                vm.recurrence.rrule.bymonthday = vm.recurrence.rrule.dtstart.getDate();
            }
            else if(val === 'dayWeek'){
                vm.recurrence.rrule.bymonthday = null;
                var day = vm.recurrence.rrule.dtstart.getDay() - 1;
                if(day < 0){
                    day = 6;
                }
                var nthDay = Math.ceil(vm.recurrence.rrule.dtstart.getDate()/7);
                vm.recurrence.rrule.byweekday = [vm.weekdays[day].value.nth(nthDay)];
            }
            else{
                vm.recurrence.rrule.byweekday = [];
                vm.recurrence.rrule.bymonthday = null;
            }
        };

        vm.toggleFreq = function(){
            if(vm.recurrence.rrule.freq === 'weekday'){
                vm.recurrence.rrule.freq = RRule.WEEKLY;
                vm.recurrence.rrule.byweekday = [RRule.MO,RRule.TU,RRule.WE,RRule.TH,RRule.FR];
                setWeekdaysArray(true,5);
            }
            else{
                vm.recurrence.rrule.byweekday = [];
                vm.recurrence.rrule.dtstart = new Date();
                setWeekdaysArray(false,vm.weekdays.length);
            }
        };

        vm.close = function(){
            $scope.$parent.instanceRecurrence.close();
        };

        vm.deleteRecurrence = function(recur){
            if(recur.id){
                RecurrencesService.deleteRecurrence(recur.id).then(function(){
                    $scope.model.recurrence = null;
                    vm.close();
                });
            }
            else {
                $scope.model.recurrence = null;
                vm.close();
            }
        };

        vm.ok = function(recur){
            if(vm.instanceId && !recur.id){
                recur.id = vm.instanceId;
            }

            $scope.model.recurrence = angular.copy(recur);
            if(recur.id){
                RecurrencesService.update(recur.id,recur).then(function(){
                    vm.close();
                });
            }
            vm.close();
        };
        _init();
        ////////////////////
        /**
         * Init controller data and server calls
         */
        function setWeekdaysArray(selected,len){
            for(var i = 0; i < len; i++){
                vm.weekdays[i].selected = selected;
            }
        }
        function _init() {
            if(_.isEmpty(vm.recurrence)){
                vm.recurrence = {
                    rrule: {
                        freq: RRule.DAILY,
                        byweekday: [],
                        dtstart: new Date($scope.startDate)
                    },
                    type: 'users',
                    groups: {},
                    summary: 'every day'
                };
            }
            else{
                //Make sure the summary works
                for(var i = 0; i < vm.recurrence.rrule.byweekday.length; i++){
                    for(var j = 0; j < vm.weekdays.length; j++){
                        if(vm.recurrence.rrule.byweekday[i].weekday == vm.weekdays[j].value.weekday){
                            vm.weekdays[j].selected = true;
                            vm.recurrence.rrule.byweekday[i] = vm.weekdays[j].value;
                        }
                    }
                }
                vm.recurrence.rrule.dtstart = new Date(vm.recurrence.rrule.dtstart);
                if(vm.recurrence.rrule.until){
                    vm.recurrence.rrule.until = new Date(vm.recurrence.rrule.until);
                }
                var rule = new RRule(angular.copy(vm.recurrence.rrule));
                vm.recurrence.summary = rule.toText();
            }
        }

        $scope.$on('auth:loginCancelled', function () {
            $timeout(function() {
                vm.close();
            });
        });
    })
    .directive('nimRecurrence', function nimRecurrence() {
        return {
            restrict: 'AE',
            scope: {
                model: '=nimRecurrence',
                startDate: '=startDate'
            },
            templateUrl: 'ui/nim-recurrence/nim-recurrence.tpl.html',
            controller: 'NimRecurrenceCtrl',
            controllerAs: 'vm'
        };
    })
;