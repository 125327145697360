angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizScheduleGanttService
     * @alias cerberus/core:VizScheduleGanttService
     * @description Provides functions for configuring Gantt charts
     */
    .factory('VizScheduleGanttService', function VizScheduleGanttService(_, ics, moment, kendo, $http, $timeout, $window,
                                                                         InstancesWindowService, VizUtilityService){
        return {
            buildDataSource: buildDataSource,
            configGantt: configGantt,
            viewRecord: viewRecord,
            exportICS: exportICS
        };

        /**
         * Builds ganttDataSource and modifies Gantt chart settings
         * @param scope
         * @param pageObject
         * @param scheduleOptions
         * @returns {kendo.data.GanttDataSource}
         */
        function buildDataSource(scope, pageObject, scheduleOptions) {
            var dependencyDataSource = new kendo.data.GanttDependencyDataSource();
            scheduleOptions.dependencies = dependencyDataSource;

            var dataSourceOptions = {
                type: 'odata',
                transport: {
                    read: read
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                serverAggregates: true,
                serverGrouping: false,
                change: function (e) {
                    var data = e.sender.data();
                    if (scope.schedule) {
                        // Adjust and set options
                        if(configGantt) {
                            configGantt(data, scope.pageObject.viz.settings, dependencyDataSource);
                        }
                    }
                    scope.data = data;
                    scope.mainPageObject.vizCtrl.data = data;
                },
                requestStart: function (e){
                    if(!_.isEmpty(pageObject.params.requiredFilters)){
                        _.forEach(pageObject.params.requiredFilters, function(required, filter){
                            if(required && VizUtilityService.isFilterEmpty(scope.filters[filter])){
                                e.preventDefault();

                                $timeout(function(){
                                    e.sender.success({d:{results: [], __count: 0}});
                                });

                                return false;
                            }
                        });
                    }
                },
                requestEnd: function (e) {
                    if (e.response) {
                        var model = scope.pageObject.viz.settings.dataSource.schema.model,
                            data = [];
                        
                        if (e.response.d.__count == 1) {
                            data = [e.response.d];
                        }
                        else {
                            data = e.response.d.results;
                        }

                        setGanttFields(model, data);
                    }

                    if (scope.schedule) {
                        $timeout(function(){
                            scope.schedule.refresh();
                        });
                    }
                }

            };

            var sortOptions = {
                sort: VizUtilityService.createSortArray(scheduleOptions.dataSource)
            };

            var baseFilter = angular.copy(pageObject.viz.settings.dataSource.filter) || {
                filters: [],
                logic: 'and'
            };

            var filterOptions = {
                filter: {
                    filters: [baseFilter],
                    logic: pageObject.params.filterLogic || 'and'
                }
            };

            if(scheduleOptions && scheduleOptions.dataSource) {
                angular.extend(dataSourceOptions, scheduleOptions.dataSource, sortOptions, filterOptions);
            }

            // Adds parse function to make sure date fields are correct
            var schemaModel = dataSourceOptions.schema.model;
            _.forEach(schemaModel.fields, function (fieldObj) {
                if(fieldObj.type === 'date') {
                    fieldObj.parse = VizUtilityService.parseDateField;
                }
            });

            return new kendo.data.GanttDataSource(dataSourceOptions);

            function read(options) {
                var httpOptions = VizUtilityService.buildBaseHttpOptions(pageObject, scope.isPreview);
                httpOptions.params = VizUtilityService.buildHttpParams(scope, dataSourceOptions, options);

                $http(httpOptions).then(function (result) {
                    options.success(result.data);
                }, function (result) {
                    options.error(result);
                });
            }
        }

        /**
         * Modifies data so parent tasks show sub-tasks
         * @param data
         */
        function configGantt(data, options, dependencyDataSource) {
            var model = options.dataSource.schema.model,
                dependencies = [];

            for (var d = 0; d < data.length; d++) {
                var dataItem = data[d];

                // Default Date-Time rules
                var hasStartTime = dataItem.start && dataItem.start instanceof Date;
                var hasEndTime = dataItem.end && dataItem.end instanceof Date;
                var defaultOffsetValue = 30;
                var defaultOffsetUnit = 'm';

                if(hasStartTime && hasEndTime){
                    var start = moment(dataItem.start.toISOString());
                    var end = moment(dataItem.end.toISOString());

                    // Make sure end time is not before start time
                    if(end.diff(start) < 0){
                        dataItem.end = start.add(defaultOffsetValue, defaultOffsetUnit).toDate();
                    }
                }
                else if(hasStartTime){
                    // If record has start and no end, set end to [defaultOffset] after start time
                    dataItem.end = moment(dataItem.start.toISOString()).add(defaultOffsetValue, defaultOffsetUnit).toDate();
                }
                else if(hasEndTime){
                    // If record has end and no start, set start to [defaultOffset] before end time
                    dataItem.start = moment(dataItem.end.toISOString()).subtract(defaultOffsetValue, defaultOffsetUnit).toDate();
                }
                else{
                    // If neither time is set, remove from data
                    // Lack of start time breaks gantt chart
                    data.splice(d, 1);
                    d--;
                    continue;
                }

                if (model.dependency && dataItem[model.dependency]) {
                    dependencies.push({
                        id: d,
                        predecessorId: dataItem[model.dependency],
                        successorId: dataItem.id,
                        type: 1
                    });
                }
            }

            dependencyDataSource.data(dependencies);
        }

        function setGanttFields(model, data) {

            _.forEach(data, function (dataItem) {
                parseFrom(model, 'title', dataItem);
                parseFrom(model, 'parentId', dataItem);
                parseFrom(model, 'start', dataItem);
                parseFrom(model, 'end', dataItem);
                parseFrom(model, 'percentComplete', dataItem);
                dataItem.orderId = 0;
                // dataItem.orderId = index;

                if (model.wbs) {
                    findParentViaWBS(dataItem, model, data);
                }
                else if(model.parentId) {
                    var hasChildren = !!(_.find(data, model.parentId, dataItem.id));

                    if (hasChildren) {
                        dataItem.summary = true;
                        dataItem.expanded = model.fields.expanded.defaultValue;
                    }
                }    
            });
        }

        function parseFrom(model, field, dataItem) {
            if (model[field]) {
                var val = angular.copy(_.get(dataItem, model[field], ''));
                
                if (model.fields[field].type === 'date') {
                    val = VizUtilityService.parseDateField(val);
                }

                dataItem[field] = val;
            }
        }

        function findParentViaWBS(dataItem, model, data) {
            var wbsField = model.wbs;
            var wbs = _.get(dataItem, wbsField, '');

            if (wbs) {
                var wbsComponents = wbs.toString().split('.');
                dataItem.orderId = wbsComponents.pop();

                if (wbsComponents.length) {
                    var parentWBS = wbsComponents.join('.');
                    var parent = _.find(data, function (item) {
                        return item[wbsField] == parentWBS;
                    });

                    if (parent) {
                        dataItem.parentId = parent.id;

                        parent.summary = true;
                        parent.expanded = model.fields.expanded.defaultValue;
                    }
                }
            }
        }

        /**
         * Opens selected record in instance window
         * @function viewRecord
         * @param id
         * @param navFunctions
         */
        function viewRecord(id, navFunctions) {
            InstancesWindowService.openWindow({
                action: 'read',
                instanceId: id,
                navFunctions: navFunctions
            });
        }

        // Exports Calendar data (current view) to .ics
        function exportICS(scope){
            var cal = ics();

            _.forEach(scope.schedule.dataSource.data(), function(event){
                // addEvent REQUIRES the following arguments:
                // @param {string} subject     Subject/Title of event
                // @param {string} description Description of event
                // @param {string} location    Location of event
                // @param {string} begin       Beginning date of event
                // @param {string} stop        Ending date of event
                cal.addEvent(event.title || '(Untitled)', event.description || '', '', event.start, event.end);
            });

            if(cal.events().length > 0) {
                var content = cal.calendar();
                var blob = new Blob([content], {type: "text/plain;charset=utf-8"});

                $window.saveAs(blob, scope.pageObject.name + '.ics');
            }
        }
    })
;