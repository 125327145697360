angular.module('cerberus.cpc')
    .controller('initiateEDI_RollInputOpenModalCtrl', function(_, $scope, $uibModalInstance){
        var vm = this;

        vm.submit = submit;
        vm.cancel = cancel;

        // Submit button execution
        function submit() {
            $uibModalInstance.close();
        }

        // Cancel button execution
        function cancel() {
            $uibModalInstance.dismiss();
        }
    })
;