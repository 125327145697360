angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name pageObjectService
     * @alias cerberus/core:pageObjectService
     * @description Utilities for the nimPageObject directive
     */
    .factory('pageObjectsService', function pageObjectsService(_, apiPath, kendo, $location, $window, $http, $q, $log, $sce, $uibModal,
                                                               ConfirmModalService, InstancesService, InstancesWindowService) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';
        var service = {
            buildHtml: buildHtml,
            buildToolbar: buildToolbar,
            deleteInstance: deleteInstance,
            reloadViz: reloadViz,
            printPageObject: printPageObject,
            buttonClickHandler: buttonClickHandler,
            getPageObject: getPageObject,
            getPageObjects: getPageObjects,
            getUnusedPageObjects: getUnusedPageObjects,
            setHtml: setHtml,
            escapePoundSign: escapePoundSign,
            addCustomValuesToDefaultData: addCustomValuesToDefaultData,
            openUploadModal: openUploadModal,
            openMassImportModal: openMassImportModal
        };
        return service;
        ////////////////////////
        /**
         * Given a PageObject this function will build the appropriate
         * Kendo UI Toolbar options object
         * @param type - viz | tabset | button
         * @param wrapperType - tile | portlet | circleTile
         * @param vizType - chart | static | table
         * @param vizSubType - bullet | bar | donut
         * @param vizSubSubType
         * @return {string} of HTML
         */
        function buildHtml(type, wrapperType, vizType, vizSubType, template) {
            // Set Defaults (f*ck you javascript)
            vizType = vizType || '';
            vizSubType = vizSubType || '';
            template = template || '';

            // Init return variable
            var html = '';

            // Set Controller Name
            var typeName = toSentenceCase(vizType);
            var vizControllerName = 'nimViz' + typeName + 'Ctrl';
            if (vizSubType && vizSubType !== '') {
                var subTypeName = toSentenceCase(vizSubType);
                vizControllerName = 'nimViz' + typeName + subTypeName + 'Ctrl';
            }

            // Build Viz HTML
            if (type === 'viz') {
                html = '<div class="nim-wrapper" nim-wrapper="' + wrapperType + '">' +
                '    <div nim-viz-' + vizType + ' ' +
                '         sub-type="' + vizSubType + '" ' +
                '         template="' + template + '"' +
                '         controller-name="' + vizControllerName + '">' +
                '    </div>' +
                '</div>';
            }

            // Build Tabs HTML
            else if (type === 'tabset') {
                html = '<div class="nim-wrapper" nim-wrapper="' + wrapperType + '">' +
                '   <div uib-tabset type="{{::pageObject.params.type}}" justified="pageObject.params.justified" vertical="pageObject.params.vertical">' +
                '       <div uib-tab ng-repeat="tab in ::pageObject.params.tabs" ng-init="$index === 0 ? tab.active = true : tab.active = false" active="tab.active" deselect="$broadcast(\'nim-remove-page-objects\')">' +
                '           <div uib-tab-heading>' +
                '               <i class="fa fa-{{::tab.icon}}"></i><span ng-bind="::tab.text"></span>' +
                '           </div>' +
                '           <div ng-include="tab.active ? \'core/pages/pages-nested.tpl.html\' : null" ng-controller="PagesNestedCtrl" onload="getPage(tab.link)"></div>' +
                '       </div>' +
                '   </div>' +
                '</div>';
            }

            // Build Buttons HTML
            else if (type === 'button') {
                html =
                    //'<div nim-wrapper="' + wrapperType + '">' +
                    '<div ng-style="::{\'margin\': pageObject.wrapper.style.margin}" ng-class="::{\'pull-right\': pageObject.params.right}">' +
                    '   <div class="btn-toolbar">' +
                    '       <div class="btn-group" ng-repeat="button in ::pageObject.params.buttons | filter:{type:\'!filter\'}" uib-dropdown>' +
                    '           <button ng-if="::button.type===\'single\' || button.type===\'split\'" type="button" class="btn btn-{{::button.style}}" ng-click="buttonClick(button, filters)">' +
                    '               <i class="fa fa-{{::button.icon}}"></i>' +
                    '               <span ng-if="::button.icon !== \'\' && button.label !== \'\'">&nbsp;</span>' +
                    '               {{::button.label}}' +
                    '           </button>' +
                    '           <button ng-if="::button.type===\'dropdown\' || button.type===\'split\'" type="button" class="btn btn-{{::button.style}} dropdown-toggle" data-toggle="dropdown" uib-dropdown-toggle aria-expanded="false">' +
                    '               <span ng-if="::button.type===\'dropdown\'">' +
                    '                   <i class="fa fa-{{::button.icon}}"></i>' +
                    '                   <span ng-if="::button.icon !== \'\' && button.label !== \'\'">&nbsp;</span>' +
                    '                   {{::button.label}}' +
                    '                   <span ng-if="::button.icon !== \'\' || button.label !== \'\'">&nbsp;</span>' +
                    '               </span>' +
                    '               <span class="caret"></span>' +
                    '           </button>' +
                    '           <ul ng-if="::button.type===\'dropdown\' || button.type===\'split\'" uib-dropdown-menu role="menu">' +
                    '               <li ng-repeat="item in ::button.items">' +
                    '                   <a href ng-click="buttonClick(item, filters)">' +
                    '                       <i class="fa fa-{{::item.icon}}"></i>' +
                    '                       <span ng-show="::item.icon !== \'\'">&nbsp;</span>' +
                    '                       {{::item.label}}' +
                    '                   </a>' +
                    '               </li>' +
                    '           </ul>' +
                    '       </div>' +
                    '   </div>' +
                    '   <div ng-repeat="button in ::pageObject.params.buttons | filter:{type:\'filter\'}" ' +
                    '        nim-filter-form="filters" ' +
                    '        nim-default-filters="defaultFilter" ' +
                    '        id="{{::button.id}}" ' +
                    '        btn-class="::button.style" ' +
                    '        type="::button.action" ' +
                    '        label="::button.label" ' +
                    '        icon="::button.icon" ' +
                    '        params="::button.filterParams"></div>' +
                    '</div>';
            }
/*            else if (type === 'filter') {
                '<div ng-style="{\'margin\': pageObject.wrapper.style.margin}" ng-class="{\'pull-right\': pageObject.params.right}">' +
                '   <div class="form-group">' +
                '       <label>' +
                '           <i class="fa fa-{{pageObject.params.icon}}"></i>' +
                '           <span ng-if="pageObject.params.icon !== \'\' && pageObject.params.label !== \'\'">&nbsp;</span>' +
                '           {{pageObject.params.label}}' +
                '       </label>' +
                '       <div nim-filter="filters" params="{{pageObject.params.filterParams}}"></div>' +
                '   </div>' +
                '</div>';
            }*/

            return html;
        }

        /**
         * @param text
         * @returns {string}
         */
        function toSentenceCase(text){
            var sentence = '';
            if(text && text.length > 0){
                sentence += text.charAt(0).toUpperCase();
                if(text.length > 1){
                    sentence += text.substr(1, text.length - 1).toLowerCase();
                }
            }
            return sentence;
        }

        /**
         * Given a PageObject this function will build the appropriate
         * Kendo UI Toolbar options object
         * @param {object} tools - PageObject.tools property
         * @param {object} callbacks - functions definitions for various actions
         * @return {object}
         */
        function buildToolbar(tools, callbacks) {
            var options = {
                items: []
            };
            if (tools.showTemplate && tools.template && angular.isFunction(callbacks.templateFunc)) {
                options.items.push({
                    id: 'toolbarTemplate',
                    template: callbacks.templateFunc
                });
            }
            if (tools.create && angular.isFunction(callbacks.createCb)) {
                options.items.push({
                    type: 'button',
                    text: 'New',
                    click: callbacks.createCb
                });
            }
            if (tools.exportXLS && angular.isFunction(callbacks.exportXLSCb)) {
                options.items.push({
                    type: 'splitButton',
                    text: '<i class="fa fa-file-excel-o"></i>&nbsp;Export Current Page',
                    click: callbacks.exportXLSCb,
                    menuButtons: [
                        {
                            type: 'button',
                            text: 'ALL (CSV)',
                            click: callbacks.exportAllXLSCb
                        }
                    ]
                });
            }
            if(tools.exportICS && angular.isFunction(callbacks.exportICSCb)){
                options.items.push({
                    type: 'button',
                    text: '<i class="fa fa-calendar"></i>&nbsp;Export Schedule',
                    click: callbacks.exportICSCb
                });
            }
            if (tools.delete && angular.isFunction(callbacks.deleteCb)) {
                options.items.push({
                    type: 'button',
                    text: 'Delete',
                    click: callbacks.deleteCb
                });
            }
            if (tools.exportPDF && angular.isFunction(callbacks.exportPDFCb)) {
                options.items.push({
                    type: 'button',
                    text: '<i class="fa fa-file-pdf-o"></i>&nbsp;Export Selected to PDF',
                    click: callbacks.exportPDFCb
                });
            }
            if (tools.print && angular.isFunction(callbacks.printCb)) {
                options.items.push({
                    type: 'button',
                    text: '<i class="fa fa-print"></i>&nbsp;Print',
                    attributes: {
                        "ng-disabled": "pageObject.viz.type==='table' && !mainPageObject.vizCtrl.selectedIds.length"
                    },
                    click: callbacks.printCb
                });
            }
            if (tools.printLabels && angular.isFunction(callbacks.printLabelsCb)) {
                options.items.push({
                    type: 'button',
                    text: '<i class="fa fa-print"></i>&nbsp;Print Labels',
                    attributes: {
                        "ng-disabled": "pageObject.viz.type==='table' && !mainPageObject.vizCtrl.selectedIds.length"
                    },
                    click: callbacks.printLabelsCb
                });
            }
            if (tools.printChecks && angular.isFunction(callbacks.printChecksCb)) {
                options.items.push({
                    type: 'button',
                    text: '<i class="fa fa-print"></i>&nbsp;Print Checks',
                    attributes: {
                        "ng-disabled": "pageObject.viz.type==='table' && !mainPageObject.vizCtrl.selectedIds.length"
                    },
                    click: callbacks.printChecksCb
                });
            }
            if (tools.upload && angular.isFunction(callbacks.uploadFilesCb)) {
                options.items.push({
                    type: 'button',
                    text: '<i class="fa fa-upload"></i>&nbsp;Upload',
                    attributes: {},
                    click: callbacks.uploadFilesCb
                });
            }
            if (tools.refresh && angular.isFunction(callbacks.refreshCb)) {
                options.items.push({
                    type: 'button',
                    text: '<i class="fa fa-refresh"></i>&nbsp;Refresh',
                    overflow: 'always',
                    click: callbacks.refreshCb
                });
            }
            if (tools.massimport && angular.isFunction(callbacks.massImportCb)) {
                options.items.push({
                    type: 'button',
                    text: '<i class="fa fa-random"></i>&nbsp;Mass Import',
                    overflow: 'always',
                    click: callbacks.massImportCb
                });
            }
            /* return */
            return options;
        }

        ///**
        // * Pushes a separator item into a Kendo Toolbar
        // * @param arr - Kendo toolbar options.items array
        // */
        //function addToolbarSeperator(arr) {
        //    if (arr.length && arr[arr.length - 1].type !== 'separator') {
        //        arr.push({type: 'separator'});
        //    }
        //}

        /**
         * Facade method to delete one more more instances
         * @param selectedRows
         * @param confirmCb
         */
        function deleteInstance(selectedRows, confirmCb) {
            InstancesWindowService.deleteInstanceModal(selectedRows, confirmCb);
        }

        /**
         * Convenience function to broadcast a Viz reload event
         * @param scope
         */
        function reloadViz(scope) {
            scope.$broadcast('nim-viz-reload');
        }

        function printPageObject(html){
            var $printSection = angular.element("print-section");
            $printSection.empty().append(html);
            $window.print();
        }

        /**
         * Click handler for button page object
         * @param button - configuration for the button
         * @param button.action - route/link/create
         * @param button.targetId - id of target page/widget or URL
         * @param filters - page filters
         */
        function buttonClickHandler(button, filters){
            switch(button.action){
                case 'route': // Routes to target page in current tab
                    $location.url(button.targetId);
                    break;
                case 'link': // Opens target URL in new tab
                    $window.open(button.targetId, '_blank');
                    break;
                case 'create': // Creates new instance for target widget
                    buttonCreateHandler(button, filters);
                    break;
                default:
                    break;
            }
        }

        function buttonCreateHandler(button, filters) {
            var defaultDataArray = [];

            //Default Data Check for filters
            if (button.defaultData) {
                _.forEach(button.defaultData, function (defaultItem) {
                    if (filters[defaultItem.value]) {
                        defaultDataArray.push({
                            data: filters[defaultItem.value].filters[0],
                            field: defaultItem.formField
                        });
                    }
                });
            }

            InstancesWindowService.openWindow({ action: button.action, widgetId: button.targetId, defaultData: defaultDataArray });
        }

        function setHtml(template, compileData){
            try{
                var html = '';
                var escapedTemplate = escapePoundSign(template);

                html = kendo.template(escapedTemplate)(compileData);
                html = html.replace(/\\#/g, '#');

                return $sce.trustAsHtml(html);
            }
            catch(e){
                $log.error(e, compileData);
                return '';
            }
        }

        /**
         * Escapes pound signs so they don't cause an error when the template string is compiled by kendo
         * @param templateString
         * @returns {string}
         */
        function escapePoundSign(templateString){
            var htmlAttributes = templateString.match(/="(?!#:|#=)[^"]+"/gm),
                escapedString = templateString;

            _.forEach(htmlAttributes, function(subString){
                escapedString = escapedString.replace(subString, subString.replace(/#/g, '\\#'));
            });

            return escapedString;
        }

        function getPageObject(id){
            var deferred = $q.defer();
            $http.get(apiPath + 'page/objects/' + id)
                .success(function(value){
                    deferred.resolve(parsePageObject(value.DATA));
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getPageObjects() {
            var deferred = $q.defer();
            $http.get(apiPath + 'page/objects')
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function getUnusedPageObjects() {
            var deferred = $q.defer();
            $http.get(apiPath + 'page/objects/unused')
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function parsePageObject(data){
            var pageObject = angular.copy(data);

            var json = angular.copy(pageObject.json_config);
            delete pageObject.json_config;
            pageObject = angular.extend(pageObject, json);

            return pageObject;
        }
        /**
         *
         * @param defaultDataArray
         * @param customValueMap
         */
        function addCustomValuesToDefaultData(defaultDataArray, customValueMap){
            if(defaultDataArray && customValueMap){
                _.forEach(customValueMap, function(jsonValue, modelId){
                    if(!_.find(defaultDataArray, 'field.modelId', modelId)){
                        if(jsonValue) {
                            defaultDataArray.push({
                                data: {
                                    value: angular.fromJson(jsonValue)
                                },
                                field: {
                                    modelId: modelId
                                }
                            });
                        }
                    }
                });
            }
        }

        function openUploadModal(pageObject, defaultData) {
            var modal = $uibModal.open({
                templateUrl: 'core/pages/viz/list/list-upload-modal.tpl.html',
                controller: 'ListUploadModalCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    defaultData: _.constant(defaultData),
                    listPageObject: _.constant(pageObject)
                }
            });

            return modal.result;
        }

        function openMassImportModal(pageObject){
            var modal = $uibModal.open({
                templateUrl: 'core/pages/viz/table/massimport-upload-modal.tpl.html',
                controller: 'MassimportUploadModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    listPageObject: _.constant(pageObject),

                    _wait: 'WaitForUserData',
                    authToken: function(WaitForUserData, $rootScope){
                        return WaitForUserData.then(function(){
                            return $rootScope.authToken;
                        });
                    },
                    workspaceId: function(WaitForUserData, $rootScope){
                        return WaitForUserData.then(function(){
                            return $rootScope.userData.currentWorkspace.id;
                        });
                    }
                }
            });

            return modal.result;
        }
    })
;