angular.module('cerberus.admin')
    .controller('FormsDesigner_numberCtrl', function FormsDesigner_numberCtrl(_, $scope, $uibModalInstance, $timeout, $window, autoTabIndex, currentFormObjects,
                                                                              editFlag, formObj, formOriginId, idArray, sections, widgetId,
                                                                              DesignerUtilityService, FormsDesignerListService, WidgetsSettingsService) {
        // Populate Scope
        $scope.temp = angular.copy(formObj);
        $scope.orig = angular.copy(formObj);
        $scope.editFlag = editFlag;
        $scope.autoTabIndex = autoTabIndex;
        $scope.tabsTemplate = FormsDesignerListService.formObjects();
        $scope.formFields = [];
        $scope.formFieldObjects = [];
        $scope.conditionOptions = [];
        $scope.sections = sections;
        $scope.defaultValueOptions = buildDefaultValueOptions(formObj.model);
        $scope.conditionalActions = FormsDesignerListService.conditionalActions();
        $scope.conditionType = '';
        $scope.conditionOperators = DesignerUtilityService.conditionOperators();
        $scope.conditionBooleanValues = DesignerUtilityService.conditionBooleanValues();
        $scope.conditionActionValues = DesignerUtilityService.conditionActionValues();
        $scope.conditionFilter = DesignerUtilityService.conditionFilter(formOriginId, formObj.model.config.modelId);
        $scope.getConditionType = DesignerUtilityService.getConditionType;
        $scope.addCondition = DesignerUtilityService.addCondition($scope.temp, formOriginId);
        $scope.rebuildDefaultValue = rebuildDefaultValue;

        // UI Helper Functions
        $scope.preventSpace = DesignerUtilityService.preventSpace;
        $scope.labelToUniqueId = function (item){
            if (!$scope.orig.model.config.modelId) {
                item.model.config.modelId = DesignerUtilityService.labelToUniqueId(item.model.label.text, idArray);
            }
        };

        $scope.setMaskAndDec = function(value){
            var decimalIndex = value.indexOf('.');
            var decimal = 0;
            if(decimalIndex >= 0){
                decimal = value.length - 1 - decimalIndex;
            }
            $scope.temp.model.param.decimals = decimal;
            $scope.temp.model.display.format = value.replace(/[1-9]/g, '0');
        };

        $scope.deleteArrayObject = function(array,index){
            array.splice(index, 1);
        };

        // Modal Actions
        $scope.ok = function (){
            $uibModalInstance.close($scope.temp);
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function getFormFieldObjects(fieldArray){
            var objects = [];
            for(var f = 0; f < fieldArray.display.length; f++){
                var form = fieldArray.display[f];
                for(var o = 0; o < form.objects.length; o++){
                    var object = form.objects[o];
                    object.formId = form.originId;
                    object.realFormId = form.formId;
                    objects.push(object);
                }
            }
            return objects;
        }

        // Init
        init();

        function init(){
            WidgetsSettingsService.getFormFields(widgetId).then(function(formFieldArray){
                DesignerUtilityService.mergeFieldsWithCurrentForm(formFieldArray, currentFormObjects);

                $scope.formFields = formFieldArray;
                $scope.formFieldObjects = getFormFieldObjects(formFieldArray);

                var conditionOptions = _.sortBy(_.filter($scope.formFieldObjects, $scope.conditionFilter), 'label');
                $scope.conditionOptions = [{ label: '(Record Action)', modelId: '__nimRecordAction' }].concat(conditionOptions);

                // Grid
                $scope.mainGridOptions = DesignerUtilityService.formGrid(formFieldArray.display);
                $scope.detailGridOptions = DesignerUtilityService.formDetailGrid($scope);

                // Codemirror
                $scope.codemirrorLoaded = DesignerUtilityService.codemirrorLoaded(formFieldArray.operands);
            });

            // Hack
            DesignerUtilityService.focusFirstElement('configForm');
        }

        function buildDefaultValueOptions(model){
            var options = {
                decimals: model.param.decimals,
                max: model.validation.max,
                min: model.validation.min,
                step: model.param.step,
                placeholder: model.display.placeholder
            };

            if(model.display.format){
                options.format = model.display.format;
            }

            return options;
        }

        function rebuildDefaultValue(model){
            $scope.defaultValueOptions = null;

            $timeout(function(){
                $scope.defaultValueOptions = buildDefaultValueOptions(model);
            });
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;