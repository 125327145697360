/*jshint bitwise: false*/
/**
 * @module ui/NimValidators
 * @description Validators the for ngModel directive
 */
angular.module('cerberus.ui')
    .directive('nimPassword', function() {
        var regex = /(?=^.{8,50}$)(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*$/;
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (value) {
                    var status = true;
                    status = status && regex.test(value);
                    ngModel.$setValidity('nimPassword', status);
                    return value;
                });
            }
        };
    })
    .directive('nimWidgetUniqueId', function () {
        var regex = /^[A-Za-z][A-Za-z0-9]{2,30}$/;
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                // Watch Model for changes
                scope.$watch(attrs.ngModel, function (value) {
                    testWidgetId(value);
                });
                ngModel.$parsers.push(function (value) {
                    testWidgetId(value);
                    return value;
                });
                function testWidgetId(value) {
                    var status = true;
                    status = status && regex.test(value);
                    ngModel.$setValidity('nimWidgetUniqueId', status);
                }
            }
        };
    })
    .directive('nimModelId', function () {
        var regex = /^[A-Za-z][A-Za-z0-9]{2,30}$/;
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                // Watch Model for changes
                scope.$watch(attrs.ngModel, function (value) {
                    testModelId(value);
                });
                ngModel.$parsers.push(function (value) {
                    testModelId(value);
                    return value;
                });
                function testModelId(value) {
                    var status = true;
                    status = status && regex.test(value);
                    ngModel.$setValidity('nimModelId', status);
                }
            }
        };
    })
    .directive('nimIp', function () {
        var regex = /^(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))$/;
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                // Watch Model for changes (presumably from controller)
                scope.$watch(attrs.ngModel, function (value) {
                    testIp(value);
                });
                ngModel.$parsers.push(function (value) {
                    testIp(value);
                    return value;
                });
                function testIp(value) {
                    var status = true;
                    status = status && regex.test(value);
                    ngModel.$setValidity('nimIp', status);
                }
            }
        };
    })
    .directive('nimFqdn', function () {
        var regex = /^[A-Za-z0-9][A-Za-z0-9\-]{1,62}[A-Za-z0-9]$/;
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                // Watch Model for changes (presumably from controller)
                scope.$watch(attrs.ngModel, function (value) {
                    testFqdn(value);
                });
                ngModel.$parsers.push(function (value) {
                    testFqdn(value);
                    return value;
                });
                function testFqdn(value) {
                    var status = true;
                    status = status && regex.test(value);
                    ngModel.$setValidity('nimFqdn', status);
                }
            }
        };
    })
    .directive('nimWorkspacePath', function () {
        var regex = /^[A-Za-z0-9][A-Za-z0-9\-\_]{1,30}[A-Za-z0-9]$/;
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                // Watch Model for changes (presumably from controller)
                scope.$watch(attrs.ngModel, function (value) {
                    testPath(value);
                });
                ngModel.$parsers.push(function (value) {
                    testPath(value);
                    return value;
                });
                function testPath(value) {
                    var status = true;
                    status = status && regex.test(value);
                    ngModel.$setValidity('nimWorkspacePath', status);
                }
            }
        };
    })
    .directive('nimSpaces', function () {
        return {
            require : 'ngModel',
            link : function(scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (value) {
                    var status = true;
                    if (value.match(' ')) {
                        status = false;
                    }
                    ngModel.$setValidity('nimSpaces', status);
                    return value;
                });
            }
        };
    })
    .directive('nimEqual', function() {
        return {
            require : 'ngModel',
            link : function(scope, element, attrs, ngModel) {
                scope.$watch(attrs.nimEqual, function() {
                    ngModel.$validate();
                });
                ngModel.$validators.nimEqual = function(value) {
                    var other = scope.$eval(attrs.nimEqual);
                    return !value || !other || value == other;
                };
            }
        };
    })
    .directive('nimIpLte', function() {
        return {
            require : 'ngModel',
            link : function(scope, element, attrs, ngModel) {
                scope.$watch(attrs.nimIpLte, function() {
                    ngModel.$validate();
                });
                ngModel.$validators.nimIpGte = function(value) {
                    var other = scope.$eval(attrs.nimIpLte);
                    if(value && other){
                        var startArr = value.split('.');
                        var endArr = other.split('.');
                        for(var i=0; i < startArr.length; i++){
                            var n = startArr[i].length;
                            startArr[i] = n >= 3 ? startArr[i] : new Array(3 - n + 1).join(0) + startArr[i];
                        }
                        for(var j=0; j < endArr.length; j++){
                            var m = endArr[j].length;
                            endArr[j] = m >= 3 ? endArr[j] : new Array(3 - m + 1).join(0) + endArr[j];
                        }
                        if(startArr.join('') <= endArr.join('')){
                            return true;
                        }
                    }
                    return false;
                };
            }
        };
    })
    .directive('nimFieldValidation', function () {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function (scope, element, attrs, ngModel) {
                // Get Elements
                var parent = element.parents('.form-group.has-feedback');
                var icon = parent.find('.nim-validation-icon');
                var validators = {};

                if (ngModel) {
                    validators = ngModel.$validators;

                    // React to view changes (without a watcher!)
                    ngModel.$viewChangeListeners.push(function () {
                        validate();
                    });

                    // Watch for model changes
                    scope.$watch(attrs.ngModel, function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            validate(true);
                        }
                    });

                    // Watch for async validators
                    scope.$watch(function () {return ngModel.$pending;}, function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            validate(true);
                        }
                    });

                    // Resets styling when form is set to pristine
                    scope.$watch(function () {return ngModel.$pristine;}, function (pristine) {
                        if(pristine){
                            clear();
                        }
                    });

                    // Register Scope Listener for batch validation
                    scope.$on('nim:validate-form-fields', function () {
                        validate(true);
                    });
                }
                if (icon) {
                    icon.addClass('fa-circle');
                }
                ////////////////////
                // Determine/assign visual validation
                function validate(force) {
                    if (ngModel.$touched || ngModel.$dirty || force) {
                        if (ngModel.$valid && !ngModel.$modelValue && !validators.required) {
                            clear();
                        }
                        else if (ngModel.$valid) {
                            success();
                        }
                        else if (ngModel.$invalid) {
                            error();
                        }
                        else {
                            clear();
                        }
                    } else {
                        clear();
                    }
                }
                function clear() {
                    parent.removeClass('has-error');
                    parent.removeClass('has-success');
                }
                function success() {
                    parent.toggleClass('has-error', false);
                    parent.toggleClass('has-success', true);
                }
                function error() {
                    parent.toggleClass('has-error', true);
                    parent.toggleClass('has-success', false);
                }

            }
        };
    })
    .directive('nimVerifyHuman', function($compile, $timeout) {
        return {
            restrict: 'AE',
            link: function(scope, element, attrs) {
                var randomId = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {var r = Math.random()*16|0,v=c=='x'?r:r&0x3|0x8;return v.toString(16);});
                var template = '<input ng-model="' + attrs.model + '" id="' + randomId + '" type="' + attrs.type + '" tabindex="' + attrs.tabindex + '" required />';
                $timeout(function () {
                    element.empty().append(template);
                    $compile(element.contents())(scope);
                }, 5000);
            }
        };
    })
;