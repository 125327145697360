angular.module('cerberus.admin')
    .factory('workspaceSettingsFactory', function($http, $q){
        /** @private */
        var getPath =  function (v){
            return '/server/rest/v1/workspaces/settings?s=' + v.toString();
        };
        var putPath =  function (){
            return '/server/rest/v1/workspaces/settings';
        };
        /** @public */
        return {
            /**
             * Cache all Settings
             *
             * @returns {promise|*}
             */
            getSettingsLookups: function () {
                var deferred = $q.defer();
                $http.get(getPath(''), {cache: true})
                    .success(function (value) {
                        deferred.resolve(value);
                    })
                    .error(function (reason) {
                        deferred.reject(reason);
                    });
                return deferred.promise;
            },
            /**
             * Read Settings from Server
             *
             * @param {Array} varNames
             * @returns {promise|*}
             */
            getSettings: function (varNames) {
                var deferred = $q.defer();
                $http.get(getPath(varNames))
                    .success(function (value) {
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(reason.DATA);
                    });
                return deferred.promise;
            },
            /**
             * Update Settings to Server
             *
             * @param {Object} data
             * @returns {promise|*}
             */
            putSettings: function (data) {
                var deferred = $q.defer();
                $http.put(putPath(), data)
                    .success(function (value) {
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(reason.DATA);
                    });
                return deferred.promise;
            }
        };
    })
;