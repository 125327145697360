angular.module('cerberus.cpc')
/**
 * @ngdoc config
 * @description router config
 */
    .config(function ($routeProvider) {
        $routeProvider.when('/cpc/inventory', {
            templateUrl: 'cpc/inventory/templates/inventory.tpl.html',
            controller: 'InventoryCtrl',
            controllerAs: 'vm',
            title: 'Inventory',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
    })
;