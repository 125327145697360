angular.module('cerberus.ui')
    .run(function ($rootScope, nimTitleService, appSettingsService) {

        nimTitleService.set(appSettingsService.branding().platform.name);

        $rootScope.$on('$routeChangeSuccess', function (event, current) {
            if (current.$$route && current.$$route.title) {
                nimTitleService.set(current.$$route.title);
            }
        });
    })
    .directive('nimTitle', function(nimTitleService) {
        return {
            restrict: 'A',
            link: function(scope, element) {
                scope.$watch(nimTitleService.get, function (newValue) {
                    element.empty().append(newValue);
                });
            }
        };
    })
;