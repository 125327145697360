angular.module('cerberus.admin')
    .factory('WorkflowsService', function WorkflowsService($http, $q, apiPath, toaster, WorkflowsClassService) {
        return {
            getPublishedWorkflow: getPublishedWorkflow,
            getRevision: getRevision,
            newRevision: newRevision,
            getRevisions: getRevisions,
            publish: publish
        };

        function getPublishedWorkflow(id){
            var deferred = $q.defer();
            $http.get(apiPath + 'workflows/' + id + '/publish')
                .success(function(value){
                    var d = value.DATA;
                    d.workflow = WorkflowsClassService.newWorkflow(d.workflow);
                    deferred.resolve(d);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getRevision(id){
            var deferred = $q.defer();
            $http.get(apiPath + 'workflows/' + id)
                .success(function(value){
                    var d = value.DATA;
                    d.workflow = WorkflowsClassService.newWorkflow(d.workflow);
                    deferred.resolve(d);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function newRevision(data, id){
            var deferred = $q.defer();
            $http.put(apiPath + 'workflows/' + id, data)
                .success(function(value){
                    toaster.pop('success', 'Workflow Saved', 'New revision added!');
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    toaster.pop('warning', 'Syntax Error', reason);
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getRevisions(id){
            var deferred = $q.defer();
            $http.get(apiPath + 'workflows/' + id + '/revisions')
                .success(function(value){
                    var d = value.DATA;
                    deferred.resolve(d);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function publish(id){
            var deferred = $q.defer();
            $http.put(apiPath + 'workflows/' + id + '/publish', null)
                .success(function (value) {
                    toaster.pop('success', 'Workflow Published', 'This revision has been published.');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
    })
;
