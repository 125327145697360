angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizChartDonutService
     * @alias cerberus/core:VizChartDonutService
     * @description Provides functions for configuring donut charts
     */
    .factory('VizChartDonutService', function VizChartDonutService(_){
        return {
            configDonut: configDonut
        };

        function configDonut(params){
            return function(data, options){
                var displayNum = params.displayNum;
                if(displayNum && data.length > displayNum) {
                    var categoryField = options.seriesDefaults.donut.categoryField;
                    if (!categoryField) {
                        categoryField = 'category';
                    }

                    var other = {};
                    other[categoryField] = 'Other';

                    var otherItems = data.splice(displayNum, data.length - displayNum);
                    _.forEach(options.series, function (series) {
                        var valueField = series.field;
                        other[valueField] = 0;

                        _.forEach(otherItems, function (item) {
                            var val = item[valueField];
                            if (val) {
                                other[valueField] += val;
                            }
                        });
                    });

                    data.push(other);
                    return data;
                }
            };
        }
    })
;