angular.module('cerberus.core')
    .controller('NimPageObjectCtrl', function NimPageObjectCtrl(_, kendo, $scope, $attrs, $timeout, $log,
                                                                pageObjectsService, printService, InstancesWindowService) {
        // In order to not create another scope we are using the $parse service
        // This controller is meant to be inside an ngRepeat which automagically creates new scopes
        // EDIT: Replaced with $scope.$eval, which is a wrapper for $parse for the given scope
        $scope.pageObject = $scope.$eval($attrs.nimPageObject);
        $scope.nimEditing = $scope.$eval($attrs.isEditingPage);
        $scope.isPreview = false;
        $scope.nimParentId = $scope.$eval($attrs.parentId);
        $scope.nimParentLookUp = $scope.$eval($attrs.parentLookUp);
        $scope.nimIsAdvSelObject = $scope.$eval($attrs.nimAdvSelObject);

        $scope.headerText = '';
        $scope.bodyText = '';
        $scope.footerText = '';

        // $scope.mainPageObject is used to reference child scopes via prototypical inheritance;
        // for example the Viz controller will expose itself via the property .vizCtrl
        $scope.mainPageObject = {};

        // Build Toolbar options with actions as callbacks
        $scope.toolbarOptions = pageObjectsService.buildToolbar($scope.pageObject.tools, {
            templateFunc: toolbarTemplate,
            refreshCb: reloadViz,
            createCb: createInstance,
            deleteCb: deleteSelected,
            printCb: print,
            printLabelsCb: printLabels,
            printChecksCb: printChecks,
            exportXLSCb: exportXLS,
            exportAllXLSCb: exportAllAsXLS,
            exportICSCb: exportICS,
            uploadFilesCb: uploadFiles,
            massImportCb: massImport
        });

        $scope.buttonClick = pageObjectsService.buttonClickHandler;

        var instanceFunctions = {
            callback: instanceCallback
        };

        if ($scope.nimEditing) {
            $scope.isPreview = $scope.$eval($attrs.nimPreviewPageObject);
        }

        $scope.$on('$destroy', function () {
            delete instanceFunctions.callback;
        });

        $scope.$watch(function () {
            var compileData = {
                data: _.get($scope.mainPageObject, 'vizCtrl.data')
            };

            if (_.get($scope.pageObject, 'viz.type') == 'table') {
                compileData.selected = _.get($scope.mainPageObject, 'vizCtrl.selectedRows', []);

                if (!$scope.pageObject.params.allowMultiSelect) {
                    compileData.selected = _.head(compileData.selected);
                }

                compileData.aggregates = _.get($scope.mainPageObject, 'vizCtrl.aggregates', {});
            }

            return compileData;
        }, function (compileData) {
            if (compileData.data) {
                if ($scope.pageObject.wrapper.header.text) {
                    $scope.headerText = pageObjectsService.setHtml($scope.pageObject.wrapper.header.text, compileData);
                }
                if ($scope.pageObject.wrapper.body.text) {
                    $scope.bodyText = pageObjectsService.setHtml($scope.pageObject.wrapper.body.text, compileData);
                }
                if ($scope.pageObject.wrapper.footer.text) {
                    $scope.footerText = pageObjectsService.setHtml($scope.pageObject.wrapper.footer.text, compileData);
                }
            }
        }, true);

        /////////////////////
        function reloadViz() {
            pageObjectsService.reloadViz($scope);
        }
        function createInstance() {
            InstancesWindowService.openWindow({
                action: 'create',
                widgetId: $scope.pageObject.viz.widgetId,
                title: $scope.pageObject.name,
                defaultData: buildDefaultDataArray(),
                navFunctions: instanceFunctions
            });
        }
        function buildDefaultDataArray() {
            var defaultDataArray = [];

            //Default Data Check for filters
            if($scope.pageObject.params.defaultData){
                for(var i = 0; i < $scope.pageObject.params.defaultData.length; i++){
                    if($scope.filters[$scope.pageObject.params.defaultData[i].value]){
                        defaultDataArray.push({
                            data: $scope.filters[$scope.pageObject.params.defaultData[i].value].filters[0],
                            field: $scope.pageObject.params.defaultData[i].formField
                        });
                    }
                }
            }

            pageObjectsService.addCustomValuesToDefaultData(defaultDataArray, $scope.pageObject.params.defaultValues);

            return defaultDataArray;
        }
        function deleteSelected() {
            pageObjectsService.deleteInstance(
                $scope.mainPageObject.vizCtrl.selectedIds,
                function () {
                    broadcastVizUpdate('delete', $scope.mainPageObject.vizCtrl.selectedIds, []);
                });
        }

        function print(){
            // Prints compiled template content
            if($scope.pageObject.viz.type === 'template') {
                pageObjectsService.printPageObject($scope.mainPageObject.vizCtrl.templateHtml);
            }
            // Prints selected instances
            else if($scope.pageObject.viz.type === 'table'){
                var widgetId = _.get($scope.pageObject, 'viz.widgetId'),
                    instanceIds = _.get($scope.mainPageObject, 'vizCtrl.selectedIds');

                if(widgetId && !_.isEmpty(instanceIds)) {
                    var displayValues = {},
                        displayCol = _.get($scope.pageObject, 'params.filterDisplay', ''),
                        enabledPrintTypes = {
                            normal: true,
                            check: _.get($scope.pageObject, 'params.printChecks', false),
                            label: _.get($scope.pageObject, 'params.printLabels', false)
                        },
                        defaultConfig = {
                            // filename: $scope.pageObject.name
                        },
                        defaultFilename = kendo.format('{0} - {1:u}', $scope.pageObject.name, new Date());
                    
                    if (displayCol) {
                        var rows = _.get($scope.mainPageObject, 'vizCtrl.selectedRows');
                        _.forEach(rows, function (r) {
                            displayValues[r.id] = _.get(r, displayCol, '[' + r.id + ']');
                        });
                    }

                    printService.configureAndPrint(widgetId, angular.copy(instanceIds), displayValues, enabledPrintTypes, defaultConfig, defaultFilename).then(function(){
                        _.set($scope.mainPageObject, 'vizCtrl.selectedRows', []);
                        _.set($scope.mainPageObject, 'vizCtrl.selectedIds', []);
                    });
                }
            }
        }

        function printLabels(){
            // Prints selected instances
            if($scope.pageObject.viz.type === 'table'){
                var widgetId = _.get($scope.pageObject, 'viz.widgetId'),
                    instanceIds = _.get($scope.mainPageObject, 'vizCtrl.selectedIds');

                if(widgetId && !_.isEmpty(instanceIds)) {
                    var displayValues = {},
                        displayCol = _.get($scope.pageObject, 'params.filterDisplay', ''),
                        enabledPrintTypes = {
                            label: true
                        },
                        defaultConfig = {
                            type: 'label'
                        },
                        defaultFilename = kendo.format('Labels - {0:yyyy-MM-dd HH-mm-ss}', new Date());

                    if (displayCol) {
                        var rows = _.get($scope.mainPageObject, 'vizCtrl.selectedRows');
                        _.forEach(rows, function (r) {
                            displayValues[r.id] = _.get(r, displayCol, '[' + r.id + ']');
                        });
                    }

                    printService.configureAndPrint(widgetId, angular.copy(instanceIds), displayValues, enabledPrintTypes, defaultConfig, defaultFilename).then(function(){
                        _.set($scope.mainPageObject, 'vizCtrl.selectedRows', []);
                        _.set($scope.mainPageObject, 'vizCtrl.selectedIds', []);
                    });
                }
            }
        }

        function printChecks(){
            // Prints selected instances
            if($scope.pageObject.viz.type === 'table'){
                var widgetId = _.get($scope.pageObject, 'viz.widgetId'),
                    instanceIds = _.get($scope.mainPageObject, 'vizCtrl.selectedIds');

                if(widgetId && !_.isEmpty(instanceIds)) {
                    var displayValues = {},
                        displayCol = _.get($scope.pageObject, 'params.filterDisplay', ''),
                        enabledPrintTypes = {
                            check: true
                        },
                        defaultConfig = {
                            type: 'check'
                        },
                        defaultFilename = kendo.format('Checks - {0:yyyy-MM-dd HH-mm-ss}', new Date());

                    if (displayCol) {
                        var rows = _.get($scope.mainPageObject, 'vizCtrl.selectedRows');
                        _.forEach(rows, function (r) {
                            displayValues[r.id] = _.get(r, displayCol, '[' + r.id + ']');
                        });
                    }

                    printService.configureAndPrint(widgetId, angular.copy(instanceIds), displayValues, enabledPrintTypes, defaultConfig, defaultFilename).then(function(){
                        _.set($scope.mainPageObject, 'vizCtrl.selectedRows', []);
                        _.set($scope.mainPageObject, 'vizCtrl.selectedIds', []);
                    });
                }
            }
        }

        function exportXLS(){
            if ($scope.mainPageObject.vizCtrl.exportXLS) {
                $scope.mainPageObject.vizCtrl.exportXLS();
            }
        }

        function exportAllAsXLS(){
            if ($scope.mainPageObject.vizCtrl.exportAllAsXLS) {
                $scope.mainPageObject.vizCtrl.exportAllAsXLS();
            }
        }
        
        function exportICS(){
            if ($scope.mainPageObject.vizCtrl.exportICS) {
                $scope.mainPageObject.vizCtrl.exportICS();
            }
        }

        function uploadFiles(){
            // if ($scope.mainPageObject.vizCtrl.uploadFiles) {
            //     $scope.mainPageObject.vizCtrl.uploadFiles();
            // }
            pageObjectsService.openUploadModal($scope.pageObject, buildDefaultDataArray());
        }

        function massImport(){
			pageObjectsService.openMassImportModal($scope.pageObject).then(function(){
            });
        }

        function toolbarTemplate() {
            var compileData = {
                data: _.get($scope.mainPageObject, 'vizCtrl.data', [])
            };

            if ($scope.pageObject.type === 'viz') {
                if ($scope.pageObject.viz.type === 'table') {
                    compileData.selected = _.get($scope.mainPageObject, 'vizCtrl.selectedRows', []);
                }
                if ($scope.pageObject.viz.type === 'schedule') {
                    compileData.selected = _.get($scope.mainPageObject, 'vizCtrl.selectedEvents', []);
                }
                if ($scope.pageObject.viz.type === 'list') {
                    compileData.selected = _.get($scope.mainPageObject, 'vizCtrl.selectedItems', []);
                }
            }

            try {
                var temp = kendo.template($scope.pageObject.tools.template);

                return temp(compileData);
            }
            catch (e) {
                $log.error(e);
                return '';
            }
        }

        function broadcastVizUpdate(action, id, data) {
            if(action && id){
                $scope.$broadcast('nim-viz-update-data', action, id, data);
            }
        }

        function instanceCallback(id, action, data) {
            // Replacement for reload
            broadcastVizUpdate(action, id, data);
        }

        //function exportPDF(){
        //    if ($scope.mainPageObject.vizCtrl.exportPDF) {
        //        $scope.mainPageObject.vizCtrl.exportPDF();
        //    }
        //}
    })
    .directive('nimPageObject', function($compile, $timeout, $window, pageObjectsService) {
        return {
            restrict: 'AE',
            controller: 'NimPageObjectCtrl',
            link: function(scope, element) {
                // Attribute no-wrapper can be added to the nim-page-object to display the page object without a wrapper
                var noWrapper = element.attr('no-wrapper');
                var wrapperType = scope.pageObject.wrapper.type;
                if(typeof noWrapper !== 'undefined' && noWrapper !== false && noWrapper !== 'false'){
                    wrapperType = 'none';
                }

                element.empty()
                    .append($compile(
                        pageObjectsService.buildHtml(
                            scope.pageObject.type,
                            wrapperType,
                            scope.pageObject.viz.type,
                            scope.pageObject.viz.subType,
                            scope.pageObject.viz.template
                        )
                    )(scope));

                if(scope.pageObject.type === 'tabset'){
                    var jqWindow = angular.element($window);

                    jqWindow.bind('focus', function() {
                        $timeout(function(){
                            resizeGridsterItem();
                        }, 1);
                    });

                    scope.$on('$destroy', function () {
                        jqWindow.unbind('focus');
                    });
                }

                if (!scope.nimParentId) {
                    scope.resizeGridsterItem = resizeGridsterItem;
                }

                function resizeGridsterItem(){
                    if(!scope.nimParentId){
                        if(scope.gridsterItem && scope.gridsterItem.gridster) {
                            var rowHeight = scope.gridsterItem.gridster.rowHeight;
                            var gridsterMargin = scope.gridsterItem.gridster.margins[0] || 0;
                            var section = element.children('.nim-wrapper').children('section');
                            var innerHeight = cssAsInt(section, 'paddingTop') + cssAsInt(section, 'paddingBottom') + cssAsInt(section, 'marginTop') + cssAsInt(section, 'marginBottom') + (gridsterMargin * 2) + 1;

                            section.children('.wrapper').children().each(function(i, element){
                                var child = $(element);

                                if(child.hasClass('body')){
                                    child.children().each(function(j, bodyElem){
                                        innerHeight += $(bodyElem).outerHeight(true);
                                    });

                                    innerHeight += 6;
                                }
                                else if(child.hasClass('toolbar')) {
                                    innerHeight += child.outerHeight(false);
                                }
                                else {
                                    innerHeight += child.outerHeight(true);
                                }
                            });

                            if(scope.tab){
                                $timeout(function(){
                                    (scope.$parent.resizeGridsterItem || angular.noop)();
                                }, 100);
                            }

                            var rows = Math.ceil(innerHeight / rowHeight);

                            $timeout(function(){
                                if(scope.gridsterItem && scope.gridsterItem.gridster) {
                                    scope.gridsterItem.setSizeY(rows);
                                }
                            });
                        }
                    }
                }

                function cssAsInt(element, css) {
                    var px = element.css(css);
                    return parseInt(px.replace('px', ''));
                }
            }
        };
    })
;