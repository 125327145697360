angular.module('cerberus.core')
    .controller('InstanceConfirmCloseCtrl', function ($uibModalInstance) {
        var vm = this;

        vm.ok = ok;
        vm.cancel = cancel;
        
        function ok(result) {
            $uibModalInstance.close(result);
        }

        function cancel() {
            $uibModalInstance.dismiss();
        }
    })
;