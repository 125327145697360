angular.module('cerberus.admin')
    .controller('FormsDesigner_dataseriesCtrl',
    function FormsDesigner_dataseriesCtrl(_, $scope, $timeout, $uibModalInstance, autoTabIndex, currentFormObjects,
                                          editFlag, formObj, formOriginId, idArray, sections, widgetId,
                                          DesignerUtilityService, FormsDesignerListService, PagesService,
                                          ViewsService, WidgetsService, WidgetsSettingsService) {
        // Populate Scope
        $scope.temp = angular.copy(formObj);
        $scope.orig = angular.copy(formObj);
        $scope.formId = formOriginId;
        $scope.editFlag = editFlag;
        $scope.autoTabIndex = autoTabIndex;
        $scope.widgets = [];
        $scope.tabsTemplate = FormsDesignerListService.formObjects();
        $scope.formFieldObjects = [];
        $scope.conditionOptions = [];
        $scope.targetWidgetFields = [];
        $scope.viewColumns = [];
        $scope.defaultData = {
            type: 'field',
            sourceValue: null,
            sourceModelId: '',
            targetField: null
        };
        $scope.workflowFormObjects = [];

        $scope.defaultFilter = {};
        $scope.defaultFilterType = null;
        $scope.defaultFilterOperators = DesignerUtilityService.filterOperators();
        $scope.cascadeFilter = {};
        $scope.filterType = null;
        $scope.filterOperators = {
            number: [
                {value: 'eq', display: 'Equal To'},
                {value: 'gte', display: 'Greater Than or Equal To'},
                {value: 'lte', display: 'Less Than or Equal To'}
            ],
            string: [
                {value: 'contains', display: 'Contains'}
            ],
            selection: [
                {value: 'eq', display: 'Equal To'}
            ]
        };

        $scope.groups = [];
        $scope.columns = [];
        $scope.fieldOptions = {};
        $scope.update = false;
        $scope.aggregates = {
            average: 'Average',
            count: 'Count',
            max: 'Max',
            min: 'Min',
            sum: 'Sum'
        };
        $scope.resultFilterOperators = [
            {value: '\'contains\'', display: 'Contains'},
            {value: '\'startswith\'', display: 'Starts With'}
        ];
        $scope.aggregateColumn = null;
        $scope.aggregateType = null;
        $scope.aggregateData = {title: '', field: ''};
        $scope.forms = [];
        $scope.lookups = [];
        $scope.sections = sections;
        $scope.conditionalActions = FormsDesignerListService.conditionalActions();
        $scope.conditionType = '';
        $scope.conditionOperators = DesignerUtilityService.conditionOperators();
        $scope.conditionBooleanValues = DesignerUtilityService.conditionBooleanValues();
        $scope.conditionActionValues = DesignerUtilityService.conditionActionValues();
        $scope.conditionFilter = DesignerUtilityService.conditionFilter(formOriginId, formObj.model.config.modelId);
        $scope.getConditionType = DesignerUtilityService.getConditionType;
        $scope.addCondition = DesignerUtilityService.addCondition($scope.temp, formOriginId);
        $scope.dataSeriesColLookupOptions = dataSeriesColLookupOptions;

        $scope.pages = [];
        $scope.pageObjects = [];
        $scope.pageObjectFields = [];

        $scope.geoProperties = [
            { value: 'formatted_address', display: 'Full Address' },
            { value: 'postal_address', display: 'Postal Address' },
            { value: 'country_long_name', display: 'Country (Long)' },
            { value: 'country_short_name', display: 'Country (Short)' },
            { value: 'administrative_area_level_1_long_name', display: 'State (Long)' },
            { value: 'administrative_area_level_1_short_name', display: 'State (Short)' },
            { value: 'administrative_area_level_2_long_name', display: 'County (Long)' },
            { value: 'administrative_area_level_2_short_name', display: 'County (Short)' },
            { value: 'locality_long_name', display: 'City (Long)' },
            { value: 'locality_short_name', display: 'City (Short)' },
            { value: 'postal_code_long_name', display: 'ZIP (Long)' },
            { value: 'postal_code_short_name', display: 'ZIP (Short)' },
            { value: 'street_address', display: 'Street Address' }
        ];

        $scope.sortableColumnsOptions = {
            ignore: "input,select",
            change: function (e) {
                $timeout(function () {
                    reorderItem($scope.temp.model.tag.inner, e.oldIndex, e.newIndex);
                });
            }
        };

        $scope.addDefaultData = addDefaultData;

        // UI Helper Functions
        $scope.deleteProperty = DesignerUtilityService.deleteProperty;
        $scope.preventSpace = DesignerUtilityService.preventSpace;
        $scope.labelToUniqueId = function (item){
            if (!$scope.orig.model.config.modelId) {
                item.model.config.modelId = DesignerUtilityService.labelToUniqueId(item.model.label.text, idArray);
            }
        };

        // Generates Unique ID for column field
        $scope.labelToColumnUniqueId = function(column){
            if(!$scope.update) {
                var ids = angular.copy(idArray);
                for(var c = 0; c < $scope.columns.length; c++){
                    ids.push($scope.columns[c].field);
                }
                column.field = DesignerUtilityService.labelToUniqueId(column.title, ids);
            }
        };

        $scope.createFieldString = function(container, options, values){
            var inputString = '<select class="k-input k-textbox" name="' + options.field + '" bind="value:' + options.field + '">';
            inputString += '<option value class>Choose...</option>';
            if(values) {
                for (var v = 0; v < values.length; v++) {
                    inputString += '<option value="' + values[v].display + '">' + values[v].display + '</option>';
                }
            }
            inputString += '</select>';
            var input = $(inputString);
            input.appendTo(container);
        };

        $scope.addOption = function(array,data){
            var r = /\d+/;
            var a = 0;
            if(array.length > 0){
                a = Math.max.apply(Math,array.map(function(o){return o.id.match(r);}));
                a = a + 1;
            }
            data.id = 'opt' + a;
            array.push(data);
        };
        $scope.deleteArrayObject = function(array,index){
            array.splice(index, 1);
        };
        $scope.concatArrays = function(arrayA, arrayB){
            var combinedArray = arrayA.concat(arrayB);
            angular.copy(combinedArray, arrayA);
        };

        $scope.selectWidget = function(id){
            $scope.temp.model.param.fk = null;
            $scope.temp.model.param.optionId = null;
            $scope.temp.model.param.column = null;
            $scope.temp.model.param.fkFormOrigin = null;
            $scope.temp.model.param.fkFormId = null;
            $scope.temp.model.param.lookup = null;
            $scope.temp.model.param.lookupFormOrigin = null;
            $scope.temp.model.param.lookupFormId = null;
            $scope.temp.model.param.defaultFilter = null;
            $scope.temp.model.param.workflowId = null;

            if($scope.temp.model.config.type === 'multiselect') {
                $scope.temp.model.option.id = null;
                $scope.temp.model.option.widgetId = null;
                $scope.temp.model.option.column = null;
            }

            if(id){
                WidgetsSettingsService.getFormFields(id).then(function(data){
                    var lookups = [];
                    var forms = [];
                    var targetWidgetFields = [];

                    for(var i = 0; i < data.display.length; i++){
                        var fields = data.display[i].objects;
                        for(var j = 0; j < fields.length; j++){
                            var field = fields[j];
                            var tmp = {
                                modelId: field.modelId,
                                label: field.label,
                                formName: data.display[i].formName,
                                formId: data.display[i].originId,
                                stateId: data.display[i].stateId,
                                isLookup: field.isLookUp,
                                isCustomLookup: field.isCustomLookup,
                                type: field.type,
                                dataType: field.dataType
                            };
                            if(field.isLookUp){
                                tmp.lookupCol = field.column;
                                tmp.viewId = field.optionId;

                                if(field.widgetId == widgetId && field.optionId){
                                    field.__formOriginId = data.display[i].originId;
                                    field.__formId = data.display[i].formId;
                                    lookups.push(field);
                                    fields.splice(j, 1);
                                    j--;
                                }

                                if($scope.temp.model.config.type === 'table' && field.widgetId == id && !$scope.temp.model.param.lookup){
                                    $scope.temp.model.param.lookup = field.modelId;
                                    $scope.temp.model.param.lookupFormOrigin = data.display[i].originId;
                                    $scope.temp.model.param.lookupFormId = data.display[i].formId;
                                }

                                if($scope.temp.model.param.lookup === field.modelId){
                                    if(!$scope.temp.model.param.lookupFormOrigin) {
                                        $scope.temp.model.param.lookupFormOrigin = data.display[i].originId;
                                        $scope.temp.model.param.lookupFormId = data.display[i].formId;
                                    }

                                    if($scope.temp.model.config.type === 'multiselect') {
                                        $scope.temp.model.param.defaultFilter = field.filters;
                                    }
                                }
                            }
                            targetWidgetFields.push(tmp);
                        }
                        forms.push(data.display[i]);
                    }

                    $scope.forms = forms;
                    $scope.targetWidgetFields = targetWidgetFields;
                    $scope.lookups = lookups;

                    if($scope.lookups.length > 0){
                        var fk = $scope.lookups[0];
                        $scope.temp.model.param.fk = fk.modelId;
                        $scope.temp.model.param.optionId = fk.optionId;
                        $scope.temp.model.param.column = fk.column;
                        $scope.temp.model.param.fkFormOrigin = fk.__formOriginId;
                        $scope.temp.model.param.fkFormId = fk.__formId;

                        if($scope.temp.model.config.type === 'multiselect') {
                            $scope.setOptionId(fk);
                        }
                    }
                });

                WidgetsService.getWidget(id).then(function(data){
                    $scope.temp.model.param.workflowId = data.workflow.originatorId;
                });
            }
        };

        $scope.toggleField = function (field, formOriginId) {
            var index = $scope.fieldIndex(field, $scope.temp.model.tag.inner);
            if (index < 0) {
                var col = {
                    title: field.label,
                    field: (field.isLookUp || field.isCustomLookup) ? '__' + field.modelId + '_display' : field.modelId,
                    isLookUp: field.isLookUp,
                    isCustomLookup: field.isCustomLookup,
                    format: field.format || null,
                    type: field.type,
                    formOriginId: formOriginId
                };

                if (col.type == 'dataseries') {
                    col.widgetId = field.widgetId;
                }

                $scope.temp.model.tag.inner.push(col);
            }
            else {
                if(angular.equals($scope.temp.model.tag.inner[index], $scope.aggregateColumn)){
                    $timeout(function(){
                        $scope.aggregateColumn = null;
                    });
                }
                $scope.temp.model.tag.inner.splice(index, 1);
            }
        };

        $scope.fieldIndex = function (field, array){
            var modelId = (field.isLookUp || field.isCustomLookup) ? '__' + field.modelId + '_display' : field.modelId;
            for(var i = 0; i < array.length; i++){
                if(array[i].field === modelId){
                    return i;
                }
            }
            return -1;
        };

        $scope.noTablesFilter = function(field){
            return field.type !== 'dataseries';
        };

        $scope.recursiveTablesFilter = function(field){
            return field.type === 'dataseries' && field.widgetId === $scope.temp.model.param.widgetId;
        };

        $scope.lookupsFilter = function(e){
            return e.isLookUp;
        };

        $scope.setOptionId = function(lookup){
            $scope.temp.model.option.id = null;
            $scope.temp.model.option.widgetId = null;
            $scope.temp.model.option.column = null;
            $scope.temp.model.param.lookupFormOrigin = null;
            $scope.viewColumns = [];

            if(lookup){
                for(var i = 0; i < $scope.forms.length; i++){
                    var objects = $scope.forms[i].objects;
                    for(var j = 0; j < objects.length; j++){
                        var obj = objects[j];
                        if(obj.modelId === lookup && obj.isLookUp){
                            $scope.temp.model.option.id = obj.optionId;
                            $scope.temp.model.option.widgetId = obj.widgetId;
                            $scope.temp.model.option.column = obj.column;
                            $scope.temp.model.param.lookupFormOrigin = $scope.forms[i].originId;
                            $scope.temp.model.param.lookupFormId = $scope.forms[i].formId;

                            if(obj.type && obj.type !== 'string'){
                                $scope.temp.model.option.type = obj.type;
                            }

                            $scope.temp.model.param.defaultFilter = obj.filters;

                            $scope.loadViewColumns(obj.optionId);
                            return;
                        }
                    }
                }
            }
        };

        $scope.loadViewColumns = function(id){
            if(id){
                ViewsService.getColumns(id).then(function(data){
                    $scope.viewColumns = data;
                });
            }
            else{
                $scope.viewColumns = [];
            }
        };

        $scope.deleteAggregate = function(field, type){
            delete $scope.temp.model.param.aggregates[field][type];
        };
        
        $scope.addAggregate = function (field, type) {
            $scope.temp.model.param.aggregates[field] = $scope.temp.model.param.aggregates[field] || {};
            if(!$scope.temp.model.param.aggregates[field][type]){
                $scope.temp.model.param.aggregates[field][type] = {
                    title: $scope.aggregateData.title,
                    field: $scope.aggregateData.field
                };
                $scope.aggregateType = "";
                _.assign($scope.aggregateData, {title: '', field: ''});
            }
        };

        function getFormFieldObjects(fieldArray){
            var objects = [];
            for(var f = 0; f < fieldArray.display.length; f++){
                var form = fieldArray.display[f];
                for(var o = 0; o < form.objects.length; o++){
                    var object = form.objects[o];
                    object.formId = form.originId;
                    objects.push(object);
                }
            }
            return objects;
        }

        // Default Filter
        $scope.notPropertyColumn = function(col){
            return col.type === 'column';
        };

        $scope.updateDefaultFilterType = function(){
            if($scope.defaultFilter.field && $scope.defaultFilter.field !== ''){
                //var index = $scope.viewColumns.map(function (elem) { return elem.colName; }).indexOf($scope.defaultFilter.field); /* NON LODASH OPTION */

                /* LODASH Version 4 ONLY */
                //var index = _.findIndex($scope.viewColumns, ['colName', $scope.defaultFilter.field]);
                /* LODASH Version 3 & 4 Compatibility Mode */
                var index = _.findIndex($scope.viewColumns, function(f){return f.colName === $scope.defaultFilter.field;});
                if(index >= 0){
                    $scope.defaultFilterType = $scope.viewColumns[index].castType;
                    return;
                }
            }
            $scope.defaultFilterType = null;
        };

        $scope.addFilter = function(){
            $scope.temp.model.param.defaultFilter.filters.push($scope.defaultFilter);
            $scope.defaultFilter = {};
            $scope.defaultFilterType = null;
        };

        // Cascade Filter
        $scope.updateFilterType = function(){
            var id = $scope.cascadeFilter.value;
            if(id) {
                for (var i = 0; i < $scope.formFieldObjects.length; i++) {
                    if ($scope.formFieldObjects[i].modelId === id) {
                        $scope.filterType = $scope.formFieldObjects[i].dataType;
                        $scope.cascadeFilter.operator = $scope.filterOperators[$scope.filterType][0].value;
                        return;
                    }
                }
            }
        };

        $scope.addCascadeFilter = function(){
            $scope.temp.model.param.cascadeFilter.filters.push($scope.cascadeFilter);
            $scope.cascadeFilter = {};
            $scope.filterType = null;
        };

        // Advanced Selection
        $scope.loadPageObjects = loadPageObjects;
        $scope.getPageObjectFields = getPageObjectFields;
        $scope.addAdvSelFilter = addAdvSelFilter;
        $scope.compatiblePageObjectFilter = compatiblePageObjectFilter;
        $scope.lookupFilter = lookupFilter;

        function loadPageObjects(pageId){
            if(pageId){
                PagesService.getPage(pageId).then(function(pageModel){
                    $scope.pageObjects = pageModel.items;
                    $scope.filters = DesignerUtilityService.getFilters($scope.pageObjects);

                    if(_.has($scope.temp, 'model.param.advSel.pageObjectId')){
                        getPageObjectFields(_.get($scope.temp, 'model.param.advSel.pageObjectId'));
                    }
                });
            }
            else {
                $scope.pageObjects = [];
            }
        }

        function getPageObjectFields(pageObjectId){
            var pageObj = _.find($scope.pageObjects, 'id', pageObjectId);
            if(pageObj){
                $scope.pageObjectFields = pageObj.viz.settings.columns;
            }
            else {
                $scope.pageObjectFields = [];
            }
        }

        function addAdvSelFilter(filter, modelId){
            var filterMap = $scope.temp.model.param.advSel.filterMap;
            var filterObj = {
                filter: filter,
                modelId: modelId
            };

            var valueObject = _.find($scope.formFieldObjects, 'modelId', modelId);
            if(valueObject){
                filterObj.formOriginId = valueObject.formId;
                filterMap.push(filterObj);
            }
        }

        function compatiblePageObjectFilter(pageObject){
            return _.get(pageObject, 'viz.type') === 'table';
        }

        function lookupFilter(column){
            return column.isLookUp || column.isCustomLookup;
        }

        function reorderItem(array, oldIndex, newIndex){
            var item = array.splice(oldIndex, 1)[0];
            array.splice(newIndex, 0, item);
        }

        init();

        // Init
        function init(){
            if($scope.temp.model.config.type === 'table') {
                _.defaults($scope.temp.model.param, {
                    otherData: [],
                    aggregates: {},
                    advSel: {
                        filterMap: []
                    },
                    disableDuplicate: true
                });

                PagesService.getPages().then(function (pages) {
                    $scope.pages = pages;
                });

                loadPageObjects(_.get($scope.temp, 'model.param.advSel.pageId'));
            }
            else if($scope.temp.model.config.type === 'multiselect') {
                _.defaults($scope.temp.model.param, {
                    defaultFilter: {
                        logic: 'and',
                        filters: []
                    },
                    cascadeFilter: {
                        logic: 'and',
                        filters: []
                    }
                });

                $scope.loadViewColumns($scope.temp.model.option.id);
            }

            WidgetsSettingsService.getFormFields(widgetId).then(function(formFieldArray){
                DesignerUtilityService.mergeFieldsWithCurrentForm(formFieldArray, currentFormObjects);

                $scope.formFieldObjects = getFormFieldObjects(formFieldArray);

                var conditionOptions = _.sortBy(_.filter($scope.formFieldObjects, $scope.conditionFilter), 'label');
                $scope.conditionOptions = [{ label: '(Record Action)', modelId: '__nimRecordAction' }].concat(conditionOptions);
            });

            WidgetsService.getWidgets().then(function(widgets){
                $scope.widgets = widgets;
            });

            if(formObj.model.param.widgetId){
                WidgetsSettingsService.getFormFields(formObj.model.param.widgetId).then(function(data){
                    var lookups = [];
                    var forms = [];
                    var targetWidgetFields = [];

                    for(var i = 0; i < data.display.length; i++){
                        var fields = data.display[i].objects;
                        for(var j = 0; j < fields.length; j++){
                            var field = fields[j];
                            var tmp = {
                                modelId: field.modelId,
                                label: field.label,
                                formName: data.display[i].formName,
                                formId: data.display[i].originId,
                                stateId: data.display[i].stateId,
                                isLookup: field.isLookUp,
                                isCustomLookup: field.isCustomLookup,
                                type: field.type,
                                dataType: field.dataType
                            };
                            if(field.isLookUp){
                                tmp.lookupCol = field.column;
                                tmp.viewId = field.optionId;

                                if(field.widgetId == widgetId && field.optionId){
                                    field.__formOriginId = data.display[i].originId;
                                    field.__formId = data.display[i].formId;
                                    lookups.push(field);
                                    fields.splice(j, 1);
                                    j--;
                                }
                            }
                            targetWidgetFields.push(tmp);
                        }
                        forms.push(data.display[i]);
                    }

                    $scope.forms = forms;
                    $scope.targetWidgetFields = targetWidgetFields;
                    $scope.lookups = lookups;
                });
            }
        }

        function addDefaultData(){
            $scope.temp.model.param.otherData.push($scope.defaultData);
            $scope.defaultData = {
                type: 'field',
                sourceValue: null,
                sourceModelId: '',
                targetField: null
            };
        }

        function dataSeriesColLookupOptions(widgetId) {
            return {
                dataTextField: 'label',
                dataValueField: 'modelId',
                optionLabel: { label: 'Choose...', modelId: '' },
                valuePrimitive: true,
                dataSource: {
                    transport: {
                        read: function (options) {
                            WidgetsSettingsService.getFormFields(widgetId).then(function (data) { 
                                options.success(getFormFieldObjects(data));
                            });
                        }
                    }
                }
            };
        }

        // Modal Actions
        $scope.ok = function (){
            $uibModalInstance.close($scope.temp);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;