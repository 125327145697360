angular.module('cerberus.core')
    .controller('nimVizChartBulletCtrl', function nimVizChartBulletCtrl(_, $scope, $timeout, VizChartBulletService, VizUtilityService, VizMathService, vizListService, $firebaseObject, fbUtil) {
        var vm = this;
        $scope.mainPageObject.vizCtrl = vm;

        vm.toPercentageString = VizMathService.toPercentageString;

        // Debounced refresh function and Firebase watchers
        var debouncedRefresh = _.debounce(function () { _.result($scope, 'dataSource.read'); }, 5000, { leading: true, trailing: true }),
            unwatchPlot = setFirebaseWatcher($scope.pageObject.viz.settings.dataSource.plot.nim_viewId),
            unwatchValue = setFirebaseWatcher($scope.pageObject.viz.settings.dataSource.value.nim_viewId),
            unwatchTarget = setFirebaseWatcher($scope.pageObject.viz.settings.dataSource.target.nim_viewId);
        
        init();

        // Event Bindings
        var removeChartSizeWatcher = angular.noop;
        $scope.$on('kendoWidgetCreated', function (event, widget) {
            if (widget === $scope.chart) {
                event.preventDefault();
                event.stopPropagation();

                var debouncedResizeFunc = _.debounce(function () {
                    widget.resize();
                }, 400, { leading: true, trailing: true });
                removeChartSizeWatcher = $scope.$watch(function(){
                    return widget.wrapper.width();
                }, debouncedResizeFunc);
            }
        });

        $scope.$on('nim-viz-reload', function (event) {
            event.preventDefault();
            debouncedRefresh();
        });

        $scope.$on('$destroy', function () {
            // Removes these firebase watchers
            unwatchPlot();
            unwatchValue();
            unwatchTarget();
        });

        $scope.$on('nim-remove-page-objects', function () {
            if ($scope.chart) {
                $scope.chart.destroy();
                $scope.chart = null;
            }

            removeChartSizeWatcher();
        });

        function init() {
            // Options not yet exposed to delay chart initialization
            var options = angular.copy($scope.pageObject.viz.settings);

            $scope.currentField = options.series[0].currentField;
            $scope.plotField = options.series[0].toField;
            vm.data = {
                value: [],
                target: [],
                plot: []
            };

            // Ensure that autoBind is set to false to prevent multiple server calls
            // We want to manually bind the data because some options will depend on the server's response
            // See the dataSource.fetch() callback function
            options.autoBind = false;

            // Used for coloring plotbands
            $scope.plotColors = vizListService.colorbrewer().sequential.Greys[3];

            var bulletDataSources = angular.copy($scope.pageObject.viz.settings.dataSource);

            // TODO: Refactor these buildBulletDataSource calls
            var dataSource = VizChartBulletService.buildBulletDataSource($scope.pageObject, bulletDataSources.value, options, $scope, 'value',
                VizChartBulletService.valueFetchCallback($scope, options));
            VizUtilityService.subscribeToPageFilters($scope.pageObject.filterMap.value || {}, $scope, dataSource);

            // Builds plotband data source and configures plotbands with data
            if ($scope.pageObject.viz.settings.dataSource.plot.nim_viewId) {
                bulletDataSources.plot = VizChartBulletService.buildBulletDataSource($scope.pageObject, bulletDataSources.plot, options, $scope, 'plot',
                    VizChartBulletService.plotBandFetchCallback($scope, options));
                VizUtilityService.subscribeToPageFilters($scope.pageObject.filterMap.plot || {}, $scope, bulletDataSources.plot);
            }
            else {
                $scope.plot = [];
            }

            // Builds target data source
            if ($scope.pageObject.viz.settings.dataSource.target.nim_viewId) {
                bulletDataSources.target = VizChartBulletService.buildBulletDataSource($scope.pageObject, bulletDataSources.target, options, $scope, 'target',
                    VizChartBulletService.targetFetchCallback($scope, options));
                VizUtilityService.subscribeToPageFilters($scope.pageObject.filterMap.target || {}, $scope, bulletDataSources.target);
            }
            else {
                $scope.target = [];
            }

            // Finally expose options to view model for kendo binding
            vm.options = options;
            $scope.dataSource = dataSource;
        }

        function setFirebaseWatcher(viewId) {
            if (viewId) {
                var initializing = true;
                return $firebaseObject(fbUtil.ref('queries/' + viewId)).$watch(function () {
                    if (initializing) {
                        //Stops first firing of the event
                        $timeout(function () { initializing = false; });
                    } else {
                        debouncedRefresh();
                    }
                });
            }

            return angular.noop;
        }
    })
;