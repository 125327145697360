angular.module('cerberus.cpc')
    /**
     * @ngdoc service
     * @name inventoryService
     * @alias cerberus/cpc:inventoryService
     * @description Makes calls to the "cpc/inventory" resources
     */
    .factory('inventoryService', function inventoryService(_, $http, $q, apiPath, $uibModal, toaster) {

        return {
            updateInventoryRowPositionsOpenModal: updateInventoryRowPositionsOpenModal,
            updateInventoryLocationOpenModal: updateInventoryLocationOpenModal,
            updateFinishedProductLocationOpenModal: updateFinishedProductLocationOpenModal,
            initiateWarehouseAuditOpenModal: initiateWarehouseAuditOpenModal,
            initiateEDI_RollInputOpenModal: initiateEDI_RollInputOpenModal,
            updateRollPositions: updateRollPositions
        };

        ////////////////////
        /**
         * Open modal to create / update inventory roll positions
         */
        function updateInventoryRowPositionsOpenModal() {
            var modalInstance = $uibModal.open({
                templateUrl: 'cpc/inventory/templates/inventory-rollpositions-update-modal.tpl.html',
                controller: 'updateInventoryRowPositionsOpenModalCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {}
            });
            return modalInstance.result;
        }

        ////////////////////
        /**
         * Open modal to update current assigned location of inventory roll materials
         */
        function updateInventoryLocationOpenModal() {
            var modalInstance = $uibModal.open({
                templateUrl: 'cpc/inventory/templates/inventory-location-update-modal.tpl.html',
                controller: 'updateInventoryLocationOpenModalCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {}
            });
            return modalInstance.result;
        }

        ////////////////////
        /**
         * Open modal to update current assigned location of finished products
         */
        function updateFinishedProductLocationOpenModal() {
            var modalInstance = $uibModal.open({
                templateUrl: 'cpc/inventory/templates/finished-product-location-update-modal.tpl.html',
                controller: 'updateFinishedProductLocationOpenModalCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {}
            });
            return modalInstance.result;
        }

        ////////////////////
        /**
         * Open modal to initiate warehouse audit
         */
        function initiateWarehouseAuditOpenModal() {
            var modalInstance = $uibModal.open({
                templateUrl: 'cpc/inventory/templates/inventory-warehouse-audit-modal.tpl.html',
                controller: 'initiateInventoryWarehouseAuditModalCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {}
            });
            return modalInstance.result;
        }

        ////////////////////
        /**
         * Open modal to initiate roll input from an EDI source
         */
        function initiateEDI_RollInputOpenModal() {
            var modalInstance = $uibModal.open({
                templateUrl: 'cpc/inventory/templates/inventory-edi-rollinput-modal.tpl.html',
                controller: 'initiateEDI_RollInputOpenModalCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {}
            });
            return modalInstance.result;
        }

        ////////////////////
        /**
         * Function to update roll positions / warehouse audit
         * Required fields: action, data
         */
        function updateRollPositions(action, data) {
            action = action || 'none';
            data = data || {};

            var deferred = $q.defer(),
                url = apiPath + 'cpc/inventory' + '/update';

            $http.put(url, {data: data}, {params: {action: action}})
                .success(function (value) {
                    toaster.pop('success', 'Update completed');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
    })
;
