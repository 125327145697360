angular.module('cerberus.admin')
    .controller('MenusCreateCtrl', function MenusCreateCtrl($scope, $location, MenusService){
        var vm = this;

        vm.menuData = {};

        vm.save = function(menu){
            MenusService.createMenu(menu).then(function(id){
                var path = '/settings/menus';
                if(id) {
                    path += '/' + id;
                }

                $location.replace().url(path);
            });
        };
        vm.cancel = function(){
            $location.replace().url('/settings/menus');
        };
    })
;