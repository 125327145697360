angular.module('cerberus.admin')
    .controller('WidgetsDetailsCtrl', function WidgetsDetailsCtrl(_, $scope, $location, $uibModal, widgetId, WidgetsService, WidgetsSettingsService, ViewsService){
        $scope.widgetPublicKey = widgetId;
        $scope.activeRoute = 'details';
        $scope.views = [];
        $scope.columns = [];
        $scope.widgetData = {};
        $scope.widgetModel = {};
        $scope.formFields = [];
        $scope.alerts = [
            {
                value: 30,
                display:'30 minutes before'
            },
            {
                value: 60,
                display:'1 hour before'
            },
            {
                value: 120,
                display:'2 hours before'
            },
            {
                value: 1440,
                display:'1 day before'
            },
            {
                value: 2880,
                display:'2 days before'
            },
            {
                value: 10080,
                display:'1 week before'
            }
        ];
        $scope.formActions = [
            { value: 'submit', display: 'Submit'},
            { value: 'submitAndNew', display: 'Submit & New'},
            { value: 'submitAndClose', display: 'Submit & Close'}
        ];

        $scope.isUnchanged = function(data){
            return angular.equals(data, $scope.widgetModel);
        };
        $scope.save = function(data){
            WidgetsSettingsService.updateWidget(data['publicKey'], data).then(function(){
                init();
            });
        };
        $scope.selectView = function(id){
            ViewsService.getColumns(id).then(function(data){
                $scope.columns = data;
            });
        };

        $scope.openWidgetDeleteModal = function(){
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/widgets/details/delete-widget-modal.tpl.html',
                controller: 'WidgetDeleteModalCtrl',
                resolve: {
                    widget: function(){
                        return $scope.widgetModel;
                    }
                }
            });
            modalInstance.result.then(function (data) {
                WidgetsSettingsService.deleteWidget(data['publicKey']).then(function(){
                    $location.url('/settings/widgets');
                });
            });
        };

        init();

        function init(){
            WidgetsService.getWidget(widgetId).then(function(data){
                $scope.widgetData = data;
                $scope.widgetModel = angular.copy($scope.widgetData);
                WidgetsSettingsService.getBaseWidgetViews($scope.widgetData.uniqueName).then(function(data){
                    $scope.views = data;
                });

                if($scope.widgetData.settings.view) {
                    $scope.selectView($scope.widgetData.settings.view.id);
                }

                if(!$scope.widgetData.settings.defaultAction){
                    $scope.widgetData.settings.defaultAction = $scope.widgetData.settings.defaultSubmitAndNew ? 'submitAndNew' : 'submitAndClose';
                }
            });

            WidgetsSettingsService.getFormFields(widgetId).then(function(data){
                $scope.formFields = WidgetsSettingsService.parseFormObjects(data.display);
            });
        }
    })
;