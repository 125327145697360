angular.module('cerberus.admin')
    .controller('FormsDesigner_mapCtrl', function FormsDesigner_mapCtrl(_, $timeout, $scope, $uibModalInstance, $window, autoTabIndex,
                                                                        currentFormObjects, editFlag, formObj, idArray, sections, widgetId,
                                                                        DesignerUtilityService, FormsDesignerListService,
                                                                        LeafletListService, WidgetsSettingsService) {
        // Populate Scope
        $scope.temp = angular.copy(formObj);
        $scope.orig = angular.copy(formObj);
        $scope.editFlag = editFlag;
        $scope.autoTabIndex = autoTabIndex;
        $scope.patternsList = FormsDesignerListService.patterns();
        $scope.masksList = FormsDesignerListService.masks();
        $scope.tabsTemplate = FormsDesignerListService.formObjects();
        $scope.separatorList = FormsDesignerListService.separators();
        $scope.isValidRegexTestCase = true;
        $scope.formInput = {
            testPattern: null
        };
        $scope.formFields = [];
        $scope.basemaps = _.defaults(LeafletListService.basemaps(), LeafletListService.layers(false));
        $scope.overlays = LeafletListService.layers(false);
        $scope.usesUrl = LeafletListService.usesUrlLookUp();
        $scope.layerType = LeafletListService.layerTypeLookUp();
        $scope.sections = sections;

        $scope.sortBasemapOptions = {
            ignore: "input,select",
            change: function (e) {
                //The internal splice gets the old and the external place the new
                $timeout(function () {
                    $scope.temp.params.basemaps.splice(e.newIndex, 0, $scope.temp.params.basemaps.splice(e.oldIndex, 1)[0]);
                },0);
            }
        };

        $scope.sortOverlayOptions = {
            ignore: "input,select",
            change: function (e) {
                $timeout(function () {
                    $scope.temp.params.overlays.splice(e.newIndex, 0, $scope.temp.params.overlays.splice(e.oldIndex, 1)[0]);
                },0);
            }
        };

        $scope.colors = [
            {value: 'none', display: 'None'},
            {value: 'red', display: 'Red'},
            {value: 'orange', display: 'Orange'},
            {value: 'yellow', display: 'Yellow'},
            {value: 'green', display: 'Green'},
            {value: 'blue', display: 'Blue'},
            {value: 'purple', display: 'Purple'}
        ];

        // UI Helper Functions
        $scope.preventSpace = DesignerUtilityService.preventSpace;
        $scope.labelToUniqueId = function (item){
            if (!$scope.orig.model.config.modelId) {
                item.model.config.modelId = DesignerUtilityService.labelToUniqueId(item.model.label.text, idArray);
            }
        };

        // Form Object Specific Logic
        $scope.checkRegexTestCase = function(){
            if ($scope.formInput.testPattern){
                var pattern = new RegExp($scope.temp.model.validation.pattern);
                $scope.isValidRegexTestCase = $scope.formInput.testPattern.match(pattern);
            }
        };
        $scope.getFormFieldObjects = function(fieldArray){
            var objects = [];
            for(var f = 0; f < fieldArray.display.length; f++){
                var form = fieldArray.display[f];
                for(var o = 0; o < form.objects.length; o++){
                    var object = form.objects[o];
                    object.formId = form.originId;
                    objects.push(object);
                }
            }
            return objects;
        };
        $scope.deleteArrayObject = function(array,index){
            array.splice(index, 1);
        };

        $scope.checkBasemapVisibility = function(index, visible, basemaps){
            if(visible){
                for(var i = 0; i < basemaps.length; i++){
                    if(i != index && basemaps[i]){
                        basemaps[i].visible = false;
                    }
                }
            }
        };

        $scope.addLayer = function(array){
            array.push({
                name: '',
                type: null,
                visible: false
            });
        };

        $scope.removeLayer = function(array, index){
            array.splice(index, 1);
        };

        // Modal Actions
        $scope.ok = function (){
            $uibModalInstance.close($scope.temp);
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        // Init
        init();

        function init(){
            WidgetsSettingsService.getFormFields(widgetId).then(function(formFieldArray){
                DesignerUtilityService.mergeFieldsWithCurrentForm(formFieldArray, currentFormObjects);

                $scope.formFields = formFieldArray;
            });

            // Hack
            DesignerUtilityService.focusFirstElement('configForm');
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;