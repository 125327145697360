angular.module('cerberus.admin')
    .controller('WorkspacesGroupsCreateCtrl', function WorkspacesGroupsCreateCtrl($location, workspaceId, WorkspacesGroupsService) {
        var vm = this;
        vm.tempGroupModel = {
            details: {
                adv:{
                    ip:[]
                }
            },
            members: {
                current: [],
                add: [],
                remove: [],
                input: []
            },
            memberOf:{current:null}
        };
        vm.tmpIpDetails = {};
        vm.origGroupModel = angular.copy(vm.tempGroupModel);
        vm.selectedMembers = {};
        vm.selectedIps = {};
        vm.availableMembersDs = null;
        vm.membersSelectOptions = {
            placeholder: 'Enter users/groups here...',
            dataTextField: 'name'
        };

        vm.add = WorkspacesGroupsService.addItem;
        vm.remove = WorkspacesGroupsService.removeItem;
        vm.addIp = WorkspacesGroupsService.addIp;
        vm.removeIp = WorkspacesGroupsService.removeIp;
        vm.isUnchanged = function(){
            return WorkspacesGroupsService.isUnchanged(vm.tempGroupModel,vm.origGroupModel);
        };
        vm.submit = function(data){
            if(data.details.name){
                WorkspacesGroupsService.createGroup(workspaceId, data.details).then(function(value){
                    $location.url('settings/groups/' + value);
                });
            }
        };

        vm.cancel = function(){
            $location.url('/settings/permissions');
        };
    })
;