angular.module('cerberus.admin')

    .factory('WorkspacesGroupsService', function WorkspacesGroupsService(_, $http, $q, apiPath, toaster) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';

        return {
            getMembers:getMembers,
            getGroupDetails:getGroupDetails,
            getAvailableMembers: getAvailableMembers,
            createGroup: createGroup,
            getGroups: getGroups,
            addItem: addItem,
            removeItem:removeItem,
            isUnchanged: isUnchanged,
            updateDetails: updateDetails,
            updateMembers: updateMembers,
            updateGroup: updateGroup,
            deleteGroup: deleteGroup,
            deleteGroups: deleteGroups,
            addIp: addIp,
            removeIp: removeIp
        };
        ////////////////////

        function addIp(form,ipArr,model){
            if(form.$valid) {
                    ipArr.push(angular.copy(model));
                    model.start = null;
                    model.end = null;
            }
        }

        function removeIp(ipArr,selected){
            var arr = angular.copy(ipArr);
            angular.forEach(selected,function(v,k){
               if(v){
                   for(var i = 0; i< ipArr.length; i++){
                       if(ipArr[i].start == arr[k].start && ipArr[i].end == arr[k].end){
                           ipArr.splice(i,1);
                           break;
                       }
                   }
               }
                selected[k] = false;
            });
        }

        function getGroups(id) {
            var deferred = $q.defer();
            $http.get(apiPath + 'workspaces/' + id + '/groups')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getMembers(id) {
            var deferred = $q.defer();
            $http.get(apiPath + 'groups/' + id + '/members')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function getGroupDetails(id) {
            var deferred = $q.defer();
            $http.get(apiPath + 'groups/' + id)
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function createGroup(id, data){
            var deferred = $q.defer();
            $http.post(apiPath + 'workspaces/' + id + '/groups', data)
                .success(function (value) {
                    toaster.pop('success', 'Group created.');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function updateMembers(id,data){
            var deferred = $q.defer();
            $http.put(apiPath + 'groups/' + id + '/members', data)
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function updateDetails(id,data){
            var deferred = $q.defer();
            $http.put(apiPath + 'groups/' + id, data)
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function updateGroup(id, data, orig){
            var promises = [];
            //Update name and description
            if(!angular.equals(data.details, orig.details)){
                promises.push(
                    updateDetails(id, data.details)
                );
            }
            //Updating members
            if(!angular.equals(data.members.current, orig.members.current)){
                promises.push(
                    updateMembers(id,{add:data.members.add, remove:data.members.remove})
                );
            }
            return $q.all(promises).then(function(){
                toaster.pop('success', 'Group updated.');
            });
        }

        function getAvailableMembers(workspaceId,groupId) {
            var deferred = $q.defer();
            $http.get(apiPath + 'workspaces/' + workspaceId + '/groups/' + groupId + '/available/members')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function isUnchanged(temp,orig){
            var isEqualGroup = angular.equals(temp.details, orig.details);
            var isEqualMembers = angular.equals(temp.members.current, orig.members.current);
            var isEqualMemberOf = angular.equals(temp.memberOf.current, orig.memberOf.current);
            return (isEqualGroup && isEqualMembers && isEqualMemberOf);
        }

        function removeItem(model,selected,ds){
            angular.forEach(selected,function(v,k){
                if(v){
                    /* LODASH Version 4 ONLY */
                    //var foundIndex = _.findIndex(model.current, ['id', k]);
                    //var findAddIdx = _.findIndex(model.add, ['id', k]);
                    /* LODASH Version 3 & 4 Compatibility Mode */
                    var foundIndex = _.findIndex(model.current, function(f){return f.id === k;});
                    var findAddIdx = _.findIndex(model.add, function(f){return f.id === k;});
                    if(findAddIdx > -1){
                        model.add.splice(findAddIdx,1);
                    }
                    else{
                        model.remove.push(model.current[foundIndex]);
                    }
                    ds.push(model.current[foundIndex]);
                    model.current.splice(foundIndex,1);
                    selected[k] = false;
                }
            });
        }

        function addItem(model,ds){
            for(var i = 0; i < model.input.length; i++){
                model.current.push(model.input[i]);
                /* LODASH Version 4 ONLY */
                //var idx = _.findIndex(model.remove, ['id', model.input[i].id]);
                /* NON LODASH OPTION - JAVA NATIVE STYLE */
                var idx = model.remove.map(function(e){ return e.id; }).indexOf(model.input[i].id); /* NON LODASH OPTION */
                if(idx > -1){
                    model.remove.splice(idx,1);
                }
                else{
                    model.add.push(model.input[i]);
                }
                removeFromArray(ds,model.input[i].id);
            }
            model.input = [];
        }

        function deleteGroup(id){
            var deferred = $q.defer();
            $http({method: 'DELETE', url: apiPath + 'groups/' + id})
                .success(function(value){
                    deferred.resolve(value);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function deleteGroups(idArray){
            var promises = [];
            for(var i = 0; i < idArray.length; i++){
                promises.push(deleteGroup(idArray[i]));
            }
            return $q.all(promises).then(function(){
                toaster.pop('info', 'Group(s) deleted.');
            });
        }

        function removeFromArray(array,id){
            for(var i = 0; i < array.length; i++){
                if(array[i].id === id){
                    array.splice(i,1);
                }
            }
        }
    })
;
