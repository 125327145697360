angular.module('cerberus.ui')
    /**
     * @ngdoc service
     * @name LeafletListService
     * @alias cerberus/ui:LeafletListService
     * @description Provides lookup values for configuring map object layers
     */
    .factory('LeafletListService', function LeafletListService(_) {
        var service = {
            basemaps: basemaps,
            layers: layers,
            usesUrlLookUp: usesUrlLookUp,
            layerTypeLookUp: layerTypeLookUp
        };
        return service;
        ////////////////////
        function basemaps() {
            return {
                'mapbox.streets': 'Streets',
                'mapbox.light':'Light',
                'mapbox.dark':'Dark',
                'mapbox.satellite':'Satellite',
                'mapbox.streets-satellite':'Streets Satellite',
                'mapbox.wheatpaste':'Wheatpaste',
                'mapbox.streets-basic':'Streets Basic',
                'mapbox.comic':'Comic',
                'mapbox.outdoors':'Outdoors',
                'mapbox.run-bike-hike':'Run Bike Hike',
                'mapbox.pencil':'Pencil',
                'mapbox.pirates':'Pirates',
                'mapbox.emerald':'Emerald',
                'mapbox.high-contrast':'High Contrast'
                //'custom': 'Custom'
            };
        }
        function layers(includeNimLayers) {
            var overlays = {
                'esri.tiledMapLayer':'Esri Tile Layer',
                'esri.dynamicMapLayer': 'Esri Dynamic Layer',
                'esri.featureLayer': 'Esri Feature Layer',
                //'esri.clusteredFeatureLayer': 'Esri Marker Clustered Layer',
                //'esri.imageMapLayer': 'Esri Image Layer',
                'L.tileLayer': 'Tile Layer',
                'L.tileLayer.wms': 'WMS Tile Layer',
                'nim.geoJson': 'Geographic Data',
                //'L.heatLayer': 'Heat Layer',
                //'L.KML': 'KML Layer',
                //'L.geoJson': 'Geo JSON Layer',
                'nim.marker': 'Marker Layer',
                'nim.markerClusterGroup': 'Marker Clustered Layer'
            };

            if(includeNimLayers === false){
                overlays = _.omit(overlays, ['nim.marker', 'nim.markerClusterGroup', 'nim.geoJson']);
            }

            return overlays;
        }
        function usesUrlLookUp(){
            return {
                'esri.tiledMapLayer': true,
                'esri.dynamicMapLayer': true,
                'esri.featureLayer': true,
                'L.tileLayer': true,
                'L.tileLayer.wms': true,
                'L.KML': true
            };
        }
        // Only for wms for now
        function layerTypeLookUp(){
            return {
                'mapbox.streets': 'mapbox',
                'mapbox.light':'mapbox',
                'mapbox.dark':'mapbox',
                'mapbox.satellite':'mapbox',
                'mapbox.streets-satellite':'mapbox',
                'mapbox.wheatpaste':'mapbox',
                'mapbox.streets-basic':'mapbox',
                'mapbox.comic':'mapbox',
                'mapbox.outdoors':'mapbox',
                'mapbox.run-bike-hike':'mapbox',
                'mapbox.pencil':'mapbox',
                'mapbox.pirates':'mapbox',
                'mapbox.emerald':'mapbox',
                'mapbox.high-contrast':'mapbox',
                'esri.tiledMapLayer': 'esri',
                'esri.dynamicMapLayer': 'esri',
                'esri.featureLayer': 'esri',
                'esri.clusteredFeatureLayer': 'esri',
                'esri.imageMapLayer': 'esri',
                'L.tileLayer': 'leaflet',
                'L.tileLayer.wms': 'wms',
                'nim.geoJson': 'nim',
                'L.heatLayer': 'leaflet',
                'L.KML': 'leaflet',
                'L.geoJson': 'leaflet',
                'nim.marker': 'nim',
                'nim.markerClusterGroup': 'nim'
            };
        }
    })
;