angular.module('cerberus.admin')
.directive('nimColumnSortResize', function ($compile, $timeout) {
        return {
            restrict: 'AE',
            scope: {
                data: '=nimColumnSortResize'
            },
            controller: function ($scope) {
                $scope.gridOptions = {
                    dataSource: {
                        data: []
                    },
                    resizable: true,
                    reorderable: true,
                    columns: angular.copy($scope.data),
                    columnResize: function (e) {
                        for (var i = 0; i < $scope.data.length; i++) {
                            if ($scope.data[i].field === e.column.field) {
                                $scope.data[i].width = e.newWidth;
                            }
                        }
                    },
                    columnReorder: function(e){
                        $timeout(function () {
                            $scope.$emit('nim-column-sorted', {columns: e.sender.columns, newIndex: e.newIndex, oldIndex: e.oldIndex});
                            $scope.data = angular.copy(e.sender.columns);
                        });
                    }
                };
            },
            link: function (scope, element) {
                var template = '<div kendo-grid="grid" k-options="gridOptions"></div>';
                var build = function () {
                    if (scope.grid) {
                        try {
                            scope.grid.destroy();
                        }
                        catch (e) {
                            //who gives a fuck
                        }
                    }
                    element.empty().append(template);
                    $compile(element.contents())(scope);
                };
                element.on('click', 'div.k-grid th', function () {
                    var field = $(this).data('field');
                    var index = $(this).parent().children().index($(this));
                    scope.$emit('columnClick', {
                        index: index,
                        field: field
                    });
                });
                //watchCollection only goes one level deep (mostly for arrays)
                scope.$watch('data', function (newValue) {
                    scope.gridOptions.columns = angular.copy(newValue);
                    build();
                }, true);

                scope.$on('$destroy', function () {
                   element.off('click');
                });
            }
        };
    })
;