angular.module('cerberus.admin')
    /**
     * @ngdoc service
     * @name PageDesignerService
     * @alias cerberus/pages-designer:PageDesignerService
     * @description REST service for "pages" resource
     */
    .factory('PageDesignerService', function PageDesignerService($http, $q, apiPath, toaster) {
            return {
                update: update,
                create: create,
                updatePagePermissions: updatePagePermissions,
                deletePage: deletePage,
                createObjects: createObjects,
                updateObjects: updateObjects,
                removeObjects: removeObjects
            };
            ///////////////////
            /**
             * Saves page edits to server
             * @function update
             * @param {string} id
             * @param {string} name
             * @param {string} description
             * @param {string} icon
             * @param {object} options
             * @return {promise}
             */
            function update(id, options, name, description, icon) {
                var deferred = $q.defer();
                $http.put(apiPath + 'pages/'+ id, {
                        options: options,
                        name: name,
                        description: description,
                        icon: icon
                    })
                    .success(function(value){
                        deferred.resolve(value.DATA);
                    })
                    .error(function(reason){
                        deferred.reject(reason);
                    });
                return deferred.promise;
            }

            /**
             * Creates a new page
             * @function create
             * @param {object} data - page
             * @return {promise}
             */
            function create(data) {
                var deferred = $q.defer();
                $http.post(apiPath + 'pages', data)
                    .success(function(value){
                        deferred.resolve(value.DATA);
                    })
                    .error(function(reason){
                        deferred.reject(reason);
                    });
                return deferred.promise;
            }

            /**
             * Updates a page's permissions
             * @function updatePagePermissions
             * @param {string} id - empty page
             * @param {object} data - permissions object
             * @return {promise}
             */
            function updatePagePermissions(id, data) {
                var deferred = $q.defer();
                $http.put(apiPath + 'pages/' + id + '/permissions', data)
                    .success(function(value){
                        deferred.resolve(value.DATA);
                    })
                    .error(function(reason){
                        deferred.reject(reason);
                    });
                return deferred.promise;
            }

            /**
             * Deletes a page from the server
             * @function deletePage
             * @param {string} id
             * @return {promise}
             */
            function deletePage(id) {
                var deferred = $q.defer();
                $http({method: 'DELETE', url: apiPath + 'pages/' + id})
                    .success(function(value){
                        deferred.resolve(value);
                    })
                    .error(function(reason){
                        deferred.reject(reason);
                    });
                return deferred.promise;
            }

            /**
             * Adds page objects
             * @function createObjects
             * @param {string} id
             * @param {array} data
             * @returns {promise}
             */
            function createObjects(id, data){
                var deferred = $q.defer();
                $http.post(apiPath + 'pages/' + id + '/objects', {pageObjects: data})
                    .success(function(value){
                        deferred.resolve(value);
                    })
                    .error(function(reason){
                        toaster.pop('warning', 'Warning', 'New Page Objects not created');
                        deferred.reject(reason);
                    });
                return deferred.promise;
            }

            /**
             * Updates page objects
             * @function updateObjects
             * @param {string} id
             * @param {array} data
             * @returns {promise}
             */
            function updateObjects(id, data){
                var deferred = $q.defer();
                $http.put(apiPath + 'pages/' + id + '/objects', {pageObjects: data})
                    .success(function(value){
                        deferred.resolve(value);
                    })
                    .error(function(reason){
                        toaster.pop('warning', 'Warning', 'Page Objects not updated');
                        deferred.reject(reason);
                    });
                return deferred.promise;
            }

            /**
             * Removes page objects
             * @function removeObjects
             * @param {string} id
             * @param {array} data
             * @returns {promise}
             */
            function removeObjects(id, data){
                var deferred = $q.defer();
                $http.put(apiPath + 'pages/' + id + '/objects/remove', {pageObjectIds: data})
                    .success(function(value){
                        deferred.resolve(value);
                    })
                    .error(function(reason){
                        toaster.pop('warning', 'Warning', 'Page Objects not removed');
                        deferred.reject(reason);
                    });
                return deferred.promise;
            }
        })
;
