angular.module('cerberus.cpc')
    .controller('updateFinishedProductLocationOpenModalCtrl', function(_, $scope, $uibModalInstance, inventoryService){
        var vm = this;
        
        
        // Never finished... In development mode...
        
        
        vm.emptyValue = '';
        vm.rollpositions_textarea = '';
        vm.rowPositionSelected = '';
        vm.rowPositionList = [
            {value: '1', display: 'Row position 1'},
            {value: '2', display: 'Row position 2'},
            {value: '3', display: 'Row position 3'}
        ];

        vm.importingDataInProgress = false;
        vm.importingDataShowError = false;
        vm.isUnchanged = isUnchanged;
        vm.submit = submit;
        vm.cancel = cancel;

        // -- add here necessary fuctions needed to be processed within controller, when done, remove this... //

        // Check if text is empty
        function isUnchanged() {
            return angular.equals(vm.rollpositions_textarea, vm.emptyValue);
        }

        // Submit button execution
        function submit() {
            //DEVELOPMENT MODE
            //BLOCK SUBMIT BUTTON
            if (vm.rollpositions_textarea != 'continue') {
                return;
            }

            // Basic validation test, nothing special
            if (vm.rollpositions_textarea == 'test') {
                return;
            }
            // Manage progress monitoring
            vm.importingDataInProgress = true;
            vm.importingDataShowError = false;
            // Initiate function here
            var action = 'update_roll_positions'; // specify here exact action to be executed
            var data = {'values': vm.rollpositions_textarea, 'rowposition': vm.rowPositionSelected};
            inventoryService.updateRollPositions(action, data).then(function (result) {
                vm.importingDataInProgress = false;
                //console.log(result);
                if (result.errMsg) {
                    vm.importingDataShowError = true;
                 //   vm.importError = result.errMsg;
                 //console.log('aaaaaaaaaaabbbbbb');
                }
                //else if (result.instanceId) {
                //    $uibModalInstance.close(result.instanceId);
                //}
                else {
                    $uibModalInstance.close(result.instanceId);
                }
            }, function () {
                vm.importingDataInProgress = false;
                vm.importingDataShowError = false;
            });
        }

        // Cancel button execution
        function cancel() {
            $uibModalInstance.dismiss();
        }

        // Removes Kendo widgets
        //$scope.$on('$destroy', function() {
        //    _.result(vm, 'updateInventoryUpload.destroy');
        //});

        // Prevents navigation while modal is open
        var allowNavigation = $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });

        $scope.$on('modal.closing', function () {
            allowNavigation();
        });
    })
;