angular.module('cerberus.les')
    .config(function($routeProvider){
        $routeProvider.when('/loan-applications/create', {
            templateUrl: 'les/loan-applications/templates/loan-application-import-page.tpl.html',
            controller: 'LESLoanApplicationImportPageCtrl',
            controllerAs: 'vm',
            title: 'Import Loan Application',
            resolve: {
                _wait: 'WaitForUserData',
                loanApplicationId: function($route){
                    return $route.current.params.id;
                }
            }
        });

        $routeProvider.when('/loan-applications/:id', {
            templateUrl: 'les/loan-applications/templates/loan-application.tpl.html',
            controller: 'LESLoanApplicationCtrl',
            controllerAs: 'vm',
            title: 'Loan Application',
            resolve: {
                _wait: 'WaitForUserData',
                loanApplicationId: function($route){
                    return $route.current.params.id;
                }
            }
        });

        $routeProvider.when('/loan-applications/:id/eligibility', {
            templateUrl: 'les/loan-applications/templates/loan-application-eligibility.tpl.html',
            controller: 'LESLoanApplicationEligibilityCtrl',
            controllerAs: 'vm',
            title: 'Eligibility',
            resolve: {
                _wait: 'WaitForUserData',
                loanApplicationId: function($route){
                    return $route.current.params.id;
                }
            }
        });
    })
;