angular.module('cerberus.admin')
/**
 * @ngdoc service
 * @name FormsDesignerConvenienceService
 * @alias cerberus/forms:FormsDesignerConvenienceService
 * @description Modifies Field classes for user convenience
 */
    .factory('FormsDesignerConvenienceService', function FormsDesignerConvenienceService(FormsClassService){
        return {
            currency: currency,
            wholeNumber: wholeNumber,
            decimal: decimal,
            email: email,
            percentage: percentage,
            phonenumber: phonenumber,
            socialsecurity: socialsecurity,
            url: url,
            zip: zip,
            shortDate: shortDate,
            longDate: longDate,
            twentyFourHourTime: twentyFourHourTime,
            twelveHourTime: twelveHourTime,
            shortDateTime: shortDateTime,
            longDateTime: longDateTime,
            hiddenText: hiddenText,
            hiddenNumber: hiddenNumber,
            hiddenDatetime: hiddenDatetime,
            hiddenDropDown: hiddenDropDown
        };

        /**
         * Builds a number field modified for currency values
         * @function currency
         * @returns {Field}
         */
        function currency(){
            var field = FormsClassService.number();
            field.display.format = "c";
            field.param.decimals = 2;
            field.param.step = 0.01;
            return field;
        }

        /**
         * Builds a number field modified for integer values
         * @function wholeNumber
         * @returns {Field}
         */
        function wholeNumber(){
            var field = FormsClassService.number();
            field.display.format = "n0";
            field.param.decimals = 0;
            field.param.step = 1;
            return field;
        }

        /**
         * Builds a number field modified for decimal values
         * @function decimal
         * @returns {Field}
         */
        function decimal(){
            var field = FormsClassService.number();
            field.display.format = "n";
            field.param.decimals = 2;
            field.param.step = 0.01;
            return field;
        }

        /**
         * Builds a text field modified for email addresses
         * @function email
         * @returns {Field}
         */
        function email(){
            var field = FormsClassService.text();
            field.validation.pattern = '/^\\w[\\w\\.\\-]*@\\w+[\\w\\.\\-]*\.[a-zA-Z]{2,}$/';
            return field;
        }

        /**
         * Builds a number field modified for percentage values
         * @function percentage
         * @returns {Field}
         */
        function percentage(){
            var field = FormsClassService.number();
            field.display.format = "p";
            field.param.decimals = 2;
            field.param.step = 0.01;
            return field;
        }

        /**
         * Builds a text field modified for phone numbers
         * @function phonenumber
         * @returns {Field}
         */
        function phonenumber(){
            var field = FormsClassService.text();
            field.validation.mask = '(000) 000-0000';
            return field;
        }

        /**
         * Builds a text field modified for social security numbers
         * @function socialsecurity
         * @returns {Field}
         */
        function socialsecurity(){
            var field = FormsClassService.text();
            field.validation.mask = '000-00-0000';
            return field;
        }

        /**
         * Builds a text field modified for URLs
         * @function url
         * @returns {Field}
         */
        function url(){
            var field = FormsClassService.text();
            field.validation.pattern = '/^((http|https):\\/\\/)?[a-zA-Z]+\\.[a-zA-Z\\.]*[a-zA-Z]+(:[0-9]+)?[a-zA-Z0-9\\.\\?#\\-%&=\\/_]*$/';
            return field;
        }

        /**
         * Builds a text field modified for ZIP codes
         * @function zip
         * @returns {Field}
         */
        function zip(){
            var field = FormsClassService.text();
//                field.validation.mask = "00000-0000"; // ZIP +4
            field.validation.mask = "00000";
            return field;
        }

        /**
         * Builds a datetime field with short date format
         * @function shortDate
         * @returns {Field}
         */
        function shortDate(){
            var field = FormsClassService.datetime();
            field.display.format = 'd';
            return field;
        }

        /**
         * Builds a datetime field with long date format
         * @function longDate
         * @returns {Field}
         */
        function longDate(){
            var field = FormsClassService.datetime();
            field.display.format = 'D';
            return field;
        }

        /**
         * Builds a datetime field with 24-hour time format
         * @function twentyFourHourTime
         * @returns {Field}
         */
        function twentyFourHourTime(){
            var field = FormsClassService.datetime();
            field.display.format = 'HH:mm';
            return field;
        }

        /**
         * Builds a datetime field with 12-hour time format
         * @function twelveHourTime
         * @returns {Field}
         */
        function twelveHourTime(){
            var field = FormsClassService.datetime();
            field.display.format = 'h:mm tt';
            return field;
        }

        /**
         * Builds a datetime field that shows short date-time format
         * @function shortDateTime
         * @returns {Field}
         */
        function shortDateTime(){
            var field = FormsClassService.datetime();
            field.display.format = 'g';
            return field;
        }

        /**
         * Builds a datetime field that shows long date-time format
         * @function shortDateTime
         * @returns {Field}
         */
        function longDateTime(){
            var field = FormsClassService.datetime();
            field.display.format = 'G';
            return field;
        }

        function hiddenText(){
            var field = FormsClassService.text();
            field.config.isHidden = true;
            return field;
        }

        function hiddenNumber(){
            var field = FormsClassService.number();
            field.config.isHidden = true;
            return field;
        }

        function hiddenDatetime(){
            var field = FormsClassService.datetime();
            field.config.isHidden = true;
            return field;
        }

        function hiddenDropDown(){
            var field = FormsClassService.select();
            field.config.isHidden = true;
            return field;
        }
    })
;
