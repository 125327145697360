/*jshint bitwise: false*/
angular.module('cerberus.admin')
    .controller('MenusDesignerCtrl', function MenusDesignerCtrl($scope, $location, $uibModal, menuId, MenusService, PagesService, PageDesignerService, DesignerUtilityService) {
        var vm = this;

        vm.menuId = menuId;
        vm.menu = {};
        vm.orig = [];
        vm.pages = [];
        vm.pagesCopy = [];
        vm.pageFilter = '';

        vm.createArray = [];
        vm.updateArray = [];
        vm.deleteArray = [];

        vm.settingsCollapse = true;
        vm.menuTree = angular.element('#menu-root').scope();
        // For scaling pages panel to menu panel height
        vm.menuPanel = document.getElementById("menu-tree-panel");

        init();
        DesignerUtilityService.cancelRouteNav($scope, hasChanges);

        vm.isUnchanged = isUnchanged;
        vm.save = save;
        vm.close = close;
        vm.openMenuPageModal = openMenuPageModal;
        vm.deleteItem = deleteItem;
        vm.deleteMenu = deleteMenu;
        vm.open = open;

        // For keeping all page items in page tree after dragging one to menu
        $scope.$watchCollection('vm.pages', function(pages){
            if(!angular.equals(pages, vm.pagesCopy)){
                vm.pages = angular.copy(vm.pagesCopy);
            }
        });

        function init(){
            MenusService.getMenu(vm.menuId).then(function(menu){
                vm.menu = menu;
                vm.menu.is_default = vm.menu.is_default == 1;
                vm.orig = angular.copy(vm.menu);
            });

            PagesService.getPages().then(function(pages){
                var formattedPages = pages;
                for(var p = 0; p < formattedPages.length; p++){
                    formattedPages[p] = pageToMenuItem(formattedPages[p]);
                }

                vm.pages = angular.copy(formattedPages);
                vm.pagesCopy = angular.copy(formattedPages);
            });
        }

        function pageToMenuItem(page){
            return {
                description: page.description,
                icon: page.icon,
                id: getId() + 1,
                isDefault: false,
                items: [],
                pageId: page.id,
                permissions: {},
                title: page.name
            };
        }

        function isUnchanged(){
            return angular.equals(vm.orig, vm.menu);
        }

        function hasChanges(){
            return !isUnchanged();
        }

        function save(closeMenu){
            var menuData = angular.copy(vm.menu);
            open(menuData.items, false);    // Set Menu items to be closed by default
            MenusService.updateMenu(vm.menuId, menuData).then(function(){
                vm.orig = angular.copy(vm.menu);
                if(closeMenu){
                    close();
                }
            });
        }

        function close (){
            $location.url('/settings/menus');
        }

        function openMenuPageModal (menuData) {
            var editFlag = menuData != null;
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/menus/designer/menus-designer-modal.tpl.html',
                controller: 'MenusDesignerModalCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    menuData: function(){
                        return menuData;
                    },
                    editFlag: function(){
                        return editFlag;
                    }
                }
            });
            modalInstance.result.then(function (data) {
                if(editFlag){
                    if(data.isDefault && !menuData.isDefault){
                        setDefaultPage(data.id);
                    }
                    editItem(data);
                }
                else{
                    var newPage = {
                        name: data.title,
                        icon: data.icon,
                        description: data.description,
                        permissions: data.permissions
                    };
                    PageDesignerService.create(newPage).then(function(pageId){
                        data.id = getId() + 1;
                        data.pageId = pageId;
                        vm.pagesCopy.push(data);
                        vm.pages.push(data);
                    });
                }
            });
        }

        function getId(){
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {var r = Math.random()*16|0,v=c=='x'?r:r&0x3|0x8;return v.toString(16);});
        }

        function setDefaultPage(id){
            MenusService.setDefaultPage(id, vm.menu.items);
        }

        function editItem(item){
            var arr = vm.menu.items;
            var edit = function(array){
                for(var i = 0;i < array.length; i++){
                    if(array[i].id === item.id){
                        array[i] = item;
                        return;
                    }
                    else{
                        edit(array[i].items);
                    }
                }
            };
            edit(arr);
        }

        function deleteItem(item){
            var arr = vm.menu.items;
            var menuObj;
            var del = function(array){
                for(var i = 0;i < array.length; i++){
                    if(array[i] === item){
                        menuObj = array[i];
                        array.splice(i,1);
                        return;
                    }
                    else{
                        del(array[i].items);
                    }
                }
            };
            del(arr);
        }

        function deleteMenu(){
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/menus/designer/delete-modal.tpl.html',
                controller: 'DeleteMenuModalCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    menu: function (){
                        return vm.menu;
                    }
                }
            });
            modalInstance.result.then(function() {
                MenusService.deleteMenu(vm.menuId).then(function(){
                    close();
                });
            });
        }

        // Sets the 'open' attribute for menu items
        function open(items, expand) {
            for(var i = 0; i < items.length; i++){
                items[i].open = expand;
                open(items[i].items);
            }
        }
    })
;