angular.module('cerberus.core')
/********FROM the Angular 1.3.8 docs*********
 *
 *      enter: function(element, done) {
 *       //run the animation here and call done when the animation is complete
 *       return function(cancelled) {
 *         //this (optional) function will be called when the animation
 *         //completes or when the animation is cancelled (the cancelled
 *         //flag will be set to true if cancelled).
 *       };
 *     },
 *     leave: function(element, done) { },
 *     move: function(element, done) { },
 *
 *     //animation that can be triggered before the class is added
 *     beforeAddClass: function(element, className, done) { },
 *
 *     //animation that can be triggered after the class is added
 *     addClass: function(element, className, done) { },
 *
 *     //animation that can be triggered before the class is removed
 *     beforeRemoveClass: function(element, className, done) { },
 *
 *     //animation that can be triggered after the class is removed
 *     removeClass: function(element, className, done) { }
 */
    .animation('.side-nav-open-animation', function ($window) {
            return {
                beforeAddClass : function(element, className, done) {
                    if(className === 'ng-hide') {
                        var tl = new $window.TimelineMax({onComplete:done});
                        var anchors = element.children('li').children('.route');
                        tl.set(element, {height:anchors.outerHeight() * anchors.length, overflow:'hidden'});
                        tl.staggerTo(element.children('li'), 0.25, {marginLeft: -20, opacity: 0.5}, -0.03);
                        tl.to(element, 0.25, {css:{height: 0, clearProps:"height"}, ease:$window.Power1.easeOut}, "-=0.25");
                    }
                },
                removeClass : function(element, className, done) {
                    if(className === 'ng-hide') {
                        var tl = new $window.TimelineMax({onComplete:done});
                        var anchors = element.children('li').children('.route');
                        tl.set(element, {height:0});
                        tl.set(element.children('li'), {marginLeft:-20, opacity:0.5});
                        tl.to(element, 0.25, {css:{height: anchors.outerHeight() * anchors.length, clearProps:"height, overflow"}, ease:$window.Power3.easeOut});
                        tl.staggerTo(element.children('li'), 0.25, {marginLeft: 0, opacity: 1}, 0.03, "-=0.25");
                    }
                }
            };
        })
;