angular.module('cerberus.admin')
    .controller('WorkspacesWhitelistCtrl', function WorkspacesWhitelistCtrl($scope, $location, workspaceSettingsFactory){
        $scope.pageTitle = 'Upload Whitelist';
        $scope.masterWhitelist = [];
        $scope.whitelist = '';

        $scope.isUnchanged = _isUnchanged;
        $scope.save = _save;
        $scope.cancel = _cancel;

        _init();

        function _init(){
            workspaceSettingsFactory.getSettings('whiteList').then(function(settingsModel){
                $scope.masterWhitelist = angular.fromJson(angular.copy(settingsModel.whiteList));
                $scope.whitelist = $scope.masterWhitelist.join(', ');
            });
        }

        function toArray(listString){
            return listString.replace(/ /g, '').replace('\n', '').split(',');
        }

        function _isUnchanged(list){
            return angular.equals($scope.masterWhitelist, toArray(list));
        }

        function _save(list){
            workspaceSettingsFactory.putSettings({settings:{whiteList:toArray(list)}}).then(function(){
                $location.url('/settings');
            });
        }

        function _cancel(){
            $location.replace().url('/settings');
        }
    })
;