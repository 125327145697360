angular.module('cerberus.admin')
    .controller('TabsetConfigCtrl', function TabsetConfigCtrl(_, $scope, $uibModalInstance, $timeout, pageObject, filters,
                                                              PagesService, PagesClassService, PagesDesignerListService,
                                                              pageObjectConfigService, DesignerUtilityService) {
        var vm = this;

        vm.temp = angular.copy(pageObject);
        vm.orig = angular.copy(pageObject);
        vm.filters= filters;
        vm.margin = DesignerUtilityService.cssToArray(pageObject.wrapper.style.margin) || [0,0,0,0];
        vm.padding = DesignerUtilityService.cssToArray(pageObject.wrapper.style.padding) || [0,0,0,0];
        vm.wrapperTypes = PagesDesignerListService.wrapperTypes();
        vm.wrapperConfig = PagesDesignerListService.wrapperConfig();
        vm.pages = [];
        vm.paletteOptions = {
            palette: PagesDesignerListService.palette(),
            columns: 31,
            tileSize: 18
        };
        vm.filterMap = {};

        vm.colSelected = {};

        vm.tabTemplates = [{
            name: "General",
            url: "admin/pages/designer/tabset/general-tabset-tab.tpl.html"
        }];

        vm.sortableOptions = pageObjectConfigService.createSortableOptions(vm.temp.params.tabs);

        // Helper functions
        vm.capitalize = _.capitalize;

        // Tab Commands
        vm.addTab = function () {
            var newTab = PagesClassService.tab();
            vm.temp.params.tabs.push(newTab);
        };

        vm.removeTab = function (index) {
            vm.temp.params.tabs.splice(index,1);
        };

        // Modal Commands
        vm.save = function () {
            if(vm.temp.wrapper.type === 'circletile') {
                vm.temp.wrapper.header.style.background = vm.temp.wrapper.body.style.background;
            }
            vm.temp.wrapper.style.margin = DesignerUtilityService.arrayToCss(vm.margin);
            vm.temp.wrapper.style.padding = DesignerUtilityService.arrayToCss(vm.padding);
            $uibModalInstance.close(vm.temp);
        };

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        init();

        function init(){
            PagesService.getPages().then(function(pages){
                vm.pages = pages;
            });
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;