angular.module("cerberus.ui-util")
    .directive('nimInputImage', function () {
        return {
            restrict: 'AE',
            scope: {
                image: '=nimInputImage'
            },
            link: function (scope, element) {

                var handleFileSelect=function(evt) {
                    var file = evt.currentTarget.files[0];
                    var reader = new FileReader();
                    reader.onload = function (evt) {
                        scope.$apply(function(scope){
                            scope.image = evt.target.result;
                        });
                    };
                    reader.readAsDataURL(file);
                };

                element.on('change', handleFileSelect);

                scope.$on('$destroy', function () {
                   element.off('change');
                });
            }
        };
    })
;

