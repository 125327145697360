angular.module('cerberus.util')
    .controller('PrintConfigurationModalCtrl', function(_, $scope, $q, $uibModalInstance, defaultConfiguration, enabledPrintTypes,
                                                           instanceIds, displayValues, widgetId, InstancesService, WidgetsService){
        var vm = this;

        vm.instanceIds = _.uniq(instanceIds);
        vm.forms = [];
        vm.startingCheckNumber = 0;
        vm.checkNumberChanged = false;
        // vm.fileNameChanged = false;
        vm.displayValues = displayValues;
        vm.checkNumberFieldMissing = false;
        vm.showPageTotals = false;
        vm.useDefaultMargin = true;

        vm.printConfiguration = {
            // filename: '',
            type: '',
            defaultMargin: { top: 0, right: 0, bottom: 0, left: 0 },
            normal: {
                paperSize: 'letter',
                landscape: false,
                margin: 0
            },
            check: {
                targetForm: '',
                pageCounter: '',
                checksPerPage: 1,
                carbonCopy: true,
                checkNumbers: {},
                checkNumExists: {}
            },
            label: {
                targetForm: '',
                pageTotal: {}
            }
        };

        vm.printTypes = [
            {value: 'normal', display: 'Normal'},
            {value: 'check', display: 'Checks'},
            {value: 'label', display: 'Labels'}
        ];

        vm.supportedPaperSizes = [
            {value: 'legal', display: 'Legal'},
            {value: 'letter', display: 'Letter'},
            {value: 'tabloid', display: 'Tabloid'}
        ];

        vm.pdfOrientations = [
            {value: false, display: 'Portrait'},
            {value: true, display: 'Landscape'}
        ];

        vm.checksPerPageOptions = [
            {value: 1, display: "1-Up"},
            {value: 3, display: "3-Up"}
        ];

        vm.enabledPrintTypes = enabledPrintTypes || {};

        vm.confirm = _confirm;
        vm.cancel = _cancel;
        vm.printTypeIsEnabled = _printTypeIsEnabled;
        vm.printTypeChanged = _printTypeChanged;
        vm.startingNumberChanged = _setCheckNumbers;
        vm.loadFormSettings = _loadFormSettings;
        vm.printConfigIncomplete = _printConfigIncomplete;

        _init();

        function _init() {
            _.assign(vm.printConfiguration, defaultConfiguration);

            if(vm.printConfiguration.type) {
                _printTypeChanged(vm.printConfiguration.type);
            }
        }

        function _confirm(config) {
            var options = config[config.type];

            if (vm.useDefaultMargin) {
                options.margin = config.defaultMargin;
            }

            $uibModalInstance.close({
                // filename: config.filename,
                type: config.type,
                options: config[config.type]
            });
        }

        function _cancel() {
            $uibModalInstance.dismiss('Canceled');
        }

        function _printTypeIsEnabled(printTypeOption) {
            return vm.enabledPrintTypes[printTypeOption.value];
        }

        function _printTypeChanged(type) {
            // Gets all forms of the selected type. Normal printing includes
            // whole instance, so no individual form needs to be selected.
            if(type !== 'normal') {
                WidgetsService.getAllForms(widgetId, type).then(function (forms) {
                    vm.forms = forms;

                    if (!_.isEmpty(forms)) {
                        // Automatically set first form as the target form
                        var formOriginId = forms[0].originatorId;

                        vm.printConfiguration[type].targetForm = formOriginId;
                        _loadFormSettings(formOriginId, type);
                    }
                });
            }

            if(type === 'check') {
                // Set check numbers for each instance
                _setCheckNumbers(vm.startingCheckNumber);
            }
        }

        function _setCheckNumbers(startingNumber) {
            // If check numbers have not been manually changed, automatically set them based on startingNumber
            if(!vm.checkNumberChanged) {
                vm.printConfiguration.check.checkNumbers = {};
                vm.printConfiguration.check.checkNumExists = {};
                var modelId = vm.printConfiguration.check.pageCounter;
                var instanceIndex = 0;
                var formArray = [];
                if(vm.printConfiguration.check.targetForm) {
                    formArray = [{
                        formId: vm.printConfiguration.check.targetForm,
                        isPk: false,
                        displayOrder: '1'
                    }];
                }
                _.forEach(instanceIds, function(id) {
                    InstancesService.getFormData(id, formArray, null, true).then(function (result) {
                        if(modelId && !vm.checkNumberFieldMissing && result.recordData[modelId]) {
                            vm.printConfiguration.check.checkNumbers[id] = result.recordData[modelId];
                            vm.printConfiguration.check.checkNumExists[id] = true;
                        }
                        else {
                            instanceIndex++;
                            vm.printConfiguration.check.checkNumbers[id] = startingNumber + instanceIndex;
                            vm.printConfiguration.check.checkNumExists[id] = false;
                        }
                    });
                });
            }
        }

        function _loadFormSettings(formOriginId, type) {
            if(formOriginId){
                var form = _.find(vm.forms, 'originatorId', formOriginId);
                vm.checkNumberFieldMissing = false;

                if (form.settings.print.margin) {
                    vm.printConfiguration.defaultMargin = form.settings.print.margin;
                }

                if(type === 'label') {
                    var formArray = [{
                            formId: formOriginId,
                            isPk: false,
                            displayOrder: '1'
                        }],
                        pageTotalModel = form.settings.print.pageTotal;

                    vm.printConfiguration.label.paperSize = form.settings.print.pageSize || [8.5, 11];
                    vm.printConfiguration.label.margin = angular.copy(vm.printConfiguration.defaultMargin);

                    if (pageTotalModel) {
                        _.forEach(instanceIds, function (id) {
                            // Get the default number of pages for this record
                            InstancesService.getFormData(id, formArray, null, true).then(function (result) {
                                // Use value set in pageTotal field as the default pageTotal value
                                vm.printConfiguration.label.pageTotal[id] = result.recordData[pageTotalModel] || 1;
                            });
                        });

                        vm.showPageTotals = true;
                    }
                    else {
                        _.forEach(instanceIds, function (id) {
                            vm.printConfiguration.label.pageTotal[id] =  1;
                        });

                        vm.showPageTotals = false;
                    }
                }
                else if(type === 'check') {
                    var modelId = form.settings.print.pageCounter;
                    vm.printConfiguration.check.pageCounter = modelId;
                    vm.printConfiguration.check.paperSize = form.settings.print.pageSize || [8.5, 11];
                    vm.printConfiguration.check.margin = angular.copy(vm.printConfiguration.defaultMargin);

                    /* Get Max Page Counter - Find last check entered in the system */
                    if (modelId) {
                        WidgetsService.getMax(widgetId, modelId).then(function(result) {
                            vm.startingCheckNumber = result.max + 1;
                            vm.checkNumberChanged = false;
                            _setCheckNumbers(vm.startingCheckNumber);
                        });
                    }
                    else {
                        vm.checkNumberFieldMissing = true;
                    }
                }
                else if (type === 'normal') {
                    vm.printConfiguration.normal.margin = angular.copy(vm.printConfiguration.defaultMargin);
                }
            }
        }

        // Checks if configuration is incomplete based on print type
        function _printConfigIncomplete(type/*, filename*/) {
            // if(!type || !filename){
            if(!type){
                return true;
            }
            else if(type === 'check') {
                var checkSettings = vm.printConfiguration.check;

                return !checkSettings.targetForm || !checkSettings.pageCounter || !_.every(checkSettings.checkNumbers) || !checkSettings.paperSize[0] || !checkSettings.paperSize[1];
            }
            else if(type === 'label') {
                var labelSettings = vm.printConfiguration.label;

                var pageTotalsSet = _.every(instanceIds, function (id) {
                    return _.isNumber(labelSettings.pageTotal[id]) && labelSettings.pageTotal[id] > 0;
                });

                return !labelSettings.targetForm || !pageTotalsSet || !labelSettings.paperSize[0] || !labelSettings.paperSize[1];
            }

            return false;
        }

        // Prevents navigation while in the modal.
        // This is cleared when the modal scope is destroyed.
        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;