angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name FormTabIndexService
     * @alias cerberus/core:FormTabIndexService
     * @description Provides functionality for manually handling tabbing between form fields
     */
    .factory('FormTabIndexService', function FormTabIndexService(kendo) {
        var service = {
            getFocusElement: getFocusElement
        };
        return service;
        ////////////////////

        function getFocusElement(e, windowContent){
            if (e.keyCode == kendo.keys.TAB) {
                e.preventDefault();
                e.stopPropagation();

                var tabEles = windowContent.find('[tabindex]');

                for(var i = 0; i < tabEles.length; i++){
                    if($(tabEles[i]).is(':focus')){
                        $(tabEles[i+1]).focus();
                    }
                }
            }
        }
    })
;
