angular.module('cerberus.admin')
    .factory('AutoFillService', function AutoFillService(_) {
        return {
            autoFillObject: autoFillObject,
            dataSeriesAutoFillObject: dataSeriesAutoFillObject,
            autoFillSubItem: autoFillSubItem,
            setAutoFillMetaData: setAutoFillMetaData,
            setDataSeriesAutoFillData: setDataSeriesAutoFillData,
            setTargetFormId: setTargetFormId,
            setSubItemIds: setSubItemIds
        };

        /**************************************************/

        /**
         * Returns json object for autoFill item
         * @returns {Object}
         */
        function autoFillObject(){
            return {
                type: '',       // The source column type
                value: '',      // The source model id
                persists: false,
                current: {      // The target model id and it's info
                    formId: '', // target form origin id
                    modelId: ''
                }
            };
        }

        /**
         * Returns json object for dataSeries' autoFill item
         * @returns {Object}
         */
        function dataSeriesAutoFillObject(){
            return {
                type: '',
                value: '',
                current: {
                    formId: '',
                    modelId: ''
                },
                // Additional Info needed if type === 'dataseries'
                widgetId: '',   // The source modelId's widget id
                fk: '',         // The source's fk
                targetWidgetId: '',
                subMap: []
            };
        }

        /**
         * Returns json object for autoFill subMap item
         * @returns {Object}
         */
        function autoFillSubItem(){
            return {
                value: '',
                current: {
                    formId: '',         // form pk
                    formOriginId: '',   // form origin id
                    modelId: ''
                }
            };
        }

        /**
         * Sets the autoFill object's cast type
         * @param obj - autoFill object
         * @param columns - view columns
         */
        function setAutoFillMetaData(obj, columns){
            for(var i = 0; i < columns.length; i++){
                var source = columns[i];
                if(source.colName === obj.value){
                    obj.type = source.castType;
                    if(obj.type === 'dataseries'){
                        obj.fk = source.fk;
                        obj.widgetId = source.widgetId;
                    }
                    else if(obj.widgetId){
                        delete obj.fk;
                        delete obj.widgetId;
                    }
                    return;
                }
            }
        }

        /**
         * Sets dataSeries autoFill object's type, widgetId, and fk
         * @param obj - autoFill object
         * @param fields - widget form fields
         * @param widgetId
         * @param fks - dataSeries' foreign keys
         */
        function setDataSeriesAutoFillData(obj, fields, widgetId, fks){
            obj.widgetId = widgetId;
            if(fks && fks.length){
                obj.fk = fks[0].modelId;
            }
            for(var i = 0; i < fields.length; i++){
                if(fields[i].modelId === obj.value){
                    obj.type = fields[i].type;
                    return;
                }
            }
        }

        /**
         * Sets autoFill target's formId
         * @param obj - autoFill object
         * @param formFieldObjects
         * @param formId
         * @param widgetId
         */
        function setTargetFormId(obj, formFieldObjects, formId){
            for(var i = 0; i < formFieldObjects.length; i++){
                var target = formFieldObjects[i];
                if(obj.current.modelId === target.modelId){
                    obj.current.formId = target.formId;
                    // Sets table-specific data
                    if(target.type === 'table'){
                        obj.targetWidgetId = target.widgetId;
                        obj.subMap = [];
                    }
                    else if(obj.targetWidgetId){
                        // removes table data
                        delete obj.targetWidgetId;
                        delete obj.subMap;
                    }
                    return;
                }
            }

            obj.current.formId = _.last(formId.split('-'));
        }

        /**
         * Sets autoFill subMap item's form and origin ids
         * @param target - subMap item's current object
         * @param formFieldObjects
         * @param originId
         */
        function setSubItemIds(target, formFieldObjects, originId){
            for(var i = 0; i < formFieldObjects.length; i++){
                if(target.modelId === formFieldObjects[i].modelId){
                    target.formId = formFieldObjects[i].realFormId;
                    target.formOriginId = formFieldObjects[i].formId;
                    return;
                }
            }

            target.originId = _.last(originId.split('-'));
        }
    })
;
