angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizMathService
     * @alias cerberus/core:VizMathService
     * @description Provides pedestrian math functions for viz directives
     */
    .factory('VizMathService', function VizMathService() {
        return {
            smartRoundUp: smartRoundUp,
            arrayMax: arrayMax,
            round: round,
            delta: delta,
            isNumber: isNumber,
            toPercentageString: toPercentageString
        };

        ////////////////////

        /**
         * @function smartRoundUp
         * @param {number} number
         * @return {number}
         */
        function smartRoundUp(number) {
            //Double tilda is a bitwise operator to eliminate
            var length = (Math.floor(number) + '').length;
            var factor = Math.pow(10, length - 1);
            return Math.ceil(number/factor)*factor;
        }

        /**
         * @function smartRoundUp
         * @param {Array} array
         * @return {number}
         */
        function arrayMax(array) {
            return Math.max.apply(Math, array);
        }

        /**
         * @function round
         * @param {number} number
         * @param {number} place
         * @return {number}
         */
        function round(number, place) {
            return Math.round(number * place)/place;
        }

        /**
         * @function delta
         * @param {number} previous
         * @param {number} current
         * @return {number}
         */
        function delta(previous, current) {
            return (current / previous) - 1;
        }

        /**
         * @function isNumber
         * @param {any} number
         * @return {boolean}
         */
        function isNumber(number) {
            return typeof number === 'number' && !isNaN(parseFloat(number)) && isFinite(number);
        }

        /**
         * @function toPercentageString
         * @param number
         * @param total
         * @returns {string}
         */
        function toPercentageString(number, total){
            return round(delta(total, number) * 100, 100) + '%';
        }
    })
;