angular.module('cerberus.les')
    .controller('LESLoanApplicationUpdateModalCtrl', function(_, $rootScope, $scope, $uibModalInstance, loanApplicationId){
        var vm = this;

        vm.updateLoanUploadOptions = {
            enabled: false, // TODO: Enable this once we've established the logic for updating a loan
            multiple: false,
            async: {
                saveField: 'file',
                saveUrl: '/Server/REST/v1/les/loanApplications/' + loanApplicationId,
                autoUpload: false
            },
            localization: {
                dropFilesHere: 'Drag Here',
                select: 'Select File',
                uploadSelectedFiles: 'Update'
            },
            upload: function (e) {
                var xhr = e.XMLHttpRequest;
                if (xhr) {
                    xhr.addEventListener("readystatechange", function () {
                        if (xhr.readyState == 1 /* OPENED */) {
                            xhr.setRequestHeader('Authentication','Bearer ' + $rootScope.authToken);
                            xhr.setRequestHeader('WSID',$rootScope.userData.currentWorkspace.id);
                            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest ');
                        }
                    });
                }
            },
            success: function(){
                $uibModalInstance.close();
            }
        };

        vm.ok = ok;
        vm.cancel = cancel;

        function ok(){
            $uibModalInstance.close();
        }

        function cancel(){
            $uibModalInstance.dismiss('cancel');
        }

        // Removes Kendo widgets
        $scope.$on('$destroy', function(){
            _.result(vm, 'updateLoanUpload.destroy');
        });

        // Prevents navigation while modal is open
        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;