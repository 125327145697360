angular.module('cerberus.account')
    .controller('OrganizationsMediaLibraryCtrl', function(_, kendo, $scope, $timeout, $location, apiPath, authToken, orgId, workspaceId, OrganizationsService){
        $scope.pageTitle = 'Media Library';
        $scope.activeRoute = 'media-library';
        $scope.orgId = orgId;
        $scope.orgData = {};
        $scope.allSelected = false;
        $scope.selected = [];

        $scope.dataSource = new kendo.data.DataSource({
            transport: {
                read: function(e){
                    OrganizationsService.getAllAssets(orgId, 'File').then(function(files){
                        var fileData = [];

                        _.forEach(files, function(asset){
                            var data = asset.data;
                            data.id = asset.id;

                            fileData.push(data);
                        });

                        e.success(fileData);
                    });
                }
            }
        });

        $scope.mediaGridOptions = {
            columns: [
                {
                    headerTemplate: '<input type="checkbox" class="form-control" ng-checked="allSelected" ng-click="selectAll()" />',
                    template: '<input type="checkbox" class="form-control" ng-checked="selected.indexOf(\'#: id #\') >= 0" ng-click="toggleSelected(\'#: id #\')" />',
                    width: 30
                },
                {
                    title: 'File',
                    template: '<div style="height:100px;" nim-file-thumb="#: path #"></div>'
                },
                {
                    title: 'Author',
                    field: 'uploadBy'
                },
                {
                    title: 'Description',
                    field: 'description'
                },
                {
                    title: 'Upload Date',
                    template: _dateTemplate
                },
                {
                    template: '<button class="btn btn-danger" ng-click="deleteFile(\'#: id #\')"><i class="fa fa-trash"></i>&nbsp;Remove</button>',
                    width: 100
                }
            ],
            dataSource: $scope.dataSource,
            selectable: true,
            change: function(e){
                var row = e.sender.select();
                var dataItem = e.sender.dataItem(row);

                $timeout(function(){
                    _editFile(dataItem.id);
                });
            }
        };

        $scope.uploadOptions = {
            async:{
                saveUrl: apiPath + '/organizations/' + orgId + '/assets',
                autoUpload: true
            },
            localization: {
                dropFilesHere: 'Drop files here to start the upload.',
                select: '<span class="fa fa-plus"></span> Add New'
            },
            multiple: false,
            showFileList: false,
            upload: function(e){
                e.data = {
                    type: 'File'
                };

                var xhr = e.XMLHttpRequest;
                if (xhr) {
                    xhr.addEventListener("readystatechange", function () {
                        if (xhr.readyState == 1) {
                            xhr.setRequestHeader('Authentication','Bearer ' + authToken);
                            xhr.setRequestHeader('WSID', workspaceId);
                            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest ');
                        }
                    });
                }
            },
            success: function() {
                $timeout(function(){
                    $scope.selected = [];
                    $scope.allSelected = false;
                    $scope.dataSource.read();
                });
            }
        };

        $scope.toggleSelected = _toggleSelected;
        $scope.selectAll = _selectAll;
        $scope.deleteFile = _deleteFile;
        $scope.batchDelete = _batchDelete;

        _init();

        function _init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });
        }

        function _toggleSelected(fileId){
            var index = $scope.selected.indexOf(fileId);

            if(index >= 0){
                _.pullAt($scope.selected, index);
            }
            else {
                $scope.selected.push(fileId);
            }

            _checkSelected();
        }

        function _selectAll(){
            if($scope.allSelected){
                $scope.allSelected = false;
                $scope.selected = [];
            }
            else {
                var data = _.result($scope.dataSource, 'data');
                var files = _.result (data, 'toJSON', []);

                $scope.allSelected = true;
                $scope.selected = _.map(files, 'id');
            }
        }

        function _checkSelected(){
            $scope.allSelected = $scope.selected.length === $scope.dataSource.total();
        }

        function _dateTemplate(dataItem){
            //var iso = file.uploadDate.replace(' ', 'T') + 'Z';
			var iso = dataItem.uploadDate; 
            var date = new Date(iso);

            return kendo.format('{0:G}', date);
        }

        function _deleteFile(fileId){
            OrganizationsService.deleteAsset(orgId, fileId).then(function(){
                $timeout(function(){
                    $scope.selected = [];
                    $scope.allSelected = false;
                    $scope.dataSource.read();
                });
            });
        }

        function _batchDelete(){
            if($scope.selected.length > 0){
                var ids = $scope.selected.join(',');
                _deleteFile(ids);
            }
        }

        function _editFile(fileId){
            $location.url('/organizations/' + orgId + '/settings/file/' + fileId);
        }
    })
;