angular.module('cerberus.ui')
    .controller('nimSignatureModalCtrl', function ($scope, $uibModalInstance, nimSignatureService, isInitial, restrictTo, penColor, isTouchpadSign) {
        var vm = this;
        // Settings
        vm.isInitial = isInitial;
        vm.restrictTo = restrictTo;
        vm.penColor = penColor;
        vm.isTouchpadSign = isTouchpadSign;
        // Data Container
        vm.signatureBase64 = '';
        vm.signatureBaseTPad64 = '';

        // UI feedback
        if (isTouchpadSign) {
            vm.signClass= 'text-muted';
            vm.signMsg = 'Use the touch pad to capture data. When done, click Accept.';
        }
        else {
            vm.signClass= 'text-muted';
            vm.signMsg = isInitial ? 'Initial above.' : 'Sign above.';
        }

        // Form Close Actions 
        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        // Form Submit Actions
        vm.submit = function(isEmpty, withAccount) {
            if (isEmpty === true) {
                vm.signClass = 'text-danger';
                vm.signMsg = 'Please sign above before proceeding!';
                return;
            }
            if (withAccount) {
                nimSignatureService.getSignatureWithAccount().then(success, error);
            }
            else {
                nimSignatureService.saveImage(vm.signatureBase64).then(success, error);
            }
        };

        // Form Submit From Touchpad Device
        vm.submitTouchPad = function(isEmpty, withAccount) {
            if (isEmpty === true) {
                vm.signClass = 'text-danger';
                vm.signMsg = 'No signature! Please sign above before proceeding.';
                return;
            }
            if (vm.signatureBaseTPad64 === '') {
                vm.signClass = 'text-danger';
                vm.signMsg = 'No signature! Please sign above before proceeding.';
                return;
            }
            if (withAccount) {
                nimSignatureService.getSignatureWithAccount().then(success, error);
            }
            else {
                nimSignatureService.saveImage(vm.signatureBaseTPad64).then(success, error);
            }
        };

        ////////////////////
        // Promise Handlers
        function success(data) {
            var response = {
                timestamp: new Date()
            };
            angular.extend(response, data);
            $uibModalInstance.close(response);
        }
        function error() {
            vm.signClass = 'text-warning';
            vm.signMsg = 'Something went wrong, please try again!';
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;