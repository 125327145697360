angular.module('cerberus.core')
    /**
     * @ngdoc config
     * @description $http interceptor & router config;
     * On 401 response (without 'ignoreAuthModule' option) stores the request
     * and broadcasts events namespaced with 'auth:'.
     */
    .config(function($httpProvider) {
        $httpProvider.interceptors.push(function(_, moment, $rootScope, $q, AuthHttpBuffer) {
            return {
                request: function (config) {
                    if ($rootScope.authToken) {
                        config.headers['Authentication'] = 'Bearer ' + $rootScope.authToken;
                        var payload = angular.fromJson(atob($rootScope.authToken.split('.')[1]));
                        var utcNow = moment.utc().valueOf();
                        var utcRotateTime = 1200000; //20 minutes
                        //If token is going to expire in the next 20 minutes rotate it and get a new one
                        if(utcNow > payload.iat + utcRotateTime){
                            //TODO: inject the rotate token service instead of broadcasting it
                            $rootScope.$broadcast('auth:rotateToken', $rootScope.authToken);
                        }
                    }
                    if(_.has($rootScope, 'userData.currentWorkspace.id')) {
                        config.headers['WSID'] = $rootScope.userData.currentWorkspace.id;
                    }

                    return config;
                },
                responseError: function(rejection) {
                    var config = rejection.config || {};
                    if (!config.ignoreAuthModule) {
                        if (rejection.status === 401) {
                            if (_.startsWith(rejection.config.url, '/server/rest/')) {
                                var deferred = $q.defer();
                                AuthHttpBuffer.append(config, deferred);
                                $rootScope.$broadcast('auth:loginRequired', rejection);
                                return deferred.promise;
                            }
                        }
                        if (rejection.status === 403) {
                            $rootScope.$broadcast('auth:forbidden', rejection);
                        }
                    }
                    // otherwise, default behaviour
                    return $q.reject(rejection);
                }
            };
        });
    })
;