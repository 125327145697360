angular.module('cerberus.admin')
    .controller('WorkspacesProfileCtrl', function WorkspacesProfileCtrl(_, $scope, $location, workspaceData, workspacesService) {
        $scope.tempData = angular.copy(workspaceData);
        $scope.tempData.logo = $scope.tempData.logo || '';
        $scope.origData = angular.copy(workspaceData);
        $scope.workspaceId = workspaceData.id;
        $scope.cropStep = 1;
        $scope.usePath = workspaceData.path || workspaceData.path !== "";

        $scope.clearLogo = function(){
            $scope.tempData.logo = '';
            $scope.imageSrc = null;
        };
        $scope.save = function(data){
            workspacesService.update($scope.workspaceId, data).then(function(){
                _.assign(workspaceData, data);
                $location.url('/settings');
            });
        };
        $scope.reset = function(){
            $location.replace().url('/settings');
        };
        $scope.isUnchanged = function() {
            return angular.equals($scope.origData, $scope.tempData);
        };
    })
;