angular.module('cerberus.admin')
    .controller('PageObjectsDesignerCtrl', function PageObjectsDesignerCtrl($scope, $location, $uibModal, $timeout, pageObjectId, pageObjectsService, PageObjectsDesignerService, DesignerUtilityService, ConfirmModalService){
        var vm = this;

        $scope.filters = {};

        vm.pageObjectId = pageObjectId;
        vm.temp = null;
        vm.orig = null;

        vm.openPageObjectConfigModal = openPageObjectConfigModal;
        vm.save = save;
        vm.saveAndClose = saveAndClose;
        vm.isUnchanged = isUnchanged;
        vm.close = close;
        vm.deletePageObject = deletePageObject;

        init();

        function init(){
            pageObjectsService.getPageObject(pageObjectId).then(function(data){
                vm.orig = angular.copy(data);
                vm.temp = angular.copy(vm.orig);
            });
        }
        
        DesignerUtilityService.cancelRouteNav($scope, function () { return !isUnchanged(); });

        $scope.$on('$locationChangeStart', function (e) {
            if (!e.defaultPrevented) {
                $scope.$broadcast('nim-remove-page-objects');
            }
        });

        function openPageObjectConfigModal(){
            var controller = 'PageObjectConfigCtrl';
            if(vm.temp.viz.subType){
                controller = formatName(vm.temp.viz.subType) + 'ConfigCtrl';
            }
            else if(vm.temp.viz.type){
                controller = formatName(vm.temp.viz.type) + 'ConfigCtrl';
            }
            else if(vm.temp.type){
                controller = formatName(vm.temp.type) + 'ConfigCtrl';
            }
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/pages/designer/common/config-modal.tpl.html',
                controller: controller,
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    pageObject: function (){
                        return vm.temp;
                    },
                    filters: function () {
                        return [];
                    }
                }
            });
            modalInstance.result.then(function (data) {
                data.viz.settings.batchEdit = !!(data.tools.edit || data.tools.delete);

                if(!angular.equals(vm.temp, data)) {
                    vm.temp = null;
                    $timeout(function () {
                        vm.temp = data;
                    });
                }
            },
            function(reason){
                if(reason === 'delete'){
                    deletePageObject();
                }
            });
        }

        function formatName(name){
            var formattedName = '';
            if(name && name.length > 0){
                formattedName += name.charAt(0).toUpperCase();
                if(name.length > 1){
                    formattedName += name.substr(1, name.length - 1).toLowerCase();
                }
            }
            return formattedName;
        }

        function save(saveAndClose){
            PageObjectsDesignerService.updatePageObject(vm.pageObjectId, vm.temp).then(function(){
                if(saveAndClose){
                    close();
                }
                else{
                    vm.orig = angular.copy(vm.temp);
                }
            });
        }

        function saveAndClose(){
            save(true);
        }

        function isUnchanged(){
            return angular.equals(vm.temp, vm.orig);
        }

        function close(){
            $location.url('/settings/page-objects');
        }

        function deletePageObject(){
            ConfirmModalService.showModal(null, {
                headerText: 'Confirm Deletion',
                bodyText: 'Are you sure you want to delete this page object and remove it from each page that uses it?',
                instructionText: 'Type "DELETE ' + vm.temp.name + '" to confirm.',
                actionButtonText: 'Delete Page Object',
                closeButtonText: 'Cancel',
                check: function(input){
                    return angular.equals(input, 'DELETE ' + vm.temp.name);
                },
                confirm: function(){
                    return PageObjectsDesignerService.deletePageObject(vm.pageObjectId);
                },
                callback: close
            });
        }
    })
;