angular.module('cerberus.admin')
    .config(function($routeProvider){
        $routeProvider.when('/settings/menus', {
            templateUrl: 'admin/menus/menus.tpl.html',
            controller: 'MenusCtrl',
            controllerAs: 'vm',
            title: 'Menus',
            resolve: {
                _wait: 'WaitForUserData',
                workspaceId: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function(){
                        return $rootScope.userData.currentWorkspace.id;
                    });
                }
            }
        });
        $routeProvider.when('/settings/menus/create', {
            templateUrl: 'admin/menus/menus-create.tpl.html',
            controller: 'MenusCreateCtrl',
            controllerAs: 'vm',
            title: 'Create Menu',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/settings/menus/:id', {
            templateUrl: 'admin/menus/designer/designer.tpl.html',
            controller: 'MenusDesignerCtrl',
            controllerAs: 'vm',
            title: 'Menu Designer',
            resolve: {
                _wait: 'WaitForUserData',
                menuId: function($route){
                    return $route.current.params.id;
                }
            }
        });
    })
;