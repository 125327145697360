// Gage
// TODO: Mapped field for Progress bar (optional)
// TODO: Mapped field for Due Date (optional)
// TODO: Mapped field for Tags Field (optional)

angular.module('cerberus.core')
    .controller('nimVizKanbanCtrl', function nimVizKanbanCtrl(_, kendo, $scope, $element, $log, $timeout, vizKanbanService, VizUtilityService, $firebaseObject, fbUtil) {
        var vm = this;
        $scope.mainPageObject.vizCtrl = vm;

        // View model
        vm.bins = [];
        vm.binKey = _.get($scope.pageObject, 'params.binKey');
        vm.orderKey = _.get($scope.pageObject, 'params.orderKey');
        vm.orderDir = _.get($scope.pageObject, 'params.orderDir', 'desc');
        vm.binField = _.get($scope.pageObject, 'params.binField');
        vm.orderField = _.get($scope.pageObject, 'params.orderField');
        vm.useCustom = _.get($scope.pageObject, 'params.useCustomCategories');
        vm.customBins = _.get($scope.pageObject, 'params.customCategories');
        vm.aggregates = _.get($scope.pageObject, 'viz.settings.dataSource.aggregate');
        vm.treeOptions = {
            beforeDrag: onBeforeDrag,
            beforeDrop: onBeforeDrop,
            dropped: onDropped,
            dragStart: function () { vm.dragging = true; },
            dragStop: function () { vm.dragging = false; }
        };
        vm.dragging = false;
        vm.loading = {};
        vm.initialized = {};
        vm.selectedIds = [];
        vm.selectedItems = [];
        vm.showOpenButton = _.get($scope.pageObject, 'params.openOnSelect', false) || _.get($scope.pageObject, 'params.routeSettings.enabled', false);

        vm.buildItemFromTemplate = buildItemFromTemplate;
        vm.selectItem = selectItem;
        vm.openItem = openItem;
        vm.addItem = vizKanbanService.addItem;

        var dataBound = false,
            checkedForDefaultFilter = false,
            fbUnwatchers = [],
            navFunctions = {
                getNext: viewNextRecord,
                getPrevious: viewPreviousRecord
            };

        // Initialize Controller
        init();

        $scope.$on('$destroy', function () {
            delete navFunctions.getNext;
            delete navFunctions.getPrevious;
            
            _.forEach(fbUnwatchers, function (unwatchFb) {
                unwatchFb();
            });
        });
        
        /////////////////////
        function init() {
            vm.dragEnabled = !!($scope.pageObject.params.allowCategoryChange || $scope.pageObject.params.allowSortChange);
            var itemDataSource = vizKanbanService.buildDataSource($scope.pageObject, $scope, vm.loading);

            vm.itemDataSource = itemDataSource;

            //Firebase
            watchFirebase(itemDataSource, _.get($scope.pageObject, 'viz.settings.dataSource.nim_viewId'), 'item');

            VizUtilityService.subscribeToPageFilters($scope.pageObject.filterMap, $scope, itemDataSource);

            if(!_.isEmpty(vm.binField)){
                vm.categoryDataSource = vizKanbanService.buildCategoryDataSource(vm.binField, vm.loading);

                if(vm.binField.isLookup){
                    watchFirebase(vm.categoryDataSource, vm.binField.viewId, 'category');
                }

                $scope.$watchGroup(['vm.categoryDataSource.data()', 'vm.itemDataSource.data()'], function (newData, oldData) {
                    var categories = newData[0],
                        items = newData[1],
                        oldItems = oldData[1];
                    
                    buildBins(categories, items);

                    if (!angular.equals(items, oldItems)) {
                        itemsChanged(items);
                    }
                });

                vm.categoryDataSource.read();
            }
            else {
                $scope.$watch('vm.itemDataSource.data()', function(items, oldItems){
                    buildBins([], items);

                    if (!angular.equals(items, oldItems)) {
                        itemsChanged(items);
                    }
                });
            }

            if(!$scope.pageObject.params.scrollable){
                $scope.$watch('vm.loading', function(loading){
                    if(!loading.item && !loading.category) {
                        resizeGridster();
                    }
                }, true);
            }
        }

        function watchFirebase(dataSource, viewId, dsId) {
            var refreshFunction = _.partial(refreshIfNotDragging, dataSource);
            var debounceOpts = { leading: true, trailing: true };
            var debouncedRefresh = _.debounce(refreshFunction, 5000, debounceOpts);
            var unwatchFb = $firebaseObject(fbUtil.ref('queries/' + viewId)).$watch(function(){
                if (!vm.initialized[dsId]) {
                    //Stops first firing of the event
                    $timeout(function() { vm.initialized[dsId] = true; });
                } else {
                    debouncedRefresh();
                }
            });

            fbUnwatchers.push(unwatchFb);
        }

        function refreshIfNotDragging(dataSource) {
            if (!vm.dragging) {
                _.result(dataSource, 'read');
            }
        }

        /**
         * Builds Kanban bins using values from data sources
         * @param categories - category values from lookup field
         * @param items - items pulled from query
         */
        function buildBins(categories, items){
            vm.bins = [];

            // Use custom categories if set
            var binCategories = vm.useCustom ? vm.customBins : categories;

            _.forEach(binCategories, function (binCategory) {
                var title = binCategory.title || binCategory.display;
                var displayValue = binCategory.display;
                var categoryId = binCategory.id;
                var titleClass = binCategory.titleClass || 'bg-default';

                if(vm.useCustom){
                    // If using custom categories and category data was pulled from
                    // the server, match the custom categories to their ids.
                    categoryId = findBinId(binCategory, categories);
                }

                var binData = vizKanbanService.filter(items, vm.binKey, displayValue, categoryId);

                if (vm.orderKey) {
                    binData = vizKanbanService.buildList(binData, vm.orderKey);
                }

                var bin = {
                    title: title,
                    category: displayValue,
                    titleClass: titleClass,
                    id: categoryId,
                    data: binData
                };

                bin.aggregate = vizKanbanService.binAggregate(vm.aggregates, bin.data);

                if(_.isEmpty(bin.data)){
                    bin.data = [{ _type: 'empty' }];
                }

                vm.bins.push(bin);
            });
        }

        function itemsChanged(items) {
            if (!checkedForDefaultFilter) {
                var params = $scope.pageObject.params,
                    filter = $scope.filters[params.filterId];
                
                if (params.asFilter && filter) {
                    if (!_.isEmpty(filter.filters)) {
                        _.forEach(filter.filters, function (f) {
                            if (f.value) {
                                var dataItem = _.find(items, 'id', f.value);

                                if (dataItem) {
                                    if (params.filterDisplay) {
                                        f.lookupDisplay = dataItem[params.filterDisplay];
                                    }
                                    else {
                                        f.dataItem = dataItem;
                                    }

                                    vm.selectedIds = [dataItem.id];
                                    vm.selectedItems = [dataItem];
                                }
                            }
                        });
                    }
                }
            }
            else if(vm.selectedIds.length){
                var updatedItem = _.find(items, 'id', vm.selectedIds[0]);
                
                if (updatedItem) {
                    vm.selectedItems[0] = updatedItem;
                }
                else {
                    vm.selectedIds = [];
                    vm.selectedItems = [];
                }
            } 

            applyFilter();
        }

        /**
         * Matches user-defined category to lookup category
         * @param customCategory
         * @param lookupCategories
         * @returns {*} - ID of matched lookup category
         */
        function findBinId(customCategory, lookupCategories){
            var match = _.find(lookupCategories, 'display', customCategory.display);
            if(match){
                return match.id;
            }
            return '';
        }

        function onBeforeDrag(sourceNodeScope) {
            return sourceNodeScope.$modelValue._type !== 'empty';
        }

        function onBeforeDrop(event) {
            if (event.source.nodeScope.$modelValue._type === 'empty') {
                return false;
            }

            var dataItem = event.source.nodeScope.$modelValue,
                // id = dataItem.id,
                sourceBin = dataItem[vm.binKey],
                destBin = event.dest.nodesScope.binModel.category,
                destBinId = event.dest.nodesScope.binModel.id,
                // sourceOrder = dataItem[vm.orderKey],
                destArr = event.dest.nodesScope.$modelValue,
                destIdx = event.dest.index,
                sourceArr = event.source.nodesScope.$modelValue,
                sourceIdx = event.source.index;

            // If no change return
            if (sourceBin === destBin && sourceIdx === destIdx) {
                // Removed redundant logic for selecting/opening item
                return true;
            }
            else if (!$scope.pageObject.params.allowCategoryChange && sourceBin !== destBin){
                $log.warn('Kanban: Category change not enabled for "' + $scope.pageObject.name + '"');
                return false;
            }
            else if(!$scope.pageObject.params.allowSortChange && sourceBin === destBin && sourceIdx !== destIdx){
                $log.warn('Kanban: Sort order change not enabled for "' + $scope.pageObject.name + '"');
                return false;
            }
            else {
                var updateData = {};

                // Set Order and Bin/Category
                if ($scope.pageObject.params.allowSortChange) {
                    if (!_.isEmpty(vm.orderField)) {
                        var oldPrevDataItem = sourceArr[sourceIdx - 1],
                            oldNextDataItem = sourceArr[sourceIdx + 1],
                            newPrevDataItem = destArr[destIdx],
                            newNextDataItem = destArr[destIdx + 1];
                        
                        if (sourceIdx > destIdx || sourceBin !== destBin) {
                            newPrevDataItem = destArr[destIdx - 1];
                            newNextDataItem = destArr[destIdx];
                        }
                        
                        vizKanbanService.addNextToUpdateData(updateData, vm.orderField, vm.orderKey, dataItem, newNextDataItem);
                        vizKanbanService.addNextToUpdateData(updateData, vm.orderField, vm.orderKey, newPrevDataItem, dataItem);
                        vizKanbanService.addNextToUpdateData(updateData, vm.orderField, vm.orderKey, oldPrevDataItem, oldNextDataItem);
                    }
                }

                if ($scope.pageObject.params.allowCategoryChange && sourceBin !== destBin) {
                    // item[vm.binKey] = destBin;

                    if (!_.isEmpty(vm.binField)) {
                        updateData[dataItem.id] = updateData[dataItem.id] || {};
                        updateData[dataItem.id][vm.binField.stateId] = updateData[dataItem.id][vm.binField.stateId] || {};

                        // Assumes the category is a lookup
                        updateData[dataItem.id][vm.binField.stateId][vm.binField.modelId] = {
                            display: destBin,
                            id: destBinId
                        };
                    }
                }

                _.forEach(updateData, function (recordData, instanceId) {
                    if (_.isEmpty(recordData)) {
                        delete updateData[instanceId];
                    }
                });

                // Resolve Promise
                if(!_.isEmpty(updateData)) {
                    vm.loading.item = true;
                    return vizKanbanService.updateItem(updateData).then(function () {
                        vm.loading.item = false;
                    }, function(){
                        vm.loading.item = false;
                    });
                }
                else {
                    return true;
                }
            }
        }

        function onDropped(event) {
            var sourceArr = event.source.nodesScope.$modelValue,
                destArr = event.dest.nodesScope.$modelValue;
            // Remove Empty Placeholder
            if (destArr.length > 1) {
                _.remove(destArr, '_type', 'empty');
            }
            // Add Empty placeholder
            if (sourceArr.length < 1) {
                var item = { _type: 'empty' };
                
                if (vm.orderKey) {
                    item[vm.orderKey] = 1;
                }
                
                sourceArr.push(item);
            }
        }

        function buildItemFromTemplate(dataItem){
            if(dataItem._type === 'empty'){
                var msg = 'Empty';
                if($scope.pageObject.params.allowCategoryChange) {
                    msg = 'Empty (drag here)';
                }

                return '<div class="empty"><em>' + msg + '</em></div>';
            }
            else {
                try {
                    var template = kendo.template($scope.pageObject.viz.settings.template);
                    var templateData = vizKanbanService.templateData(dataItem);
                    var html = template(templateData).replace(/&lt;/g, '<').replace(/&quot;/g, '"').replace(/&gt;/g, '>');

                    return vizKanbanService.stripMisplacedAttributes(html, templateData.onClick);
                }
                catch (e) {
                    $log.error(e);
                    return '';
                }
            }
        }

        function selectItem(item, eventType) {
            // Run this function on mousedown if drag is enabled or on click if drag is disabled
            if ((eventType == 'mousedown' && !vm.dragEnabled) || (eventType == 'click' && vm.dragEnabled)) {
                return;
            }

            // Do not select if this is an empty item
            if (item._type === 'empty') {
                return;
            }

            vm.selectedIds = [item.id];
            vm.selectedItems = [item];

            if ($scope.pageObject.params.asFilter) {
                applyFilter();
            }
        }

        function openItem(item, eventType, event) {
            // Run this function on mousedown if drag is enabled or on click if drag is disabled
            if ((eventType == 'mousedown' && !vm.dragEnabled) || (eventType == 'click' && vm.dragEnabled)) {
                return;
            }

            // Do not open/route if this is an empty item
            if (item._type === 'empty') {
                return;
            }
            if (eventType == 'mousedown' && vm.dragEnabled) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            }

            // Opens Instance Window
            if($scope.pageObject.params.openOnSelect && item.id){
                vizKanbanService.viewRecord(item.id, $scope.pageObject, navFunctions);
            }

            // Opens Route
            var routeSettings = _.get($scope.pageObject.params, 'routeSettings', {});
            if (routeSettings.enabled && routeSettings.template) {
                var target = routeSettings.newTab ? '_blank' : '_self';
                VizUtilityService.openPageObjectRoute(routeSettings.template, item, target);
            }
        }

        function applyFilter() {
            var params = $scope.pageObject.params;
            if (params.asFilter && params.filterId) {
                if (!dataBound) {
                    dataBound = true;
                }
                else {
                    $timeout(function () {
                        var filters = _.map(vm.selectedItems, function (row) {
                            var dataItem = angular.copy(row),
                                filterObj = {
                                    field: '__nimColumn__',
                                    operator: 'eq',
                                    value: row.id
                                };

                            if (params.filterDisplay && dataItem) {
                                filterObj.lookupDisplay = dataItem[params.filterDisplay];
                            }
                            else if (dataItem) {
                                // For objects that will use the filter for default data
                                filterObj.dataItem = dataItem;
                            }

                            return filterObj;
                        });

                        $scope.filters[params.filterId] = {
                            logic: 'or',
                            filters: filters
                        };
                    });
                }
            }
        }

        function viewNextRecord(instanceController){
            var id = instanceController.instanceId,
                dataItem = vm.itemDataSource.get(id),
                categoryVal = dataItem[vm.binKey],
                category = _.find(vm.bins, 'category', categoryVal),
                data = category.data,
                index = data.indexOf(dataItem);

            if(index >= 0 && index < data.length - 1){
                var next = data[index + 1];
                instanceController.updateInstanceAttrs(next.id, 'read');
                instanceController.init();
            }
        }

        function viewPreviousRecord(instanceController){
            var id = instanceController.instanceId,
                dataItem = vm.itemDataSource.get(id),
                categoryVal = dataItem[vm.binKey],
                category = _.find(vm.bins, 'category', categoryVal),
                data = category.data,
                index = data.indexOf(dataItem);

            if(index > 0){
                var previous = data[index - 1];
                instanceController.updateInstanceAttrs(previous.id, 'read');
                instanceController.init();
            }
        }

        function resizeGridster() {
            if ($scope.resizeGridsterItem) {
                $timeout(function () {
                    $scope.resizeGridsterItem();
                }, 400);
            }    
        }
    })
    .directive('nimVizKanban', function() {
        return {
            restrict: 'AE',
            templateUrl: 'core/pages/viz/kanban/kanban.tpl.html',
            scope: true,
            require: '^nimPageObject',
            controller: 'nimVizKanbanCtrl',
            controllerAs: 'vm'
        };
    })
;