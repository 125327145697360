angular.module('cerberus.admin')
    .controller('KanbanConfigCtrl', function KanbanConfigCtrl(_, $scope, $uibModalInstance, pageObject, filters,
                                                            DesignerUtilityService, PagesDesignerListService, pageObjectConfigService,
                                                            ViewsService, vizListService, WidgetsService, WidgetsSettingsService) {
        var vm = this;

        vm.temp = angular.copy(pageObject);
        vm.orig = angular.copy(pageObject);
        vm.filters = filters;
        vm.views = [];
        vm.widgets = [];
        vm.wrapperTypes = PagesDesignerListService.wrapperTypes();
        vm.wrapperConfig = PagesDesignerListService.wrapperConfig();
        vm.paletteOptions = {
            palette: PagesDesignerListService.palette(),
            columns: 31,
            tileSize: 18
        };
        vm.margin = DesignerUtilityService.cssToArray(pageObject.wrapper.style.margin) || [0,0,0,0];
        vm.padding = DesignerUtilityService.cssToArray(pageObject.wrapper.style.padding) || [0,0,0,0];
        vm.viewColumns = [];
        vm.editView =  vm.temp.viz.settings.dataSource.nim_viewId;
        vm.filterMap = {};
        vm.tempFilter = {};
        vm.filterType = null;
        vm.tabTemplates = [
            {name: "General", url: "admin/pages/designer/common/general-tab.tpl.html"},
            {name: "Wrapper", url: "admin/pages/designer/common/wrapper-tab.tpl.html"},
            {name: "Data", url: "admin/pages/designer/viz/kanban/data-tab.tpl.html"},
            {name: "Record Actions", url: "admin/pages/designer/viz/kanban/records-tab.tpl.html"},
            {name: "Filters", url: "admin/pages/designer/common/filter-tab.tpl.html"},
            {name: "Tools", url: "admin/pages/designer/common/tools-tab.tpl.html"}
        ];
        vm.filterOperators = pageObjectConfigService.filterOperators();
        vm.textEditorOptions = pageObjectConfigService.textEditorOptions($scope);
        /* LODASH Version 4 ONLY */
        //vm.editorTools = _.reject(vm.textEditorOptions.tools, ['name', 'nimViewHtml']);
        /* LODASH Version 3 & 4 Compatibility Mode */
        vm.editorTools = _.reject(vm.textEditorOptions.tools, function(r){return r.name === 'nimViewHtml';});
        vm.formFieldsArray = [];
        vm.categories = [];

        vm.aggregate = {
            field: null,
            aggregate: null,
            nimLabel: ''
        };

        vm.aggregateFunctions = [
            {value:'sum', display:'Sum'},
            {value:'count', display:'Count'}/*,
            {value:'avg', display:'Average'}*/
        ];

        vm.titleClasses = [
            {value: 'bg-default', display: 'Default'},
            {value: 'bg-primary', display: 'Primary'},
            {value: 'bg-success', display: 'Success'},
            {value: 'bg-danger', display: 'Danger'},
            {value: 'bg-warning', display: 'Warning'},
            {value: 'bg-info', display: 'Info'}
        ];

        vm.vizDict = vizListService.vizDictionary();

        vm.sortableOptions = pageObjectConfigService.createSortableOptions(vm.temp.viz.settings.columns);
        vm.aggregateSortableOptions = pageObjectConfigService.createSortableOptions(vm.temp.viz.settings.dataSource.aggregate);
        vm.listViewTemplatePresets = PagesDesignerListService.kanbanTemplatePresets();

        vm.getFilterType = pageObjectConfigService.getFilterType;
        vm.addDSFilter = pageObjectConfigService.addDSFilter;

        vm.notPropertyColumn = pageObjectConfigService.notPropertyColumnFilter;
        vm.loadCategories = _.partial(pageObjectConfigService.loadCategories, vm);

        // Helper functions
        vm.deleteProperty = pageObjectConfigService.deleteProperty;
        vm.capitalize = _.capitalize;
        vm.removeItemFromArray = _.pullAt;
        vm.fillCSSArray = pageObjectConfigService.fillCSSArray;
        vm.preventSpace = DesignerUtilityService.preventSpace;

        // View functions
        vm.loadColumns = _.partial(pageObjectConfigService.loadColumns, vm);
        vm.onViewChange = _.partial(pageObjectConfigService.onViewChange, vm, onColumnRemoved);

        // Widget functions
        vm.loadFormFields = _.partial(pageObjectConfigService.loadFormFields, vm);
        vm.setBaseWidget = _.partial(pageObjectConfigService.setBaseWidget, vm, vm.temp);
        vm.resetRecordFields = resetRecordFields;

        // Column selection
        vm.removeColumn = pageObjectConfigService.removeColumn;
        vm.selectColumn = pageObjectConfigService.selectColumn(onColumnAdded, onColumnRemoved);
        vm.selectAllColumns = pageObjectConfigService.selectAllColumns(onColumnAdded, onColumnRemoved);
        vm.removeAllColumns = pageObjectConfigService.removeAllColumns;
        vm.colIsSelected = pageObjectConfigService.colIsSelected;
        vm.allColSelected = pageObjectConfigService.allColSelected;

        vm.onColumnAdded = onColumnAdded;
        vm.onColumnRemoved = onColumnRemoved;

        // Filter Commands
        vm.addToFilterMap = pageObjectConfigService.addToFilterMap;
        vm.removeFromFilterMap = pageObjectConfigService.removeFromFilterMap;

        // Sort Commands
        vm.addSortItem = pageObjectConfigService.addSortItem;

        // Modal Commands
        vm.save = pageObjectConfigService.savePageObjConfig($uibModalInstance, vm.margin, vm.padding);

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.deletePageObject = function(){
            $uibModalInstance.dismiss('delete');
        };

        vm.addCategory = function(){
            var newCategory = {
                display: '',
                id: ''
            };

            vm.temp.params.customCategories.push(newCategory);
        };

        // Aggregates
        vm.addAggregate = function(aggregate){
            vm.temp.viz.settings.dataSource.schema.aggregates = 'nim_aggregate';
            vm.temp.viz.settings.dataSource.aggregate = vm.temp.viz.settings.dataSource.aggregate || [];
            vm.temp.viz.settings.dataSource.aggregate.push(angular.copy(aggregate));

            for(var c = 0; c < vm.temp.viz.settings.columns.length; c++){
                var column = vm.temp.viz.settings.columns[c];
                if(column.field == aggregate.field){
                    if(column.aggregates.indexOf(aggregate.aggregate) == -1) {
                        column.aggregates.push(aggregate.aggregate);
                    }
                    break;
                }
            }

            aggregate.field = null;
            aggregate.format = null;
            aggregate.aggregate = null;
            aggregate.nimLabel = '';
        };

        vm.removeAggregate = function(aggregate, index){
            vm.temp.viz.settings.dataSource.aggregate.splice(index, 1);

            _.forEach(vm.temp.viz.settings.columns, function (column) {
                if(column.field == aggregate.field){
                    column.aggregates.splice(column.aggregates.indexOf(aggregate.aggregate), 1);
                    return false;
                }
            });
        };

        //Init
        init();

        function init(){
            ViewsService.get(false).then(function(views){
                vm.views = views;
            });

            vm.loadColumns(vm.editView);
            loadFormFields(pageObject.viz.widgetId);

            pageObjectConfigService.updateSortConfig(vm.temp.viz.settings.dataSource);
            vm.sortBySortableOptions = pageObjectConfigService.createSortableOptions(vm.temp.viz.settings.dataSource.sort);

            WidgetsService.getWidgets().then(function(widgets){
                vm.widgets = widgets;
            });

            _.defaults(vm.temp.params, {
                customCategories: [],
                openOnSelect: true,
                allowSortChange: vm.temp.params.editable,
                allowCategoryChange:  vm.temp.params.editable,
                showNewButton: true,
                defaultValues: {},
                requiredFilters: {},
                routeSettings: {
                    enabled: false,
                    newTab: false,
                    template: ''
                }
            });

            if(vm.temp.params.binField) {
                vm.loadCategories(vm.temp.params.binField);
            }

            vm.categorySortableOptions = pageObjectConfigService.createSortableOptions(vm.temp.params.customCategories);
        }

        // Controller-specific functions
        function onColumnAdded(colField){ // Fills in fields by default when column is selected
            var source = vm.temp.viz.settings.dataSource;
            var schemaFields = source.schema.model.fields;
            var type = schemaFields[colField].type;
            var params = vm.temp.params;

            if(type === 'number' && !params.orderKey){ // Item Order Field
                params.orderKey = colField;
            }
        }

        function onColumnRemoved(colField){ // Clears fields bound to the removed column
            var source = vm.temp.viz.settings.dataSource;
            var params = vm.temp.params;

            // Item Category Field
            if(params.binKey === colField){
                delete params.binKey;
            }

            // Item Order Field
            if(params.orderKey === colField){
                delete params.orderKey;
            }

            // Sort By
            if(_.get(source, 'sort.field') === colField){
                delete source.sort;
            }

            // Group By
            if(_.get(source, 'group.field') === colField){
                delete source.group;
            }

            // ID Override
            if(_.get(source, 'schema.model.id') === colField){
                delete source.schema.model.id;
            }
        }

        function loadFormFields(widgetId){
            if(widgetId) {
                WidgetsSettingsService.getFormFields(widgetId).then(function (data) {
                    vm.formFieldsArray = WidgetsSettingsService.parseFormObjects(data.display);
                });
            }
        }

        function resetRecordFields(){
            vm.temp.params.binField = null;
            vm.temp.params.orderField = null;
            vm.temp.params.defaultValues = {};
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;