angular.module('cerberus.admin')
    .controller('WorkspacesSettingsCtrl', function SettingsCtrl($scope, $location, $window, currentOrg, currentWorkspace, ConfirmModalService, workspacesService) {
        $scope.wsCollapse = false;
        $scope.accountCollapse = true;
        $scope.currentOrg = currentOrg;
        $scope.workspaceName = currentWorkspace.name;
        $scope.workspacePath = currentWorkspace.path;

        $scope.openDeleteWorkspaceModal = function(workspaceName, workspacePath){
            ConfirmModalService.showModal(null, {
                headerText: 'Confirm Deletion',
                bodyText: 'Are you sure you want to delete this workspace?',
                instructionText: 'Type "DELETE ' + workspaceName + '" to confirm.',
                actionButtonText: 'Delete Workspace Forever',
                closeButtonText: 'Cancel',
                check: function(input){
                    return angular.equals(input, 'DELETE ' + workspaceName);
                },
                confirm: function(){
                    return workspacesService.deleteCurrentWorkspace();
                },
                callback: function(){
                    $window.location = $location.absUrl().replace('/' + workspacePath + '/#/','/#/');
                }
            });
        };
    })
;