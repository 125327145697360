angular.module('cerberus.ui')
    /**
     * @ngdoc service
     * @name NimIconPickerService
     * @alias cerberus/ui:NimIconPickerService
     * @description Provides Icons to icon Picker
     */
    .factory('NimIconPickerService', function NimIconPickerService($http) {
        var service = {
            getIcons: getIcons
        };
        return service;
        ////////////////////
        /**
         * @function getIcons
         * @return {Promise} fontawesome icons
         */
        function getIcons() {
            return $http.get('/assets/fontawesomeicons.json', {cache:true}).then(function (value) {
                return value.data.icons;
            });
        }
    })
;