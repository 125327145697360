angular.module('cerberus.core')
/**
 * @ngdoc service
 * @name VizTableService
 * @alias cerberus/core:VizTableService
 * @description Provides datasources and helpers table viz
 */
    .factory('VizTableService', function VizTableService(_, kendo, moment, apiPath, $window, $timeout, $http, $log,
                                                        InstancesService, InstancesWindowService, OdataUtilityService,
                                                        pageObjectsService, VizUtilityService) {
        return {
            buildDataSource: buildDataSource,
            addCheckColumns: addCheckColumns,
            addMainColumns: addMainColumns,
            addDetailColumnsAndTemplates: addDetailColumnsAndTemplates,
            attachmentGridOptions: attachmentGridOptions,
            notesGridOptions: notesGridOptions,
            viewRecord: viewRecord,
            viewNextRecord: viewNextRecord,
            viewPreviousRecord: viewPreviousRecord,
            footerTemplate: footerTemplate,
            exportRecordsAsXLS: exportRecordsAsXLS,
            addDetailPageObjects: addDetailPageObjects,
            buildRow: buildRow
        };
        ////////////////////
        /**
         * Parses grid definition to properly set defines schema
         * @function buildDataSource
         * @param {object} pageObject
         * @param {object} scope - angularJs scope
         * @return {object} containing an Array of Columns and a Fields object
         */
        function buildDataSource(pageObject, scope) {
            var dataSourceOptions = {
                type: 'odata',
                transport:{
                    read: read
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                serverAggregates: true,
                serverGrouping: false,
                pageSize: 20,
                requestStart: function (e){
                    if(!_.isEmpty(pageObject.params.requiredFilters)){
                        _.forEach(pageObject.params.requiredFilters, function(required, filter){
                            if(required && VizUtilityService.isFilterEmpty(scope.filters[filter])){
                                e.preventDefault();

                                $timeout(function(){
                                    e.sender.success({d:{results: [], __count: 0}});
                                });

                                return false;
                            }
                        });
                    }
                }
            };

            var sortOptions = {
                sort: VizUtilityService.createSortArray(pageObject.viz.settings.dataSource)
            };

            var baseFilter = angular.copy(pageObject.viz.settings.dataSource.filter) || {
                filters: [],
                logic: 'and'
            };

            var filterOptions = {
                filter: {
                    filters: [baseFilter],
                    logic: pageObject.params.filterLogic || 'and'
                }
            };

            // Extend any user-defined dataSource settings
            angular.extend(dataSourceOptions, pageObject.viz.settings.dataSource, sortOptions, filterOptions);

            _.forEach(dataSourceOptions.schema.model.fields, function (field) {
                if(field.type === 'string') {
                    field.parse = parseStringValue;
                }
                else if(field.type === 'date') {
                    field.parse = VizUtilityService.parseDateField;
                }
                else if(field.type === 'number') {
                    field.parse = VizUtilityService.parseNumberField;
                }
            });

            if (_.get(pageObject, 'params.isTreeList', false)) {
                dataSourceOptions.schema.model.expanded = _.get(pageObject, 'params.treeExpanded', false);
                dataSourceOptions.serverAggregates = false;
                delete dataSourceOptions.pageSize;

                var parentModel = dataSourceOptions.schema.model.fields.parentId;
                if (!parentModel) {
                    parentModel = {
                        type: 'number'
                    };
                    dataSourceOptions.schema.model.fields.parentId = parentModel;
                }

                parentModel.parse = function (id) {
                    return id || null;
                 };

                return new kendo.data.TreeListDataSource(dataSourceOptions);
            }
            // Return new Datasource
            return new kendo.data.DataSource(dataSourceOptions);

            function read(options) {
                var httpOptions = VizUtilityService.buildBaseHttpOptions(pageObject, scope.isPreview);
                httpOptions.params = VizUtilityService.buildHttpParams(scope, dataSourceOptions, options);

                if (pageObject.params) {
                    var recurrence = VizUtilityService.buildRecurrenceData(pageObject, scope, options.data);
                    if (recurrence) {
                        httpOptions.params.$recurrence = recurrence;
                    }
                }

                $http(httpOptions).then(function (result) {
                    options.success(result.data);
                }, function (result) {
                    options.error(result);
                });
            }
        }

        function addCheckColumns(options, pageObject) {
            var columnArray = options.columns;
            
            //Page Object Filtering
            if (pageObject.params.allowMultiSelect) {
                columnArray.push({
                    template: '<input class="form-control" type="checkbox" ng-checked="vm.rowSelected(#: id #)" ng-model="selected" ng-click="vm.selectRow(#: id #)" />',
                    width: 30,
                    type: 'filter',
                    title: '',
                    headerTemplate: '<input class="form-control" type="checkbox" ng-checked="vm.allRowsSelected" ng-click="vm.selectAllRows(!vm.allRowsSelected)" />',
                    menu: false
                });
            }

            // Row Icons
            if (pageObject.params.useRowColors && pageObject.params.useRowIcons) {
                columnArray.push({
                    template: _.partial(rowIconTemplate, pageObject.params.rowColors),
                    width: 34,
                    title: '',
                    headerTemplate: ' ',
                    menu: false
                });
            }
        }

        // Builds Primary data columns
        function addMainColumns(options, pageObject) {
            var mainColumns = angular.copy(pageObject.viz.settings.columns),
                columnArray = options.columns;
            
            _.forEach(mainColumns, function (col) {
                // Hide Group Column
                if (_.has(pageObject, 'viz.settings.dataSource.group.field')) {
                    if (col.field === pageObject.viz.settings.dataSource.group.field) {
                        col.hidden = true;
                    }
                }    

                // Hide Parent Column
                if (pageObject.params.isTreeList && pageObject.params.parentField) {
                    if (col.field === pageObject.params.parentField) {
                        col.hidden = true;
                    }
                }

                // Set Template
                if(col.type === 'string'){
                    col.template = stringColumnTemplate(col);
                }
                else if(col.type === 'location'){
                    setLocationColumnField(col);
                }
                else if(col.type === 'number'){
                    col.filterable = {
                        ui: _.partial(numberColumnFilterableUI, col)
                    };
                }

                // Set Footer Template - 'Get aggregate columns'
                /* LODASH Version 4 ONLY */
                //var colAggregates = _.filter(pageObject.viz.settings.dataSource.aggregate, ['field', col.field]);
                /* LODASH Version 3 & 4 Compatibility Mode */
                var colAggregates = _.filter(pageObject.viz.settings.dataSource.aggregate, function(c){return c.field === col.field;});

                if (colAggregates.length) {
                    col.footerTemplate = _.partial(footerTemplate, colAggregates);
                }

                columnArray.push(col);
            });

            return mainColumns;
        }

        function addDetailColumnsAndTemplates(vm, options, pageObject, navFunctions) {
            var detailTemplates = [];

            // Recurrence column
            if (pageObject.viz.settings.showRecurrence) {
                options.columns.push({
                    title: 'Repeat',
                    field: '__nimRRuleText'
                });
            }

            // Detail Page Objects - now much simpler
            if (_.isArray(pageObject.viz.drillDownSettings)) {
                vm.detailPageObjects = new Array(pageObject.viz.drillDownSettings.length);
                addDetailPageObjects(detailTemplates, vm.detailPageObjects, pageObject.viz.drillDownSettings);
            }

            //Attachments
            if (pageObject.viz.settings.showAttaches) {
                detailTemplates.push({
                    title: 'Attachments',
                    body: '<div kendo-grid="attachmentGrid" k-options="vm.attachmentGridOptions(dataItem)"></div>'
                });

                options.columns.push({
                    title: 'Attachments',
                    field: 'attachment_count',
                    headerTemplate: '<i class="fa fa-lg fa-paperclip" title="Attachments"></i>',
                    width: 50,
                    filterable: false
                });
            }

            //Notes
            if (pageObject.viz.settings.showNotes) {
                detailTemplates.push({
                    title: 'Notes',
                    body: '<div kendo-grid="noteGrid" k-options="vm.notesGridOptions(dataItem)"></div>'
                });

                options.columns.push({
                    title: 'Notes',
                    field: 'note_count',
                    headerTemplate: '<i class="fa fa-lg fa-sticky-note-o" title="Notes"></i>',
                    width: 50,
                    filterable: false
                });
            }

            // "Open" Button
            if (pageObject.params.openButton) {
                options.columns.push({
                    title: ' ',
                    menu: false,
                    width: 90,
                    headerTemplate: '<i class="fa fa-lg fa-clone"></i>',
                    command: [{
                        name: 'openRecord',
                        text: 'Open',
                        click: function (e) {
                            e.preventDefault();

                            var tr = e.target.closest('tr'),
                                dataItem = this.dataItem(tr);

                            if (dataItem) {
                                viewRecord(dataItem.id, navFunctions);
                            }
                        }
                    }]
                });
            }

            // Tree List "Add Child" Button
            if (pageObject.params.isTreeList && pageObject.params.addChild) {
                options.columns.push({
                    title: '',
                    menu: false,
                    command: [{
                        name: 'addChild',
                        text: _.get(pageObject, 'params.addChildText', 'Add Child'),
                        click: function (e) {
                            var tr = e.target.closest('tr'),
                                dataItem = this.dataItem(tr),
                                defaultData = [{
                                    data: {
                                        value: dataItem.id,
                                        lookupDisplay: dataItem.__parent_text
                                    },
                                    field: pageObject.params.parentField
                                }];

                            pageObjectsService.addCustomValuesToDefaultData(defaultData, pageObject.params.defaultValues);

                            InstancesWindowService.openWindow({
                                action: 'create',
                                widgetId: pageObject.viz.widgetId,
                                title: pageObject.wrapper.header.title,
                                defaultData: defaultData
                            });
                        }
                    }]
                });
            }

            if (detailTemplates.length > 1) {
                var titleHtml = '';
                var bodyHtml = '';
                _.forEach(detailTemplates, function (dtemp, i) {
                    var activeState = '';
                    if (i === 0) {
                        activeState = 'class="k-state-active"';
                    }
                    titleHtml = titleHtml + '<li ' + activeState + '>' + dtemp.title + '</li>';
                    bodyHtml = bodyHtml + '<div>' + dtemp.body + '</div>';
                });
                options.detailTemplate = '<div kendo-tabstrip k-activate="vm.detailTabChanged">' + '<ul>' + titleHtml + '</ul>' + bodyHtml + '</div>';
            }
            else if (detailTemplates.length == 1) {
                options.detailTemplate = detailTemplates[0].body;
            }

            return detailTemplates.length > 0;
        }

        // Options for Attachments detail grid
        function attachmentGridOptions(dataItem) {
            return {
                columns: [
                    {
                        field: 'name',
                        title: 'Name'
                    },
                    {
                        field: 'uploadedBy',
                        title: 'Uploaded By'
                    },
                    {
                        field: 'uploadDate',
                        title: 'Upload Date',
                        template: function(attachment){
                            return VizUtilityService.parseDateField(attachment.uploadDate);
                        }
                    }
                ],
                dataSource: {
                    data: dataItem.__nimAttachments,
                    pageSize: 10
                },
                height: 377,
                columnMenu: false,
                editable: false,
                filterable: true,
                mobile: false,
                navigatable: true,
                pageable: true,
                reorderable: false,
                resizable: true,
                scrollable: true,
                selectable: true,
                sortable: true,
                change: function(e){
                    var selected = e.sender.select();
                    if(selected.length > 0){
                        var attachment = e.sender.dataItem(selected[0]);

                        InstancesService.getDownloadKey(attachment.id).then(function (d) {
                            InstancesService.getBlob(d).then(function (pdfBlob) {
                                $window.saveAs(pdfBlob, attachment.name);
                            });
                        });
                    }
                }
            };
        }

        // Options for Notes detail grid
        function notesGridOptions(dataItem) {
            var noteDs = new kendo.data.DataSource({
                type: 'odata',
                transport:{
                    read: {
                        url: apiPath + 'instances/' + dataItem.id + '/metadata/odata.svc?type=Note',
                        beforeSend: VizUtilityService.setRequestHeaders,
                        dataType: 'json'
                    },
                    create: {
                        url: apiPath + 'instances/' + dataItem.id + '/metadata',
                        beforeSend: VizUtilityService.setRequestHeaders,
                        dataType: 'json'
                    },
                    update: {
                        url: function(options){
                            return apiPath + 'instance/metadata/' + options.id;
                        },
                        beforeSend: VizUtilityService.setRequestHeaders,
                        dataType: 'json'
                    },
                    destroy: {
                        url: function(options){
                            return apiPath + 'instance/metadata/' + options.id;
                        },
                        beforeSend: VizUtilityService.setRequestHeaders,
                        dataType: 'json'
                    },
                    parameterMap: function(options, operation) {
                        if ((operation !== 'destroy' && operation !== 'read') && options.title) {
                            return angular.toJson({
                                dataStruct:{
                                    title: options.title,
                                    body: options.body
                                },
                                type: 'Note',
                                isPublic: options.is_public
                            });
                        }
                    }
                },
                serverFiltering: true,
                serverPaging: true,
                serverSorting: true,
                noRecords: true,
                pageSize: 10,
                schema: {
                    model: {
                        id: 'id',
                        fields:{
                            id: {editable: false},
                            sys_created_by: {editable: false},
                            is_public: {type:'boolean'},
                            title: {validation: { required: true}}
                        }
                    }
                }
            });
            return {
                columns: [
                    {
                        field: 'title',
                        title: 'Title',
                        width: 200
                    },
                    {
                        field: 'body',
                        title: 'Body'
                    },
                    {
                        field: 'is_public',
                        title: 'Public',
                        template: '#= is_public ? \'Yes\' : \'No\' #',
                        width: 80
                    },
                    {
                        field: 'sys_created_by',
                        title: 'Created By',
                        width: 150
                    },
                    {
                        command: ['edit', 'destroy'],
                        title: '&nbsp;',
                        width: '230px'
                    }

                ],
                toolbar: ["create"],
                dataSource: noteDs,
                height: 377,
                columnMenu: false,
                editable: 'inline',
                filterable: true,
                mobile: false,
                navigatable: true,
                pageable: true,
                reorderable: true,
                resizable: true,
                scrollable: true,
                sortable: true
            };
        }

        /**
         * Callback function to be used with kendo grid's onChange event
         * @function viewRecord
         */
        function viewRecord(instanceId, navFunctions) {
            InstancesWindowService.openWindow({
                action: 'read',
                instanceId: instanceId,
                navFunctions: navFunctions
            });
        }

        /**
         * Navigation function used to open next record from instance window
         * @param instanceController
         * @param dataSource
         */
        function viewNextRecord(instanceController, dataSource){
            var currentId = instanceController.instanceId,
                currentDataItem = dataSource.get(currentId),
                currentIndex = dataSource.indexOf(currentDataItem),
                currentPageSize = dataSource.data().length,
                nextItem;

            if(currentIndex >= 0) {
                if (currentIndex < currentPageSize - 1) {
                    nextItem = dataSource.at(currentIndex + 1);
                }

                if (nextItem) {
                    instanceController.updateInstanceAttrs(nextItem.id, 'read');
                    instanceController.init();
                }
            }
        }

        /**
         * Navigation function used to open previous record from instance window
         * @param instanceController
         * @param dataSource
         */
        function viewPreviousRecord(instanceController, dataSource){
            var currentId = instanceController.instanceId,
                currentDataItem = dataSource.get(currentId),
                currentIndex = dataSource.indexOf(currentDataItem),
                prevItem;

            if(currentIndex >= 0) {
                if (currentIndex > 0) {
                    prevItem = dataSource.at(currentIndex - 1);
                }

                if (prevItem) {
                    instanceController.updateInstanceAttrs(prevItem.id, 'read');
                    instanceController.init();
                }
            }
        }

        /**
         * Builds footer string to display column aggregates
         * @param aggregates
         * @param aggregatesValue
         */
        function footerTemplate(aggregates, aggregatesValue) {
            var footerHTML = '';

            _.forEach(aggregates, function (aggr) {
                var value;

                if(_.has(aggregatesValue, aggr.field)){
                    value = aggregatesValue[aggr.field][aggr.aggregate];
                }
                else {
                    value = aggregatesValue[aggr.aggregate];
                }

                if (aggr.format) {
                    try {
                        value = kendo.format(aggr.format, value);
                    }
                    catch (e) {
                        $log.error(e);
                    }
                }
                footerHTML += '<div>' + aggr.nimLabel + ': ' + value + '</div>';
            });

            return footerHTML;
        }

        function exportRecordsAsXLS(id, filter, sort) {
            var params = {
                $orderBy: VizUtilityService.formatOrderBy(sort)
            };

            if (filter) {
                VizUtilityService.cleanFilterConfiguration(filter.filters);

                if (!_.isEmpty(filter.filters)) {
                    params.$filter = OdataUtilityService.toOdataFilter(filter);
                }
            }
            $http.get(apiPath + '/page/objects/' + id + '/export',{
                params: params
            }).then(function(result){
                $window.open(result.data, '_self');
            });
        }

        function addDetailPageObjects(tempArray, detailArray, pageObjects) {
            _.forEach(pageObjects, function (pageObj, i) {
                var col = pageObj.column || 'id';
                tempArray.push({
                    title: pageObj.title,
                    body: '<div ng-if="vm.detailPageObjects[' + i + ']" nim-page-object="vm.detailPageObjects[' + i + ']" no-wrapper parent-id="dataItem.' + col + '" parent-look-up="\'' + pageObj.field + '\'"></div>'
                });
                loadDetailPageObject(detailArray, pageObj.pageObjectId, i);
            });
        }

        function buildRow(rowColors, columns, hasDetails, dataItem){
            var styles = [];

            _.forEach(rowColors, function (rc) {
                if (rc.conditions.length > 0) {
                    if (checkCondition(rc, dataItem)) {
                        styles.push('background-color:' + rc.color);

                        var color = kendo.parseColor(rc.color);
                        if (color) {
                            // Taken from kendo code
                            var brightness = Math.sqrt(0.241 * color.r * color.r + 0.691 * color.g * color.g + 0.068 * color.b * color.b);

                            if (brightness < 180) {
                                styles.push('color:#ffffff');
                            }
                        }

                        return false;
                    }
                }    
            });

            var rowClass = '';

            if(hasDetails){
                rowClass = 'class="k-master-row"';
            }

            var row = '<tr ' + rowClass + ' data-uid="' + dataItem.uid + '" style="' + styles.join(';') + '">';

            if(hasDetails){
                row += '<td class="k-hierarchy-cell"><a class="k-icon k-i-expand" tabindex="-1"></a></td>';
            }

            _.forEach(columns, function(c){
                if(!c.hidden) {
                    row += '<td>';

                    if (c.template) {
                        var tempFunction = c.template;

                        if (!_.isFunction(tempFunction)) {
                            tempFunction = kendo.template(c.template);
                        }

                        row += tempFunction(dataItem);
                    }
                    else if (c.format) {
                        row += kendo.format(c.format, dataItem[c.field]);
                    }
                    else if (c.command) {
                        _.forEach(c.command, function (command) {
                            row += commandTemplate(command);
                        });
                    }
                    else {
                        row += dataItem[c.field];
                    }

                    row += '</td>';
                }
            });

            row += '</tr>';

            return row;
        }

        // Helpers

        function numberColumnFilterableUI(column, element){
            element.kendoNumericTextBox({
                format: column.format || 'n',
                decimals: 13
            });
        }

        // For formatting Phone Numbers and URLs
        function stringColumnTemplate(column){
            return function(dataItem){
                var field = column.field,
                    value = dataItem[field];

                if(typeof value === 'string'){
                    if (value.search(/^((http|https):\/\/)[a-zA-Z]+\.[a-zA-Z\.]*[a-zA-Z]+(:[0-9]+)?(\/[a-zA-Z0-9\.\?#\-%&=])*$/) >= 0) {
                        return '<a ng-bind="dataItem.' + field + '" ng-href="{{dataItem.' + field + '}}" target="_blank"></a>';
                    }
                    else if (value.search(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/) >= 0) {
                        var pNum = value.replace('(', '').replace(')', '').replace(' ', '-');
                        return '<a ng-bind="dataItem.' + field + '" href="tel:' + pNum + '"></a>';
                    }
                    else if (value.search(/^\\w[\\w\\.\\-]*\\w@\\w+[\\w\\.\\-]*\\.[a-zA-Z]{2,}$/) >= 0) {
                        return '<a ng-bind="dataItem.' + field + '" ng-href="mailto:{{dataItem.' + field + '}}"></a>';
                    }
                    else if (column.format === 'image') {
                        if (value) {
                            var widthString = '';
                            if (column.width) {
                                widthString = 'width="' + (column.width - 20) + '"';
                            }
                            return '<div nim-img="' + value + '" ' + widthString + '></div>';
                        }
                        else {
                            return '';
                        }
                    }
                }
                else if (typeof value == 'number' && column.format === 'fileSize'){
                    return getFileSize(value);
                }
                else if(typeof value === 'object'){
                    return '<span ng-bind="dataItem.' + field + '.display"></span>';
                }
                else if(column.format === 'boolCheck'){
                    if(value === true) {
                        return '<i class="fa fa-check"></i>';
                    }
                    else if(!value){
                        return '';
                    }
                }
                return '<span ng-bind="dataItem.' + field + '"></span>'; 
            };
        }

        function getFileSize(bytes){
            var kilobytes = bytes / 1024,
                megabytes = kilobytes / 1024,
                gigabytes = megabytes / 1024;

            if(bytes < 1000){
                return bytes + ' B';
            }
            else if(kilobytes < 1000){
                return _.round(kilobytes, 1) + ' KB';
            }
            else if(megabytes < 1000){
                return _.round(megabytes, 1) + ' MB';
            }
            else {
                return _.round(gigabytes, 1) + ' GB';
            }
        }

        function setLocationColumnField(column){
            var property = _.get(column, 'nim_locationProperty', 'formatted_address');
            column.field = column.field + '.properties.' + property;

            column.template = function(dataItem){
                return _.get(dataItem, column.field, '');                                                                                                                                                                                                                                                                                                                                                                                                 
            };
        }

        function parseStringValue(value){
            if(typeof value === 'string') {
                // Removes HTML tags from strings
                // Wraps in div to ensure the string is not treated as a selector
                return angular.element('<div>' + value + '</div>').text();
            }
            else if(_.has(value, 'display')){
                return parseStringValue(value.display);
            }
            else if(angular.equals(value, {})){
                return '';
            }
            return value;
        }

        function loadDetailPageObject(detailArray, id, index){
            pageObjectsService.getPageObject(id).then(function(pageObject){
                detailArray[index] = pageObject;
            });
        }

        // Checks row color conditions and displays an icon
        function rowIconTemplate(rowColors, dataItem) {
            var icon = '';

            _.forEach(rowColors, function (rc) {
                if (rc.conditions.length > 0) {
                    if (rc.icon && checkCondition(rc, dataItem)) {
                        icon = '<i class="fa fa-' + rc.icon + '" style="color:' + rc.color + '"></i>';

                        return false;
                    }
                }    
            });

            return icon;
        }

        function checkCondition(rowColor, dataItem){
            var iteratee = function(cond){
                var colValue = dataItem[cond.field];
                var value = cond.value;
                if(_.isString(value) && value.length > 2 && _.startsWith(value, '"') && _.endsWith(value, '"')){
                    var valueCol = cond.value.replace(/^"/, '').replace(/"$/, '');
                    value = dataItem[valueCol];
                }

                switch(cond.operator){
                    case 'conains':
                        return _.get(dataItem, cond.field, '').indexOf(value) >= 0;
                    case 'doesnotcontain':
                        return _.get(dataItem, cond.field, '').indexOf(value) < 0;
                    case 'startswith':
                        return _.startsWith(_.get(dataItem, cond.field, ''), value);
                    case 'endswith':
                        return _.endsWith(_.get(dataItem, cond.field, ''), value);
                    case 'eq':
                        if(_.isDate(colValue) && _.isDate(value)){
                            return moment(colValue).isSame(value);
                        }
                        else if(!_.isDate(colValue) && !_.isDate(value)){
                            return colValue === value;
                        }
                        return false;
                    case 'neq':
                        if(_.isDate(colValue) && _.isDate(value)){
                            return !moment(colValue).isSame(value);
                        }
                        else if(!_.isDate(colValue) && !_.isDate(value)){
                            return colValue !== value;
                        }
                        return false;
                    case 'gt':
                        if(_.isDate(colValue) && _.isDate(value)){
                            return moment(colValue).isAfter(value);
                        }
                        else if(!_.isDate(colValue) && !_.isDate(value)){
                            return colValue > value;
                        }
                        return false;
                    case 'gte':
                        if(_.isDate(colValue) && _.isDate(value)){
                            return moment(colValue).isSameOrAfter(value);
                        }
                        else if(!_.isDate(colValue) && !_.isDate(value)){
                            return colValue >= value;
                        }
                        return false;
                    case 'lt':
                        if(_.isDate(colValue) && _.isDate(value)){
                            return moment(colValue).isBefore(value);
                        }
                        else if(!_.isDate(colValue) && !_.isDate(value)){
                            return colValue < value;
                        }
                        return false;
                    case 'lte':
                        if(_.isDate(colValue) && _.isDate(value)){
                            return moment(colValue).isSameOrBefore(value);
                        }
                        else if(!_.isDate(colValue) && !_.isDate(value)){
                            return colValue <= value;
                        }
                        return false;
                    default:
                        return false;
                }
            };

            if(rowColor.logic === 'or'){
                return _.some(rowColor.conditions, iteratee);
            }
            else {
                return _.every(rowColor.conditions, iteratee);
            }
        }

        function commandTemplate(command) {
            var temp = '<a class="k-button k-button-icontext# if(data.name){ # k-grid-#: data.name # # }## if(data.className){ # #: data.className # # }#" href="">' +
                '<span class=""></span># if(data.text){ # #= data.text # # } else if(data.name){ # #: data.name # # } #' +
            '</a > ';

            try {
                return kendo.template(temp)(command);
            }
            catch (e) {
                $log.error(e);
                return '';
            }
        }
    })
;