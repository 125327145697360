angular.module('cerberus.admin')
    .controller('WidgetMassUpdateModalCtrl', function WidgetMassUpdateModalCtrl(_, $scope, $uibModalInstance,
                    pageObjectConfigService, WidgetsSettingsService, DesignerUtilityService, widget, updateOptions) {
        var vm = this;

        vm.widget = angular.copy(widget);
        vm.updateOptions = updateOptions;
        $scope.temp = {
            model: {
                calculation: {
                    formula: "" //formula struct holder, (used by codemirror)
                }
            }
        };

        vm.updateActionTypes = [
            {value: 'deleteKey', display: 'Delete Column'},
            {value: 'updateKey', display: 'Update Column'},
            {value: 'copyKey', display: 'Copy Column'},
            {value: 'moveKey', display: 'Move Column'}
        ];

        vm.formulasList = [
            {value: '(((\'Price\' * \'Rate\') + 5.5 - 3) \\ 2)', display: 'Arithmetic'},
            {value: '(\'Rate\' - 2) ^ 2', display: 'Math power (^)'},
            {value: '\'CustomerName\' & " - " & \'OrderNumber\'', display: 'Str - Concatenate'},
            {value: 'REReplace(\'CustomerName\', "[q|7|W|\\n]", "X", "ALL")', display: 'Str - RegExReplace (Case sensitive)'},
            {value: 'REReplaceNoCase(\'CustomerName\', "[q|7|W|\\n]", "X", "ALL")', display: 'Str - RegExReplace (Case unsensitive)'},
            {value: 'UCase(\'CustomerName\')', display: 'Str - Convert uppercase'},
            {value: 'LCase(\'CustomerName\')', display: 'Str - Convert lowercase'},
            {value: 'Len(\'CustomerName\')', display: 'Str - Return length of string'},
            {value: 'Left(\'CustomerName\', "5")', display: 'Str - Get nr. of chars starting leftside'},
            {value: 'Right(\'CustomerName\', "5")', display: 'Str - Get nr. of chars starting rightside'},
            {value: 'LTrim(\'CustomerName\')', display: 'Str - Trim leading spaces from left'},
            {value: 'RTrim(\'CustomerName\')', display: 'Str - Trim trailing spaces from right'},
            {value: 'Trim(\'CustomerName\')', display: 'Str - Trim leading & trailing spaces'}
        ];

        vm.FormulaHelper =  "..:: Useful variables and formula usage ::..\n" +
                            "If more than 2 'math operators', use '( )' brackets to set up calculation order and priority. " + 
                            "Tested math operators: '+ - * / ^'. To join (Concatenate) multiple objects use '&' operator. " + 
                            "single-quotes represent object links, double-quotes represent text strings.\n" +
                            "..:: List of supported ColdFusion function(s) ::..\n" +
                            "REReplace, ReReplaceNoCase, UCase, LCase, Len, Left, Right, LTrim, RTrim, Trim.\n" +
                            "..:: Basic examples ::..\n" +
                            "{Concatenation} - 'CustomerName' & \" - \" & 'OrderNumber' - 'Concatenation' example.\n" +
                            "{Math Operations} - ('Price' * 'Rate') + 1.5 - 'Math' example.\n" +
                            "(End of instructions).";

        // Widget functions
        vm.formFieldsArray = [];       // fields from Page objects service script (pageObjectConfigService)
        vm.WidgetFormFieldsArray = []; // fields from Widgets service script (WidgetsSettingsService)
        vm.loadFormFields = _.partial(pageObjectConfigService.loadFormFields, vm);

        // Modal functions
        $scope.isNotEqual = function(data){
            return !angular.equals('MASSUPDATE ' + vm.widget.name, data);
        };

        $scope.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        $scope.finish = function(){
            updateOptions.updateNewVal.formula = $scope.temp.model.calculation.formula; //update formula
            $uibModalInstance.close(vm.widget, vm.updateOptions);
        };

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });

        //Init
        init();

        //Predefined functions
        function init(){
            // Get all Field Objects Form Objects for the DropDown
            vm.loadFormFields(vm.widget.publicKey);
            
            // Get all Field Objects from Widget for Grid Table
            WidgetsSettingsService.getFormFields(vm.widget.publicKey).then(function(formFieldArray){
            vm.WidgetFormFieldsArray = getFormFieldObjects(formFieldArray);
            
            // Form Objects Grid
            $scope.mainGridOptions = DesignerUtilityService.formGrid(formFieldArray.display);
            $scope.detailGridOptions = DesignerUtilityService.formDetailGrid($scope);

            // Codemirror
            $scope.codemirrorLoaded = DesignerUtilityService.codemirrorLoaded(formFieldArray.operands);
            });
        }

        function getFormFieldObjects(fieldArray){
            var objects = [];
            for(var f = 0; f < fieldArray.display.length; f++){
                var form = fieldArray.display[f];
                for(var o = 0; o < form.objects.length; o++){
                    var object = form.objects[o];
                    object.formId = form.originId;
                    object.realFormId = form.formId;
                    objects.push(object);
                }
            }
            return objects;
        }

    //-----------------
    //end of controller
    })
;