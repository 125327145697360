angular.module('cerberus.admin')
    .controller('WidgetsDataCtrl', function WidgetsDataCtrl(_, kendo, moment, $scope, $rootScope, $window, $uibModal, $timeout, widgetId,
                                                            ConfirmModalService, InstancesService, InstancesWindowService, OdataUtilityService, WidgetsService, WidgetsSettingsService) {

        /* Populate Scope */
        $scope.widgetPublicKey = widgetId;
        $scope.widgetModel = {};
        $scope.activeRoute = 'overview';
        $scope.formFieldsArray = [];
        $scope.selectedRows = [];
        $scope.columns = [{
            template: '<input class="form-control" type="checkbox" ng-checked="rowSelected(#: id #)" ng-model="selected" ng-click="toggleSelectRow(#: id #)" />',
            width: 30,
            type: 'batch',
            title: '',
            headerTemplate: '<input class="form-control" type="checkbox" ng-model="selected" ng-click="selectAllRows(selected)" />',
            menu: false
        }];
        $scope.fields = {};
        $scope.nestedDataSources = {};
        $scope.navFunctions = {
            getNext: getNext,
            getPrevious: getPrevious,
            callback: instanceCallback
        };
        $scope.pageSize = 20;

        $scope.tools = [
            {
                type: 'button',
                text: '<span class="fa fa-plus"></span>&nbsp;Create Record',
                click: function(){
                    $scope.openWindow($scope.widgetPublicKey);
                }
            },
            {
                type: 'splitButton',
                text: '<i class="fa fa-file-excel-o"></i>&nbsp;Export as Excel',
                click: function(){
                    $scope.exportAsExcel();
                },
                menuButtons: [
                    {
                        type: 'button',
                        text: '<i class="fa fa-file-text-o"></i>&emsp;Export All',
                        click: function(){
                            $scope.openWidgetExportAllModal();
                        }
                    }
                ]
            },
            {
                type: 'splitButton',
                text: 'Page Size: {{pageSize}}',
                menuButtons: [
                    {
                        type: 'button',
                        text: '20',
                        click: function(){
                            $timeout(function(){
                                $scope.setPageSize(20);
                            });
                        }
                    },
                    {
                        type: 'button',
                        text: '50',
                        click: function(){
                            $timeout(function(){
                                $scope.setPageSize(50);
                            });
                        }
                    },
                    {
                        type: 'button',
                        text: '100',
                        click: function(){
                            $timeout(function(){
                                $scope.setPageSize(100);
                            });
                        }
                    }
                ]
            },
            {
                type: 'separator'
            },
            {
                type: 'button',
                attributes: {
                    'ng-disabled': 'selectedRows.length < 1'
                },
                text: '<i class="fa fa-trash-o"></i>&nbsp;Delete Selected',
                click: function(){
                    $scope.deleteSelected($scope.selectedRows);
                }
            },
            {
                type: 'separator'
            },
    //-------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------
    // REMOVED IN FEBRUARY 2019, MODAL INTRODUCED FOR BETTER RESULTS AND OPTIONS
    //-------------------------------------------------------------------------------------------------
    //        {
    //            type: 'splitButton',
    //            text: '<i class="fa fa-wrench"></i>&nbsp;Update Selected Schema',
    //            attributes: {
    //                'ng-disabled': 'selectedRows.length < 1'
    //            },
    //            click: function(){
    //                $scope.updateSelectedSchemas($scope.selectedRows, true, true);
    //            },
    //            menuButtons: [
    //                //{
    //                //    type: 'button',
    //                //    text: 'Selected',
    //                //    click: function(){
    //                //        $scope.updateSelectedSchemas($scope.selectedRows, true, true);
    //                //    }
    //                //},
    //                {
    //                    type: 'button',
    //                    text: 'Update All',
    //                    click: function(){
    //                        $scope.updateAllSchemas($scope.widgetPublicKey, true, true);
    //                    }
    //                }
    //            ]
    //        },
    //-------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------
            {
                type: 'splitButton',
                text: '<i class="fa fa-wrench"></i>&nbsp;Schema Update Selected',
                attributes: {
                    'ng-disabled': 'selectedRows.length < 1'
                },
                click: function(){
                    $scope.openWidgetSchemaUpdateModal($scope.selectedRows, null);
                },
                menuButtons: [
                    {
                        type: 'button',
                        text: '<i class="fa fa-exclamation-triangle"></i>&emsp;All Widget Schema Update',
                        click: function(){
                            $scope.openWidgetSchemaUpdateModal(null, $scope.widgetPublicKey);
                        }
                    }
                ]
            },
            {
                type: 'splitButton',
                text: '<i class="fa fa-retweet"></i>&nbsp;Data Mass Update',
                attributes: {
                    'ng-disabled': 'selectedRows.length < 1'
                },
                click: function(){
                    $scope.openWidgetMassUpdateModal($scope.selectedRows, null);
                },
                menuButtons: [
                    {
                        type: 'button',
                        text: '<i class="fa fa-exclamation-triangle"></i>&emsp;All Widget Mass Update',
                        click: function(){
                            $scope.openWidgetMassUpdateModal(null, $scope.widgetPublicKey);
                        }
                    }
                ]
            },
            /*{
                type: 'splitButton',
                text: '<i class="fa fa-wrench"></i>&nbsp;Recalculate Selected',
                attributes: {
                    'ng-disabled': 'selectedRows.length < 1'
                },
                click: function(){
                    $scope.updateSelectedCalculations($scope.selectedRows);
                },
                menuButtons: [
                    {
                        type: 'button',
                        text: 'Recalculate All',
                        click: function(){
                            $scope.updateAllCalculations($scope.widgetPublicKey);
                        }
                    }
                ]
            },*/
            {
                type: 'separator'
            },
            {
                type: 'button',
                text: '<span class="fa fa-lightbulb-o"></span>&nbsp;Id',
                attributes: {
                    'ng-disabled': '((selectedRows.length > 5) || (selectedRows.length < 1))'
                },
                click: function(){
                  ConfirmModalService.showModal(null, {
                    headerText: 'Instance(s) Id',
                    bodyText: '<strong>' + $scope.selectedRows + '</strong>',
                    closeButtonText: 'Cancel',
                    check: function(){
                        return true;
                    }
                  });
                }
            }
        ];

        //Batch Options
        $scope.toggleSelectRow = function(rowId){
            var index = $scope.selectedRows.indexOf(rowId);
            if(index < 0){
                $scope.selectedRows.push(rowId);
            }
            else{
                $scope.selectedRows.splice(index, 1);
            }
            selectGridRows($scope.selectedRows);
        };

        $scope.selectAllRows = function(selected){
            var rows = $scope.grid.dataSource.data();
            if(rows.length > 0 && $scope.grid.pager){
                var pageNum = $scope.grid.pager.page();
                var pageSize = $scope.grid.pager.pageSize();
                var start = pageSize * (pageNum - 1);
                var end = (pageSize * pageNum) - 1;
                if(end >= rows.length){
                    end = rows.length - 1;
                }
                for(var r = start; r <= end; r++){
                    var id = rows[r].id;
                    var index = $scope.selectedRows.indexOf(id);
                    if(selected && index < 0){
                        $scope.selectedRows.push(id);
                    }
                    else if(!selected && index >= 0){
                        $scope.selectedRows.splice(index, 1);
                    }
                }
            }
        };

        $scope.rowSelected = function(id){
            return $scope.selectedRows.indexOf(id) >= 0;
        };

        $scope.deleteSelected = function(idArr){
            ConfirmModalService.showModal(null, {
                headerText: 'Confirm Deletion',
                bodyText: '<strong>Are you sure you want to delete these records FOREVER?</strong>',
                actionButtonText: 'Delete',
                closeButtonText: 'Cancel',
                confirm: function(){
                    InstancesService.hardDelete(idArr).then(function(){
                        $scope.options.dataSource.read();
                        $scope.selectedRows = [];
                    });
                },
                check: function(){
                    return true;
                }
            });
        };

        //Modal schema update
        $scope.openWidgetSchemaUpdateModal = function(idArr, widId){
            var selectiveUpdate;
            if(idArr){selectiveUpdate = true;}else{selectiveUpdate = false;}
            var updateOptions = {
                isSelective: selectiveUpdate,
                createRevision: true,
                updateWorkflow: false,
                dataSourceFilter: ""
            };
            var sourceFilter = $scope.options.dataSource.filter();
            if (sourceFilter) {
                updateOptions.dataSourceFilter = OdataUtilityService.toOdataFilter(sourceFilter);
            }
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/widgets/data/widgets-data-schema-update-modal.tpl.html',
                controller: 'WidgetSchemaUpdateModalCtrl',
                backdrop: 'static',
                resolve: {
                    widget: function(){
                        return $scope.widgetModel;
                    },
                    updateOptions: function(){
                        return updateOptions;
                    }
                }
            });
            modalInstance.result.then(function () {
                //update selective records
                if(idArr){
                    InstancesService.updateSchema({ids:idArr, updateSettings:updateOptions}).then(function(){
                        $scope.options.dataSource.read();
                        $scope.selectedRows = [];
                    });
                }
                //update all records from widget / (include option to use selective filter criteria)
                else if(widId) {
                    InstancesService.updateAllSchema(widId, updateOptions).then(function(){
                        $scope.options.dataSource.read();
                        $scope.selectedRows = [];
                    });
                }
            });
        };

        //Modal 'Object Mass Update Data' from columns
        $scope.openWidgetMassUpdateModal = function(idArr, widId){
            var selectiveUpdate;
            if(idArr){selectiveUpdate = true;}else{selectiveUpdate = false;}
            var updateOptions = {
                isSelective: selectiveUpdate,
                createRevision: true,
                updateRevision: false,
                updateAction: "",
                updateSrcKey: {
                    isFormObj: true,
                    modelId: "",
                    label: ""
                    },
                updateSrcKey_IsObject: true,
                updateNewKey: {
                    isFormObj: true,
                    modelId: "",
                    label: ""
                    },
                updateNewKey_IsObject: true,
                updateNewVal: {
                    text: "",
                    formula: "",
                    obj_keys: "id,display",
                    obj_values: "1234,Sample Test"
                    },
                updateNewValueType: "",
                dataSourceFilter: ""
            };
            var sourceFilter = $scope.options.dataSource.filter();
            if (sourceFilter) {
                updateOptions.dataSourceFilter = OdataUtilityService.toOdataFilter(sourceFilter);
            }
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/widgets/data/widgets-data-mass-update-modal.tpl.html',
                controller: 'WidgetMassUpdateModalCtrl',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    widget: function(){
                        return $scope.widgetModel;
                    },
                    updateOptions: function(){
                        return updateOptions;
                    }
                }
            });
            modalInstance.result.then(function () {
                //update selective records
                if(idArr){
                    //console.log(updateOptions['updateNewVal']['formula']);
                    InstancesService.updateInstanceObject({ids:idArr, updateSettings:updateOptions}).then(function(){
                        $scope.options.dataSource.read();
                        $scope.selectedRows = [];
                    });
                }
                //update all records from widget / (include option to use selective filter criteria)
                else if(widId){
                    InstancesService.updateAllInstanceObject(widId, updateOptions).then(function(){
                        $scope.options.dataSource.read();
                        $scope.selectedRows = [];
                    });
                }
            });
        };

    //-------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------
    // REMOVED IN FEBRUARY 2019, MODAL INTRODUCED FOR BETTER RESULTS AND OPTIONS
    //-------------------------------------------------------------------------------------------------
    //
    //    //Update Schemas
    //        $scope.updateSelectedSchemas = function(idArr, createRev, updWorkFlowState){
    //            var body_msg = '<p><strong>Are you sure you want to update the selected records?</strong></p>';
    //            body_msg = body_msg + '<p>Schema update <strong>"with revisions"</strong> will push existing instance data into a revision and resets instances with corresponding new published workflow and ';
    //            body_msg = body_msg + 'associated workflow -> object -> form(s). <i><strong>"Current Workflow StateID" resets to "1".</strong></i></p>'; 
    //            body_msg = body_msg + '<p>Schema update <strong>"without revisions"</strong> is similar, but revisions are not made, "Current Workflow StateID" & "Previous Workflow Paths" are not changed.</p>';
    //            body_msg = body_msg + '<p>Note: <strong><i>Workflows will be processed and operations within objects may occur.</i></strong> Ex: send emails, push notifications, update data within other widgets, etc.</p>';
    //            ConfirmModalService.showModal(null, {
    //                headerText: 'Confirm Schema Update',
    //                bodyText: body_msg,
    //                actionButtonText: 'YES, I HAVE READ THE INSTRUCTIONS AND I AM READY TO PROCEED.',
    //                closeButtonText: 'Cancel',
    //                confirm: function(){
    //                InstancesService.updateSchema({ids:idArr, createRevision:createRev, updateWflowState:updWorkFlowState}).then(function(){
    //                    $scope.options.dataSource.read();
    //                    $scope.selectedRows = [];
    //                });
    //                },
    //                check: function(){
    //                    return true;
    //                }
    //            });
    //        };
    //        $scope.updateAllSchemas = function(widgetId, createRev, updWorkFlowState){
    //            var body_msg = '<p><strong>Are you sure you want to update "All Records"?</strong></p>';
    //            body_msg = body_msg + '<p>Schema update <strong>"with revisions"</strong> will push existing instance data into a revision and resets instances with corresponding new published workflow and ';
    //            body_msg = body_msg + 'associated workflow -> object -> form(s). <i><strong>"Current Workflow StateID" resets to "1".</strong></i></p>';
    //            body_msg = body_msg + '<p>Schema update <strong>"without revisions"</strong> is similar, but revisions are not made, "Current Workflow StateID" & "Previous Workflow Paths" are not changed.</p>';
    //            body_msg = body_msg + '<p>Note: <strong><i>Workflows will be processed and operations within objects may occur.</i></strong> Ex: send emails, push notifications, update data within other widgets, etc.</p>';
    //            ConfirmModalService.showModal(null, {
    //                headerText: 'Confirm Schema Update',
    //                bodyText: body_msg,
    //                actionButtonText: 'YES, I HAVE READ THE INSTRUCTIONS AND I AM READY TO PROCEED.',
    //                closeButtonText: 'Cancel',
    //                confirm: function(){
    //                InstancesService.updateAllSchema(widgetId, createRev, updWorkFlowState).then(function(){
    //                    $scope.options.dataSource.read();
    //                    $scope.selectedRows = [];
    //                });
    //                },
    //                check: function(){
    //                    return true;
    //                }
    //            });
    //        };
    //-------------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------------

        //Update Calculations
        $scope.updateSelectedCalculations = function(idArr){
            InstancesService.updateCalculations({ids:idArr}).then(function(){
                $scope.options.dataSource.read();
                $scope.selectedRows = [];
            });
        };
        $scope.updateAllCalculations = function(widgetId){
            InstancesService.updateAllCalculations(widgetId).then(function(){
                $scope.options.dataSource.read();
                $scope.selectedRows = [];
            });
        };

        /* Get widget data */
        $scope.$watch(WidgetsService.widgetComponents, function (newValue){
            if(newValue) {
                $scope.widgetModel = angular.copy(newValue);
            }
        });

        /* Overview Logic */
        $scope.openFile = function (loc) {
            $window.open(loc);
        };

        $scope.init = function(){
            // widgetModel resolve
            WidgetsService.getWidget(widgetId).then(function(widgetModel){
                $scope.widgetModel = angular.copy(widgetModel);

                var widgetFileName = widgetModel.name || 'UntitledWidget';
                widgetFileName = widgetFileName.replace(/[\W_]/g,'') + '_' + kendo.toString(new Date(), 'yyyy_MM_dd');
                var excelOptions = {
                    excel: {
                        fileName: widgetFileName + ".xlsx",
                        filterable: true
                    }
                };

                _.assign($scope.options, excelOptions);

                if($scope.grid){
                    $scope.grid.setOptions(excelOptions);
                }
            });

            // formFieldsArray resolve
            WidgetsSettingsService.getFormFields(widgetId).then(function(formFieldsArray){
                $scope.formFieldsArray = formFieldsArray;

                for(var i = 0; i < $scope.formFieldsArray.display.length; i++){
                    if($scope.formFieldsArray.display[i].objects){
                        for(var j = 0; j < $scope.formFieldsArray.display[i].objects.length; j++){
                            var colObj = $scope.formFieldsArray.display[i].objects[j];
                            if(colObj.type != 'readonly' && colObj.type != 'dataseries' && colObj.type != 'map'){
                                var fieldName = colObj.modelId;
                                var schemaField = {
                                    type: colObj.type
                                };

                                if(colObj.type === 'string'){
                                    schemaField.parse = stripHTML;
                                }
                                else if (colObj.type == 'date') {
                                    schemaField.parse = parseDateField;
                                }
                                if(colObj.isLookUp || colObj.isUsersRelationship || colObj.isCustomLookup){
                                    fieldName = colObj.modelId + '.display';
                                    schemaField.parse = setDefaultDisplayValue;
                                }
                                else if(colObj.type === 'location'){
                                    fieldName = colObj.modelId + '.properties.formatted_address';
                                    schemaField.parse = setDefaultGeoDisplayValue;
                                }

                                $scope.columns.push(
                                    {
                                        field: fieldName,
                                        title: colObj.label,
                                        type: colObj.type,
                                        width: 130
                                    }
                                );

                                if(colObj.format){
                                    _.last($scope.columns).format = colObj.format;
                                }

                                $scope.fields[colObj.modelId] = schemaField;
                            }
                        }
                    }
                }

                $scope.fields['sys_created_date'] = {
                    type: 'date',
                    parse: parseSystemDateField
                };

                $scope.fields['sys_last_modified_date'] = {
                    type: 'date',
                    parse: parseSystemDateField
                };

                $scope.columns.push(
                    {
                        title: 'Created By',
                        field: 'sys_created_by',
                        width: 130,
                        menu: false
                    },
                    {
                        title: 'Created On',
                        field: 'sys_created_date',
                        format: '{0:G}',
                        width: 130,
                        menu: false
                    },
                    {
                        title: 'Last Modified By',
                        field: 'sys_last_modified_by',
                        width: 130,
                        menu: false
                    },
                    {
                        title: 'Last Modified On',
                        field: 'sys_last_modified_date',
                        format: '{0:G}',
                        width: 130,
                        menu: false
                    },
                    {
                        title: 'Attachments',
                        field: 'attachment_count',
                        width: 60,
                        menu: false
                    },
                    {
                        title: 'Notes',
                        field: 'note_count',
                        width: 60,
                        menu: false
                    }
                );

                var dataSource = new kendo.data.DataSource({
                    type: 'odata',
                    transport:{
                        read: {
                            url: '/server/rest/v1/widgets/' + $scope.widgetPublicKey + '/records/odata.svc',
                            beforeSend: function(xhr){
                                xhr.setRequestHeader('Authentication','Bearer ' + $rootScope.authToken);
                                xhr.setRequestHeader('WSID',$rootScope.userData.currentWorkspace.id);
                            },
                            dataType: 'json',
                            data: function(config){
                                if(config.filter) {
                                    var filter = config.filter;
                                    delete config.filter;

                                    return {
                                        $filter: OdataUtilityService.toOdataFilter(filter)
                                    };
                                }
                            }
                        }
                    },
                    serverFiltering: true,
                    serverPaging: true,
                    serverSorting: true,
                    pageSize: $scope.pageSize,
                    schema: {
                        model: {
                            fields: $scope.fields
                        }
                    }
                });

                $scope.options.dataSource = dataSource;

                if($scope.grid){
                    $scope.grid.setDataSource(dataSource);
                    $scope.grid.setOptions({ columns: $scope.columns });
                }
            });
        };

        $scope.init();

        $scope.options = {
            columnMenu: true,
            columns:  $scope.columns,
            filterable: true,
            mobile: false,
            pageable: true,
            reorderable: true,
            resizable: true,
            sortable: true,
            groupable: true,
            selectable: "row",
            remove: function (e) {
                var id = e.model.id;
                InstancesService.remove(id);
            },
            change: function(e){
                var selected = e.sender.select();
                if(selected.length > 0) {
                    var dataItem = this.dataItem(selected[0]);
                    
                    InstancesWindowService.openWindow({
                        action: 'read',
                        instanceId: dataItem.id,
                        title: $scope.widgetModel.name,
                        navFunctions: $scope.navFunctions
                    });
                }
            }
        };

        $scope.$on('$locationChangeStart', function(){
            if ($scope.grid) {
                $scope.grid.destroy();
                $scope.grid = null;
            }
        });

        $scope.$on('$destroy', function () {
            
            delete $scope.navFunctions.getNext;
            delete $scope.navFunctions.getPrevious;
            delete $scope.navFunctions.callback;
        });

        $scope.setPageSize = function (size) {
            if ($scope.grid) {
                $scope.pageSize = size;
                $scope.options.dataSource.pageSize(parseInt(size));
                $scope.grid.refresh();
            }
        };

        $scope.exportAsExcel = function () {
            if ($scope.grid) {
                $scope.grid.saveAsExcel();
            }
        };

        $scope.openWidgetExportAllModal = function(){
            var exportOptions = {
                    LineBreak: 'crlf',
                    CellsFormatCrLfDetection: 'none',
                    CellsFormatQuoteCharReplace: 'none',
                    quoteAllCells: false,
                    markNullCells: false,
                    fieldDelimiter: 'comma',
                    CellsFormatCrLfDetection_IsField: true,
                    CellsFormatQuoteCharReplace_IsField: true
                };
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/widgets/data/widgets-data-exportall-advanced-modal.tpl.html',
                controller: 'WidgetExportAllAdvancedModalCtrl',
                backdrop: 'static',
                resolve: {
                    exportOptions: function(){
                        return exportOptions;
                    }
                }
            });
            modalInstance.result.then(function(){
                WidgetsSettingsService.exportAllAsExcel($scope.widgetPublicKey, $scope.options.dataSource.filter(), $scope.options.dataSource.sort(), exportOptions).then(function(result){
                        $scope.options.dataSource.read();
                        $scope.selectedRows = [];
                        if(result){ //download file...
                            $window.open(result, '_self');
                        }
                    });
            });
        };

        $scope.openWindow = function(wId){
            InstancesWindowService.openWindow({
                action: 'create',
                widgetId: wId,
                title: $scope.widgetModel.name,
                navFunctions: $scope.navFunctions
            });
        };

        function selectGridRows(ids){
            var data = $scope.options.dataSource.data();
            for(var i = 0; i < data.length; i++){
                var selector = ".k-grid tr:eq(" + (i + 1) + ")";
                if(ids.indexOf(data[i].id) >= 0){
                    $(selector).addClass('selected');
                }
                else{
                    $(selector).removeClass('selected');
                }
            }
        }

        // For removing HTML tags from strings
        function stripHTML(string){
            if(string) {
                // Wraps in div to ensure the string is not treated as a selector
                return angular.element('<div>' + string + '</div>').text();
            }
            return string;
        }

        function setDefaultDisplayValue(displayVal){
            return displayVal || '';
        }

        function setDefaultGeoDisplayValue(displayVal){
            if(!_.isEmpty(displayVal)){
                return displayVal;
            }
            else {
                return { properties: { formatted_address: '' } };
            }
        }

        function getPrevious(instanceController){
            if($scope.grid){
                var dataSource = $scope.grid.dataSource,
                    currentId = instanceController.instanceId,
                    currentDataItem = dataSource.get(currentId),
                    currentIndex = dataSource.indexOf(currentDataItem),
                    prevItem;

                if(currentIndex >= 0) {
                    if (currentIndex > 0) {
                        prevItem = dataSource.at(currentIndex - 1);
                    }

                    if (prevItem) {
                        instanceController.updateInstanceAttrs(prevItem.id, 'read');
                        instanceController.init();
                    }
                }
            }
        }

        function getNext(instanceController){
            if($scope.grid){
                var dataSource = $scope.grid.dataSource,
                    currentId = instanceController.instanceId,
                    currentDataItem = dataSource.get(currentId),
                    currentIndex = dataSource.indexOf(currentDataItem),
                    currentPageSize = dataSource.data().length,
                    nextItem;

                if(currentIndex >= 0) {
                    if (currentIndex < currentPageSize - 1) {
                        nextItem = dataSource.at(currentIndex + 1);
                    }

                    if (nextItem) {
                        instanceController.updateInstanceAttrs(nextItem.id, 'read');
                        instanceController.init();
                    }
                }
            }
        }

        function instanceCallback(){
            $scope.options.dataSource.read();
        }

        /**
         * The parse function for data-source schema fields of type date.
         * Changes date string back to a Date object
         * @param value - date string serialized by Coldfusion
         * @returns {Date, string} - corrected Date or empty string if no value
         */
        function parseDateField(value){
            if(value && !_.isDate(value)){
                try {
                    var dateValue = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
                    dateValue.local();

                    return dateValue.toDate();
                }
                catch(e){}
            }
            else if(_.isDate(value)){
                return value;
            }

            return '';
        }

        /**
         * The parse function for data-source schema fields of type date.
         * Changes date string back to a Date object
         * @param value - date string serialized by Coldfusion
         * @returns {Date, string} - corrected Date or empty string if no value
         */
        function parseSystemDateField(value){
            if(value && !_.isDate(value)){
                try {
                    var dateValue = moment.utc(value, 'MMMM, DD YYYY HH:mm:ss');
                    dateValue.local();

                    return dateValue.toDate();
                }
                catch(e){}
            }
            else if(_.isDate(value)){
                return value;
            }

            return '';
        }
    })
;