angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizChartScatterService
     * @alias cerberus/core:VizChartScatterService
     * @description Provides functions for configuring scatter charts
     */
    .factory('VizChartScatterService', function VizChartScatterService(){
        return {
            //configScatter: configScatter
        };

        /**
         * This function dynamically adjusts certain parameters for the chart settings
         * @function configScatter
         * @param data
         * @param options
         *  function configScatter(data, options){
         *   // No extra config needed, for now
         *  }
         */

    })
;