angular.module('cerberus.core')
    .controller('InstancePushActionCtrl', function (_, $q, $scope, kendo, InstanceId, actionType, pushActionStruct, $uibModalInstance, WorkflowsClassService, pushActionService, InstancesService ) {
        var vm = this;

        vm.InstanceId = InstanceId;
        vm.pushActionStruct = pushActionStruct;
        vm.temp = {
            actionType: actionType,
            email: {}
        };
        vm.workflowEmailAccounts = WorkflowsClassService.getDefaultSettings('emailAccounts');

        vm.subjectList = [
            {value: 'Important - Invoice Confirmation', display: 'Invoice confirmation'},
            {value: 'Important - Purchase Order Confirmation', display: 'P.O. confirmation'},
            {value: 'Request reply - Quote Confirmation', display: 'Quote confirmation'},
            {value: 'Request reply - Sales Order Confirmation', display: 'S.O. confirmation'},
            {value: 'Please find the revised invoice in the email', display: 'Revised Invoice'},
            {value: 'Please find the revised Purchase Order in the email', display: 'Revised P.O.'},
            {value: 'Please find the revised Quote in the email', display: 'Revised Quote'},
            {value: 'Please find the revised Sales Order in the email', display: 'Revised S.O.'},
            {value: 'Please find a newer file version in the current email', display: 'Updated file'}
        ];

        vm.actionInProgress = false;
        vm.submitStatusError = false;
        vm.submitErrMsg = '';
        vm.submit = submit;
        vm.cancel = cancel;

        _init();

        function _init() {
            // Load all instance data values
            _getInstanceDataValues().then(function () {
                // Get all params for input push action type 'actionEmail'
                if (vm.temp.actionType === 'actionEmail') {
                    vm.temp.email = angular.copy(vm.pushActionStruct.actions.email); // Initiate default values
                    _getPushActionDataValues(vm.temp.actionType);
                }
                //else if (vm.temp.actionType === 'someOtherAction') { // Get params for other actions... (Reserved for future usage)
                //}
            });
        }

        function _getInstanceDataValues() {
            var deferred = $q.defer();
            InstancesService.getInstance(vm.InstanceId).then(function (data) {
                vm.instance = data;
                _.forEach(vm.pushActionStruct.instance, function (instanceFormAuxiliaryData, key) {
                    vm.instance[key] = instanceFormAuxiliaryData;
                });
                deferred.resolve();
            });
            return deferred.promise;
        }

        function _getPushActionDataValues(actionType) {
            if (actionType === 'actionEmail') {
                // Get subject line.
                var subject = getDataFieldValue(_.get(vm.temp.email.subject, 'text', null), _.get(vm.temp.email.subject, 'autoFill', null), _.get(vm.temp.email.subject, 'modelId', null));
                vm.temp.email.subject = subject;
                // Get 'Send To' email address line.
                var SendTo = getDataFieldValue(_.get(vm.temp.email.SendTo, 'text', null), _.get(vm.temp.email.SendTo, 'autoFill', null), _.get(vm.temp.email.SendTo, 'modelId', null));
                vm.temp.email.SendTo = SendTo;
                // Get 'Send To CC' email address line.
                var SendToCC = getDataFieldValue(_.get(vm.temp.email.SendToCC, 'text', null), _.get(vm.temp.email.SendToCC, 'autoFill', null), _.get(vm.temp.email.SendToCC, 'modelId', null));
                vm.temp.email.SendToCC = SendToCC;
                // Get 'Send To BCC' email address line.
                var SendToBCC = getDataFieldValue(_.get(vm.temp.email.SendToBCC, 'text', null), _.get(vm.temp.email.SendToBCC, 'autoFill', null), _.get(vm.temp.email.SendToBCC, 'modelId', null));
                vm.temp.email.SendToBCC = SendToBCC;
            }
        }

        function getDataFieldValue(column_StaticText, column_AutofillSource, column_FormModelField) {
            if(column_StaticText || column_AutofillSource || column_FormModelField) {
                var textValue = '',
                    autofillValue = '',
                    fieldValue = '';

                if(column_StaticText && _.isString(column_StaticText)) {
                    // Attempt to evaluate data value from workflow explicit static text column
                    textValue = column_StaticText;
                }

                if(column_AutofillSource) {
                    // Attempt to evaluate data value from instance auto-fill object container
                    autofillValue = _.get(vm.instance.autoFillData, column_AutofillSource, ''); 
                    if(_.has(autofillValue, 'display')) {
                        autofillValue = autofillValue.display;
                    }
                }

                if(column_FormModelField) {
                    // Attempt to evaluate data value from instance dataSet object container
                    fieldValue = _.get(vm.instance.dataSet, column_FormModelField, ''); 
                    if(_.has(fieldValue, 'display')) {
                        fieldValue = fieldValue.display;
                    }
                }
                // Concatenate string values and return
                var ret = ''.concat(textValue, autofillValue, fieldValue);
                return ret;
            }
            else {
                return '';
            }
        }

        function submit() {
            // Manage progress monitoring
            vm.actionInProgress = true;
            // Initiate function here
            var promiseData = {
                'action222': 'someactionhere',
                'otherstuff': 'otherCrap2'
            };
            
            vm.temp.promiseDataTempTest = promiseData;
            //temp... here we may propagate explicit instance ID State (single form printing)
            vm.temp.instance_stateId = null;
            //temp... to create invoice number
            vm.temp.instance_defaultFileName = 'Invoice_'.concat(_.get(vm.instance.dataSet, 'InvoiceNumber', '').toString());


            pushActionService.execute(vm.InstanceId, vm.temp).then(function (result) {
                var noteData = {
                    title: _.get(vm.temp.email, 'menutitle', 'Auto'),
                    body: kendo.format('{0} - {1:yyyy-MM-dd HH:mm:ss (zzz)}', '[Auto] '.concat(vm.temp.actionType), new Date()),
                    isPublic: 'YES',
                    type: 'Note'
                };
                pushActionService.placeNote(vm.InstanceId, noteData).then(function (/*result2*/) {
                    vm.actionInProgress = false;
                    if (result.errMsg) {
                        vm.submitStatusError = true;
                        vm.submitErrMsg = result.errMsg;
                    }
                    else if (result) {
                        $uibModalInstance.close(result);
                    }
                    else {
                        $uibModalInstance.close(result);
                    }
                }, function () {
                    vm.actionInProgress = false;
                    vm.submitStatusError = false;
                });
            });
        }

        // Cancel button execution
        function cancel() {
            $uibModalInstance.dismiss();
        }

        // Prevents navigation while modal is open
        var allowNavigation = $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });

        $scope.$on('modal.closing', function () {
            allowNavigation();
        });
    })
;