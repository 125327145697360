angular.module('cerberus')
    // == app environment ==
    .constant('buildEnvironment', {                                  // main app build environment (to identify proper edition)
        env: 'Prod'
    })
    // == firebase related constants ==
      .constant('fbUrl', 'https://cpcmrp-6a749.firebaseio.com')      // must match fbUrl from app_vars.xml file (production)
    //.constant('fbUrl', 'https://cpcmrpdev.firebaseio.com')         // must match fbUrl from app_vars.xml file (development)
    // == mapbox related constants ==
    .constant('mapboxConfig', {
        token: 'pk.eyJ1IjoibmltYnVzc3ciLCJhIjoiY2o0amd3YXh5MDh4bjJxcWpxYXQwdHFzaSJ9.JwV5qI6JUww1P8-GP7DBWg'
    })
;