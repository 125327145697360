angular.module('cerberus.account')
    .controller('OrganizationsGroupsDetailsCtrl', function OrganizationsGroupsDetailsCtrl(
        $scope, kendo, $location, toaster, OrganizationsService, organizationGroupsService, groupId, orgId) {
        var vm = this;

        $scope.activeRoute = 'groups';
        $scope.orgData = {};
        $scope.orgId = orgId;
        vm.groupId = groupId;

        vm.tempGroupModel = organizationGroupsService.groupModel();
        vm.origGroupModel = angular.copy(vm.tempGroupModel);

        vm.selectedMembers = {};
        vm.selectedMemberOf = {};
        vm.availableMembersDs = null;
        vm.availableMemberOfDs = null;
        vm.membersSelectOptions = {
            placeholder: 'Enter users/groups here...',
            dataTextField: 'name'
        };
        vm.memberOfSelectOptions = {
            placeholder: 'Enter group(s) here...',
            dataTextField: 'name'
        };

        vm.add = organizationGroupsService.addItem;
        vm.remove = organizationGroupsService.removeItem;
        vm.isUnchanged = organizationGroupsService.isUnchanged;
        vm.submit = function(isValid, data){
            if(isValid){
                organizationGroupsService.updateOrganizationGroup(groupId, data, vm.origGroupModel).then(function(){
                    toaster.pop('success', 'Group updated.');
                    $location.url('organizations/' + orgId + '/settings/groups');
                });
            }
        };

        vm.cancel = function(){
            $location.url('organizations/' + orgId + '/settings/groups');
        };

        init();

        function init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });

            organizationGroupsService.getGroupDetails(vm.groupId).then(function(data){
                vm.tempGroupModel.details = data;
                vm.origGroupModel = angular.copy(vm.tempGroupModel);
            });

            organizationGroupsService.getMembers(vm.groupId).then(function(data){
                vm.tempGroupModel.members.current = data;
                vm.origGroupModel = angular.copy(vm.tempGroupModel);
            });

            organizationGroupsService.getMemberOf(vm.groupId).then(function(data){
                vm.tempGroupModel.memberOf.current = data;
                vm.origGroupModel = angular.copy(vm.tempGroupModel);
            });

            organizationGroupsService.getAvailableMembers(orgId, vm.groupId).then(function(data){
                vm.availableMembersDs = new kendo.data.ObservableArray(data);
            });

            organizationGroupsService.getAvailableMemberOf(orgId, vm.groupId).then(function(data){
                vm.availableMemberOfDs = new kendo.data.ObservableArray(data);
            });
        }
    })
;