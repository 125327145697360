angular.module('cerberus.admin')
    .controller('RulesCreateCtrl', function($scope, $location, RulesService){
        var vm = this;

        vm.temp = {};
        vm.orig = {};
        vm.engines = [{
            value: 'nimbus',
            display: 'Nimbus'
        }];

        vm.isUnchanged = _isUnchanged;
        vm.save = _save;
        vm.cancel = _cancel;

        _init();

        function _init(){
            vm.orig = {
                name: '',
                type: 'nimbus',
                path: '',
                description: ''
            };
            vm.temp = angular.copy(vm.orig);
        }

        function _isUnchanged(data){
            return angular.equals(data, vm.orig);
        }

        function _save(data){
            RulesService.addRule(data).then(function(result){
                $location.replace().url('/settings/rules/' + result.ruleId);
            });
        }

        function _cancel(){
            $location.replace().url('/settings/rules');
        }
    })
;