angular.module('cerberus.core')
/**
 * @ngdoc service
 * @name VizScheduleCalendarService
 * @alias cerberus/core:VizScheduleCalendarService
 * @description Provides functions for configuring calendars
 */
    .factory('VizScheduleCalendarService', function VizScheduleCalendarService(){
        return {
            //configCalendar: configCalendar
        };

        /**
         * Modifies calendar settings
         * @param data
         * @param options
         * function configCalendar(data, options){}
         */

    })
;