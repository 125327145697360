angular.module('cerberus.core')
    .controller('AuthLoginCtrl', function AuthLoginCtrl(moment, $scope, $location, $rootScope, appSettingsService, $uibModalInstance, AuthService, AuthPasswordService, localStorageService) {
        // Populate Scope
        $scope.branding = appSettingsService.branding();
        $scope.mode = 'login';
        $scope.loginData = {type: localStorageService.get('device') || 'public'};
        $scope.regData = {};
        $scope.regConfirm = null;
        $scope.regTermsConditions = null;
        $scope.forgotData = {};
        $scope.result = {};
        $scope.signingIn = false;

        /* UI */
        // This controls the collapse directive to show and hide sections
        $scope.setMode = function (mode) {
            $scope.mode = mode;
        };

        /* Login */
        $scope.login = function(isValid, data) {
            if (isValid) {
                $scope.signingIn = true;
                
                AuthService.login(data).then(
                    function () {
                        localStorageService.set('device', data.type);
                        $uibModalInstance.close();
                        $scope.signingIn = false;
                    }, function () {
                        $scope.signingIn = false;
                        $scope.loginData.userPassword = null;
                        $scope.result.visible = true;
                        $scope.result.class = 'danger';
                        $scope.result.title = 'Error:';
                        $scope.result.message = 'Invalid email and password combination; try again.';
                    }
                );
            }
        };

        /* Cancel Button */
        $scope.cancel = function () {
            // Reset registration data and form
            //$scope.regData = {};
            //$scope.regConfirm = null;
            //$scope.regTermsConditions = null;
            //$scope.regForm.$setPristine();
            //$scope.regForm.$setUntouched();
            // Reset forgot password data and form
            $scope.forgotData = {};
            $scope.forgotForm.$setPristine();
            $scope.forgotForm.$setUntouched();
            // Reset login data and form
            $scope.loginData.userPassword = null;
            $scope.loginForm.$setPristine();
            $scope.loginForm.$setUntouched();
            // Show the login form
            $scope.setMode('login');
        };

        /* Registration */
        //$scope.register = function(isValid, data){
        //    if (isValid && $scope.regTermsConditions) {
        //        $scope.mode = 'login';
        //        $scope.result.visible = true;
        //        $scope.result.class = 'success';
        //        $scope.result.title = 'Awesome!';
        //        $scope.result.message = 'Check your email for a verification link; you must verify your account before logging in.';
        //        $scope.buttonText = 'Okay';
        //        AuthService.postUser(data).then(
        //            function (value) {},
        //            function (reason) {
        //                $scope.result.visible = true;
        //                $scope.result.class = 'danger';
        //                $scope.mode = 'register';
        //                if (reason.status === 409 || reason.data.CODE === 409) {
        //                    $scope.result.title = 'Error:';
        //                    $scope.result.message = 'Please verify that you entered a valid email.';
        //                } else {
        //                    $scope.result.title = 'Error:';
        //                    $scope.result.message = 'Something went wrong, please try again.';
        //                }
        //            }
        //        );
        //    }
        //    else if(!$scope.regTermsConditions){
        //        $scope.result.visible = true;
        //        $scope.result.class = 'danger';
        //        $scope.result.title = 'Error:';
        //        $scope.result.message = 'Please check "I agree to the Terms and Conditions and Privacy Policy" to continue.';
        //    }
        //};

        /* Forgot Password */
        $scope.forgotPassword = function (isValid, data) {
            if (isValid) {
                AuthPasswordService.sendEmail(data).then(
                    function (/*value*/) {
                        $scope.result.visible = true;
                        $scope.result.class = 'success';
                        $scope.result.title = 'Success:';
                        $scope.result.message = 'Check your email for a password reset link.';
                    },
                    function (reason) {
                        $scope.result.visible = true;
                        $scope.result.class = 'danger';
                        if (reason.status === 404) {
                            $scope.result.title = 'Error:';
                            $scope.result.message = 'We do not have an account with that email address.';
                        } else if (reason.status === 406) {
                            $scope.result.title = 'Whoa there:';
                            $scope.result.message = 'Please verify your email first; a link has been sent to your email.';
                        } else {
                            $scope.result.title = 'Error:';
                            $scope.result.message = 'Please try again.';
                        }
                    }
                );
            }
        };
    })
;