angular.module('cerberus.ui')
    /**
     * @ngdoc service
     * @name ConfirmModalService
     * @alias cerberus/ui:ConfirmModalService
     * @description Provides quick, custom modals
     */
    .factory('ConfirmModalService', function ConfirmModalService($uibModal){

        var modalDefaults = {
            backdropClass: 'confirm',
            windowClass: 'modal-confirm',
            backdrop: true,
            keyboard: true,
            modalFade: true,
            templateUrl: 'ui/nim-confirm-modal/confirm-modal.tpl.html'
        };

        var modalOptions = {
            closeButtonText: 'Close',
            actionButtonText: 'OK',
            headerText: 'Proceed?',
            bodyText: 'Perform this action?',
            showActionButton: true,
            actionButtonClass: 'btn-danger',
            actionButtonIcon: '',
            actionButtonPosition: 'body',
            showCloseButton: true,
            closeButtonClass: 'btn-default',
            closeButtonIcon: 'fa-ban',
            showFooter: true,
            footerAlignment: 'right'
        };

        function showModal(customModalDefaults, customModalOptions) {
            if (!customModalDefaults) {
                customModalDefaults = {};
            }
            customModalDefaults.backdrop = 'static';
            return show(customModalDefaults, customModalOptions);
        }

        function show(customModalDefaults, customModalOptions) {
            //Create temp objects to work with since we're in a singleton service
            var tempModalDefaults = {};
            var tempModalOptions = {};

            //Map angular-ui modal custom defaults to modal defaults defined in service
            angular.extend(tempModalDefaults, modalDefaults, customModalDefaults);

            //Map modal.html $scope custom properties to defaults defined in service
            angular.extend(tempModalOptions, modalOptions, customModalOptions);

            if (!tempModalDefaults.controller) {
                tempModalDefaults.controller = tempModalDefaultsCtrl;
                tempModalDefaults.controllerAs = 'vm';
                tempModalDefaultsCtrl.$inject = ['$scope','$uibModalInstance'];
            }
            function tempModalDefaultsCtrl($scope, $uibModalInstance) {
                var vm = this;

                // Prevents Navigation while in modal
                var allowNavigation = $scope.$on('$locationChangeStart', function(event){
                    event.preventDefault();
                });

                vm.confirmTitle = '';
                vm.modalOptions = tempModalOptions;
                vm.modalOptions.ok = function (result) {
                    // De-registers event that prevents navigation
                    allowNavigation();

                    if(vm.modalOptions.confirm){
                        var promise = vm.modalOptions.confirm();
                        if(promise){
                            promise.then(function(){
                                if(vm.modalOptions.callback){
                                    vm.modalOptions.callback();
                                }
                                $uibModalInstance.close(result);
                            });
                        }
                        else {
                            $uibModalInstance.close(result);
                        }
                    }
                    else {
                        $uibModalInstance.close(result);
                    }
                };

                vm.modalOptions.close = function () {
                    $uibModalInstance.dismiss('cancel');
                };
            }

            return $uibModal.open(tempModalDefaults).result;
        }

        return {
            showModal: showModal
        };
    })
;