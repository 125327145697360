/*globals define, CodeMirror */
(function(mod) {
    if (typeof exports == "object" && typeof module == "object") {// CommonJS
        mod(require("../../lib/codemirror")); }
    else if (typeof define == "function" && define.amd) {// AMD
        define(["../../lib/codemirror"], mod); }
    else {// Plain browser env
        mod(CodeMirror); }
})(function(CodeMirror) {
    "use strict";

    var functions = [
        'ABS',
        'AVERAGE',
        'CEILING',
        'CONCATENATE',
        'DATEADD',
        'DATEDIFF',
        'DATESUB',
        'FLOOR',
        'IF',
        'INROLLCALIPER',
        'LEFT',
        'LEN',
        'MAX',
        'MIN',
        'NOW',
        'PI',
        'POWER',
        'PRODUCT',
        'QUOTIENT',
        'RIGHT',
        'ROLLBASISWEIGHT',
        'ROLLINNERDIAMETER',
        'ROLLLENGTH',
        'ROLLOUTERDIAMETER',
        'ROLLWEIGHT',
        'ROUND',
        'SQRT',
        'SUBSTITUTE',
        'SUM',
        'TEXT',
        'TEXTCASE(Text, [OPTIONS: "TITLECASE","UPPERCASE","LOWERCASE","FIRSTUPPERCASE","FIRSTLOWERCASE","FIRSTONLYUPPERCASE","FIRSTONLYLOWERCASE"])',
        'TRIM',
        'VALUE',
        'WRITTENNUMBER'
    ];
    var pos = CodeMirror.Pos;

    function getText(item) {
        return typeof item == "string" ? item : item.text;
    }

    function match(string, word) {
        var len = string.length;
        var sub = getText(word).substr(0, len);
        return string.toUpperCase() === sub.toUpperCase();
    }

    function looseMatch(string, word) {
        var sub = getText(word).indexOf(string.replace("'", ""));
        return sub !== -1;
    }

    function addMatches(result, search, wordlist, formatter) {
        if (wordlist && wordlist.length && typeof wordlist === 'object') {
            for (var i = 0; i < wordlist.length; i++) {
                if (match(search, wordlist[i])) {
                    result.push(formatter(wordlist[i]));
                }
            }
        }
    }

    function addLooseMatches(result, search, wordlist, formatter) {
        if (wordlist && wordlist.length && typeof wordlist === 'object') {
            for (var i = 0; i < wordlist.length; i++) {
                if (looseMatch(search, wordlist[i])) {
                    result.push(formatter(wordlist[i]));
                }
            }
        }
    }

    CodeMirror.registerHelper("hint", "nimcalc", function(editor, options) {
        var udf = options && options.udf || [];
        var namedVars = options && options.namedVars || [];

        var cur = editor.getCursor();
        var result = [];
        var token = editor.getTokenAt(cur),
            start, end, search;
        if (token.end > cur.ch) {
            token.end = cur.ch;
            token.string = token.string.slice(0, cur.ch - token.start);
        }
        if (token.string.match(/^[.'\w@]\w*$/)) {
            search = token.string;
            start = token.start;
            end = token.end;
        } else {
            start = end = cur.ch;
            search = "";
        }

        if (search.charAt(0) == "'" || token.type === 'variable') {
            addLooseMatches(result, search, namedVars, function(w) {
                return w;
            });
        } else if (search.charAt(0) == "("){
            // TODO: Show parameter help
        } else {
            addMatches(result, search, functions, function(w) {
                return w;
            });
            addMatches(result, search, udf, function(w) {
                return w;
            });
        }
        return {
            list: result,
            from: pos(cur.line, start),
            to: pos(cur.line, end)
        };
    });
});