angular.module('cerberus.admin')
    .config(function ($routeProvider) {
        $routeProvider.when('/settings/queries', {
            templateUrl: 'admin/views/views.tpl.html',
            controller: 'ViewsCtrl',
            controllerAs: 'views',
            title: 'Queries',
            resolve: {
                _wait: 'WaitForUserData',
                workspaceId: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function(){
                        return $rootScope.userData.currentWorkspace.id;
                    });
                }
            }
        });
        $routeProvider.when('/settings/queries/create', {
            templateUrl: 'admin/views/views-create.tpl.html',
            controller: 'ViewsCreateCtrl',
            controllerAs: 'vm',
            title: 'Create Query',
            resolve: {
                _wait: 'WaitForUserData',
                viewData: function(){
                    return {
                        name:'',
                        description:''
                    };
                }
            }
        });
        $routeProvider.when('/settings/queries/:id', {
            templateUrl: 'admin/views/designer/views-designer.tpl.html',
            controller: 'ViewsDesignerCtrl',
            controllerAs: 'vm',
            title: 'Query Designer',
            resolve: {
                _wait: 'WaitForUserData',
                viewId: function ($route) {
                    return $route.current.params.id;
                }
            }
        });
    })
;