angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name WaitForUserData
     * @alias cerberus/core:WaitForUserData
     * @description This is a FML hack. We need to halt most route changes until
     * userData has been received from the server and applied to the $rootScope
     */
    .factory('WaitForUserData',
    function WaitForUserData($q) {
        var p = $q.defer();
        var prom = p.promise;
        prom.doneWaiting = function() {
            p.resolve();
        };
        return prom;
    })

    /**
     * @ngdoc service
     * @name AuthService
     * @alias cerberus/core:AuthService
     * @description Makes calls to the "auth" resource for user login and information
     */
    .factory('AuthService', function AuthService(_, $q, $http, $timeout, $location, $rootScope, $window, apiPath, AuthHttpBuffer, toaster, localStorageService) {
        var attempts = 0;
        var maxAttempts = 5;
        var service = {
            loginConfirmed: loginConfirmed,
            loginCancelled: loginCancelled,
            login: login,
            getUserData: getUserData,
            logout: logout,
            //postUser: postUser,
            getUrlWorkspace: getUrlWorkspace,
            refreshJwt: refreshJwt
        };
        return service;
        ////////////////////
        /**
         *
         * @returns {*|r.promise|Function|promise|Document.promise}
         */
        function refreshJwt(){
            var deferred = $q.defer(),
                deviceType = localStorageService.get('device');
            $http.get(apiPath + 'refresh/jwt?deviceType=' + deviceType)
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        /**
         * Determines the workspace based upon the current URL
         * @function getUrlWorkspace
         * @param {string} url
         * @returns {string}
         */
        function getUrlWorkspace(url) {
            // isolate http:
            url = url.split('//');
            // isolate Angular route
            url = url[1].split('/#');
            // split path
            url = url[0].split('/');
            // remove the domain name
            url.splice(0,1);
            return url[0] || '';
        }

        /**
         * Call this function to indicate that authentication was successful and trigger a
         * retry of all deferred requests.
         * @param data an optional argument to pass on to $broadcast which may be useful for
         * example if you need to pass through details of the user that was logged in
         * @param configUpdater an optional transformation function that can modify the
         * requests that are retried after having logged in.  This can be used for example
         * to add an authentication token.  It must return the request.
         */
        function loginConfirmed(data, configUpdater) {
            var updater = configUpdater || function(config) {return config;};
            $rootScope.$broadcast('auth:loginConfirmed', data);
            AuthHttpBuffer.retryAll(updater);
        }

        /**
         * Call this function to indicate that authentication should not proceed.
         * All deferred requests will be abandoned or rejected (if reason is provided).
         * @param data an optional argument to pass on to $broadcast.
         * @param reason if provided, the requests are rejected; abandoned otherwise.
         */
        function loginCancelled(data, reason) {
            AuthHttpBuffer.rejectAll(reason);
            $rootScope.userData = {};
            $rootScope.authToken = null;

            var device = localStorageService.get('device');

            localStorageService.clearAll();

            localStorageService.set('device', device);

            angular.element($window).find('[ng-view]').empty();
            $rootScope.$broadcast('auth:loginCancelled', data);
        }

        /**
         * @function login
         * @param {object} data - username, password, and isPrivate
         * @returns {promise}
         */
        function login(data){
            var deferred = $q.defer();
            var options = {
                method:'POST',
                url: apiPath + 'auth/login',
                data: data
            };
            $http(options)
                .success(function(value){
                    // Clear attempts counter
                    attempts = 0;

                    // Set token in local storage
                    if (value.DATA.type === 'private') {
                        localStorageService.set('jwt', value.DATA.jwt);
                        $rootScope.isPrivateDevice = true;
                    }

                    // Set token in rootScope
                    $rootScope.authToken = value.DATA.jwt;


                    // Get UserData
                    service.getUserData(true);

                    deferred.resolve(value);
                })
                .error(function(reason){
                    attempts++;
                    if(attempts >= maxAttempts) {
                        loginCancelled();
                        $location.url('/');
                    }
                    deferred.reject(reason);
                    toaster.pop('warning', 'Login Failed', _.get(reason, 'MESSAGE', ''));
                });
            return deferred.promise;
        }

        /**
         * This call will included session information for the user
         * @function getUserData
         * @param {boolean} isLogin - will be used to broadcast the loginConfirmed event
         * @returns {promise}
         */
        function getUserData(isLogin) {
            var deferred = $q.defer();
            var urlWorkspace = getUrlWorkspace($location.absUrl());
            var options = {
                method:'GET',
                url: apiPath + 'auth/userData',
                ignoreAuthModule: false,
                params: {
                    currentWorkspace: urlWorkspace
                }
            };

            $http(options)
                .success(function(value){
                    var userData = value.DATA;

                    // Check if user defined URL was valid
                    if (!_.has(value, 'DATA.currentWorkspace.path') || value.DATA.currentWorkspace.path.toLowerCase() !== urlWorkspace.toLowerCase()) {
                        delete userData.currentWorkspace;
                    }

                    // Set userData on the Root Scope
                    $rootScope.userData = userData;
                    // Confirm login
                    if (isLogin) {
                        service.loginConfirmed(value.DATA, function (config) {
                            if (!_.isUndefined(userData.currentWorkspace)) {
                                config.headers["WSID"] = userData.currentWorkspace.id;
                            }
                            return config;
                        });
                    }

                    deferred.resolve(userData);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * This function will clear user information and then logout
         * @function logout
         */
        function logout() {
            $http({method:'POST', url: apiPath + 'auth/logout'}).success(function () {
                loginCancelled();
                $location.url('/');
            });
        }

        /**
         * Call this to create an account
         * @param {object} data
         * @returns {promise}
         */
        //function postUser(data){
        //    var deferred = $q.defer();
        //    $http.post(apiPath + 'users/register', data)
        //        .then(function(value){
        //            deferred.resolve(value);
        //        }, function(reason){
        //            deferred.reject(reason);
        //        });
        //    return deferred.promise;
        //}
    })
;