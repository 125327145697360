angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name FormsBuildHelperService
     * @alias cerberus/core:FormsBuildHelperService
     * @description Provides helper functions fot the Form Builder Services
     */
    .factory('FormsBuildHelperService', function FormsBuildHelperService() {
        return {
            divOpenForm: divOpenForm,
            pFormControlStatic: pFormControlStatic,
            parseAttr: parseAttr,
            makeLabel: makeLabel,
            makeHelpBlock: makeHelpBlock,
            makeValidationHelpBlock: makeValidationHelpBlock,
            filter: filter
        };
        ////////////////////
        /**
         * Html Snippet
         * @function divOpenForm
         * @param params - form field parameters
         * @return {string} of html
         */
        function divOpenForm() {
            return '<div class="form-group has-feedback">';
        }

        /**
         * Html snippet
         * @function pFormControlStatic
         * @return {string} of html
         */
        function pFormControlStatic() {
            return '<p class="form-control-static">';
        }

        /**
         * Concatenates attribute key values to a string
         * @function parseAttr
         * @param {object} attrs
         * @return {string} of html/xml parameters
         */
        function parseAttr(attrs) {
            var string = '';
            if (attrs) {
                for (var key in attrs) {
                    if (attrs.hasOwnProperty(key) && typeof attrs[key] !== 'undefined' && attrs[key] !== null) {
                        string = string + ' ' + key + '="' + attrs[key] + '"';
                    }
                }
            }
            return string;
        }

        /**
         * Creates label html as string
         * @function makeLabel
         * @param {Field} field
         * @param {boolean} isDynamic
         * @return {string} of html
         */
        function makeLabel(field, isDynamic) {
            // Sanity Check
            if (field.label.isHidden === true) { return ''; }

            // Define label parts
            var link = '';
            var model = field.config.modelId || '';
            var text = field.label.text || '';
            var attrs = angular.copy(field.label.attrs);

            if (isDynamic === true) {
                // Add Item link
                if (!field.param.hideAddItem && field.option.type && field.option.type === 'views') {
                    link += ' <a href="" class="add-new" ng-click="openCreateWindow(\'' + field.option.id + '\')" ng-if="!isReadonly"><em>Add Item</em></a>';
                }

                // Advanced Select link
                if (field.param.advSel && field.param.advSel.enabled && field.config.type === 'select') {
                    if (link !== '') { link += ' | '; }
                    link += '<a href="" class="add-new" ng-click="openAdvSelPage(dataSet[nimFormObject.config.modelId])" ng-if="!isReadonly"><em>Advanced</em></a>';
                }

                // Help Popup
                if (field.help && field.help.type === 'popup' && field.help.text) {
                    attrs['uib-popover-template'] = '\'core/forms/help.tpl.html\'';
                    attrs['popover-trigger'] = 'outsideClick';
                    attrs['popover-placement'] = 'auto top-left';
                    text += ' <i class="fa fa-question-circle"></i>';
                }
            }

            return '<label class="control-label" ' + parseAttr(attrs) + ' for="' + model + '">' + text + link + '</label>';
        }

        /**
         * Creates helpBlock html as string
         * @function makeHelpBlock
         * @param {object} helpBlock
         * @return {string} of html
         */
        function makeHelpBlock(helpBlock) {
            if (helpBlock && helpBlock.type === 'static' && helpBlock.text) {
                return '<span class="help-block">' + helpBlock.text + '</span>';
            }
            return '';
        }

        function makeValidationHelpBlock(modelId){
            return '<div class="help-block validation" ng-if="!hideValidation" ng-messages="instanceForm.' + modelId + '.$error" ng-messages-multiple>' +
                '<div ng-message="required">This field is required <a class="close" href="" title="Close" ng-click="hideValidation = true;"><i class="fa fa-close"></i></a></div>' +
                '<div ng-message="nimHasLocation">Invalid Address <a class="close" href="" title="Close" ng-click="hideValidation = true;"><i class="fa fa-close"></i></a></div>' +
                '<div ng-message="maxlength">Text too long <a class="close" href="" title="Close" ng-click="hideValidation = true;"><i class="fa fa-close"></i></a></div>' +
                '<div ng-message="minlength">Text too short <a class="close" href="" title="Close" ng-click="hideValidation = true;"><i class="fa fa-close"></i></a></div>' +
                '<div ng-message="pattern">Invalid text <a class="close" href="" title="Close" ng-click="hideValidation = true;"><i class="fa fa-close"></i></a></div>' +
                '</div>';
        }

        /**
         * Creates angular braces with filter
         * @function filter
         * @param {object} filterObj
         * @param {string} string
         * @return {string} of angularjs templating syntax
         */
        //TODO: use $filter service instead
        function filter(filterObj, string) {
            if (filterObj && filterObj.type) {
                if (filterObj.params && filterObj.params.length > 0) {
                    return '{{::\'' + string + '\'|' + filterObj.type + ':' + filterObj.params + '}}';
                }
                return '{{::\'' + string + '\'|' + filterObj.type + '}}';
            }
            return string;
        }
    })
;