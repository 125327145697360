angular.module('cerberus.les')
    .controller('LESLoanApplicationFindingsCtrl', function (_, kendo, moment, $scope, $document, $location, $timeout, $uibModalInstance,
                                                              loanApplicationId, productId, product, pricingIndex,
                                                              lesLoanApplicationsService, normalPrintingService) {
        var vm = this;
        var today = new Date();
        
        vm.loanApplicationId = loanApplicationId;
        vm.simpleLoanId = _.last(loanApplicationId.split('-'));
        vm.productId = productId;
        vm.product = product;
        vm.pricing = {};
        vm.primaryBorrowerName = '';
        vm.propertyAddress = '';
        vm.applicationDataLoaded = false;
        vm.productSelected = false;
        vm.borrowers = [];
        vm.reasons = [];
        vm.reasonCategories = {};
        vm.conditions = [];
        vm.proposedExpenses = [];
        vm.printingFindingsReport = false;
        vm.showReasonsCitations = false;
        vm.showConditionsCitations = false;
        vm.conditionsShown = { all: true };
        vm.eligibilityRunDate = '';
        vm.totalOtherProposedExpenses = 0;
        vm.totalRateAdjustments = 0;
        vm.showSummaryTab = true;
        vm.showConditionsTab = false;
        vm.showAnalysisTab = false;
        vm.showAllTabs = false;
        
        vm.ok = ok;
        vm.cancel = cancel;
        vm.formatPercentage = formatPercentage;
        vm.formatDate = formatDate;
        vm.min = min;
        vm.finalRate = finalRate;
        vm.totalAssets = totalAssets;
        vm.totalConditions = totalConditions;
        vm.setConditionsAndReasons = setConditionsAndReasons;
        vm.printFindingsReport = printFindingsReport;
        vm.assetSum = assetSum;
        
        init();

        function init() {
            vm.totalRateAdjustments = 0;

            _.forEach(product.adjustments, function(adj){
                vm.totalRateAdjustments += adj.value;
            });

            if(pricingIndex >= 0){
                vm.pricing = product.pricing[pricingIndex];
            }
            _.forEach(vm.pricing.adjustments, function(adj){
                vm.totalRateAdjustments += adj.value;
            });
            lesLoanApplicationsService.getLoanApplication(loanApplicationId).then(function (loanApplication) {
                if(loanApplication.recordData.findings){
                    vm.productSelected = true;
                    vm.loanApplication = loanApplication.recordData.loanApp;
                    vm.product = loanApplication.recordData.findings;
                    vm.pricing = loanApplication.recordData.findings;

                    vm.product.conditions = [];
                    vm.product.reasons = [];
                }
                else{
                    vm.productSelected = false;
                    vm.loanApplication = loanApplication.recordData;
                }

                var mainBorrowerName = _.get(vm.loanApplication, 'mainBorrower.fullName');
            
                vm.primaryBorrowerName = mainBorrowerName || 'No Primary Borrower';
                vm.applicationDataLoaded = true;

                if (vm.loanApplication.RegisteredDate) {
                    vm.eligibilityRunDate = vm.loanApplication.RegisteredDate;
                }
                else {
                    vm.eligibilityRunDate = today;
                }

                if (_.has(vm.loanApplication, 'PropertyAddress.properties.formatted_address')) {
                    vm.propertyAddress = vm.loanApplication.PropertyAddress.properties.formatted_address;
                }
                else {
                    vm.propertyAddress = vm.loanApplication.PropertyAddressStreetAddress + ' ' + vm.loanApplication.PropertyAddressCity + ' ' + vm.loanApplication.PropertyAddressState + ' ' + vm.loanApplication.PropertyAddressZIPCode;
                }
                setConditionsAndReasons();
            });
        }

        function ok() {
            $uibModalInstance.close(vm.pricing);
        }

        function cancel(){
            $uibModalInstance.dismiss('cancel');
        }

        function formatPercentage(number, decimals) {
            decimals = decimals || '';
            return kendo.toString(number, 'p' + decimals);
        }

        function formatDate(dateValue, format) {
            if (!dateValue) {
                return '';
            }
            if (_.isString(dateValue)) {
                dateValue = moment(dateValue, ['MMMM, DD YYYY HH:mm:ss', 'YYYY-MM-DDTHH:mm:ssZ']).toDate();
            }
            return kendo.toString(dateValue, format);
        }

        function setConditionsAndReasons() {
            var reasons = [],
                conditions = [];

            if(!_.isEmpty(vm.product)){
                reasons = reasons.concat(vm.product.reasons);
                conditions = conditions.concat(vm.product.conditions);
            }
            if(!_.isEmpty(vm.pricing)){
                reasons = reasons.concat(vm.pricing.reason);
                conditions = conditions.concat(vm.pricing.condition);
            }

            /* LODASH Version 4 ONLY */
            //var standardReasons = _.reject(reasons, ['type', 'Rate Sheet']);
            /* LODASH Version 3 & 4 Compatibility Mode */
            var standardReasons = _.reject(reasons, function(r){return r.type === 'Rate Sheet';});
            if (standardReasons.length) {
                reasons = standardReasons;
            }

            vm.reasons = reasons;
            vm.reasonCategories = formatReasons(reasons);
            vm.conditions = formatConditions(conditions);
        
            if (!vm.conditionsShown.all) {
                var index = _.findKey(vm.conditionsShown);
            
                if (vm.conditions[index].items.length === 0) {
                    vm.conditionsShown = { all: true };
                }
            }
        }

        function formatConditions(conds) {
            var map = {
                loan: 0,
                borrower: 1,
                credit: 2,
                assets: 3,
                income: 4,
                property: 5,
                pricing: 6
            };
            var result = [
                {label: 'General', items: []},
                {label: 'Borrower', items: []},
                {label: 'Credit', items: []},
                {label: 'Assets', items: []},
                {label: 'Income', items: []},
                {label: 'Property', items: []},
                {label: 'Pricing', items: []}
            ];
            for (var i = 0; i < conds.length; i++) {
                var key = conds[i].type.toLowerCase();
                if (angular.isDefined(map[key])) { 
                    result[map[key]].items.push(conds[i]);
                }
            }
            return result;
        }

        function formatReasons(reasons) {
            var result = {};

            _.forEach(reasons, function (r) {
                if (r.type) {
                    var category = r.type.toLowerCase();
                    if (!result[category]) {
                        result[category] = { label: r.type, eligibility: r.eligibility };
                    }
                    else if (result[category].eligibility != 'ineligible') {
                        result[category].eligibility = r.eligibility;
                    }
                }    
            });

            return result;
        }

        function min(a, b) {
            return Math.min(a, b);
        }

        function finalRate(baseRate, adjustments) {
            var final = baseRate;

            _.forEach(adjustments, function (adj) {
                final += adj.value;
            });

            return final;
        }

        function totalAssets(assetMap) {
            if(assetMap){
                return assetMap.closingCosts.total + assetMap.downPayment.total + assetMap.reserves.total;
            }

            return 0;
        }

        function totalConditions(conditionsArray) {
            var total = 0;

            _.forEach(conditionsArray, function (c) {
                total += c.items.length;
            });

            return total;
        }

        function assetSum(assets, key) {
            if(assets){
                return assets.downPayment[key] + assets.prepaid[key] + assets.buyDown[key]  + assets.fundingFee[key] + assets.closingCosts[key];
            }

            return 0;
        }

        function printFindingsReport(showAll) {
            if (!vm.printingFindingsReport){
                vm.printingFindingsReport = true;

                var currentlyShown = vm.conditionsShown;
                vm.conditionsShown = { all: true };

                vm.showAllTabs = !!(showAll);

                $timeout(function () {
                    var findingsReportElement = $document.find('#findings-report'),
                        filename = kendo.format('Findings Report - {0:u}', new Date()),
                        options = {
                            paperSize: 'letter',
                            landscape: false,
                            margin: 0
                        };
                    
                    normalPrintingService.printElement(findingsReportElement, filename, options).then(function () {
                        $timeout(function () {
                            vm.printingFindingsReport = false;
                            vm.conditionsShown = currentlyShown;
                            vm.showAllTabs = false;
                        });
                    });
                });
            }
        }

        // Prevents navigation while modal is open
        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;