angular.module('cerberus.admin')
    .controller('FormsDesigner_selectionCtrl',
    function FormsDesigner_selectionCtrl(_, $scope, $timeout, $rootScope, $uibModalInstance, autoTabIndex,
                                         currentFormObjects, editFlag, formObj, formOriginId, idArray, sections, widgetId,
                                         AutoFillService, DesignerUtilityService, FormsDesignerListService, FormsDesignerService,
                                         PagesService, UsersProfileListService, ViewsService, WidgetsSettingsService, WorkspacesGroupsService) {
        // Populate Scope
        $scope.formOriginId = formOriginId;
        $scope.temp = angular.copy(formObj);
        $scope.orig = angular.copy(formObj);
        $scope.editFlag = editFlag;
        $scope.autoTabIndex = autoTabIndex;
        $scope.tabsTemplate = FormsDesignerListService.formObjects();
        $scope.views = [];
        $scope.viewColumns = [];
        $scope.autoFillViewColumns = {};
        $scope.groups = [];
        $scope.profiles = [];
        $scope.errStr = null;
        $scope.selectedWidgetOperands = [];
        $scope.selectedProfileFields = [];
        $scope.curModelIds = idArray;
        $scope.formFieldObjects = [];
        $scope.conditionOptions = [];
        $scope.filterFieldArray = [];
        $scope.widgetFieldIds = idArray;
        $scope.baseWidgetFields = [];
        $scope.sections = sections;
        $scope.autoFill = AutoFillService.autoFillObject();
        $scope.autoFillSubItem = [];
        $scope.autoFillSourceItems = [];
        $scope.autoFillTargetItems = [];
        $scope.lookUpValues = null;
        $scope.sortObj = {
            field: '',
            dir: 'asc'
        };

        $scope.pages = [];
        $scope.pageObjects = [];
        $scope.pageObjectFields = [];
        $scope.advSelTargetColumns = [];

        // UI Helper Functions
        $scope.preventSpace = DesignerUtilityService.preventSpace;
        $scope.cascadeFilter = {value:''};
        $scope.filterType = null;
        $scope.filterOperators = {
            number: [
                {value: 'eq', display: 'Equal To'},
                {value: 'gte', display: 'Greater Than or Equal To'},
                {value: 'lte', display: 'Less Than or Equal To'}
            ],
            string: [
                {value: 'contains', display: 'Contains'}
            ],
            selection: [
                {value: 'eq', display: 'Equal To'}
            ]
        };
        $scope.resultFilterOperators = [
            {value: '\'contains\'', display: 'Contains'},
            {value: '\'startswith\'', display: 'Starts With'}
        ];
        $scope.buttonClasses = [
            {value: 'default', display: 'Default'},
            {value: 'primary', display: 'Primary'},
            {value: 'success', display: 'Success'},
            {value: 'danger', display: 'Danger'},
            {value: 'warning', display: 'Warning'},
            {value: 'info', display: 'Info'}
        ];

        $scope.sortBySortableOptions = {
            ignore: "input,select",
            change: function (e) {
                $timeout(function () {
                    var sortArray = $scope.temp.model.param.sortBy;
                    if(sortArray){
                        reorderItem(sortArray, e.oldIndex, e.newIndex);
                    }
                },0);
            }
        };

        $scope.defaultFilter = {value: ''};
        $scope.defaultFilterType = null;
        $scope.defaultFilterOperators = DesignerUtilityService.filterOperators();
        $scope.conditionalActions = FormsDesignerListService.conditionalActions();
        $scope.conditionType = '';
        $scope.conditionOperators = DesignerUtilityService.conditionOperators();
        $scope.conditionBooleanValues = DesignerUtilityService.conditionBooleanValues();
        $scope.conditionActionValues = DesignerUtilityService.conditionActionValues();
        $scope.conditionFilter = DesignerUtilityService.conditionFilter(formOriginId, formObj.model.config.modelId);
        $scope.getConditionType = DesignerUtilityService.getConditionType;
        $scope.addCondition = DesignerUtilityService.addCondition($scope.temp, formOriginId);
        $scope.lookUpDefaultValueOptions = DesignerUtilityService.lookUpDefaultValueOptions($scope.temp.model.option);
        $scope.deleteProperty = DesignerUtilityService.deleteProperty;
        $scope.addSortItem = addSortItem;
        $scope.removeSortItem = removeSortItem;
        $scope.setOptionColumnType = setOptionColumnType;

        $scope.labelToUniqueId = function (item){
            if (!$scope.orig.model.config.modelId) {
                item.model.config.modelId = DesignerUtilityService.labelToUniqueId(item.model.label.text, idArray);
            }
        };

        // Form Object Specific Logic
        $scope.addOption = function(array,data){
            if(!array){
                array = [];
            }
            var r = /\d+/;
            var a = 0;
            if(array.length > 0){
                a = Math.max.apply(Math,array.map(function(o){return o.id.match(r);}));
                a = a + 1;
            }
            data.id = 'opt' + a;
            array.push(data);
        };

        $scope.addAutoFill = function(){
            var index = $scope.autoFillTargetItems.length;
            var widgetId = $scope.autoFill.widgetId;
            var targetWidgetId = $scope.autoFill.targetWidgetId;
            var type = $scope.autoFill.type;

            $scope.temp.model.option.autoFill.push($scope.autoFill);
            $scope.autoFillSubItem.push(AutoFillService.autoFillSubItem());
            $scope.autoFillSourceItems.push([]);
            $scope.autoFillTargetItems.push([]);
            $scope.autoFill = AutoFillService.autoFillObject();

            if(type !== 'query'){
                loadTableFields(widgetId, $scope.autoFillSourceItems, index);
            }

            loadTableFields(targetWidgetId, $scope.autoFillTargetItems, index);
        };

        $scope.addAutoFillSubItem = function(index){
            $scope.temp.model.option.autoFill[index].subMap.push($scope.autoFillSubItem[index]);
            $scope.autoFillSubItem[index] = AutoFillService.autoFillSubItem();
        };

        $scope.removeAutoFill = function(index){
            $scope.deleteArrayObject($scope.temp.model.option.autoFill, index);
            $scope.deleteArrayObject($scope.autoFillSubItem, index);
            $scope.deleteArrayObject($scope.autoFillTargetItems, index);
        };

        $scope.setAutoFillMetaData = AutoFillService.setAutoFillMetaData;
        $scope.setTargetFormId = AutoFillService.setTargetFormId;
        $scope.setSubItemIds = AutoFillService.setSubItemIds;

        $scope.toggleDefaultValue = function(objConfig, opt){
            $timeout(function(){
                if($scope.optionIsDefault(objConfig.defaultValue, opt)){
                    objConfig.defaultValue = null;
                }
                else {
                    objConfig.defaultValue = opt;
                }
            });
        };

        $scope.optionIsDefault = function(defaultVal, opt){
            return (defaultVal != null && defaultVal.id === opt.id);
        };

        $scope.deleteArrayObject = function(array,index){
            array.splice(index,1);
        };
        $scope.selectColumn = function(id){
            if(id){
                ViewsService.getColumns(id).then(function(data){
                    if(data.length === 0){
                        $scope.errStr = '<div class="alert alert-warning" role="alert"><strong>Warning!</strong> There are no columns in this view.</div>';
                    }
                    else{
                        $scope.errStr = null;
                    }

                    $scope.viewColumns = data;

                    // Populates Select Column when query is selected
                    /* LODASH Version 4 ONLY */
                    //if(_.findIndex(data, ['colName', $scope.temp.model.option.column]) <= 0){
                    /* LODASH Version 3 & 4 Compatibility Mode */
                    if(_.findIndex(data, function(f){return f.colName === $scope.temp.model.option.column;}) <= 0){ 
                        $scope.temp.model.option.column = data[0].colName;
                        setOptionColumnType(data, $scope.temp.model.option);
                        $scope.lookUpDefaultValueOptions.dataSource.read();
                    }
                });

                for(var i = 0; i < $scope.views.length; i++){
                    if($scope.views[i].id === id){
                        $scope.temp.model.option.widgetId = $scope.views[i].base_widget_id;
                        loadBaseWidgetFields($scope.temp.model.option.widgetId);
                        break;
                    }
                }
            }
            else{
                $scope.viewColumns = [];
                $scope.errStr = null;
            }
        };

        $scope.loadAutoFillViewColumns = function(id){
            if(id){
                if(!$scope.autoFillViewColumns[id]) {
                    ViewsService.getColumns(id).then(function (data) {
                        _.forEach(data, function(col){
                            col.colName = col.colName.replace(/_display$/, '');
                        });
                        $scope.autoFillViewColumns[id] = data;
                    });
                }
            }
        };

        $scope.setAutoFillBaseWidget = function(id){
            $scope.loadAutoFillViewColumns(id);
            for(var i = 0; i < $scope.views.length; i++){
                if($scope.views[i].id === id){
                    $scope.autoFill.widgetId = $scope.views[i].base_widget_id;
                    break;
                }
            }
        };

        $scope.queryName = function(id){
            var query = _.find($scope.views, 'id', id);
            return _.get(query, 'name', '');
        };

        $scope.getGroupsList = function(){
            $scope.temp.model.option.type='groups';
            WorkspacesGroupsService.getGroups($rootScope.userData.currentWorkspace.id).then(function(value){
                $scope.groups = value;
            });
        };
        $scope.getProfileTypes = function(){
            $scope.temp.model.option.type='profile';
            $scope.profiles = [];
            angular.forEach(UsersProfileListService.profileFields(),function(v,k){
                $scope.profiles.push(k);
            });
        };
        $scope.selectProfile = function(type){
            $scope.selectedProfileFields = UsersProfileListService.profileFields()[type];
        };

        // Cascade Filter
        $scope.updateFilterType = function(){
            var id = $scope.cascadeFilter.value;
            if(id) {
                for (var i = 0; i < $scope.formFieldObjects.length; i++) {
                    if ($scope.formFieldObjects[i].modelId === id) {
                        $scope.filterType = $scope.formFieldObjects[i].dataType;
                        $scope.cascadeFilter.operator = $scope.filterOperators[$scope.filterType][0].value;
                        return;
                    }
                }
            }
        };

        $scope.addCascadeFilter = function(){
            $scope.temp.model.param.cascadeFilter.filters.push($scope.cascadeFilter);
            $scope.cascadeFilter = {value:''};
            $scope.filterType = null;
        };

        // Default Filter
        $scope.notPropertyColumn = function(col){
            return col.type === 'column';
        };

        $scope.updateDefaultFilterType = function(){
            if($scope.defaultFilter.field && $scope.defaultFilter.field !== ''){
                var index = $scope.viewColumns.map(function(elem){return elem.colName;}).indexOf($scope.defaultFilter.field);
                if(index >= 0){
                    $scope.defaultFilterType = $scope.viewColumns[index].castType;
                    return;
                }
            }
            $scope.defaultFilterType = null;
        };

        $scope.addFilter = function(){
            $scope.temp.model.param.defaultFilter.filters.push($scope.defaultFilter);
            $scope.defaultFilter = {value:''};
            $scope.defaultFilterType = null;
        };

        $scope.sortableOptions = {
            ignore: "input",
            change: function (e) {
                $timeout(function () {
                    var item = $scope.temp.model.option.values.splice(e.oldIndex, 1)[0];
                    $scope.temp.model.option.values.splice(e.newIndex, 0, item);
                },0);
            }
        };

        // Advanced Selection
        $scope.loadPageObjects = loadPageObjects;
        $scope.getPageObjectFields = getPageObjectFields;
        $scope.addAdvSelFilter = addAdvSelFilter;
        $scope.getAdvSelTargetColumns = getAdvSelTargetColumns;
        $scope.compatiblePageObjectFilter = compatiblePageObjectFilter;
        $scope.lookupFilter = lookupFilter;
        $scope.tableFormObjectFilter = tableFormObjectFilter;

        function loadPageObjects(pageId){
            if(pageId){
                PagesService.getPage(pageId).then(function(pageModel){
                    $scope.pageObjects = pageModel.items;
                    $scope.filters = DesignerUtilityService.getFilters($scope.pageObjects);

                    if(_.get($scope.temp, 'model.param.advSel.pageObjectId')){
                        getPageObjectFields($scope.temp.model.param.advSel.pageObjectId);
                    }
                });
            }
            else {
                $scope.pageObjects = [];
            }
        }

        function getPageObjectFields(pageObjectId){
            var pageObj = _.find($scope.pageObjects, 'id', pageObjectId);
            if(pageObj){
                $scope.pageObjectFields = pageObj.viz.settings.columns;
            }
            else {
                $scope.pageObjectFields = [];
            }
        }

        function loadBaseWidgetFields(widgetId){
            if(widgetId){
                WidgetsSettingsService.getFormFields(widgetId).then(function(data){
                    var baseWidgetFields = [];

                    _.forEach(data.display, function(form){
                        _.forEach(form.objects, function(field){
                            baseWidgetFields.push({
                                modelId: field.modelId,
                                label: field.label,
                                formName: form.formName,
                                formId: form.originId,
                                stateId: form.stateId,
                                isLookup: field.isLookUp,
                                isCustomLookup: field.isCustomLookup,
                                dataType: field.dataType
                            });
                        });
                    });

                    $scope.baseWidgetFields = baseWidgetFields;
                });
            }
        }

        function addAdvSelFilter(filter, modelId){
            _.defaults($scope.temp.model.param.advSel, { filterMap: [] });
            var filterMap = $scope.temp.model.param.advSel.filterMap;
            var filterObj = {
                filter: filter,
                modelId: modelId
            };

            var valueObject = _.find($scope.formFieldObjects, 'modelId', modelId);
            if(valueObject){
                filterObj.formOriginId = valueObject.formId;
                filterMap.push(filterObj);
            }
        }

        function getAdvSelTargetColumns(formObjects, modelId){
            var target = _.find(formObjects, 'modelId', modelId);
            if(target.widgetId){
                WidgetsSettingsService.getFormFields(target.widgetId).then(function(data){
                    var formFields = getFormFieldObjects(data);

                    $scope.advSelTargetColumns = _.map(formFields, function(field){
                        var col = {
                            label: field.label
                        };

                        if(field.isLookUp || field.isCustomLookup){
                            col.modelId = '__' + field.modelId + '_display';
                        }
                        else {
                            col.modelId = field.modelId;
                        }

                        return col;
                    });
                });
            }
        }

        function compatiblePageObjectFilter(pageObject){
            return _.get(pageObject, 'viz.type') === 'table';
        }

        function lookupFilter(column){
            return column.isLookUp || column.isCustomLookup;
        }

        function tableFormObjectFilter(formObject){
            return formObject.type === 'dataseries';
        }

        // Init
        init();

        function init(){
            // Hack
            DesignerUtilityService.focusFirstElement('configForm');

            WidgetsSettingsService.getFormFields(widgetId).then(function(formFieldArray){
                DesignerUtilityService.mergeFieldsWithCurrentForm(formFieldArray, currentFormObjects);

                $scope.formFieldObjects = getFormFieldObjects(formFieldArray);
                $scope.widgetFieldIds = _.union(idArray, _.map($scope.formFieldObjects, 'modelId'));

                var conditionOptions = _.sortBy(_.filter($scope.formFieldObjects, $scope.conditionFilter), 'label');
                $scope.conditionOptions = [{ label: '(Record Action)', modelId: '__nimRecordAction' }].concat(conditionOptions);

                $scope.updateFilterType();

                if(_.get($scope.temp, 'model.param.advSel.modelId')) {
                    getAdvSelTargetColumns($scope.formFieldObjects, $scope.temp.model.param.advSel.modelId);
                }
            });

            if($scope.temp.model.config.type !== 'checkbox'){
                if($scope.temp.model.config.type === 'select') {
                    $scope.temp.model.param.defaultData = $scope.temp.model.param.defaultData || [];

                    PagesService.getPages().then(function (pages) {
                        $scope.pages = pages;
                    });

                    loadPageObjects(_.get($scope.temp, 'model.param.advSel.pageId'));
                }

                ViewsService.get().then(function(views){
                    $scope.views = views;

                    if($scope.editFlag && $scope.temp.model.option.type === 'views'){
                        $scope.selectColumn($scope.temp.model.option.id);
                    }
                    else if($scope.editFlag && $scope.temp.model.option.type === 'groups'){
                        $scope.getGroupsList();
                    }
                    else if($scope.editFlag && $scope.temp.model.option.type === 'profile'){
                        $scope.getProfileTypes($scope.temp.model.option.id);
                    }
                });

                _.defaults($scope.temp.model.param, {
                    defaultFilter: {
                        logic: 'and',
                        filters: []
                    },
                    cascadeFilter: {
                        logic: 'and',
                        filters: []
                    }
                });

                if(!$scope.temp.model.param.sortBy){
                    var sortBy = [],
                        option = $scope.temp.model.option;

                    if(option.orderBy === 'oldest'){
                        sortBy.push({
                            field: 'id',
                            dir: 'asc'
                        });
                    }
                    else if(option.orderBy === 'newest'){
                        sortBy.push({
                            field: 'id',
                            dir: 'desc'
                        });
                    }
                    else if(option.orderCol || option.column){
                        sortBy.push({
                            field: option.orderCol || option.column,
                            dir: option.orderBy || 'asc'
                        });
                    }

                    $scope.temp.model.param.sortBy = sortBy;
                }

                // Init Auto-Fill stuff
                if($scope.temp.model.option.autoFill){
                    for(var af = 0; af < $scope.temp.model.option.autoFill.length; af++){
                        $scope.autoFillSubItem.push(AutoFillService.autoFillSubItem());
                        $scope.autoFillSourceItems.push([]);
                        $scope.autoFillTargetItems.push([]);

                        var autoFill = $scope.temp.model.option.autoFill[af];

                        if(!_.has(autoFill, 'persists')){
                            autoFill.persists = true;
                        }

                        if(autoFill.type === 'query'){
                            $scope.loadAutoFillViewColumns(autoFill.queryId);
                        }
                        else {
                            loadTableFields(autoFill.widgetId, $scope.autoFillSourceItems, af);
                        }
                        loadTableFields(autoFill.targetWidgetId, $scope.autoFillTargetItems, af);
                    }
                }
                else{
                    $scope.temp.model.option.autoFill = [];
                }
            }
        }

        // Modal Actions
        $scope.ok = function (){
            $uibModalInstance.close($scope.temp);
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function getFormFieldObjects(fieldArray){
            var objects = [];
            for(var f = 0; f < fieldArray.display.length; f++){
                var form = fieldArray.display[f];
                for(var o = 0; o < form.objects.length; o++){
                    var object = form.objects[o];
                    object.formId = form.originId;
                    object.realFormId = form.formId;
                    objects.push(object);
                }
            }
            return objects;
        }

        function loadTableFields(id, array, index){
            if(id){
                WidgetsSettingsService.getFormFields(id).then(function(data){
                    array[index] = getFormFieldObjects(data);
                });
            }
        }

        function addSortItem(sortObj){
            $scope.temp.model.param.sortBy.push(
                angular.copy(sortObj)
            );
            sortObj.field = '';

            $scope.lookUpDefaultValueOptions.dataSource.sort($scope.temp.model.param.sortBy);
        }

        function removeSortItem($index){
            _.pullAt($scope.temp.model.param.sortBy, $index);
            $scope.lookUpDefaultValueOptions.dataSource.sort($scope.temp.model.param.sortBy);
        }

        function reorderItem(array, oldIndex, newIndex){
            var item = array.splice(oldIndex, 1)[0];
            array.splice(newIndex, 0, item);
        }

        function setOptionColumnType(viewColumns, option){
            if(option.column) {
                var column = _.find(viewColumns, "colName", option.column);

                if (column) {
                    option.columnType = column.castType;
                }
            }
            else {
                option.columnType = "";
            }
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;