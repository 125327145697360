angular.module('cerberus.ui')
    /**
     * @ngdoc service
     * @name LeafletMapService
     * @alias cerberus/ui:LeafletMapService
     * @description Provides functions for building leaflet maps
     */
    .factory('LeafletMapService', function LeafletMapService(_, $window, $compile, LeafletListService, mapboxConfig) {
        var localStorage = $window.localStorage || {};
        var L = $window.L;
        var service = {
            getView: getView,
            setView: setView,
            buildBasemaps: buildBasemaps,
            buildLayers: buildLayers,
            buildLayer: buildLayer,
            buildIconMarker: buildIconMarker,
            buildPopup: buildPopup,
            popupEventHandler: popupEventHandler,
            geoJsonOptions: geoJsonOptions,
            isCollection: isCollection
        };
        return service;
        ////////////////////
        /**
         * Retrieves last view from local storage
         * @returns {object}
         */
        function getView(id) {
            var view = localStorage[id];
            try {
                view = $window.JSON.parse(view);
            }
            catch (e) {
                view = null;
            }
            return view;
        }

        /**
         * Sets local storage view
         */
        function setView(id, view) {
            localStorage[id] = $window.JSON.stringify(view);
        }

        /**
         *
         * @param configs
         * @param basemaps
         * @param map
         */
        function buildBasemaps(configs, basemaps, map) {
            var token = mapboxConfig.token;
            var basemapsDict = LeafletListService.basemaps();
            var layersDict = LeafletListService.layers();
            var defaultBasemap = null;
            var lastBasemap = null;

            // Populate basemap object reference
            for (var i = 0; i < configs.length; i++) {
                var label;
                if (basemapsDict[configs[i].type]) {
                    label = configs[i].name || basemapsDict[configs[i].type];
                    basemaps[label] = L.tileLayer('https://{s}.tiles.mapbox.com/v4/' +
                        configs[i].type + '/{z}/{x}/{y}.png?access_token=' +
                        token, {noWrap: true, continuousWorld: false, maxZoom: 22, maxNativeZoom:18});
                }
                else if (layersDict[configs[i].type]) {
                    label = configs[i].name;
                    buildLayer(configs[i], basemaps, map);
                }
                lastBasemap = label;
                if (configs[i].visible){
                    defaultBasemap = configs[i].name;
                }
            }

            // Set Initial Basemap
            if (map) {
                if (defaultBasemap) {
                    map.addLayer(basemaps[defaultBasemap]);
                }
                else if (lastBasemap) {
                    map.addLayer(basemaps[lastBasemap]);
                }
            }
        }

        /**
         * (Convenience function) Parses and configures multiple layers for Leaflet
         * @param {Array} configs - defines the layer parameters
         * @param {object} layers - will hold the layerGroup
         * @param {*} map - Leaflet map
         * @param markers
         * @param watchCallback
         * @param scope
         */
        function buildLayers(configs, layers, map, markers, watchCallback, scope) {
            for (var j = 0; j < configs.length; j++) {
                buildLayer(configs[j], layers, map, markers, watchCallback, j, scope);
            }
        }

        /**
         * Parses and configures a layer for Leaflet.  This will also add layers to the map
         * @param {object} config - defines the layer parameters
         * @param {object} layers - reference to layers object
         * @param {*} map - Leaflet map
         * @param markers
         * @param watchCallback
         * @param idx
         * @param scope
         */
        function buildLayer(config, layers, map, markers, watchCallback, idx, scope) {
            var type = config.type,
                name = config.name,
                url = config.url,
                settings = config.settings;
            if (type && name) {
                switch (type) {
                    case 'nim.marker':
                        // TODO: Styling functions
                        layers[name] = L.layerGroup({opacity: settings.opacity});
                        markers[name] = [];
                        watchCallback(idx, type);
                        break;
                    case 'nim.markerClusterGroup':
                        // TODO: Styling functions
                        layers[name] = L.markerClusterGroup({opacity: settings.opacity});
                        markers[name] = [];
                        watchCallback(idx, type);
                        break;
                    case 'nim.geoJson':
                        layers[name] = L.geoJson(null, geoJsonOptions(scope, idx));
                        markers[name] = [];
                        watchCallback(idx, type);
                        break;
                    case 'L.geoJson':
                        //TODO: geoJson Styling and Build functions
                        layers[name] = L.geoJson();
                        break;
                    case 'L.heatLayer':
                        markers[name] = [];
                        layers[name] = L.heatLayer(markers[name]);
                        watchCallback(idx, type);
                        break;
                    case 'L.KML':
                        //TODO: geoJson Styling and Build functions
                        layers[name] = L.KML(url, {async: true});
                        break;
                    case 'L.tileLayer':
                        settings.noWrap = true;
                        settings.continuousWorld = false;
                        layers[name] = L.tileLayer(url, settings);
                        break;
                    case 'L.tileLayer.wms':
                        layers[name] = L.tileLayer.wms(url, settings);
                        break;
                    case 'esri.tiledMapLayer':
                        //layers[name] = L.esri.tiledMapLayer(url, settings);
                        settings = settings || {};
                        settings.url = url;
                        layers[name] = L.esri.tiledMapLayer(settings);
                        break;
                    case 'esri.dynamicMapLayer':
                        //layers[name] = L.esri.dynamicMapLayer(url, settings);
                        settings = settings || {};
                        settings.url = url;
                        layers[name] = L.esri.dynamicMapLayer(settings);
                        break;
                    case 'esri.featureLayer':
                        //TODO: Styling Functions
                        //layers[name] = L.esri.featureLayer(url);
                        layers[name] = L.esri.featureLayer({url: url});
                        break;
                    case 'esri.clusteredFeatureLayer':
                        //TODO: Styling and Build functions
                        layers[name] = L.esri.clusteredFeatureLayer();
                        break;
                }
            }

            // Turn on overlays with visible: truthy
            if (map && config.visible && layers[name]) {
                map.addLayer(layers[name]);
            }
        }

        function geoJsonOptions(scope, layerIndex){
            var settings = scope.nimOptions.overlays[layerIndex].settings;
            var opacity = 1;
            if(settings && settings.hasOwnProperty('opacity')){
                opacity = settings.opacity;
            }

            return {
                onEachFeature: function (feature, layer) {
                    layer.bindPopup(buildPopup(feature.properties,feature.properties.title,scope));

                    layer.on('popupopen', popupEventHandler(scope, layerIndex, 'nim-leaflet-popup-open'));
                    layer.on('popupclose', popupEventHandler(scope, layerIndex, 'nim-leaflet-popup-close'));
                },
                style: function(feature){
                    var styleOptions = {
                        opacity: opacity
                    };
                    var markerColor = feature.properties.markerColor;
                    styleOptions.markerColor = markerColor;
                    styleOptions.color = markerColor;
                    switch(markerColor){
                        case 'orange-dark':
                            styleOptions.color = 'red';
                            break;
                        case 'green-light':
                            styleOptions.color = 'green';
                            break;
                        case 'cyan':
                            styleOptions.color = 'blue';
                            break;
                        case 'violet':
                            styleOptions.color = 'purple';
                            break;
                    }
                    styleOptions.fillColor = styleOptions.color;

                    var pathOptions = scope.nimOptions.overlays[layerIndex].pathOptions;
                    if(pathOptions){
                        if(pathOptions.hasOwnProperty('weight') && !isNaN(pathOptions.weight)){
                            styleOptions.weight = pathOptions.weight;
                        }
                        if(pathOptions.color && pathOptions.color !== 'default'){
                            styleOptions.color = pathOptions.color;
                        }
                        if(pathOptions.fillColor && pathOptions.fillColor !== 'default'){
                            styleOptions.fillColor = pathOptions.fillColor;
                        }
                    }

                    return styleOptions;
                },
                pointToLayer: function(feature, point){
                    return L.marker(point, {
                        icon: service.buildIconMarker(feature.properties.markerColor),
                        riseOnHover: true,
                        showOnMouseOver: true,
                        opacity: opacity
                    });
                }
            };
        }


        /**
         * @function isCollection
         * Determines the data structure of an array or object
         * @param data
         * @returns {{type: string, mapping: {lat: *, lon: *}}}
         */
        function isCollection(data) {
            if (data.isArray() && data.length > 0) {
                // if Array
                if (data[0].isArray() && data.length > 1) {
                    if (typeof data[0][0] === 'object') {
                        var x, y;
                        for (var key in data[0][0]) {
                            // lat = y, long = x
                            if (data[0][0].hasOwnProperty(key)) {
                                if (key === "lat") {
                                    y = key;
                                }
                                else if (key === "lon") {
                                    x = key;
                                }
                                else if (key === "lng") {
                                    x = key;
                                }
                                else if (key === "long") {
                                    x = key;
                                }
                                else if (key === "x") {
                                    x = key;
                                }
                                else if (key === "y") {
                                    y = key;
                                }
                                else if (key === "latitude") {
                                    y = key;
                                }
                                else if (key === "longitude") {
                                    x = key;
                                }
                            }
                        }
                        return {
                            type: 'collection',
                            mapping: {
                                lat: y,
                                lng: x
                            }
                        };
                    }
                }
            }
        }

        /**
         * Builds an icon marker for use with Leaflet
         * @param {string} color
         * @param {string} shape
         * @param {string} icon
         * @returns {*}
         */
        function buildIconMarker(color, shape, icon) {
            return $window.L.ExtraMarkers.icon({
                markerColor: color || 'cyan',
                shape: shape || 'circle',
                icon: icon || 'fa-circle',
                prefix: 'fa'
            });
        }

        /**
         * Builds a popup template for leaflet layers
         * @param {object} obj
         * @param {string} title
         * @param {Scope} scope
         * @returns {string}
         */
        function buildPopup(obj, title, scope) {
            var html = '<div>';
            if (title) {
                html += '<h4>' + title + '</h4>';
            }
            html += '<table class="table table-condensed"><tbody>';
            for (var key in obj) {
                if (obj.hasOwnProperty(key) && key !== 'title' && key !== 'markerColor' && key !== "lat" && key !=="lng" && key !=="id") {
                                        // removes underscore added in viz-map-directive
                    html += '<tr><td>' + key.replace('_', '') + ':</td>' + '<td>' + obj[key] + '</td></tr>';
                }
            }
            html += '</tbody></table>';

            // TODO: Move this logic out to the Viz directive
            if(scope && scope.nimOptions.controls.editPopup){
                html += '<div class="btn-group"><button class="btn btn-default" ng-click="edit(' + obj.id + ')">Details</button></div></div>';
                return $compile(html)(scope)[0];
            }
            else {
                html += '</div>';
                return html;
            }
        }

        function popupEventHandler(scope, index, event){
            return function(e){
                var dataSourceId = scope.nimOptions.overlays[index].queryId;
                var instanceId = _.get(e.target, 'feature.properties.id', _.get(e.target, 'options.id', null));
                scope.$emit(event, dataSourceId, instanceId);
            };
        }
    })
;