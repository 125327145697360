angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name FormsService
     * @alias cerberus/core:FormsService
     * @description Reads data from the "forms" resource
     */
    .factory('FormsService', function FormsService(_, moment, kendo, $http, $q, apiPath, FormsClassService, DesignerUtilityService, nimSignatureService) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';
        return {
            getForm: getForm,
            getAutoFillValues: getAutoFillValues,
            parseDefaultValue: parseDefaultValue,
            processFormData: processFormData,
            sortFormObjects: sortFormObjects,
            fixDataseriesCols: fixDataseriesCols,
            sectionFilter: sectionFilter,
            sectionCondition: sectionCondition,
            signatureHandler: signatureHandler
        };
        ////////////////////
        /**
         * parseDefaultValue
         * @param type
         * @param value
         * @param interval
         * @returns {*}
         */
        function parseDefaultValue(type, value, interval){
            if(type === 'datetime' && value === '@nim_today'){
                var date = moment(new Date());

                if(interval && date.round){
                    date.round(interval, 'minutes');
                }

                return date.toDate();
            }
            return value;
        }
        /**
         * Given a valid response for the REST api this function
         * constructs classes for the form objects and sort them for display
         * @function processFormData
         * @param {object} data - this is the return from the HTTP call
         * @param {object} defaultDataSet - default data set
         * @return {object} formModel
         */
        function processFormData(data, defaultDataSet) {
            var totalWindowsOpen = $('div.k-widget.k-window').length;

            var formModel = data;
            var sortFunc = DesignerUtilityService.firstBy(function(v1,v2){return v1.row - v2.row;})
                .thenBy(function(v1,v2){return v1.col - v2.col;});

            if(formModel.form.objects && formModel.form.objects.length > 0) {
                // Separate the form objects by section to set the correct tab indices
                var sections = [];
                sections.push(_.reject(formModel.form.objects, 'model.display.section').sort(sortFunc));

                _.forEach(formModel.form.sections, function(section){
                    /* LODASH Version 4 ONLY */
                    //sections.push(_.filter(formModel.form.objects, ['model.display.section', section.name]).sort(sortFunc));
                    /* LODASH Version 3 & 4 Compatibility Mode */
                    sections.push(_.filter(formModel.form.objects, function(c){return c.model.display.section === section.name;}).sort(sortFunc));
                });

                /* LODASH Version 4 ONLY */
                //sections.push(_.filter(formModel.form.objects, ['model.display.section', '__nimHiddenSection']).sort(sortFunc));
                /* LODASH Version 3 & 4 Compatibility Mode */
                sections.push(_.filter(formModel.form.objects, function(c){return c.model.display.section === '__nimHiddenSection';}).sort(sortFunc));

                var tabIndexCounter = 2 + (totalWindowsOpen * 500);
                formModel.tabindexStart = tabIndexCounter;

                _.forEach(sections, function (formObjects) {
                    _.forEach(formObjects, function (formObj) {
                        var newField = FormsClassService.newField();
                        newField.setData(formObj.model);
                        formObj.model = newField;
                        //Default Value
                        if(defaultDataSet && !_.isNull(newField.config.defaultValue) && newField.config.defaultValue !== ''){
                            var defaultVal = {},
                                interval = null;

                            // For parsing default date value to nearest interval
                            if(newField.config.type === 'datetime' && newField.display.format.match(/[HhfmstgG]/g)){
                                interval = newField.tag.attrs['k-interval'];
                            }

                            defaultVal[newField.config.modelId] = parseDefaultValue(newField.config.type, newField.config.defaultValue, interval);
                            if(defaultDataSet[newField.config.modelId] === ''){
                                defaultDataSet[newField.config.modelId] = defaultVal[newField.config.modelId];
                            }
                            else {
                                _.defaultsDeep(defaultDataSet, defaultVal);
                            }
                        }
                        //Generate tab index
                        if(formModel.form.settings.autoTabIndex.enabled){
                            newField.tag.attrs.tabindex = tabIndexCounter;
                            tabIndexCounter++;
                        }
                    });
                });

                formModel.tabindexEnd = tabIndexCounter + 10;
            }
            return formModel;
        }

        function sortFormObjects(formModel) {
            var totalWindowsOpen = $('div.k-widget.k-window').length;

            var sortFunc = DesignerUtilityService.firstBy(function(v1,v2){return v1.row - v2.row;})
                .thenBy(function(v1,v2){return v1.col - v2.col;});

            if(formModel.form.objects && formModel.form.objects.length > 0) {
                // Separate the form objects by section to set the correct tab indices
                var sections = [];
                sections.push(_.reject(formModel.form.objects, 'model.display.section').sort(sortFunc));

                _.forEach(formModel.form.sections, function(section){
                    /* LODASH Version 4 ONLY */
                    //sections.push(_.filter(formModel.form.objects, ['model.display.section', section.name]).sort(sortFunc));
                    /* LODASH Version 3 & 4 Compatibility Mode */
                    sections.push(_.filter(formModel.form.objects, function(c){return c.model.display.section === section.name;}).sort(sortFunc));
                });

                /* LODASH Version 4 ONLY */
                //sections.push(_.filter(formModel.form.objects, ['model.display.section', '__nimHiddenSection']).sort(sortFunc));
                /* LODASH Version 3 & 4 Compatibility Mode */
                sections.push(_.filter(formModel.form.objects, function(c){return c.model.display.section === '__nimHiddenSection';}).sort(sortFunc));

                var tabIndexCounter = 2 + (totalWindowsOpen * 500);
                formModel.tabindexStart = tabIndexCounter;

                _.forEach(sections, function (formObjects) {
                    _.forEach(formObjects, function (formObj) {
                        //Generate tab index
                        if(formModel.form.settings.autoTabIndex.enabled){
                            formObj.model.tag.attrs.tabindex = tabIndexCounter;
                            tabIndexCounter++;
                        }
                    });
                });
                formModel.tabindexEnd = tabIndexCounter + 10;
            }

            //Sort form objects by tabindex and return
            return _.sortBy(formModel.form.objects, 'model.tag.attrs.tabindex');
        }

        /**
         * Get form by id
         * @function getFrom
         * @param {string} id
         * @param {boolean} isPk
         * @returns {promise}
         */
        function getForm(id, isPk) {
            var deferred = $q.defer();
            var url = apiPath + 'forms/' + id;
            if(isPk){
                url += '?isPk=true';
            }
            $http.get(url)
                .success(function (value) {
                    deferred.resolve(processFormData(value.DATA));
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Gets autofill values and populates the scope with appropriate values
         * @function getAutoFillValues
         * @param {string} id
         * @param {object} data
         * @returns {promise}
         */
        function getAutoFillValues(id, data) {
            var deferred = $q.defer();
            $http.put(apiPath + 'autoFill/'+ id, {settings:data})
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function fixDataseriesCols(dataItem, schemaFields) {
            _.forEach(schemaFields, function (col, colField) {
                var modelId = colField,
                    isLookup = false;
                
                if (_.startsWith(modelId, '__') && _.endsWith(modelId, '_display')) {
                    modelId = colField.replace(/^__/, '').replace(/_display$/, '');
                    isLookup = true;
                }
                
                if (_.has(dataItem.__nimData, modelId)) {
                    var val = dataItem.__nimData[modelId],
                        displayVal = '';
                    if (isLookup) {
                        displayVal = _.get(val, 'display', '');
                    }
                    else if (col.type == 'number') {
                        val = parseFloat(val);
                        if (!isNaN(val)) {
                            displayVal = val;
                        }
                    }
                    else if (col.type == 'date') {
                        if (_.isDate(val)) {
                            displayVal = val;
                        }
                        else if (_.isString(val)) {
                          //replaced by HD 2019-07-03
                          //displayVal = kendo.parseDate(val, ['MMMM, dd yyyy HH:mm:ss', 'yyyy-MM-ddTHH:mm:ss.fffZ']);
                            displayVal = kendo.parseDate(val, ['MMMM, dd yyyy HH:mm:ss', 'yyyy-MM-ddTHH:mm:ssz', 'yyyy-MM-ddTHH:mm:ss.fffz'], 'yyyy-MM-ddTHH:mm:sszzz');
                        }
                    }
                    else {
                        displayVal = val;
                    }

                    dataItem[colField] = displayVal;
                }

                if (!dataItem.__rowId) {
                    dataItem.__rowId = DesignerUtilityService.generateGuid();
                }    
            });
        }

        function sectionFilter(section){
            if (angular.isDefined(section)) {
                return function (field) {
                    return field.model.display.section === section;
                };
            }
            else {
                return function (field) {
                    return !field.model.display.section;
                };
            }
        }

        /**
         * Checks if data meets section conditions
         * @function sectionCondition
         * @param condArray - section condition array
         * @param dataSet - state data set
         * @param logic - and/or (optional - assumes "and" by default)
         * @param action - record action ("create", "read", or "readonly")
         * @returns {boolean}
         */
        function sectionCondition(condArray, dataSet, logic, action) {
            if (action == 'readonly') {
                action = 'read';
            }
            var iteratee = function(c){
                // Gets model value from dataSet
                var match,
                    model = _.get(dataSet, c.modelId, false),
                    value = c.val;
                
                if (c.modelId === '__nimRecordAction') {
                    model = action;
                }

                if(c.isFormObj){
                    value = _.get(dataSet, c.val, false);

                    if (_.has(value, 'display')) {
                        value = value.display;
                    }
                }

                // Lookup Value
                if(c.op === 'eq' || c.op === 'neq') {
                    // Check if model value matches condition
                    if (_.isBoolean(model) || _.isBoolean(value)) {
                        var boolModel = !!model,
                            boolValue = !!value;
                        match = boolModel === boolValue;
                    }
                    else if (value === 'NULL' || (!value && value !== 0)) {
                        match = _.isEmpty(model) || model.value === '-1';
                    }
                    else if (_.has(model, 'display')) {
                        match = value === model.display;
                    }
                    else {
                        match = _.map(model, 'display').indexOf(value) >= 0;
                    }

                    // Inverts match result if operator is neq
                    if (c.op === 'neq') {
                        match = !match;
                    }
                }
                else {
                    switch(c.op){
                        // String cases
                        case 'str_contains':
                            match = model.search(value) >= 0;
                            break;
                        case 'str_doesnotcontain':
                            match = model.search(value) < 0;
                            break;
                        case 'str_startswith':
                            match = _.startsWith(model, value);
                            break;
                        case 'str_endswith':
                            match = _.endsWith(model, value);
                            break;
                        case 'str_eq':
                            match = model === value;
                            break;
                        case 'str_neq':
                            match = model !== value;
                            break;

                        // Number cases
                        case 'num_eq':
                            match = model === parseFloat(value);
                            break;
                        case 'num_neq':
                            match = model !== parseFloat(value);
                            break;
                        case 'num_gt':
                            match = model > parseFloat(value);
                            break;
                        case 'num_gte':
                            match = model >= parseFloat(value);
                            break;
                        case 'num_lt':
                            match = model < parseFloat(value);
                            break;
                        case 'num_lte':
                            match = model <= parseFloat(value);
                            break;
                    }
                }

                return match;
            };

            if(logic === 'or'){
                return _.some(condArray, iteratee);
            }
            else {
                return _.every(condArray, iteratee);
            }
        }

        /**
         * Handlers signing process when a sign button is clicked on the forms
         *
         * TODO:  Remove modal header to save space on smaller device
         *
         * @param {object} [sigData]
         * @param {boolean} [isInitial]
         * @param {string} [restrictTo]
         * @param {string} [penColor]
         * @param {boolean} [reSign]
		 * @param {boolean} [isTouchpadSign]
         * @returns {promise}
         */
        function signatureHandler(sigData, isInitial, restrictTo, penColor, reSign, isTouchpadSign) {
            var deferred = $q.defer();
            var sigModel = angular.extend({}, sigData);
            // Has it already been signed in anyway
            if (sigData && !reSign && restrictTo !== 'anon') {
                // If so then was is signed by an account
                if (sigData.userId) {
                    // Set signature
                    sigModel.timestamp = new Date();
                    deferred.resolve(sigModel);
                }
                // If it was an external signature we must check for
                // the existence of the requested signature type: signature or initial
                // if it does not exist we must prompt the user for it.
                else {
                    // Is this an initial signing
                    if (isInitial) {
                        // Does it already exist
                        if (sigData.iniPath) {
                            sigModel.path = sigData.iniPath;
                            sigModel.timestamp = new Date();
                            deferred.resolve(sigModel);
                        }
                        else {
                            // Open Modal and default to anon signature
                            nimSignatureService.openModal(isInitial, restrictTo, penColor, isTouchpadSign).result.then(success, error);
                        }
                    }
                    // Or is this a signature signing
                    else {
                        if (sigData.sigPath) {
                            sigModel.path = sigData.sigPath;
                            sigModel.timestamp = new Date();
                            deferred.resolve(sigModel);
                        }
                        else {
                            // Open Modal and default to anon signature
                            nimSignatureService.openModal(isInitial, restrictTo, penColor, isTouchpadSign).result.then(success, error);
                        }
                    }
                }
            }
            // Signature or initials do not exist and
            // this signature is restricted to an account only
            else if (restrictTo === 'account') {
                // Make the service call to get account signature.
                nimSignatureService.getSignatureWithAccount().then(success, error);
            }
            // Signature or initials do not exist
            else {
                nimSignatureService.openModal(isInitial, restrictTo, penColor, isTouchpadSign).result.then(success, error);
            }

            // Promise success handler
            function success(data) {
                // If this was an account signing
                if (data.userId) {
                    sigModel = data;
                }
                // If this was an anon initials signing
                else if (isInitial) {
                    sigModel.iniPath = data.path;
                    sigModel.timestamp = data.timestamp;
                }
                // Then it must have be an anon signature signing
                else {
                    // Save Signature
                    sigModel.sigPath = data.path;
                    sigModel.timestamp = data.timestamp;
                }
                // Save the signature value
                deferred.resolve(sigModel);
            }

            // Promise error handler
            function error() {
                deferred.reject();
            }

            return deferred.promise;
        }
    })
;
