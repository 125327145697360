angular.module('cerberus.account')
    .config(function ($routeProvider) {
        $routeProvider.when('/organizations/:id/settings', {
            templateUrl: 'account/organizations/settings.tpl.html',
            controller: 'OrganizationsSettingsCtrl',
            title: 'Organization Settings',
            resolve: {
                _wait: 'WaitForUserData',
                orgId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/organizations/:id/settings/profile', {
            templateUrl: 'account/organizations/profile.tpl.html',
            controller: 'OrganizationsProfileCtrl',
            title: 'Organization Profile',
            resolve: {
                _wait: 'WaitForUserData',
                orgId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/organizations/:id/settings/users', {
            templateUrl: 'account/organizations/users.tpl.html',
            controller: 'OrganizationsUsersCtrl',
            controllerAs: 'vm',
            title: 'Organization Users',
            resolve: {
                _wait: 'WaitForUserData',
                orgId: function($route){
                    return $route.current.params.id;
                },
                userId: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function(){
                        return $rootScope.userData.id;
                    });
                }
            }
        });
        $routeProvider.when('/organizations/:orgId/settings/users/:id', {
            templateUrl: 'account/organizations/users-details.tpl.html',
            controller: 'OrganizationsUsersDetailsCtrl',
            controllerAs: 'vm',
            title: 'Organization User Details',
            resolve: {
                _wait: 'WaitForUserData',
                orgId: function($route){
                    return $route.current.params.orgId;
                },
                userId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/organizations/:id/settings/groups', {
            templateUrl: 'account/organizations/groups.tpl.html',
            controller: 'OrganizationsGroupsCtrl',
            title: 'Organization Groups',
            resolve: {
                _wait: 'WaitForUserData',
                orgId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/organizations/:id/settings/groups/create', {
            templateUrl: 'account/organizations/groups-create.tpl.html',
            controller: 'OrganizationsGroupsCreateCtrl',
            controllerAs: 'vm',
            title: 'Create Organization Group',
            resolve: {
                _wait: 'WaitForUserData',
                orgId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/organizations/:id/settings/groups/:id2', {
            templateUrl: 'account/organizations/groups-details.tpl.html',
            controller: 'OrganizationsGroupsDetailsCtrl',
            controllerAs: 'vm',
            title: 'Organization Group Details',
            resolve:{
                _wait: 'WaitForUserData',
                orgId: function($route){
                    return $route.current.params.id;
                },
                groupId: function($route){
                    return $route.current.params['id2'];
                }
            }
        });
        $routeProvider.when('/organizations/:id/settings/audits', {
            templateUrl: 'account/organizations/audits.tpl.html',
            controller: 'OrganizationsAuditsCtrl',
            title: 'Organization Audits',
            resolve: {
                _wait: 'WaitForUserData',
                authToken: function($rootScope, WaitForUserData){
                    return WaitForUserData.then(function () {
                        return $rootScope.authToken;
                    });
                },
                orgId: function($route){
                    return $route.current.params.id;
                },
                workspaceId: function($rootScope, WaitForUserData){
                    return WaitForUserData.then(function () {
                        return $rootScope.userData.currentWorkspace.id;
                    });
                }
            }
        });
        $routeProvider.when('/organizations/:id/settings/workspaces', {
            templateUrl: 'account/organizations/workspaces.tpl.html',
            controller: 'OrganizationsWorkspacesCtrl',
            title: 'Organization Workspaces',
            resolve: {
                _wait: 'WaitForUserData',
                orgId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/organizations/:id/settings/workspaces/create', {
            templateUrl: 'account/organizations/workspaces-create.tpl.html',
            controller: 'OrganizationsWorkspacesCreateCtrl',
            title: 'Create Organization Workspace',
            resolve: {
                _wait: 'WaitForUserData',
                orgId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/organizations/:id/settings/workspaces/:id2', {
            templateUrl: 'account/organizations/workspaces-details.tpl.html',
            controller: 'OrganizationsWorkspacesDetailsCtrl',
            title: 'Organization Workspace Details',
            resolve: {
                _wait: 'WaitForUserData',
                orgId: function($route){
                    return $route.current.params.id;
                },
                workspaceId: function($route){
                    return $route.current.params['id2'];
                }
            }
        });

        $routeProvider.when('/organizations/:id/settings/mail-servers', {
            templateUrl: 'account/organizations/mail-servers.tpl.html',
            controller: 'OrganizationsMailServersCtrl',
            title: 'Organization Mail Servers',
            resolve: {
                _wait: 'WaitForUserData',
                orgId: function($route){
                    return $route.current.params.id;
                }
            }
        });

        $routeProvider.when('/organizations/:id/settings/mail-servers/create', {
            templateUrl: 'account/organizations/mail-servers-create.tpl.html',
            controller: 'OrganizationsMailServersCreateCtrl',
            title: 'Create Organization Mail Server',
            resolve: {
                _wait: 'WaitForUserData',
                orgId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/organizations/:id/settings/mail-servers/:serverId', {
            templateUrl: 'account/organizations/mail-servers-details.tpl.html',
            controller: 'OrganizationsMailServersDetailsCtrl',
            title: 'Organization Mail Server Details',
            resolve: {
                _wait: 'WaitForUserData',
                orgId: function($route){
                    return $route.current.params.id;
                },
                serverId: function($route){
                    return $route.current.params.serverId;
                }
            }
        });

        $routeProvider.when('/organizations/:id/settings/media-library', {
            templateUrl: 'account/organizations/media-library.tpl.html',
            controller: 'OrganizationsMediaLibraryCtrl',
            title: 'Organization Media Library',
            resolve: {
                _wait: 'WaitForUserData',
                authToken: function($rootScope) {
                    return $rootScope.authToken;
                },
                orgId:function($route) {
                    return $route.current.params.id;
                },
                workspaceId: function(WaitForUserData, $rootScope) {
                    return WaitForUserData.then(function () {
                        return $rootScope.userData.currentWorkspace.id;
                    });
                }
            }
        });

        $routeProvider.when('/organizations/:id/settings/file/:fileId', {
            templateUrl: 'account/organizations/file-details.tpl.html',
            controller: 'OrganizationsFileDetailsCtrl',
            title: 'Organization File Details',
            resolve: {
                _wait: 'WaitForUserData',
                orgId: function($route) {
                    return $route.current.params.id;
                },
                fileId: function($route) {
                    return $route.current.params.fileId;
                }
            }
        });

        $routeProvider.when('/organizations/create', {
            templateUrl: 'account/organizations/organizations-create.tpl.html',
            controller: 'OrganizationsCreateCtrl',
            title: 'Create Organization',
            resolve: {
                _wait: 'WaitForUserData',
                defaultDomain: function(WaitForUserData, $rootScope) {
                    return WaitForUserData.then(function () {
                        return $rootScope.userData.defaultDomain;
                    });
                }
            }
        });

        $routeProvider.when('/organizations/:id/settings/workspaces/profile', {
            templateUrl: 'account/organizations/workspaces-details.tpl.html',
            controller: 'OrganizationsCurrentWorkspaceDetailsCtrl',
            title: 'Current Workspace Details',
            resolve: {
                _wait: 'WaitForUserData',
                orgId: function($route){
                    return $route.current.params.id;
                },
                workspaceData: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function () {
                        return $rootScope.userData.currentWorkspace;
                    });
                }
            }
        });

    })
;