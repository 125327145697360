angular.module('cerberus.admin')
    .config(function ($routeProvider) {
        $routeProvider.when('/settings/pages', {
            templateUrl: 'admin/pages/pages.tpl.html',
            controller: 'PagesListCtrl',
            controllerAs: 'vm',
            title: 'Pages',
            resolve: {
                _wait: 'WaitForUserData',
                workspaceId: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function(){
                        return $rootScope.userData.currentWorkspace.id;
                    });
                }
            },
            reloadOnSearch: false
        });
        $routeProvider.when('/settings/pages/create', {
            templateUrl: 'admin/pages/pages-create.tpl.html',
            controller: 'PagesCreateCtrl',
            controllerAs: 'vm',
            title: 'Create Page',
            resolve: {
                _wait: 'WaitForUserData',
                workspace: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function () {
                        return $rootScope.userData.currentWorkspace;
                    });
                }
            },
            reloadOnSearch: false
        });
        $routeProvider.when('/settings/pages/:pageId', {
            templateUrl: 'admin/pages/designer/designer.tpl.html',
            controller: 'PagesDesignerCtrl',
            title: 'Page Designer',
            resolve: {
                _wait: 'WaitForUserData',
                pageId: function($route){
                    return $route.current.params.pageId;
                }
            },
            reloadOnSearch: false
        });
    })
;