angular.module('cerberus.core')
    .controller('NotificationsCtrl', function (_, $scope, NotificationsService, InstancesWindowService) {
        var vm = this, 
			pageSize = 5;

        vm.notifications = [];
        vm.notificationsBeingMarkedAsRead = {};
		vm.notificationsBeingMarkedAsDeleted = {};
        vm.markingAsRead = false;
        vm.markingAsDeleted = false;
        vm.markingAllAsRead = false;
		vm.markingAllAsDeleted = false;
        vm.loadingNotifications = false;
        vm.allNotificationsLoaded = false;

        vm.openNotification = openNotification;
        vm.markAsRead = markAsRead;
        vm.markAllAsRead = markAllAsRead;
		vm.deleteNotification = deleteNotification;
		vm.deleteAllNotifications = deleteAllNotifications;
        vm.loadNextPage = loadNextPage;

        init();

        function init() {
            loadNextPage();
        }

        //Open notification in a separate window, enable function when no pending operations are running and when record is not deleted.
        function openNotification(notification) {
            if (!notification.isDeleted && !vm.notificationsBeingMarkedAsDeleted[notification.id] && !vm.notificationsBeingMarkedAsRead[notification.id]) {
                var navFunctions = {getNext: false, getPrevious: false};
                InstancesWindowService.openWindow({
                    action: 'read',
                    instanceId: notification.instanceId,
                    navFunctions: navFunctions
                });
            }
        }

        //mark notifications as read  
        function markAsRead(notification) {
            if (!notification.isRead && !vm.notificationsBeingMarkedAsRead[notification.id]) {
                updateMarkingAsRead(notification.id, true);

                NotificationsService.markAsRead(notification.id).then(function () {
                    updateMarkingAsRead(notification.id, false);
                    notification.isRead = true;
                    vm.totalUnread--;
                }, function () {
                    updateMarkingAsRead(notification.id, false);
                });
            }
        }

        function markAllAsRead(notifications) {
            var unreadNotifications = _.reject(notifications, 'isRead');
            if (unreadNotifications.length) {
                vm.markingAllAsRead = true;
                NotificationsService.markAllAsRead(_.map(unreadNotifications, 'id')).then(function () {
                    vm.markingAllAsRead = false;
                    _.forEach(unreadNotifications, function (n){
                        n.isRead = true;
                        vm.totalUnread--;
                    });
                });
            }
        }

        function updateMarkingAsRead(id, value) {
            vm.notificationsBeingMarkedAsRead[id] = value;
            vm.markingAsRead = _.some(vm.notificationsBeingMarkedAsRead);
        }

        //delete notifications (Include only if are not deleted yet and if is not marked already as Deleted)
         function deleteNotification(notification) {
            if (!notification.isDeleted && !vm.notificationsBeingMarkedAsDeleted[notification.id]) {
                updateMarkingAsDeleted(notification.id, true);
                NotificationsService.deleteNotification(notification.id).then(function () {
                    updateMarkingAsDeleted(notification.id, false);
                    notification.isDeleted = true;
                    vm.totalUserNotifications--;
                    if (notification.isRead == false) {
                        notification.isRead = true;
                        vm.totalUnread--;
                    }
                }, function () {
                    updateMarkingAsDeleted(notification.id, false);
                });
            }
        }
        
        //delete all notifications (Include only if are not deleted yet and if are marked already as Read)
        function deleteAllNotifications(notifications) {
            var undeletedNotifications = _.reject(_.filter(notifications, 'isRead'), 'isDeleted');
            if (undeletedNotifications.length) {
                vm.markingAllAsDeleted = true;
                NotificationsService.deleteAllNotifications(_.map(undeletedNotifications, 'id')).then(function () {
                    vm.markingAllAsDeleted = false;
                    _.forEach(undeletedNotifications, function (n){
                        n.isDeleted = true;
                        vm.totalUserNotifications--;
                        if (n.isRead == false) {
                            n.isRead = true;
                            vm.totalUnread--;
                        }
                    });
                    //update list after mass deletion
                    vm.notifications = [];
                    loadNextPage();
                });
            }
        }

        function updateMarkingAsDeleted(id, value) {
            vm.notificationsBeingMarkedAsDeleted[id] = value;
            vm.markingAsDeleted = _.some(vm.notificationsBeingMarkedAsDeleted);
        }

        //get content and load next page
        function loadNextPage() {
            vm.loadingNotifications = true;
            NotificationsService.getNotifications(pageSize, vm.notifications.length).then(function (result) {
                vm.loadingNotifications = false;
                vm.notifications = vm.notifications.concat(result.notifications);
                vm.totalUnread = result.totalUnread;
                vm.totalUserNotifications = result.totalUserNotifications;

                if (result.notifications.length < pageSize) {
                    vm.allNotificationsLoaded = true;
                }
            });
        }
    })
;