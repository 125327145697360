angular.module('cerberus.account')
    .controller('UsersSessionsCtrl', function UsersSessionsCtrl($scope, UsersSecurityService) {
        $scope.activeRoute = 'security';
        $scope.userTokens = [];

        $scope.revokeToken = _revokeToken;

        _init();

        function _init(){
            UsersSecurityService.getAll().then(function(userTokens){
                $scope.userTokens = userTokens;
            });
        }

        function _revokeToken(id, idx){
            UsersSecurityService.revoke(id).then(function(){
                $scope.userTokens.splice(idx, 1);
            });
        }
    })
;