angular.module('cerberus.admin')
    .factory('SmartObjectsService', function SmartObjectsService() {
        var service = {
            smartObjectsList: smartObjectsList
        };
        return service;
        ////////////////////

        function smartObjectsList() {
            return [
                {
                    name: 'Email',
                    value: 'email'
                }
            ];
        }
    })
;
