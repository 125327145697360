angular.module('cerberus.admin')
    .config(function ($routeProvider) {
        $routeProvider.when('/settings', {
            templateUrl: 'admin/workspaces/settings.tpl.html',
            controller: 'WorkspacesSettingsCtrl',
            title: 'Workspace Settings',
            resolve: {
                _wait: 'WaitForUserData',
                currentOrg: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function(){
                        return $rootScope.userData.currentOrg.id;
                    });
                },
                currentWorkspace: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function(){
                        return $rootScope.userData.currentWorkspace;
                    });
                }
            }
        });
        $routeProvider.when('/settings/permissions', {
            templateUrl: 'admin/workspaces/permissions.tpl.html',
            controller: 'WorkspacesPermissionsCtrl',
            controllerAs: 'vm',
            title: 'Workspace Permissions',
            resolve:{
                _wait: 'WaitForUserData',
                currentUserId: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function(){
                        return $rootScope.userData.id;
                    });
                },
                workspaceId: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function(){
                        return $rootScope.userData.currentWorkspace.id;
                    });
                }
            }
        });
        $routeProvider.when('/settings/users/:id', {
            templateUrl: 'admin/workspaces/users-details.tpl.html',
            controller: 'WorkspacesUsersDetailsCtrl',
            controllerAs: 'vm',
            title: 'Workspace User Details',
            resolve:{
                _wait: 'WaitForUserData',
                userId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/settings/groups/create', {
            templateUrl: 'admin/workspaces/groups-create.tpl.html',
            controller: 'WorkspacesGroupsCreateCtrl',
            controllerAs: 'vm',
            title: 'Create Workspace Group',
            resolve:{
                _wait: 'WaitForUserData',
                workspaceId: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function(){
                        return $rootScope.userData.currentWorkspace.id;
                    });
                }
            }
        });
        $routeProvider.when('/settings/groups/:id', {
            templateUrl: 'admin/workspaces/groups-details.tpl.html',
            controller: 'WorkspacesGroupsDetailsCtrl',
            controllerAs: 'vm',
            title: 'Workspace Group Details',
            resolve:{
                _wait: 'WaitForUserData',
                groupId: function($route){
                    return $route.current.params.id;
                },
                workspaceId: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function(){
                        return $rootScope.userData.currentWorkspace.id;
                    });
                }
            }
        });
        $routeProvider.when('/settings/currency', {
            templateUrl: 'admin/workspaces/currency.tpl.html',
            controller: 'WorkspacesCurrencyCtrl',
            title: 'Workspace Currency',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/settings/numbers', {
            templateUrl: 'admin/workspaces/numbers.tpl.html',
            controller: 'WorkspacesNumbersCtrl',
            title: 'Workspace Numbers',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/settings/date-time', {
            templateUrl: 'admin/workspaces/date-time.tpl.html',
            controller: 'WorkspacesDateTimeCtrl',
            title: 'Workspace Date Time',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/settings/whitelist', {
            templateUrl: 'admin/workspaces/whitelist.tpl.html',
            controller: 'WorkspacesWhitelistCtrl',
            title: 'Workspace White List',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });

        $routeProvider.when('/settings/profile', {
            templateUrl: 'admin/workspaces/profile.tpl.html',
            controller: 'WorkspacesProfileCtrl',
            title: 'Workspace Profile',
            resolve: {
                _wait: 'WaitForUserData',
                workspaceData: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function () {
                        return $rootScope.userData.currentWorkspace;
                    });
                }
            }
        });
    })
;