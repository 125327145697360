angular.module('cerberus.admin')
    .controller('WidgetExportAllAdvancedModalCtrl', function WidgetExportAllAdvancedModalCtrl($scope, $uibModalInstance, exportOptions) {

        $scope.exportOptions = exportOptions;

        $scope.exportLineBreakTypes = [
            {value: 'crlf', display: '[CR] + [LF]'},
            {value: 'lf', display: '[LF] - New Line Feed'},
            {value: 'cr', display: '[CR] - Carriage Return'},
            {value: 'pipe', display: 'PIPE SIGN [ | ]'}
        ];

        $scope.CellsFormatCrLfDetectTypes = [
            {value: 'none', display: 'None - (AS IS)'},
            {value: 'deleteCrLf', display: 'Remove [\\r\\n]'},
            {value: 'injectSpace', display: 'Replace with space'}
        ];
        
        $scope.CellsFormatQuoteCharReplaceTypes = [
            {value: 'none', display: 'None - (AS IS)'},
            {value: 'backQuote', display: 'Back quote [ ` ]'},
            {value: 'tildeChar', display: 'Tilde sign [ ~ ]'},
            {value: 'injectSpace', display: 'Replace with space'}
        ];
        
        //init fucntions here...

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.finish = function(){
            $uibModalInstance.close($scope.widget);
        };

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;