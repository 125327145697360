angular.module('cerberus.account')
    .controller('UserVerifySignatureModalCtrl', function UserVerifySignatureModalCtrl($scope, $uibModalInstance, usersProfileService){
        $scope.password = '';

        $scope.ok = function(){
            usersProfileService.verifySignaturePass($scope.password).then(function(data){
                $uibModalInstance.close(data);
            });
        };

        $scope.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;