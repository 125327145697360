angular.module('cerberus.admin')
    .controller('ViewsDesignerCtrl', function ViewsDesignerCtrl(_, kendo, $scope, $location, $uibModal, $rootScope, viewId, ConfirmModalService, DesignerUtilityService, ViewsService, ViewsDesignerService, WidgetsSettingsService) {
        var vm = this;

        $scope.viewDesignerNormalCtrl = {};
        $scope.viewDesignerAdvCtrl = {};
        $scope.viewId = viewId;
        vm.mode = '';
        vm.resultsCollapse = true;
        vm.settingsCollapse = true;
        vm.updateError = null;
        vm.viewData = {};
        vm.viewDataOrig = {};

        vm.hasChanges = function () {
            var adv = false;
            var norm = false;
            if($scope.viewDesignerAdvCtrl.vm){
                adv = !angular.equals($scope.viewDesignerAdvCtrl.vm.advModel, $scope.viewDesignerAdvCtrl.vm.advModelOrig);
            }
            if($scope.viewDesignerNormalCtrl.vm){
                norm = !angular.equals($scope.viewDesignerNormalCtrl.vm.viewModel, $scope.viewDesignerNormalCtrl.vm.viewModelOrig);
            }
            return norm || !angular.equals(vm.viewData, vm.viewDataOrig) || adv;
        };
        DesignerUtilityService.cancelRouteNav($scope, vm.hasChanges);

        //Results grid functions
        vm.viewResults = function(){
            vm.resultsCollapse = !vm.resultsCollapse;
            if(!vm.resultsCollapse){
                //var columns = [];
                var fields = {};
                if(vm.mode === 'normal'){
                    for(var i = 0; i < $scope.viewDesignerNormalCtrl.vm.viewModel.model.selectArray.length; i++){
                        var fieldName = $scope.viewDesignerNormalCtrl.vm.viewModel.model.selectArray[i].uniqueName + '_' + $scope.viewDesignerNormalCtrl.vm.viewModel.model.selectArray[i].modelId;
                        if($scope.viewDesignerNormalCtrl.vm.viewModel.model.selectArray[i].isLookUp){
                            fieldName = fieldName + '_display';
                        }
                        //columns.push({
                        //    field: fieldName,
                        //    width: 200,
                        //    type: $scope.viewDesignerNormalCtrl.vm.viewModel.model.selectArray[i].type
                        //});
                        fields[fieldName] = {
                            type: $scope.viewDesignerNormalCtrl.vm.viewModel.model.selectArray[i].type
                        };
                    }
                }
                //Kendo Grid
                var dataSource = new kendo.data.DataSource({
                    type: 'odata',
                    transport:{
                        read: {
                            url: '/server/rest/v1/views/' + viewId + '/odata.svc',
                            beforeSend: function(xhr){
                                xhr.setRequestHeader('Authentication','Bearer ' + $rootScope.authToken);
                                xhr.setRequestHeader('WSID',$rootScope.userData.currentWorkspace.id);
                            },
                            dataType: 'json'
                        }
                    },
                    serverFiltering: true,
                    serverPaging: true,
                    serverSorting: true,
                    pageSize: 20,
                    schema: {
                        model: {
                            fields: fields
                        }
                    }
                });
                vm.options = {
                    excel: {
                        fileName: "view" + viewId + ".xlsx",
                        filterable: true
                    },
                    columnMenu: true,
                    //columns:  columns,
                    dataSource: dataSource,
                    filterable: true,
                    mobile: false,
                    pageable: true,
                    reorderable: true,
                    resizable: true,
                    sortable: true,
                    scrollable: true,
                    groupable: true
                };

                if(vm.grid) {
                    vm.grid.setOptions(vm.options);
                }
            }
        };
        //Delete view
        vm.deleteView = function(id){
            ConfirmModalService.showModal(null, {
                headerText: 'Confirm Deletion',
                bodyText: '<strong>Are you sure you want to delete this view?</strong>',
                actionButtonText: 'Delete',
                closeButtonText: 'Cancel',
                confirm: function(){
                    ViewsService.remove(id).then(function(){
                        $location.url('/settings/queries');
                    });
                },
                check: function(){
                    return true;
                }
            });
        };
        //Open Modal to add a widget
        vm.openAddWidgetModal = function () {
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/views/designer/views-designer-add-widget-modal.tpl.html',
                controller: 'ViewsDesignerAddWidgetModalCtrl',
                controllerAs: 'viewDesignerWidget',
                size: 'lg',
                backdrop: 'static'
            });
            modalInstance.result.then(function (widgetData) {
                WidgetsSettingsService.getFormFields(widgetData.id).then(function(data){
                    data.display.push({
                        formName: 'System Columns',
                        objects: [
                            {
                                dataType: 'date',
                                isLookUp: false,
                                label: 'Date Created',
                                modelId: 'sys_created_date',
                                type: 'date',
                                isSystem: true
                            },
                            {
                                dataType: 'date',
                                isLookUp: false,
                                label: 'Date Modified',
                                modelId: 'sys_last_modified_date',
                                type: 'date',
                                isSystem: true
                            },
                            {
                                dataType: 'string',
                                isLookUp: false,
                                label: 'Created By User',
                                modelId: 'sys_created_by',
                                type: 'string',
                                isSystem: true
                            },
                            {
                                dataType: 'string',
                                isLookUp: false,
                                label: 'Modified By User',
                                modelId: 'sys_last_modified_by',
                                type: 'string',
                                isSystem: true
                            }
                        ]
                    });
                    $scope.viewDesignerNormalCtrl.vm.viewModel.model.tableArray.push({
                        id: widgetData.id,
                        name: widgetData.name,
                        uniqueName: widgetData.uniqueName,
                        forms: data.display,
                        selectAll: true
                    });
                    $scope.viewDesignerNormalCtrl.vm.selectAll($scope.viewDesignerNormalCtrl.vm.viewModel.model.tableArray[$scope.viewDesignerNormalCtrl.vm.viewModel.model.tableArray.length-1],$scope.viewDesignerNormalCtrl.vm.viewModel.model);
                    if(!$scope.viewDesignerNormalCtrl.vm.viewModel.baseWidget){
                        $scope.viewDesignerNormalCtrl.vm.viewModel.baseWidget = widgetData.uniqueName;
                    }
                });
            });
        };

        //Tool bar functions
        vm.switchMode = function(){
            if(vm.mode === 'normal'){
                vm.mode = 'adv';
            }
            else{
                vm.mode = 'normal';
            }
        };
        vm.refreshView = function(){
            ViewsDesignerService.refresh(viewId);
        };

        vm.save = save;
        $scope.save = save;

        vm.close = function(){
            $location.url('/settings/queries');
        };
        //Init function
        _init();

        $scope.$on('$locationChangeStart', function (e) {
            if (!e.defaultPrevented) {
                if (vm.grid) {
                    vm.grid.destroy();
                    vm.grid = null;
                }
            }
        });
        ////////////////////
        /**
         * Init controller data and server calls
         */
        function _init() {
            ViewsService.getDetails(viewId).then(function(d){
                vm.mode = d.mode;
                vm.viewData = d;
                vm.viewDataOrig = angular.copy(vm.viewData);
            });
        }

        function save(close){
            if(vm.viewData.name){
                var data = {
                    viewData: vm.viewData
                };
                if(vm.mode === 'normal'){
                    data.viewModel = ViewsDesignerService.createViewModel($scope.viewDesignerNormalCtrl.vm.viewModel.model);
                    data.baseWidget = $scope.viewDesignerNormalCtrl.vm.viewModel.baseWidget;
                    if($scope.viewDesignerAdvCtrl.vm){
                        $scope.viewDesignerAdvCtrl.vm.advModel.advSql = '';
                    }
                }
                else{
                    data.viewModel = $scope.viewDesignerAdvCtrl.vm.advModel;
                    data.baseWidget = $scope.viewDesignerAdvCtrl.vm.advModel.baseWidget;
                    if($scope.viewDesignerNormalCtrl.vm){
                        $scope.viewDesignerNormalCtrl.vm.viewModel = {};
                    }
                }
                ViewsDesignerService.updateModel(viewId, data).then(function(){
                    // Clear error
                    vm.updateError = null;

                    if(vm.mode === 'normal'){
                        $scope.viewDesignerNormalCtrl.vm.viewModelOrig = angular.copy($scope.viewDesignerNormalCtrl.vm.viewModel);
                        vm.viewDataOrig = angular.copy(vm.viewData);
                    }
                    else{
                        $scope.viewDesignerAdvCtrl.vm.advModelOrig = angular.copy($scope.viewDesignerAdvCtrl.vm.advModel);
                    }

                    // If user has not clicked the "Results" button, this grid does not exist
                    if(vm.grid) {
                        vm.grid.dataSource.read();
                    }
                    if(close) {
                        vm.close();
                    }
                }, function(error){
                    // Display error
                    vm.updateError = _.get(error, 'DATA.details');
                });
            }
        }
    })
;