angular.module('cerberus.les')
    .factory('lesLoanApplicationsService', function (_, kendo, moment, $http, $location, $q, $rootScope, $timeout, $uibModal, $window, apiPath,
                                                    ConfirmModalService, InstancesService, InstancesWindowService) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';
        
        return {
            actionAvailabilityByLoanStatus: actionAvailabilityByLoanStatus,
            getLoanApplication: getLoanApplication,
            validateLoanApplication: validateLoanApplication,
            exportLoanApplication: exportLoanApplication,
            getLoanApplicants: getLoanApplicants,
            getLoanPrimaryBorrower: getLoanPrimaryBorrower,
            getLoanAppStatuses: getLoanAppStatuses,
            getCreditScores: getCreditScores,
            getBorrowerIncomeSources: getBorrowerIncomeSources,
            getProposedExpenses: getProposedExpenses,
            getLoanPrograms: getLoanPrograms,
            getLoanProgramEligibility: getLoanProgramEligibility,
            openImportModal: openImportModal,
            openUpdateModal: openUpdateModal,
            openApplicationRecord: openApplicationRecord,
            openExportModal: openExportModal,
            openFindProductsModal: openFindProductsModal,
            openEligibilityPage: openEligibilityPage,
            openCreditPullModal: openCreditPullModal,
            openCreditManualEntryModal: openCreditManualEntryModal,
            openWithdrawalModal: openWithdrawalModal,
            openUnregistrationModal: openUnregistrationModal,
            openCloseAndFundModal: openCloseAndFundModal,
            openReopenModal: openReopenModal,
            openFindingsReportModal: openFindingsReportModal,
            openRegistrationModal: openRegistrationModal,
            updateBorrowerCreditPullPermission: updateBorrowerCreditPullPermission,
            getCreditData: getCreditData,
            pullCredit: pullCredit,
            addCreditScore: addCreditScore,
            updateCreditScore: updateCreditScore,
            updateLoan: updateLoan,
            saveProductForLater: saveProductForLater,
            removeSavedProduct: removeSavedProduct,
            loanAuditLogOptions: loanAuditLogOptions,
            loanFeeLogOptions: loanFeeLogOptions,
            loanSavedForLaterOptions: loanSavedForLaterOptions,
            loanDocumentVaultOptions: loanDocumentVaultOptions,
            attachmentGridOptions: attachmentGridOptions,
            productEligibilityTableOptions: productEligibilityTableOptions,
            productRateDetailTableOptions: productRateDetailTableOptions,
            assetsTableOptions: assetsTableOptions,
            assetDetailsTableOptions: assetDetailsTableOptions,
            eligibilityFilterLookupOptions: eligibilityFilterLookupOptions,
            getFilterOptions: getFilterOptions,
            openIncompleteLoanApplication: openIncompleteLoanApplication,
            addDocumentToVault: addDocumentToVault,
            buildProductsQueryParams: buildProductsQueryParams,
            parseProductsQueryParams: parseProductsQueryParams
        };

        function actionAvailabilityByLoanStatus() {
            return {
                1735: { // New Application
                    edit: true,
                    export: true,
                    pullCredit: true,
                    withdraw: true,
                    findProducts: true
                },
                // 1731: {
                //     edit: true,
                //     export: true,
                //     repullCredit: true,
                //     withdraw: true,
                //     findProducts: true
                // },
                1732: { // Products Found
                    edit: true,
                    export: true,
                    pullCredit: true,
                    withdraw: true,
                    findProducts: true
                },
                1733: { // Product Selected
                    edit: true,
                    readonly: true,
                    export: true,
                    // pullCredit: true,
                    unregister: true,
                    closeAndFund: true
                },
                1734: { // Closed & Insured
                    edit: true,
                    readonly: true,
                    export: true,
                    reopen: true
                }
            };
        }

        function getLoanApplication(id) {
            var deferred = $q.defer();

            $http.get(apiPath + 'les/loanApp/' + id)
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function validateLoanApplication(id) {
            var deferred = $q.defer();

            $http.get(apiPath + 'les/loanApp/' + id + '/validate')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function exportLoanApplication(id, format) {
            var deferred = $q.defer(),
                options = {
                    params: {
                        format: format
                    }
                };

            $http.get(apiPath + 'les/loanApp/' + id, options)
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function getLoanApplicants(id) {
            var deferred = $q.defer(),
                url = apiPath + 'views/46D9163D5583CF87E28716D7CED85CC6-120/odata.svc';

            $http.get(url, {
                params: {
                    $filter: 'Borrowers_LoanApplicationID eq ' + id + 'f'
                }
            }).success(function (value) {
                resolveOData(deferred, value);
            });

            return deferred.promise;
        }

        function getLoanPrimaryBorrower(id) {
            var deferred = $q.defer(),
                url = apiPath + 'views/46D9163D5583CF87E28716D7CED85CC6-120/odata.svc';

            $http.get(url, {
                params: {
                    $filter: 'Borrowers_LoanApplicationID eq ' + id + 'f and Borrowers_PrintPositionType eq 424f'
                }
            }).success(function (value) {
                resolveOData(deferred, value);
            });

            return deferred.promise;
        }

        function getLoanAppStatuses() {
            var deferred = $q.defer();

            var url = apiPath + 'views/F507A886590BBCF8929EF81143812791-175/odata.svc';
            $http.get(url, {
                params: {
                    $lookupDisplay: 'LoanAppStatus_Name',
                    $orderBy: 'LoanAppStatus_SortOrder asc'
                }
            }).success(function (value) {
                resolveOData(deferred, value);
            });

            return deferred.promise;
        }

        function getCreditScores(loanId, borrowerId) {
            var deferred = $q.defer(),
                url = apiPath + 'views/1E1B6FC299BA6A278D94C32B90A97E07-184/odata.svc',
                filter = 'Borrowers_LoanApplicationID eq ' + loanId + 'f';
            
            if (borrowerId) {
                filter += ' and BorrowerCreditScores_Borrower eq \'' + borrowerId + '\'';
            }

            $http.get(url, {
                params: {
                    $filter: filter
                }
            }).success(function (value) {
                resolveOData(deferred, value);
            });

            return deferred.promise;
        }

        function getBorrowerIncomeSources(borrowerId) {
            var deferred = $q.defer(),
                url = apiPath + 'views/9EB426A81E62F9487C39E80A76CF3371-124/odata.svc',
                filter = 'BorrowerCurrentIncome_BorrowerID eq \'' + borrowerId + '\'';
            
            $http.get(url, {
                params: {
                    $filter: filter
                }
            }).success(function (value) {
                resolveOData(deferred, value);
            });

            return deferred.promise;
        }

        function getProposedExpenses(loanId) {
            var deferred = $q.defer(),
                url = apiPath + 'views/9D7D5B555D1059859AA0F500E5598893-162/odata.svc',
                filter = 'OtherProposedHousingExpenses_LoanApplicationID eq \'' + loanId + '\'';
            
            $http.get(url, {
                params: {
                    $filter: filter
                }
            }).success(function (value) {
                resolveOData(deferred, value);
            });

            return deferred.promise;
        }

        function getLoanPrograms(loanId) {
            var deferred = $q.defer();

            $http.get(apiPath + 'les/loanApp/' + loanId + '/programs')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function getLoanProgramEligibility(loanId, programId, conduit, queryParams) {
            var deferred = $q.defer();
            var apiUrl = apiPath + 'les/loanApp/' + loanId + '/eligibility';
            $http.post(apiUrl, { program_id: programId, conduit: conduit || {} }, { params: queryParams })
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason.DATA);
                });
            return deferred.promise;
        }

        function openImportModal() {
            var modalInstance = $uibModal.open({
                templateUrl: 'les/loan-applications/templates/loan-application-import-modal.tpl.html',
                controller: 'LESLoanApplicationImportModalCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {}
            });

            return modalInstance.result;
        }

        function openUpdateModal(id) {
            var modalInstance = $uibModal.open({
                templateUrl: 'les/loan-applications/templates/loan-application-update.tpl.html',
                controller: 'LESLoanApplicationUpdateModalCtrl',
                controllerAs: 'vm',
                resolve: {
                    loanApplicationId: _.constant(id)
                }
            });

            return modalInstance.result;
        }

        function openApplicationRecord(loanRecordId, readonly, navFunctions){
            InstancesWindowService.openWindow({
                action: readonly ? 'readonly' : 'read',
                instanceId: loanRecordId,
                navFunctions: navFunctions
            });
        }

        function openExportModal(id) {
            var modalInstance = $uibModal.open({
                templateUrl: 'les/loan-applications/templates/loan-application-export.tpl.html',
                controller: 'LESLoanApplicationExportModalCtrl',
                controllerAs: 'vm',
                resolve: {
                    loanApplicationId: _.constant(id)
                }
            });

            return modalInstance.result;
        }

        function openFindProductsModal(defaultQueryParams, validation) {
            defaultQueryParams = defaultQueryParams || {};
            validation = validation || { isValid: true, messages: [] };
            var modalInstance = $uibModal.open({
                templateUrl: 'les/loan-applications/templates/loan-application-find-products.tpl.html',
                controller: 'LESLoanApplicationFindProductsModalCtrl',
                controllerAs: 'vm',
                resolve: {
                    queryParams: _.constant(defaultQueryParams),
                    validation: _.constant(validation)
                }
            });

            return modalInstance.result;
        }

        function openEligibilityPage(id, queryParams) {
            if (id) {
                $location.url('loan-applications/' + id + '/eligibility').search(queryParams);
            }
        }

        function openCreditPullModal(id) {
            var modalInstance = $uibModal.open({
                templateUrl: 'les/loan-applications/templates/loan-application-credit-pull.tpl.html',
                controller: 'LESLoanApplicationCreditPullModalCtrl',
                controllerAs: 'vm',
                resolve: {
                    loanApplicationId: _.constant(id)
                }
            });

            return modalInstance.result;
        }

        function openCreditManualEntryModal(id) {
            var modalInstance = $uibModal.open({
                templateUrl: 'les/loan-applications/templates/loan-application-manual-entry-modal.tpl.html',
                controller: 'LESLoanApplicationManualEntryModalCtrl',
                controllerAs: 'vm',
                resolve: {
                    loanApplicationId: _.constant(id)
                }
            });

            return modalInstance.result;
        }

        function openWithdrawalModal(id) {
            var modalInstance = $uibModal.open({
                templateUrl: 'les/loan-applications/templates/loan-application-withdrawal.tpl.html',
                controller: 'LESLoanApplicationWithdrawalModalCtrl',
                controllerAs: 'vm',
                resolve: {
                    loanApplicationId: _.constant(id)
                }
            });

            return modalInstance.result;
        }

        function openUnregistrationModal(id) {
            var unregistering = false;

            return ConfirmModalService.showModal(null, {
                closeButtonText: 'Cancel',
                actionButtonText: 'Unselect Product',
                headerText: 'Unselect Product',
                bodyText: 'Are you sure you want to unselect the Loan product for this application?',
                actionButtonPosition: 'footer',
                check: function () {
                    return !unregistering;
                },
                confirm: function () {
                    unregistering = true;

                    var promise = updateLoan(id, { action: 'unregister' });
                    promise.finally(function(){
                        unregistering = false;
                    });

                    return promise;
                }
            });
        }

        function openCloseAndFundModal(id, loanAmount, noteRate) {
            var modalInstance = $uibModal.open({
                templateUrl: 'les/loan-applications/templates/loan-application-close-and-fund.tpl.html',
                controller: 'LESLoanApplicationCloseAndFundModalCtrl',
                controllerAs: 'vm',
                resolve: {
                    loanApplicationId: _.constant(id),
                    loanAmount: _.constant(loanAmount),
                    noteRate: _.constant(noteRate)
                }
            });

            return modalInstance.result;
        }

        function openReopenModal(id) {
            var modalInstance = $uibModal.open({
                templateUrl: 'les/loan-applications/templates/loan-application-reopen-modal.tpl.html',
                controller: 'LESLoanApplicationReopenModalCtrl',
                controllerAs: 'vm',
                resolve: {
                    loanApplicationId: _.constant(id)
                }
            });

            return modalInstance.result;
        }

        function openFindingsReportModal(id, productId, product, pricingIndex) {
            var modalInstance = $uibModal.open({
                size: 'lg',
                templateUrl: 'les/loan-applications/templates/loan-application-findings.tpl.html',
                controller: 'LESLoanApplicationFindingsCtrl',
                controllerAs: 'vm',
                resolve: {
                    loanApplicationId: _.constant(id),
                    productId: _.constant(productId),
                    product: _.constant(product),
                    pricingIndex: _.constant(pricingIndex)
                }
            });

            return modalInstance.result;
        }

        function openRegistrationModal(id, productId, product, pricing, queryParams) {
            var modalInstance = $uibModal.open({
                templateUrl: 'les/loan-applications/templates/loan-application-registration-modal.tpl.html',
                controller: 'LESLoanApplicationRegistrationModalCtrl',
                controllerAs: 'vm',
                resolve: {
                    loanApplicationId: _.constant(id),
                    productId: _.constant(productId),
                    product: _.constant(product),
                    pricing: _.constant(pricing),
                    queryParams: _.constant(queryParams)
                }
            });

            return modalInstance.result;
        }

        function updateBorrowerCreditPullPermission(borrowerId, creditPullPermission) {
            var permissionValue = creditPullPermission ? { display: 'Yes', id: 'opt0' } : { display: 'No', id: 'opt1' };

            return InstancesService.update(borrowerId, {
                currentStateId: 1,
                recordData: {
                    BorrowerCreditPullPermission: permissionValue
                }
            });
        }

        function getCreditData(id) {
            var deferred = $q.defer();

            $http.get(apiPath + 'les/' + id + '/credit')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            
            return deferred.promise;
        }

        function pullCredit(id, creditPullData) {
            var deferred = $q.defer(),
                url = apiPath + 'les/' + id + '/credit';

            $http.post(url, {
                credit_provider_instance_id: creditPullData.creditProvider.id,
                override_liabilities: creditPullData.overrideLiabilities,
                credit_ref_number: creditPullData.referenceNumber
            })
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function addCreditScore(scoreData) {
            return InstancesService.createInstance({
                widgetId: 'B40EFAE20C0A98E72CA89C3D63C6E9A8-173',
                current: '',
                recordData: {
                    Borrower: {
                        display: scoreData.borrowerFullName,
                        id: scoreData.borrowerId
                    },
                    Value: scoreData.value,
                    IsManualEntry: true,
                    CreditRepositorySourceType: 'Manual Entry',
                    CreditDate: kendo.toString(new Date(), 'yyyy-MM-ddTHH:mm:ss.fffZ')
                }
            });
        }

        function updateCreditScore(scoreData) {
            return InstancesService.update(scoreData.id, {
                currentStateId: 1,
                recordData: {
                    Value: scoreData.value
                }
            });
        }

        function updateLoan(id, data, queryParams) {
            queryParams = queryParams || {};

            var deferred = $q.defer(),
                url = apiPath + 'les/loanApp/' + id + '/action';

            $http.put(url, data, { params: queryParams })
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        // function exportLoan(id, format) {
            
        // }

        function saveProductForLater(id, data) {
            var deferred = $q.defer(),
                url = apiPath + 'les/loanApp/' + id + '/save/for/later';

            $http.post(url, data)
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function removeSavedProduct(loanId, savedProductId) {
            var deferred = $q.defer(),
                url = apiPath + 'les/loanApp/' + loanId + '/save/for/later';

            $http.put(url, {saveForLaterId: savedProductId})
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function loanAuditLogOptions(loanId) {
            return {
                columns: [
                    { title: 'Date', field: 'LoanAppLedger_StatusDateTime', format: '{0:g}' },
                    { title: 'Subject', field: 'LoanAppLedger_Subject' },
                    { title: 'Description', field: 'LoanAppLedger_Description' },
                    { title: 'By', field: 'LoanAppLedger_StatusBy' }
                ],
                resizable: true,
                filterable: true,
                sortable: true,
                pageable: true,
                dataSource: {
                    type: 'odata',
                    pageSize: 10,
                    serverFiltering: true,
                    serverSorting: true,
                    sort: {
                        field: 'LoanAppLedger_StatusDateTime',
                        dir: 'desc'
                    },
                    transport: {
                        read: {
                            url: apiPath + 'views/291C8BED3D8503A658F3C1E4ABC99FD1-176/odata.svc',
                            dataType: 'json',
                            data: {
                                $filter: 'LoanAppLedger_LoanApp eq ' + loanId + 'f'
                            },
                            beforeSend: function (xhr) {
                                xhr.setRequestHeader('Authentication', 'Bearer ' + $rootScope.authToken);
                                xhr.setRequestHeader('WSID', $rootScope.userData.currentWorkspace.id);
                            }
                        }
                    },
                    schema: {
                        model: {
                            fields: {
                                LoanAppLedger_StatusDateTime: { type: 'date', parse: parseDate },
                                LoanAppLedger_Subject: { type: 'string' },
                                LoanAppLedger_Description: { type: 'string' },
                                LoanAppLedger_StatusBy: { type: 'string' }
                            }
                        }
                    }
                }
            };
        }

        function loanFeeLogOptions(loanId) {
            return {
                columns: [
                    { title: 'Date Incurred', field: 'LoanandFeeLedger_DateIncurred', format: '{0:g}' },
                    { title: 'Fee Type', field: 'FeeSchedule_FeeType_display' },
                    { title: 'Amount', field: 'FeeSchedule_Amount', format: '{0:c2}' }
                ],
                resizable: true,
                filterable: true,
                sortable: true,
                dataSource: {
                    type: 'odata',
                    pageSize: 20,
                    serverFiltering: true,
                    serverSorting: true,
                    sort: {
                        field: 'LoanandFeeLedger_DateIncurred',
                        dir: 'desc'
                    },
                    transport: {
                        read: {
                            url: apiPath + 'views/291C8BED3D8503A658F3C1E4ABC99FD1-150/odata.svc',
                            dataType: 'json',
                            data: {
                                $filter: 'LoanandFeeLedger_LoanInstanceID eq ' + loanId + 'f'
                            },
                            beforeSend: function (xhr) {
                                xhr.setRequestHeader('Authentication', 'Bearer ' + $rootScope.authToken);
                                xhr.setRequestHeader('WSID', $rootScope.userData.currentWorkspace.id);
                            }
                        }
                    },
                    schema: {
                        model: {
                            fields: {
                                LoanandFeeLedger_DateIncurred: { type: 'date', parse: parseDate },
                                FeeSchedule_FeeType_display: { type: 'string' },
                                FeeSchedule_Amount: { type: 'number' }
                            }
                        }
                    }
                }
            };
        }

        function loanSavedForLaterOptions(id) {
            return {
                resizable: true,
                filterable: true,
                sortable: true,
                columns: [ // TODO: Add button to remove from list
                    { title: 'Product', field: 'SavedForLaterProducts_Product_display' },
                    { title: 'Conduit', field: 'SavedForLaterProducts_Conduit_display' },
                    { title: 'Date Saved', field: 'SavedForLaterProducts_DateSaved', format: '{0:g}' },
                    {
                        title: ' ',
                        width: 91,
                        command: [{
                            name: 'removeFromSavedForLater', text: 'Remove', className: 'btn btn-sm btn-danger',
                            click: function (e) {
                                e.preventDefault();
                                e.stopPropagation();
                                e.stopImmediatePropagation();
                                
                                var grid = this;
                
                                $timeout(function () {
                                    var row = angular.element(e.target).closest("tr"),
                                        dataItem = grid.dataItem(row);
                
                                    removeSavedProduct(id, dataItem.id).then(function () {
                                        grid.dataSource.read();
                                    });
                                });
                            }
                        }]
                    }
                ],
                dataSource: {
                    type: 'odata',
                    serverFiltering: true,
                    serverSorting: true,
                    transport: {
                        read: {
                            url: apiPath + 'views/3437A61E95CA10A66DA643259BCA80C5-183/odata.svc',
                            dataType: 'json',
                            data: {
                                $filter: 'SavedForLaterProducts_LoanApplication eq ' + id + 'f'
                            },
                            beforeSend: function (xhr) {
                                xhr.setRequestHeader('Authentication', 'Bearer ' + $rootScope.authToken);
                                xhr.setRequestHeader('WSID', $rootScope.userData.currentWorkspace.id);
                            }
                        }
                    },
                    schema: {
                        model: {
                            id: 'SavedForLaterProducts_instance_id',
                            fields: {
                                SavedForLaterProducts_DateSaved: { type: 'date', parse: parseDate },
                                SavedForLaterProducts_Conduit_display: { type: 'string' },
                                SavedForLaterProducts_Product_display: { type: 'string' }
                            }
                        }
                    }
                }
            };
        }

        function loanDocumentVaultOptions(id) {
            return {
                columnMenu: false,
                columns: [
                    { title: 'Name', field: 'DocumentVault_Name' },
                    { title: 'Type', field: 'DocumentVault_Type_display' },
                    { title: 'Date Created', field: 'DocumentVault_sys_created_date', format: '{0:g}' },
                    { title: 'Last Modified By', field: 'DocumentVault_sys_last_modified_by', hidden: true },
                    { title: 'Last Modified Date', field: 'DocumentVault_sys_last_modified_date', hidden: true },
                    { title: 'Attachments', field: 'attachment_count', filterable: false, width: 50, headerTemplate: '<i class="fa fa-lg fa-paperclip" title="Attachments"></i>' }
                ],
                detailTemplate: '<div kendo-grid="attachmentGrid" k-options="vm.attachmentGridOptions(dataItem)"></div>',
                editable: false,
                filterable: false,
                groupable: false,
                mobile: false,
                navigatable: true,
                noRecords: true,
                pageable: true,
                reorderable: true,
                resizable: true,
                selectable: 'row',
                sortable: true,
                toolbar: false,
                dataSource: {
                    pageSize: 10,
                    schema: {
                        model: {
                            fields: {
                                DocumentVault_Name: { type: 'string' },
                                DocumentVault_Type_display: { type: 'string' },
                                DocumentVault_sys_created_date: { type: 'date', parse: parseDate },
                                DocumentVault_sys_last_modified_by: { type: 'date', parse: parseDate },
                                DocumentVault_sys_last_modified_date: { type: 'date', parse: parseDate }
                            }
                        }
                    },
                    serverFiltering: true,
                    serverGrouping: false,
                    serverPaging: true,
                    serverSorting: true,
                    transport: {
                        read: {
                            url: apiPath + 'page/objects/22DACED3D22F2B95DABF06125C9DD278-151/odata.svc',
                            dataType: 'json',
                            data: {
                                $filter: 'DocumentVault_LoanApplicationID eq ' + id + 'f and DocumentVault_Type_display ne \'Credit Report\''
                            },
                            beforeSend: function(xhr) {
                                xhr.setRequestHeader('Authentication', 'Bearer ' + $rootScope.authToken);
                                xhr.setRequestHeader('WSID', $rootScope.userData.currentWorkspace.id);
                            }
                        }
                    },
                    type: 'odata'
                },
                change: function(e) {
                    var selected = e.sender.select();
                    if (!_.isEmpty(selected)) {
                        var dataItem = e.sender.dataItem(selected[0]);

                        InstancesWindowService.openWindow({
                            action: 'read',
                            instanceId: dataItem.id
                        });
                    }
                }
            };
        }

        function attachmentGridOptions(dataItem){
                return {
                    columns: [
                        {
                            field: 'name',
                            title: 'Name'
                        },
                        {
                            field: 'uploadedBy',
                            title: 'Uploaded By'
                        },
                        {
                            field: 'uploadDate',
                            title: 'Upload Date',
                            template: function(attachment){
                                return parseDate(attachment.uploadDate);
                            }
                        }
                    ],
                    dataSource: {
                        data: dataItem.__nimAttachments,
                        pageSize: 10
                    },
                    height: 377,
                    columnMenu: false,
                    editable: false,
                    filterable: true,
                    mobile: false,
                    navigatable: true,
                    pageable: true,
                    reorderable: false,
                    resizable: true,
                    scrollable: true,
                    selectable: true,
                    sortable: true,
                    change: function(e){
                        var selected = e.sender.select();
                        if(selected.length > 0){
                            var attachment = e.sender.dataItem(selected[0]);

                            InstancesService.getDownloadKey(attachment.id).then(function (d) {
                                InstancesService.getBlob(d).then(function (pdfBlob) {
                                    $window.saveAs(pdfBlob, attachment.name);
                                });
                            });
                        }
                    }
                };
            }

        function productEligibilityTableOptions(eligibilityType, lenderData) {
            var products = [],
                filters = [];
            
            if (eligibilityType) {
                filters.push({
                    field: 'eligibility',
                    operator: 'eq',
                    value: eligibilityType
                });
            }

            _.forEach(lenderData, function (lender) {
                _.forEach(lender.programs, function (lenderProgram) {
                    _.forEach(lenderProgram.programs, function (prog) {
                        _.forEach(prog.products, function (product) {
                            var productProgramData = {
                                lenderName: lender.name,
                                lenderId: lender.id,
                                conduit: lender.conduit || {},
                                programName: lenderProgram.progName,
                                programCode: prog.programCode,
                                creditScore: prog.creditScore,
                                programId: lenderProgram.id,
                                investorName: prog.investorName,
                                docType: prog.docType,
                                grade: prog.grade,
                                conditions: prog.conditions,
                                reasons: prog.reasons,
                                adjustments: prog.adjustments
                            };

                            products.push(
                                _.assign({ rate: lowestEligibleRate(product) }, product, productProgramData)
                            );
                        });
                    });
                });
            });

            var openRows = {},
                clickHandlerSet = false;

            return {
                selectable: false,
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            contains: 'Contains',
                            startswith: 'Starts with'
                        },
                        number: {
                            lte: 'Is less than or equal to'
                        }
                    }
                },
                resizable: false,
                sortable: true,
                detailTemplate: '<table kendo-grid k-options="::vm.productRateDetailTableOptions(vm.loanApplicationId, \'#: data.id #\', vm.eligibilityType, #: kendo.stringify(data) #)"></table>',
                columns: [
                    {
                        title: 'Eligibility', width: 100, filterable: false,
                        template: function (dataItem) {
                            var labelClass = '',
                                text = '';
                            
                            switch (dataItem.eligibility){
                                case 'eligible':
                                    labelClass = 'label-success';
                                    text = 'Eligible';
                                    break;

                                case 'nearmiss':
                                    labelClass = 'label-warning';
                                    text = 'Near Miss';
                                    break;

                                case 'ineligible':
                                    labelClass = 'label-danger';
                                    text = 'Refer/Ineligible';
                                    break;
                                    
                                default:
                                    return '';
                            }

                            return '<span class="label ' + labelClass + '">' + text + '</span>';
                        }
                    },
                    { title: 'Investor', field: 'investorName', width: 120 },
                    { title: 'Program', field: 'programName', width: 120 },
                    { title: 'Product', field: 'productName', width: 160 },
                    { title: 'Doc Type', field: 'docType', width: 120, filterable: { multi: true, checkAll: false } },
                    { title: 'Rate', field: 'rate', format: '{0:n2} %', width: 80 },
                    {
                        title: 'Tags', filterable: false,
                        template: function (dataItem) {
                            var labels = [];

                            if (dataItem.prepaymentPenaltyTermMonths !== '') {
                                labels.push('<span class="label label-info">PPP ' + dataItem.prepaymentPenaltyTermMonths + 'M</span> ');
                            }
                            
                            if (dataItem.interestOnlyTerm !== '') {
                                labels.push('<span class="label label-info">IO ' + (dataItem.interestOnlyTerm / 12) + 'YR</span> ');
                            }

                            return labels.join(' ');
                        }
                    }
                ],
                dataSource: {
                    serverFiltering: false,
                    data: products,
                    sort: [
                        {
                            field: 'rate',
                            dir: 'asc'
                        }                        
                    ],
                    filter: {
                        logic: 'and',
                        filters: filters
                    },
                    schema: {
                        model: {
                            fields: {
                                conduit: { default: {} },
                                rate: { type: 'number' }
                            }
                        }
                    }
                },
                dataBound: function (e) {
                    var grid = e.sender;

                    if (!clickHandlerSet) {
                        clickHandlerSet = true;

                        grid.tbody.on('click', '.k-master-row', function (event) {
                            var uid = angular.element(event.currentTarget).data('uid');

                            if (!angular.element(event.target).is('a.k-icon')) {
                                if (openRows[uid]) {
                                    grid.collapseRow(event.currentTarget);
                                    openRows[uid] = false;
                                }
                                else {
                                    grid.expandRow(event.currentTarget);
                                    openRows[uid] = true;
                                }
                            }
                        });
                    }    

                    openRows = {};
                },
                detailExpand: function (e) {
                    var uid = e.masterRow.data('uid');
                    openRows[uid] = true;
                },
                detailCollapse: function (e) {
                    var uid = e.masterRow.data('uid');
                    openRows[uid] = false;
                }
            };
        }

        function productRateDetailTableOptions(loanAppId, productId, eligibilityType, productData) {
            // var filter = {
            //     logic: 'and',
            //     filters: []
            // };

            // if (eligibilityType) {
            //     filter.filters.push({
            //         field: 'eligibility',
            //         operator: 'eq',
            //         value: eligibilityType
            //     });
            // }

            var openFindings = function (index) {
                // Opens Findings Report
                openFindingsReportModal(loanAppId, productId, productData, index).then(function (data) {
                    // Opens modal to select product
                    openRegistrationModal(loanAppId, productId, productData, data, $location.search()).then(function () {
                        $timeout(function () {
                            $location.url('loan-applications/' + loanAppId);
                        });
                    }, function () {
                        // On cancel, reopen Findings Report
                        openFindings(productData.pricing.indexOf(data));
                    });
                });
            };

            return {
                filterable: false,
                resizable: false,
                sortable: true,
                columns: [
                    {
                        // title: 'Eligibility', width: 80,
                        title: ' ', width: 31, attributes: { 'class': 'text-center' },
                        template: function (dataItem) {
                            // var labelClass = '',
                            //     text = '';
                            
                            var iconClass = '';
                            
                            switch (dataItem.eligibility){
                                case 'eligible':
                                    // labelClass = 'label-success';
                                    // text = 'Eligible';
                                    iconClass = 'text-success';
                                    break;

                                case 'nearmiss':
                                    // labelClass = 'label-warning';
                                    // text = 'Near Miss';
                                    iconClass = 'text-warning';
                                    break;

                                case 'ineligible':
                                    // labelClass = 'label-danger';
                                    // text = 'Refer/Ineligible';
                                    iconClass = 'text-danger';
                                    break;
                                    
                                default:
                                    return '';
                            }

                            // return '<span class="label ' + labelClass + '">' + text + '</span>';
                            return '<i class="fa fa-circle ' + iconClass + '"></i>';
                        }
                    },
                    { title: 'Rate', field: 'noteRate', format: '{0:p3}', width: 80 },
                    // { title: 'Price', field: 'lenderPrice', format: '{0:n2}', width: 80 },
                    // { title: 'Premium', field: 'premium', format: '{0:n2}', width: 100 },
                    // { title: 'Discount Points / $', template: '#: discount # / #: kendo.toString(buyUpDown, "c0") #' },
                    { title: 'Discount Points', template: _.partial(discountTemplate, 'discount', '{0}'), width: 110 },
                    { title: 'Discount Cost', template: _.partial(discountTemplate, 'buyUpDown', '{0:c0}') },
                    { title: 'P & I', field: 'payment', format: '{0:c0}', width: 80 },
                    { title: 'PITIA', field: 'pitia', format: '{0:c0}', width: 80 },
                    { title: 'DTI', field: 'dti', format: '{0:p2}', width: 80 },
                    {
                        title: ' ',
                        width: 197,
                        command: [
                            {
                                name: 'detailsButton', text: 'Findings', className: 'btn btn-sm k-primary',
                                click: function (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.stopImmediatePropagation();

                                    var grid = this,
                                        row = angular.element(e.target).closest("tr"),
                                        pricingData = grid.dataItem(row);
                
                                    if (productId) {
                                        $timeout(function () {
                                            var index = grid.dataSource.indexOf(pricingData);
                                            openFindings(index);
                                        });
                                    }    
                                }
                            },
                            {
                                name: 'registerButton', text: 'Select Product', className: 'btn btn-sm k-primary',
                                click: function (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.stopImmediatePropagation();

                                    var grid = this,
                                        row = angular.element(e.target).closest("tr"),
                                        pricingData = grid.dataItem(row);
                                        
                                    if (productId) {
                                        openRegistrationModal(loanAppId, productId, productData, pricingData, $location.search()).then(function () {
                                            $timeout(function () {
                                                $location.url('loan-applications/' + loanAppId);
                                            });
                                        });
                                    }        
                                }
                            }//,
                            // {
                            //     name: 'saveForLaterButton', text: 'Save', className: 'btn btn-sm k-default',
                            //     click: function (e) {
                            //         e.preventDefault();
                            //         e.stopPropagation();
                            //         e.stopImmediatePropagation();
                
                            //         if (productId) {
                            //             $timeout(function () {
                            //                 saveProductForLater(loanAppId, {
                            //                     product: {
                            //                         id: productId,
                            //                         display: productData.productName
                            //                     },
                            //                     conduit: productData.conduit || {}
                            //                 });
                            //             });
                            //         }    
                            //     }
                            // }
                        ]
                    }
                ],
                dataSource: {
                    data: productData.pricing,
                    // filter: filter,
                    sort: [{
                        field: 'noteRate',
                        dir: 'asc'
                    }]
                }
            };
        }

        function lowestEligibleRate(dataItem) {
            var eligibilities = _.groupBy(dataItem.pricing, 'eligibility');

            var pricing = [];
            if (eligibilities['eligible']) {
                pricing = eligibilities['eligible'];
            }
            else if (eligibilities['nearmiss']) {
                pricing = eligibilities['nearmiss'];
            }
            else if (eligibilities['ineligible']) {
                pricing = eligibilities['ineligible'];
            }

            var minimum = _.min(pricing, 'noteRate');
            if (minimum) {
                return minimum.noteRate * 100;
            }

            return '';
        }

        function assetsTableOptions(assets) {
            var data = [],
                assetMap = [
                    { key: 'downPayment', name: 'Down Payment' },
                    { key: 'prepaid', name: 'Prepaid' },
                    { key: 'buyDown', name: 'Buydown' },
                    { key: 'closingCosts', name: 'Closing' }/*,
                    { key: 'reserves', name: 'Reserves' }*/
                ];

            _.forEach(assetMap, function(mapItem) {
                var asset = assets[mapItem.key];
                if (asset) {
                    data.push({
                        name: mapItem.name,
                        required: asset.required,
                        total: asset.total,
                        need: asset.need,
                        assets: asset.assets
                    });
                }    
            });
            // var rowTemplate = '#if(assets.length > 0){# <td class="k-hierarchy-cell"><a class="k-icon k-plus" href="#" tabindex="-1"></a></td> #} else {# <td></td> #}#' +
            //     '<td>#: name #</td>' +
            //     '<td>#: kendo.toString(required, \'c2\') #</td>' +
            //     '<td>#: kendo.toString(total, \'c2\') #</td>' +
            //     '<td>#: kendo.toString(need, \'c2\') #</td></tr>';
            // var detailTemplate = '#if(assets.length > 0){# <tr class="k-detail-row"></tr> #}#';

            return {
                filterable: false,
                groupable: false,
                resizable: true,
                sortable: false,
                // rowTemplate: '<tr class="k-master-row">' + rowTemplate,
                // altRowTemplate: '<tr class="k-alt k-master-row">' + rowTemplate,
                detailTemplate: '<table kendo-grid k-options="::vm.assetDetailsTableOptions(#: kendo.stringify(data) #)"></table>',
                columns: [
                    { title: ' ', field: 'name' },
                    { title: 'Required', field: 'required', format: '{0:c2}', footerTemplate: '#: kendo.toString(data.required.sum, \'c2\') #' },
                    { title: 'Available', field: 'total', format: '{0:c2}', footerTemplate: '#: kendo.toString(data.total.sum, \'c2\') #' },
                    { title: 'Need', field: 'need', format: '{0:c2}', footerTemplate: '#: kendo.toString(data.need.sum, \'c2\') #' }
                ],
                dataSource: {
                    data: data,
                    aggregate: [
                        { field: 'required', aggregate: 'sum' },
                        { field: 'total', aggregate: 'sum' },
                        { field: 'need', aggregate: 'sum' }
                    ]
                }
            };
        }

        function assetDetailsTableOptions(asset) {
            return {
                filterable: false,
                groupable: false,
                resizable: true,
                sortable: false,
                columns: [
                    { title: 'Type', field: 'name' },
                    { title: 'Available', field: 'total', format: '{0:c2}' }
                ],
                dataSource: {
                    data: asset.assets
                }
            };
         }

        function eligibilityFilterLookupOptions(data) {
            return {
                optionLabel: 'Choose...',
                valuePrimitive: true,
                dataTextField: 'display',
                dataValueField: 'value',
                dataSource: {
                    data: data || []
                }
            };
        }

        function getFilterOptions(lenders) {
            var docTypes = [],
                amortizationTypes = [];

            _.forEach(lenders, function (lender) {
                _.forEach(lender.programs, function (program) {
                    _.forEach(program.programs, function (doc) {
                        docTypes.push({
                            display: doc.docType,
                            value: doc.docType
                        });

                        _.forEach(doc.products, function (product) {
                            var filterValue = {
                                display: product.loanAmortizationType,
                                value: product.loanAmortizationType
                            };

                            if(filterValue.value == 'Adjustable Rate'){
                                filterValue.display = 'ARM';
                            }

                            amortizationTypes.push(filterValue);
                        });
                    });
                });
            });

            docTypes = _.sortBy(docTypes, 'display');
            amortizationTypes = _.sortBy(amortizationTypes, 'display');

            /* LODASH MIGRATION HELPER FUNCTION */
            /* LODASH Version 3 & 4 Compatibility Mode */
            if ((_.VERSION).charAt(0) <= 3){ //Detect LODASH version 3 or 4.
                //V3 or lower
                docTypes = _.uniq(docTypes, true, 'value');
                amortizationTypes = _.uniq(amortizationTypes, true, 'value');
            } else {
                /* LODASH Version 4 ONLY */
                //V4 or Higher
                docTypes = _.uniqBy(docTypes, 'value'); // ++ AFTER MIGRATION TO V4 remove helper function with all V3 detect options ++
                amortizationTypes = _.uniqBy(amortizationTypes, 'value'); // ++ AFTER MIGRATION TO V4 remove helper function with all V3 detect options ++
            }

            return {
                docTypes: docTypes,
                amortizationTypes: amortizationTypes
            };
        }

        function openIncompleteLoanApplication(incompleteDataSet, navFunctions) {
            var defaultData = _.map(incompleteDataSet, function (value, modelId) {
                return {
                    data: {
                        value: value
                    },
                    field: {
                        modelId: modelId
                    }
                };
            });
            
            InstancesWindowService.openWindow({
                action: 'create',
                widgetId: 'CDBD77360CD343F5508B1465EDA43380-108',
                defaultData: defaultData,
                validateOnOpen: true,
                navFunctions: navFunctions
            });
        }

        function addDocumentToVault(id) {
            InstancesWindowService.openWindow({
                action: 'create',
                widgetId: 'D84650CD65706FE7A37261002D87D6E6-105',
                defaultData: [{
                    data: {
                        value: {
                            display: id,
                            id: id
                        }
                    },
                    field: {
                        modelId: 'LoanApplicationID'
                    }
                }]
            });
        }

        function buildProductsQueryParams(queryFormData) {
            var amortizations = [],
                ppp = [],
                docTypes = [];
            
            if (queryFormData.arm) {
                amortizations.push('arm');
            }
            if (queryFormData.fixed) {
                amortizations.push('fixed');
            }
            if (queryFormData.interestOnly) {
                amortizations.push('interestonly');
            }

            if (queryFormData.noPrepaymentPenalty) {
                ppp.push('no');
            }
            if (queryFormData['12monthPrepaymentPenalty']) {
                ppp.push('12');
            }
            if (queryFormData['24monthPrepaymentPenalty']) {
                ppp.push('24');
            }
            if (queryFormData['36monthPrepaymentPenalty']) {
                ppp.push('36');
            }
            
            if (queryFormData.fullDocumentation) {
                docTypes.push('Full Document');
            }
            if (queryFormData['12monthBankStatements']) {
                docTypes.push('12M Bank Statements');
            }
            if (queryFormData['24monthBankStatements']) {
                docTypes.push('24M Bank Statements');
            }
            if (queryFormData.assetUtilization) {
                docTypes.push('Asset Utilization');
            }
            if (queryFormData.debtServiceCoverage) {
                docTypes.push('Debt Service Coverage');
            }

            return {
                amortizations: amortizations.join(','),
                ppp: ppp.join(','),
                docTypes: docTypes.join(',')
            };
        }

        function parseProductsQueryParams(queryParams) {
            var queryDefaultData = {
                arm: false,
                fixed: false,
                interestOnly: false,
                noPrepaymentPenalty: false,
                '12monthPrepaymentPenalty': false,
                '24monthPrepaymentPenalty': false,
                '36monthPrepaymentPenalty': false,
                fullDocumentation: false,
                '12monthBankStatements': false,
                '24monthBankStatements': false,
                assetUtilization: false,
                debtServiceCoverage: false
            };
            
            if (queryParams.amortizations) {
                var amortizations = queryParams.amortizations.split(',');
                _.forEach(amortizations, function (val) {
                    if (val == 'arm') {
                        queryDefaultData.arm = true;
                    }
                    else if (val == 'fixed') {
                        queryDefaultData.fixed = true;
                    }
                    else if (val == 'interestonly') {
                        queryDefaultData.interestOnly = true;
                    }
                });
            }
            
            if (queryParams.ppp) {
                var ppp = queryParams.ppp.split(',');
                _.forEach(ppp, function (val) {
                    if (val == 'no') {
                        queryDefaultData.noPrepaymentPenalty = true;
                    }
                    else if (val == '12') {
                        queryDefaultData['12monthPrepaymentPenalty'] = true;
                    }
                    else if (val == '24') {
                        queryDefaultData['24monthPrepaymentPenalty'] = true;
                    }
                    else if (val == '36') {
                        queryDefaultData['36monthPrepaymentPenalty'] = true;
                    }
                });
            }

            if (queryParams.docTypes) {
                var docTypes = queryParams.docTypes.split(',');
                _.forEach(docTypes, function (val) {
                    if (val == 'Full Document') {
                        queryDefaultData.fullDocumentation = true;
                    }
                    else if (val == '12M Bank Statements') {
                        queryDefaultData['12monthBankStatements'] = true;
                    }
                    else if (val == '24M Bank Statements') {
                        queryDefaultData['24monthBankStatements'] = true;
                    }
                    else if (val == 'Asset Utilization') {
                        queryDefaultData['assetUtilization'] = true;
                    }
                    else if (val == 'Debt Service Coverage') {
                        queryDefaultData['debtServiceCoverage'] = true;
                    }
                });
            }

            return queryDefaultData;
        }

        // Helpers
        function parseDate(isoString) {
            var momentObj = moment.utc(isoString, 'MMMM, DD YYYY HH:mm:ss');
            momentObj.local();
            return momentObj.toDate();
        }

        function resolveOData(deferred, oData) {
            if (oData.d.__count !== 1) {
                deferred.resolve(oData.d.results);
            }
            else {
                deferred.resolve([_.omit(oData.d, '__count')]);
            }
        }

        function discountTemplate(field, format, dataItem) {
            var value = dataItem[field],
                formattedValue = kendo.format(format, value);

            if (value < 0) {
                return '<span class="text-bold">' + formattedValue + '</span>';
            }

            return formattedValue;
        }
    })
;