angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name PagesClassService
     * @alias cerberus/core:PagesClassService
     * @description
     *   Constructs PageObject class
     */
    .factory('PagesClassService', function PagesClassService(WrapperClassService, VizClassService) {
        /**
         * Constructs a new tabset
         * @class Tab
         * @classdesc for use with a tabset
         * @memberOf PagesClassService
         * @param {string} icon
         * @param {string} text
         * @param {boolean} active
         * @param {boolean} disabled
         * @param {string} link
         */
        function Tab(icon, text, active, disabled, link) {
            this.icon = icon;
            this.text = text;
            this.active = active;
            this.disabled = disabled;
            this.link = link;
        }

        /**
         * Constructs a new PageObject
         * @class PageObject
         * @classdesc describes a PageObject
         * @memberOf PagesClassService
         * @param {string} id
         * @param {string} type
         * @param {string} subType
         * @param {Object} tools - Actions or Commands that are allowed by the page object
         * @param {Object} filterMap - Maps page filters to adjust the query
         * @param {Wrapper} wrapper - the wrapper that transcludes the viz
         * @param {Viz} viz - the visualization (chart, table, etc)
         * @param {Object} params - additional parameters for specific subtypes
         */
        function PageObject(id, type, subType, tools, filterMap, wrapper, viz, params) {
            this.id = id;
            this.type = type;
            this.subType = subType;
            this.tools = tools;
            this.filterMap = filterMap;
            this.wrapper = wrapper;
            this.viz = viz;
            this.params = params;
            this.name = '';
            this.sizeX = 6;
            this.sizeY = 7;
        }

        /**
         * setData method - revive/extend json objects
         * @memberOf PageObject
         */
        PageObject.prototype.setData = function (pageObject) {
            angular.extend(this, pageObject);
        };

        return {
            pageObject: PageObject,
            newPageObject: newPageObject,
            tab: tab,
            tabset: tabset,
            button: button
        };
        ////////////////////
        /**
         * Builds a empty page object
         * @function newPageObject
         * @returns {PageObject}
         */
        function newPageObject() {
            return new PageObject(
                null,
                null,
                null,
                {},
                {},
                WrapperClassService.newWrapper(),
                VizClassService.newViz(),
                {}
            );
        }

        /**
         * Builds a empty view control button
         * @function tab
         * @returns {Tab}
         */
        function tab() {
            return new Tab(null, null, false, false, null);
        }

        /**
         * Builds a empty view control page object
         * @function tabset
         * @returns {PageObject}
         */
        function tabset() {
            return new PageObject(
                'tabset',
                'tabset',
                null,
                {},
                {},
                WrapperClassService.newWrapper(),
                VizClassService.newViz(),
                {
                    type: 'tabs',
                    vertical: false,
                    justified: false,
                    tabs: [
                        this.tab()
                    ]
                }
            );
        }

        function button() {
            return new PageObject(
                'button',
                'button',
                null,
                {},
                {},
                WrapperClassService.newWrapper(),
                VizClassService.newViz(),
                {
                    buttons: []
                    //icon: '',
                    //label: '',
                    //action: 'route',    // 'route' | 'create' | 'link'
                    //targetId: '',       // ID of widget or page
                    //type: 'single',     //  'single' | 'split' | 'dropdown'
                    //style: 'default',   // 'danger' | 'primary' | 'info' | 'warning' | 'default'
                    //items: []
                }
            );
        }
    })
;