angular.module('cerberus.core')
    .config(['$routeProvider',
        function ($routeProvider) {
            $routeProvider.when('/search', {
                templateUrl: 'core/search/search.tpl.html',
                controller: 'SearchCtrl',
                controllerAs: 'vm',
                title: 'Search',
                reloadOnSearch: false,
                resolve: {
                    _wait: 'WaitForUserData'
                }
            });
        }
    ])
;