angular.module('cerberus.core')
    .controller('MenusWorkspacesCtrl', function MenusWorkspacesCtrl($scope, $location, $uibModalInstance, userOrgs, OrganizationsService) {
        $scope.orgs = [];
        $scope.filter = '';
        $scope.setFilter = function (value) {
            $scope.filter = value;
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };

        init();

        function init(){
            if(!userOrgs) {
                OrganizationsService.getUserOrganizations().then(function (userOrgs) {
                    $scope.orgs = userOrgs;
                    $scope.filter = getCurrentOrg(userOrgs);
                });
            }
            else {
                $scope.orgs = userOrgs;
                $scope.filter = getCurrentOrg(userOrgs);
            }
        }

        function getCurrentOrg(userOrgs) {
            var host = $location.host();
            var org = '';
            var isOrgValid = false;
            host = host.split('.');
            if (host.length > 2) {
                host.pop();
                host.pop();
                org = host.toString().replace(',', '.');
            }
            for (var i = 0; i < userOrgs.length; i++) {
                if (userOrgs[i].sub_domain === org) {
                    isOrgValid = true;
                    break;
                }
            }
            return isOrgValid ? org : '';
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;