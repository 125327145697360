angular.module('cerberus.admin')
    .controller('ViewsDesignerNormalCtrl', function ViewsDesignerNormalCtrl($scope, ViewsService, DesignerUtilityService, ViewsPropertiesService, ViewsDesignerService) {
        var vm = this;
        $scope.viewDesignerNormalCtrl.vm = vm;
        vm.viewModelOrig  = {};
        vm.viewModel  = {};
        vm.tempViewProp = [];
        vm.origViewProp = [];
        vm.joinTypes = ViewsDesignerService.getJoinTypes();
        vm.editPropIndex = null;
        vm.editProp = null;
        vm.custom = {};
        vm.agg = {};
        vm.simpleAggFunctions = ViewsDesignerService.simpleAggFunctions();
        vm.tempJoin = {
            type: 'INNER',
            leftCol:{},
            rightCol:{},
            operator:'='
        };

        //Property and customer column functions
        vm.preventSpace = DesignerUtilityService.preventSpace;
        vm.addProperty = function(data,adv,type){
            var modeledData = ViewsPropertiesService.modelData(data,adv,type);
            if(modeledData.isValid){
                ViewsPropertiesService.add($scope.viewId,modeledData.d).then(function(id){
                    modeledData.d.id = id;
                    vm.tempViewProp.push(modeledData.d);
                    data.name = null;
                    data.description = null;
                    data.adv = null;
                    $scope.save();
                });
            }

        };

        vm.delProperty = function(property){
            var id = property.id;
            ViewsPropertiesService.remove(id).then(function(){
                var index = vm.tempViewProp.indexOf(property);
                vm.tempViewProp.splice(index,1);
                $scope.save();
            });
        };

        vm.editProperty = function(index, property){
            vm.editPropIndex = index;
            vm.editProp = angular.copy(property);
        };

        vm.cancelProperty = function(){
            vm.editProp = null;
            vm.editPropIndex = null;
        };

        vm.updateProperty = function(prop, data){
            if(data.name && (data.property.adv || data.property.function && data.property.field)){
                ViewsPropertiesService.update(prop.id, data).then(function(){
                    angular.extend(prop, data);
                    $scope.save();
                    vm.cancelProperty();
                });
            }
        };
        //Widgets and join functions
        vm.addJoinArray = function(joinObj){
            var d = {
                type: joinObj.type,
                operator: joinObj.operator,
                leftCol:{
                    id: joinObj.leftCol.id,
                    uniqueName: joinObj.leftCol.uniqueName,
                    modelId: joinObj.leftCol.modelId,
                    isLookUp: joinObj.leftCol.isLookUp
                },
                rightCol:{
                    id: joinObj.rightCol.id,
                    uniqueName: joinObj.rightCol.uniqueName,
                    modelId: joinObj.rightCol.modelId || 'instance_id',
                    isLookUp: joinObj.rightCol.isLookUp || false
                }
            };
            vm.viewModel.model.joinArray.push(d);
        };

        vm.delJoinArr = function(index){
          vm.viewModel.model.joinArray.splice(index,1);
        };

        vm.modifySelectArray = ViewsDesignerService.modifySelectArray;

        vm.selectAll = ViewsDesignerService.selectAll;

        vm.setBaseWidget = function(index){
            var widget = vm.viewModel.model.tableArray[index];
            vm.viewModel.baseWidget = widget.uniqueName;
        };

        vm.removeWidget = function(index){
            var widget = vm.viewModel.model.tableArray[index];
            for(var i = 0; i < vm.viewModel.model.selectArray.length; i++){
                if (vm.viewModel.model.selectArray[i].id === widget.id) {
                    vm.viewModel.model.selectArray.splice(i, 1);
                    i = i - 1;
                }
            }
            vm.viewModel.model.tableArray.splice(index,1);
            if(widget.uniqueName === vm.viewModel.baseWidget){
                if(vm.viewModel.model.tableArray.length >0){
                    vm.setBaseWidget(0);
                }
                else{
                    vm.viewModel.baseWidget = null;
                }
            }
        };
        //Init function
        _init();
        ////////////////////
        /**
         * Init controller data and server calls
         */
        function _init() {
            ViewsDesignerService.getModel($scope.viewId).then(function(d){
               vm.viewModel = d;
               vm.viewModelOrig = angular.copy(vm.viewModel);
                ViewsDesignerService.removeOldFields(d.model);
            });

            ViewsPropertiesService.get($scope.viewId).then(function(d){
                vm.tempViewProp = d;
                vm.origViewProp = angular.copy(vm.tempViewProp);
            });
        }
    })
;