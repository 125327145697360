angular.module('cerberus.ui')
    /**
     * --------------------------------------------------------------------------------------------
     * @TODO: ADDED BY DHZ ON JAN/11/2019  -  NEED FIX ON "DATE FILTER"
     * @TODO: FIX "Equal to" & "Not equal to" should become range (full day not only 00:00:00), 
     * @TODO: FIX "After" to look at 23:59:59 greater than, (NO RANGE).
     * @TODO: FIX "Before or equal" to look at 23:59:59 less or equal than, (NO RANGE).
     * @TODO: CAN BE FOUND IN OPERATORS AND Functions: simpleFilter & readSimpleFilter similar to readNumberFilter and NumberFilter with "case" options
     * --------------------------------------------------------------------------------------------
     * @ngdoc service
     * @name OdataUtilityService
     * @alias cerberus/ui:OdataUtilityService
     * @description Assists in the creation of Odata filters
     */
    .factory('OdataUtilityService', function OdataUtilityService(kendo, moment) {
        // Declare oData filters to allow for easier upgrades to future specs
        var odataFilters = {
            eq: "eq",
            neq: "ne",
            gt: "gt",
            gte: "ge",
            lt: "lt",
            lte: "le",
            contains : "substringof",
            doesnotcontain: "substringof",
            endswith: "endswith",
            startswith: "startswith",
            isnull: "isnull",
            isnotnull: "isnotnull"
        };

        return {
            toOdataFilter: toOdataFilter,
            toOdataString: toOdataString,
            operators: operators,
            messages: messages,
            operatorSymbols: operatorSymbols
        };

        ////////////////////
        /**
         * @function messages
         * @return {object} of ui messages
         */
        function messages() {
            return {
                info: "Show items that:",
                isTrue: "is true",
                isFalse: "is false",
                filter: "Filter",
                clear: "Clear",
                and: "And",
                or: "Or",
                selectValue: "-Select value-",
                operator: "Operator",
                value: "Value",
                cancel: "Cancel"
            };
        }
        /**
         * @function operatorSymbols
         * @return {object} of Odata Operator symbols by type
         */
        function operatorSymbols() {
            return {
                between: 'From',
                notBetween: 'Not from',
                eq: '=',
                neq: '!=',
                gte: ">=",
                gt: ">",
                lte: "<=",
                lt: "<",
                startswith: 'Start with:',
                contains: '&sub;',
                doesnotcontain: '&nsub;',
                endswith: 'End with:'
            };
        }
        /**
         * @function operators
         * @return {object} of Odata Operators by type
         */
        function operators() {
            var EQ = "Equal to";
            var NEQ = "Not equal to";
            return {
                date: {
                    eq: EQ,
                    neq: NEQ,
                    gte: "After or equal to",
                    gt: "After",
                    lte: "Before or equal to",
                    lt: "Before"
                },
                dateRange: {
                    today: 'Today',
                     yesterday: 'Yesterday',
                     tomorrow: 'Tomorrow',
                    thisWeek: 'This Week',
                     lastWeek: 'Last Week',
                     nextWeek: 'Next Week',
                    thisMonth: 'This Month',
                     thisMtD: 'This Month-to-date',
                     lastMonth: 'Last Month',
                     lastMtD: 'Last Month-to-date',
                     nextMonth: 'Next Month',
                    thisQter: 'This Quarter',
                     thisQtD: 'This Quarter-to-date',
                     lastQter: 'Last Quarter',
                     lastQtD: 'Last Quarter-to-date',
                     nextQter: 'Next Quarter',
                    thisYear: 'This Year',
                     thisYtD: 'This Year-to-date',
                     lastYear: 'Last Year',
                     lastYtD: 'Last Year-to-date',
                     nextYear: 'Next Year'
                },
                number: {
                    between: 'Between or equal to',
                    notBetween: 'Not Between or equal to',
                    eq: EQ,
                    neq: NEQ,
                    gte: "Greater or equal to",
                    gt: "Greater",
                    lte: "Less or equal to",
                    lt: "Less"
                },
                string: {
                    eq: EQ,
                    neq: NEQ,
                    startswith: "Start with",
                    contains: "Contain",
                    doesnotcontain: "Does not contain",
                    endswith: "End with"
                },
                enums: {
                    eq: EQ,
                    neq: NEQ
                },
                lookup: {
                    eq: EQ,
                    neq: NEQ
                }
            };
        }
        /**
         * @function toOdataFilter
         * @param {object} filter - an object with javascript values
         * @return {object} with values in the proper string Odata format
         */
        function toOdataFilter(filter) {
            var result = [],
                logic = filter.logic || "and",
                idx,
                length,
                field,
                type,
                format,
                operator,
                value,
                // ignoreCase,
                filters = filter.filters,
                isColumnValue;

            for (idx = 0, length = filters.length; idx < length; idx++) {
                filter = filters[idx];
                field = filter.field;
                value = filter.value;
                operator = filter.operator;
                isColumnValue = filter.isColumnValue;

                if (filter.filters) {
                    filter = toOdataFilter(filter);
                } else if(field) {
                    // ignoreCase = filter.ignoreCase;
                    field = field.replace(/\./g, "/");
                    filter = odataFilters[operator];

                    if(filter === "isnull"){
                        filter = kendo.format("{0} eq null", field);
                    }
                    else if(filter === "isnotnull"){
                        filter = kendo.format("{0} ne null", field);
                    }
                    else if (filter && value !== undefined) {
                        type = $.type(value);
                        if (type === "string" && !isColumnValue && value !== 'null') {
                            var parsedDateValue;
                            if (value === '@nim_now') {
                                parsedDateValue = new Date();
                            }
                            else if (
                                (value === '@nim_today') ||
                                (value === '@nim_7days') ||
                                (value === '@nim_30days') ||
                                (value === '@nim_60days') ||
                                (value === '@nim_90days') ||
                                (value === '@nim_180days') ||
                                (value === '@nim_365days') ||
                                (value === '@nim_currYear') ||
                                (value === '@nim_lastYear')
                            ) {
                                result.push(
                                    momentTimeFilter(field, filter, value)
                                );
                                continue;
                            }
                            else {
                                // replaced by HD 2019-07-03
                                // parsedDateValue = kendo.parseDate(value, ['yyyy-MM-ddTHH:mm:ss', 'yyyy-MM-ddTHH:mm:ss.fffZ']);
                                parsedDateValue = kendo.parseDate(value, ['yyyy-MM-ddTHH:mm:ssz', 'yyyy-MM-ddTHH:mm:ss.fffz'], 'yyyy-MM-ddTHH:mm:sszzz');
                            }

                            if(parsedDateValue instanceof Date){
                                value = parsedDateValue.toISOString();
                                format = "datetime'{1}'";
                            }
                            else {
                                format = "'{1}'";
                                value = value.replace(/'/g, "''");

                                // if (ignoreCase === true) {
                                //     field = "tolower(" + field + ")";
                                // }
                            }

                        } else if (type === "date") {
                            value = value.toISOString();
                            format = "datetime'{1}'";
                        } else {
                            format = "{1}";

                            if(type === 'number'){
                                value += 'f';
                            }
                        }

                        if (filter.length > 3) {
                            if (filter !== "substringof") {
                                format = "{0}({2}," + format + ")";
                            } else {
                                format = "{0}(" + format + ",{2})";
                                if (operator === "doesnotcontain") {
                                    format += " eq false";
                                }
                            }
                        } else {
                            format = "{2} {0} " + format;
                        }
                        filter = kendo.format(format, filter, value, field);
                    }
                }

                result.push(filter);
            }

            filter = result.join(" " + logic + " ");

            //if (result.length > 1) {
                filter = "(" + filter + ")";
            //}

            return filter;
        }
        /**
         * @function momentTimeFilter
         * @param {string} field - a string with javascript values from data source column
         * @param {string} operator - a string with javascript values from filter operator
         * @param {string} value - a string with javascript values from filter column
                                (value === '@nim_today') ||
                                (value === '@nim_7days') ||
                                (value === '@nim_30days') ||
                                (value === '@nim_60days') ||
                                (value === '@nim_90days') ||
                                (value === '@nim_180days') ||
                                (value === '@nim_365days') ||
                                (value === '@nim_currYear') ||
                                (value === '@nim_lastYear')
         * @return {string} with values in the proper Kendo datetime format
         */
        function momentTimeFilter(field, operator, value) {
            var format, startOfDay, endOfDay;

            switch (value) {
                case '@nim_7days':
                    startOfDay = moment().subtract(7, 'days').startOf('day').toISOString();
                    endOfDay = moment().endOf('day').toISOString();
                    break;

                case '@nim_30days':
                    startOfDay = moment().subtract(30, 'days').startOf('day').toISOString();
                    endOfDay = moment().endOf('day').toISOString();
                    break;

                case '@nim_60days':
                    startOfDay = moment().subtract(60, 'days').startOf('day').toISOString();
                    endOfDay = moment().endOf('day').toISOString();
                    break;

                case '@nim_90days':
                    startOfDay = moment().subtract(90, 'days').startOf('day').toISOString();
                    endOfDay = moment().endOf('day').toISOString();
                    break;

                case '@nim_180days':
                    startOfDay = moment().subtract(180, 'days').startOf('day').toISOString();
                    endOfDay = moment().endOf('day').toISOString();
                    break;

                case '@nim_365days':
                    startOfDay = moment().subtract(365, 'days').startOf('day').toISOString();
                    endOfDay = moment().endOf('day').toISOString();
                    break;

                case '@nim_currYear':
                    startOfDay = moment().startOf('year').toISOString();
                    endOfDay = moment().endOf('day').toISOString();
                    break;

                case '@nim_lastYear':
                    startOfDay = moment().subtract(1, 'year').startOf('year').toISOString();
                    endOfDay = moment().endOf('day').toISOString();
                    break;

                default: // @nim_today
                    startOfDay = moment().startOf('day').toISOString();
                    endOfDay = moment().endOf('day').toISOString();
            }

            switch (operator) {
                case 'ne':
                    format = '({0} lt datetime\'{1}\' or {0} gt datetime\'{2}\')';
                    break;

                case 'ge':
                    format = '{0} ge datetime\'{1}\'';
                    break;

                case 'gt':
                    if (value === '@nim_today') {
                        format = '{0} gt datetime\'{2}\''; // [greater than] - 'end of Today'; (not including current day).
                    } else {
                        format = '{0} gt datetime\'{1}\'';
                    }
                    break;

                case 'le':
                    if (value === '@nim_today') {
                        format = '{0} le datetime\'{2}\''; // [less or equal] - 'end of Today'; (including current day).
                    } else {
                        format = '{0} le datetime\'{1}\'';
                    }
                    break;

                case 'lt':
                    format = '{0} lt datetime\'{1}\'';
                    break;

                default: // eq
                    format = '({0} ge datetime\'{1}\' and {0} le datetime\'{2}\')';
            }

            return kendo.format(format, field, startOfDay, endOfDay);
        }
        /**
         * @function toOdataString
         * @param {object} operator - Odata operator
         * @param {Array} values - left and values for operator comparison
         * @param {object} field - field name for filtering by
         * @return {string} URL parameter for oData
         */
        function toOdataString(operator, values, field) {
            values = values.map(function(val){
                if(typeof val === 'number'){
                    return val + 'f';
                }
            });

            var filter = {
                logic: 'and',
                filters: [
                    {
                        field: field,
                        value: values[0],
                        operator: operator
                    }
                ]
            };
            if (operator === 'between') {
                filter.filters[0].operator = 'gte';
                filter.filters[1] = {
                    field: field,
                    value: values[1],
                    operator: 'lte'
                };
            }
            if (operator === 'notBetween') {
                filter.filters[0].operator = 'lte';
                filter.filters[1] = {
                    field: field,
                    value: values[1],
                    operator: 'gte'
                };
            }
            return filter;
        }
    })
;