angular.module('cerberus.core')
    .controller('nimVizScheduleCtrl', function(_, moment, ics, kendo, $scope, $rootScope, $log, $timeout, $window, $element, $firebaseObject, fbUtil, nimUtilityService, VizScheduleService) {
        var vm = this;

        $scope.mainPageObject.vizCtrl = vm;
        $scope.dragging = false;

        vm.selectedEvents = [];
        vm.selectedIds = [];
        vm.exportICS = exportICS;

        //Firebase
        var initObj = {},
            fbUnwatchers = [],
            templates = $scope.pageObject.params.eventTemplates || {},
            navFunctions = {
                getNext: getNext,
                getPrevious: getPrevious
            };

        init();

        // Event Bindings
        var removeHeightWatcher = angular.noop;
        var removeWidthWatcher = angular.noop;
        $scope.$on("kendoWidgetCreated", function (e, widget) {
            e.preventDefault();
            e.stopPropagation();
            // $scope.dataSource.read();

            // Re-render widget when size changes
            // Necessary to prevent bug when selecting dates
            var resizeTimeout;
            removeHeightWatcher = $scope.$watch(function () {
                return widget.element.height();
            }, function (size, oldSize) {
                if (size != oldSize || !resizeTimeout) {
                    if (resizeTimeout) {
                        $timeout.cancel(resizeTimeout);
                    }
                    // Refreshes
                    resizeTimeout = $timeout(function () {
                        // refresh(widget);
                        resizeGridster();
                    }, 400);
                }
            });
            removeWidthWatcher = $scope.$watch(function () {
                return widget.element.width();
            }, function (size, oldSize) {
                if (size != oldSize) {
                    widget.resize();
                }
            });
            
            widget.dataSource.read();
        });

        $scope.$on('nim-viz-update-data', function(e, action, id){
            if (action === 'delete') {
                _.forEach(id, function (instanceId) {
                    var dataItem = $scope.dataSource.get(instanceId);

                    if(dataItem) {
                        $scope.dataSource.remove(dataItem);
                    }
                });
                
                vm.selectedIds = [];
                vm.selectedEvents = [];
            }
        });

        $scope.$on('$destroy', function () {
            delete navFunctions.getNext;
            delete navFunctions.getPrevious;

            _.forEach(fbUnwatchers, function (unwatchFb) {
                unwatchFb();
            });
        });

        $scope.$on('nim-remove-page-objects', function () {
            if ($scope.schedule) {
                $scope.schedule.destroy();
                $scope.schedule = null;
            }

            removeHeightWatcher();
        });

        function init() {
            // Firebase watchers
            _.forEach($scope.pageObject.viz.settings.dataSource, function (source, pgDs) {
                initObj[pgDs] = true;

                var unwatchFb = $firebaseObject(fbUtil.ref('queries/' + source.nim_viewId)).$watch(firebaseRefresh(pgDs));
                fbUnwatchers.push(unwatchFb);

                if (!templates[pgDs]) {
                    templates[pgDs] = '#: data.title #';
                }
            });

            // Data Source
            $scope.dataSource = VizScheduleService.buildDataSource($scope, $scope.pageObject);
            
            // Scheduler options
            var options = angular.copy($scope.pageObject.viz.settings);
            _.assign(options, {
                autoBind: false,
                dataSource: $scope.dataSource,
                editable: {
                    confirmation: false,
                    create: _.get($scope.pageObject.params, 'createOnClick', true),
                    destroy: false,
                    move: $scope.pageObject.params.allowMove,
                    resize: $scope.pageObject.params.allowMove,
                    update: false
                },
                selectable: $scope.pageObject.params.openOnSelect || $scope.pageObject.tools.delete,
                workDayStart: toDate(options.workDayStart, '2013/6/6 08:00 AM'),
                workDayEnd: toDate(options.workDayEnd, '2013/6/6 05:00 AM'),
                add: addHandler,
                allDayEventTemplate: eventTemplate,
                change: eventSelect,
                dataBound: dataBoundHandler,
                eventTemplate: eventTemplate,
                moveStart: dragHandler,
                moveEnd: moveEndHandler,
                navigate: navigateHandler,
                resizeStart: dragHandler,
                resizeEnd: moveEndHandler
            });

            if ($scope.pageObject.viz.settings.resources) {
                // Creates flat array of all colors specified by user
                var colors = [];
                _.forEach($scope.pageObject.viz.settings.resources, function (resource) {
                    colors.push({
                        color: resource.defaultColor
                    });

                    _.forEach(resource.dataSource, function (resItem) {
                        if (_.isBoolean(resItem.value)) {
                            resItem.value += '';
                        }

                        colors.push({
                            color: resItem.color
                        });
                    });
                });

                // Scheduler checks this to determine what color to use when rendering events
                options.resources = [{
                    field: '__color',
                    dataValueField: 'color',
                    dataSource: colors
                }];
            }

            // Expose Options to Scope
            vm.options = options;
        }

        function dataBoundHandler(e) {
            if($scope.pageObject.params.openOnSelect){
                e.sender.element.find('.k-event')
                    .click(function(jqEvent){
                        var uid = angular.element(jqEvent.currentTarget).data('uid');
                        var dataItem = e.sender.dataSource.getByUid(uid);

                        VizScheduleService.viewRecord(dataItem.id, navFunctions);
                    });
            }
        }

        function eventSelect(e){
            e.preventDefault();

            vm.selectedEvents = e.events;
            vm.selectedIds = _.map(e.events, function(ev){
                // If this event is recurring, the recurrenceId will be used as the instanceId
                return _.isUndefined(ev.recurrenceId) ? ev.id : ev.recurrenceId;
            });
        }

        function navigateHandler(e) {
            // Reloads data when view changes
            if (e.action === 'changeView' || e.action === 'next' || e.action === 'previous') {
                $timeout(function () {
                    $scope.$broadcast('nim-viz-reload');
                    //e.sender.dataSource.read();
                });
             }

            // Resizes gridster when user changes view or work day hours
            // Note: action "changeWorkDay" is undocumented for some reason
            if (e.action === 'changeView' || e.action === 'changeWorkDay') {
                resizeGridster();
            }

            // When user switches to day view, scroll to current time 
            if(e.action === 'changeView') {
                $timeout(function () {
                    if (e.view === 'day') {
                        var element = e.sender.element;
                        if (element) {
                            var currentTime = element.find('.k-current-time');
                            if (currentTime.length) {
                                var top = currentTime.offset().top;
                                element.parents('#page-content').scrollTop(top - 56);
                            }
                        }
                    }
                }, 400);
            }
        }

        function dragHandler() {
            $scope.dragging = true;
        }

        var movingEvent = false;
        function moveEndHandler(e) {
            $scope.dragging = false;
            if (movingEvent || !e.event || !e.sender.dataSource.getByUid(e.event.uid)) {
                e.preventDefault();
                return;
            }

            movingEvent = true;

            try {
                var dataItem = e.sender.dataSource.get(e.event.id);
                var sourceId = dataItem.__sourceId;
                var ds = $scope.pageObject.viz.settings.dataSource[sourceId];
                var updateData = {};
                var startField = $scope.pageObject.params.startDateField,
                    endField = $scope.pageObject.params.endDateField,
                    localStart = toLocal(e.start),
                    localEnd = toLocal(e.end);
                //endMoment = moment(localEnd);

                //if(endMoment.hours() === 0 && endMoment.minutes() === 0 && endMoment.seconds() === 0){
                //    endMoment.subtract(1, 's');
                //    localEnd = endMoment.toDate();
                //}

                if (ds.nim_widgetId) {
                    startField = _.get(ds, 'secondaryFieldMap.startDateField');
                    endField = _.get(ds, 'secondaryFieldMap.endDateField');
                }

                if (startField) {
                    updateData[startField.stateId] = updateData[startField.stateId] || {};
                    updateData[startField.stateId][startField.modelId] = localStart;
                }

                if (endField) {
                    updateData[endField.stateId] = updateData[endField.stateId] || {};
                    updateData[endField.stateId][endField.modelId] = localEnd;
                }

                if (!_.isEmpty(updateData)) {
                    dataItem.start = localStart;
                    dataItem.end = localEnd;
                    refresh(e.sender);

                    VizScheduleService.updateItem(e.event.id, updateData).then(function () {
                        movingEvent = false;

                        e.event.start = localStart;
                        e.event.end = localEnd;
                        refresh(e.sender);
                    }, function () {
                        movingEvent = false;
                    });
                }
                else {
                    movingEvent = false;
                    e.preventDefault();
                }
            }
            catch (ex) {
                $log.error(ex);
                movingEvent = false;
            }
        }

        // Handles adding new record when user double-clicks date/time on calendar
        function addHandler(e){
            // Stop Kendo from using its own window for data creation
            e.preventDefault();

            var defaultData = [],
                startDateField = $scope.pageObject.params.startDateField,
                endDateField = $scope.pageObject.params.endDateField;

            if (startDateField) {
                var startValue = e.event.start;

                if(e.sender.viewName() == 'month'){
                    startValue = moment(startValue).hour(11).toDate();
                }

                defaultData.push({
                    data: {
                        value: startValue
                    },
                    field: startDateField
                });
            }

            if(endDateField){
                var endValue = e.event.end;

                if(e.sender.viewName() == 'month'){
                    endValue = moment(endValue).hour(12).toDate();
                }

                defaultData.push({
                    data: {
                        value: endValue
                    },
                    field: endDateField
                });
            }

            VizScheduleService.createRecord($scope.pageObject, defaultData);
        }

        function exportICS(){
            var cal = ics();

            _.forEach($scope.schedule.data(), function(event){
                // addEvent REQUIRES the following arguments:
                var title = event.title || '(Untitled)',    // @param {string} subject     Subject/Title of event
                    description = event.description || '',  // @param {string} description Description of event
                    location = '',                          // @param {string} location    Location of event
                    begin = event.start,                    // @param {string} begin       Beginning date of event
                    stop = event.end,                       // @param {string} stop        Ending date of event
                    before = '',
                    unit = '';
                
                if ($scope.pageObject.params.setReminders){
                    before = $scope.pageObject.params.reminderBeforeAmount || 0;
                    unit = $scope.pageObject.params.reminderBeforeUnit || 'M';
                }

                cal.addEvent(title, description, location, begin, stop, before, unit);
            });

            if (cal.events().length > 0) {
                var content = cal.calendar();
                var blob = new Blob([content], {type: "text/plain;charset=utf-8"});

                $window.saveAs(blob, $scope.pageObject.name + '.ics');
            }
        }

        // Fix for kendo scheduler directive consistently assigning the wrong text color
        function eventTemplate(dataItem){
            var textColor = '#ffffff',
                eventContent = dataItem.title || '',
                template = templates[dataItem['__sourceId']] || '#: data.title #',
                user = $rootScope.userData;

            if(dataItem.__color) {
                var color = kendo.parseColor(dataItem.__color);
                if (color) {
                    // Taken from kendo code
                    var brightness = Math.sqrt(0.241 * color.r * color.r + 0.691 * color.g * color.g + 0.068 * color.b * color.b);

                    if (brightness >= 180) {
                        textColor = '#555555';
                    }
                }
            }

            var compileData = {
                data: defaultPropsToBlank(dataItem),
                nim: nimUtilityService,
                user: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    fullName: user.firstName + ' ' + user.lastName
                }
            };

            try {
                eventContent = kendo.template(template)(compileData);
            }
            catch(e){
                $log.warn(e);
            }

            return '<div style="height:100%;position:relative;color:' + textColor + '">' + eventContent + '</div>';
        }

        /**
         * Sets null values in dataItem to empty string
         * @param dataItem
         */
        function defaultPropsToBlank(dataItem){
            var newDataItem = {};

            _.forEach(dataItem, function(value, field){
                if(value == null){
                    newDataItem[field] = '';
                }
                else {
                    newDataItem[field] = value;
                }
            });

            return newDataItem;
        }

        function toLocal(date){
            // Eliminates UTC offset so time sent to server is consistent with time selected on calendar
            var m = moment.utc(date);
            m.local();
            return m.toDate();
        }

        function resizeGridster(){
            if ($scope.resizeGridsterItem) {
                $timeout(function () {
                    $scope.resizeGridsterItem();
                }, 10);
            }
        }

        function toDate(value, defaultVal){
            if(_.isDate(value)){
                return value;
            }
            else if(_.isString(value)){
                return new Date(value);
            }

            return new Date(defaultVal);
        }

        function refresh(scheduler) {
            var view = scheduler.viewName();

            if (view && scheduler.element) {
                scheduler.view(view);
            }
        }

        function firebaseRefresh(dsId){
            return function() {
                if (initObj[dsId]) {
                    //Stops first firing of the event
                    $timeout(function () {
                        initObj[dsId] = false;
                    });
                } else {
                    reloadIfNotDragging();
                }
            };
        }

        function reloadIfNotDragging() {
            if (!$scope.dragging) {
                $timeout(function () {
                    $scope.$broadcast('nim-viz-reload');
                });
            }
        }

        function getPrevious(instanceController){
            var currentId = instanceController.instanceId,
                currentDataItem = $scope.dataSource.get(currentId),
                currentIndex = $scope.dataSource.indexOf(currentDataItem),
                prevItem;

            if(currentIndex >= 0) {
                if (currentIndex > 0) {
                    prevItem = $scope.dataSource.at(currentIndex - 1);
                }

                if (prevItem) {
                    instanceController.updateInstanceAttrs(prevItem.id, 'read');
                    instanceController.init();
                }
            }
        }

        function getNext(instanceController){
            var currentId = instanceController.instanceId,
                currentDataItem = $scope.dataSource.get(currentId),
                currentIndex = $scope.dataSource.indexOf(currentDataItem),
                currentPageSize = $scope.dataSource.data().length,
                nextItem;

            if(currentIndex < currentPageSize - 1){
                nextItem = $scope.dataSource.at(currentIndex + 1);
            }

            if(nextItem){
                instanceController.updateInstanceAttrs(nextItem.id, 'read');
                instanceController.init();
            }
        }
    })
    .directive('nimVizSchedule', function() {
        return {
            restrict: 'AE',
            templateUrl: function(tElement, tAttributes){
                if (tAttributes.subType) {
                    if (tAttributes.template) {
                        return 'core/pages/viz/' + tAttributes.subType + '/schedule-' + tAttributes.subType + '-' + tAttributes.template + '.tpl.html';
                    }
                    else {
                        return 'core/pages/viz/' + tAttributes.subType + '/schedule-' + tAttributes.subType + '.tpl.html';
                    }
                }
                else {
                    return 'core/pages/viz/schedule/schedule.tpl.html';
                }
            },
            scope: true,
            require: '^nimPageObject',
            controller: '@',
            name: 'controllerName',
            controllerAs: 'vm'
        };
    })
;
