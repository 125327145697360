angular.module('cerberus.account')
    .controller('OrganizationsAuditsCtrl', function OrganizationsAuditsCtrl(kendo, _, moment, $scope, authToken, orgId, workspaceId, OrganizationsService) {
        $scope.activeRoute = 'audits';
        $scope.orgId = orgId;
        $scope.orgData = {};
        $scope.auditDataSource = new kendo.data.DataSource({
            type: 'odata',
            transport:{
                read: {
                    url: '/server/rest/v1/organizations/' + orgId + '/rest/audit/odata.svc',
                    beforeSend: function(xhr){
                        xhr.setRequestHeader('Authentication', 'Bearer ' + authToken);
                        xhr.setRequestHeader('WSID', workspaceId);
                    },
                    dataType: 'json'
                }
            },
            serverFiltering: true,
            serverPaging: true,
            serverSorting: true,
            pageSize: 200,
            schema: {
                model: {
                    fields: {
                        id:{type: 'string'},
                        date:{type: 'date', parse: parseDateField},
                        remote_addr:{type: 'string'},
                        http_verb:{type: 'string'},
                        path:{type: 'string'},
                        http_response_status_code:{type: 'string'},
                        user_name:{type: 'string'},
                        user_email:{type: 'string'},
                        user_id:{type: 'string'},
                        http_user_agent:{type: 'string'},
                        request_body:{type: 'string', parse: parseRequestBody}
                    }
                }
            }
        });

        $scope.auditGridOptions = {
            columnMenu: true,
            columns:  [
                {width: 120, field: 'user_name', title:'Name'},
                {width: 144, field: 'user_email', title:'Email'},
                {width: 140, field: 'date', title:'Date', format: "{0:g}"},
                {width: 80, field: 'http_verb', title:'HTTP Verb'},
                {width: 360, field: 'path', title:'URL'},
                {width: 80, field: 'http_response_status_code', title:'Response Status Code'},
                {width: 120, field: 'remote_addr', title:'IP Address'},
                {width: 360, field: 'http_user_agent', title:'User Agent'},
                {width: 360, field: 'request_body', title:'Request Body'}
            ],
            dataSource: $scope.auditDataSource,
            filterable: true,
            mobile: true,
            navigatable: true,
            pageable: true,
            reorderable: true,
            resizable: true,
            sortable: true,
            scrollable: true,
            groupable: true,
            selectable: 'row',
            excel: {
                allPages: false,
                fileName: 'Audit-Log.xlsx'
            }
        };

        $scope.exportAuditLog = exportAuditLog;

        init();

        $scope.$on('$locationChangeStart', function (e) {
            if (!e.defaultPrevented) {
                if ($scope.auditGrid) {
                    $scope.auditGrid.destroy();
                    $scope.auditGrid = null;
                }
            }    
        });

        function init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });
        }

        function parseDateField(value){
            var dateValue = moment(value, 'MMMM, DD YYYY HH:mm:ss');
            return dateValue.toDate();
        }

        function parseRequestBody(value){
            if(!_.isString(value)){
                return angular.toJson(value);
            }

            return value;
        }

        function exportAuditLog(auditGrid){
            if(auditGrid){
                auditGrid.saveAsExcel();
            }
        }
    })
;