angular.module('cerberus.ui')
    .factory('ExportService', function(_, kendo, $q, $timeout, $uibModal){
        var exportClass = 'nim-export-element';

        return {
            exportElement: exportElement,
            exportPage: exportPage
        };

        function exportElement(elem, defaultConfig, cb){
            var callback = angular.noop;

            if(_.isFunction(cb)){
                callback = cb;
            }

            configureExport(defaultConfig).then(function(config){
                elem.addClass(exportClass);

                if(config.options.paperSize !== 'auto') {
                    var paperOptions = kendo.pdf.getPaperOptions(config.options);
                    var width = paperOptions.paperSize[0];

                    if(paperOptions.margin.left){
                        width -= paperOptions.margin.left;
                    }

                    if(paperOptions.margin.right){
                        width -= paperOptions.margin.right;
                    }

                    width = width * 120 / 72;

                    elem.css('width', width);
                }

                $timeout(function(){
                    kendo.drawing.drawDOM(elem, config.options).then(function(group){
                        switch(config.type){
                            case "pdf":
                                return kendo.drawing.exportPDF(group, config.options);
                            case "png":
                                return kendo.drawing.exportImage(group, config.options);
                            case "svg":
                                return kendo.drawing.exportSVG(group, config.options);
                            default:
                                elem.css('width', '')
                                    .removeClass(exportClass);

                                callback();
                                return false;
                        }
                    }).done(function(dataURI){
                        kendo.saveAs({
                            dataURI: dataURI,
                            fileName: config.filename + '.' + config.type
                        });

                        elem.removeClass(exportClass);
                        elem.css('width', '');

                        callback();
                    });
                }, 800);
            }, function(){
                callback();
            });
        }

        function exportPage(body, defaultConfig, cb){
            var callback = angular.noop,
                pageObjects = body
                    .find('#page-content')
                    .children('#content-wrap')
                    .children('.gridster-container')
                    .children('div[gridster]')
                    .children('ul')
                    .children('.nim-page-object');

            if(_.isFunction(cb)){
                callback = cb;
            }

            configureExport(defaultConfig).then(function(config){
                body.addClass(exportClass);

                if(config.options.paperSize !== 'auto') {
                    var paperOptions = kendo.pdf.getPaperOptions(config.options);

                    var width = paperOptions.paperSize[0];

                    if(paperOptions.margin.left){
                        width -= paperOptions.margin.left;
                    }

                    if(paperOptions.margin.right){
                        width -= paperOptions.margin.right;
                    }

                    width = width * 120 / 72;

                    body.css('width', width);
                }

                $timeout(function(){
                    var promises = [];

                    pageObjects.each(function(i, elem){
                        promises.push(
                            kendo.drawing.drawDOM(elem, config.options)
                        );
                    });

                    $q.all(promises).then(function(groups){
                        var root = new kendo.drawing.Group(),
                            exportPromise = null;

                        _.forEach(groups, function(group){
                            root.append(group);
                        });

                        switch(config.type){
                            case "pdf":
                                exportPromise = kendo.drawing.exportPDF(root, config.options);
                                break;
                            case "png":
                                exportPromise = kendo.drawing.exportImage(root, config.options);
                                break;
                            case "svg":
                                exportPromise = kendo.drawing.exportSVG(root, config.options);
                                break;
                            default:
                                body.css('width', '')
                                    .removeClass(exportClass);

                                callback();
                                return;
                        }

                        exportPromise.then(function(dataURI){
                            kendo.saveAs({
                                dataURI: dataURI,
                                fileName: config.filename + '.' + config.type
                            });

                            body.css('width', '')
                                .removeClass(exportClass);

                            callback();
                        });
                    });
                }, 800);
            }, function(){
                callback();
            });
        }

        /**
         * Opens the export configuration modal
         * @param defaultConfig - Initial exportSettings for exported file. Includes filename.
         * @returns {Promise} - Fulfilled when the modal is closed
         */
        function configureExport(defaultConfig){
            var exportConfigModal = $uibModal.open({
                templateUrl: 'ui/nim-export/export-config-modal.tpl.html',
                controller: 'ExportConfigModalCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass: 'modal-export-config',
                backdropClass: 'export-config',
                resolve: {
                    defaultConfig: _.constant(defaultConfig)
                }
            });

            return exportConfigModal.result;
        }
    })
;