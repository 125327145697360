angular.module('cerberus.ui')
    .controller('NimIconPickerCtrl', function NimIconPickerCtrl($scope, NimIconPickerService) {
        var vm = this;
        vm.filterText = '';
        vm.icons = [];
        vm.init = init;
        vm.select = select;
        ////////////////////
        function init() {
            NimIconPickerService.getIcons().then(function (value) {
                vm.icons = value;
            });
        }
        function select(icon) {
            $scope.icon = icon;
        }
    })
    .directive('nimIconPicker', function nimIconPicker() {
        return {
            restrict: 'AE',
            scope: {
                icon: '=nimIconPicker',
                btnClass: '@'
            },
            controller: 'NimIconPickerCtrl',
            controllerAs: 'vm',
            templateUrl: 'ui/nim-icon-picker/nim-icon-picker.tpl.html',
            link: function (scope, element, attributes, controller) {
                controller.init();
            }
        };
    })
;