angular.module('cerberus.les')
    .controller('LESLoanApplicationImportModalCtrl', function(_, $rootScope, $scope, $uibModalInstance){
        var vm = this;

        vm.importLoanUploadOptions = {
            multiple: false,
            async: {
                saveField: 'file',
                saveUrl: '/Server/REST/v1/les/loanApplications',
                autoUpload: false
            },
            localization: {
                dropFilesHere: 'Drag Here',
                select: 'Select File',
                uploadSelectedFiles: 'Import'
            },
            upload: function (e) {
                var xhr = e.XMLHttpRequest;
                if (xhr) {
                    xhr.addEventListener("readystatechange", function () {
                        if (xhr.readyState == 1 /* OPENED */) {
                            xhr.setRequestHeader('Authentication','Bearer ' + $rootScope.authToken);
                            xhr.setRequestHeader('WSID', $rootScope.userData.currentWorkspace.id);
                            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest ');
                        }
                    });
                }
            },
            success: function (e) {
                if (e.response.DATA.instanceId) {
                    $uibModalInstance.close(e.response.DATA.instanceId);
                }
                if (e.response.DATA.errMsg) {
                    $uibModalInstance.dismiss(e.response.DATA);
                }
            },
            error: function (e) {
                var response = angular.fromJson(e.XMLHttpRequest.response);
                $uibModalInstance.dismiss(response.DATA);
            }
        };

        vm.ok = ok;
        vm.cancel = cancel;

        function ok(){
            $uibModalInstance.close();
        }

        function cancel(){
            $uibModalInstance.dismiss();
        }

        // Removes Kendo widgets
        $scope.$on('$destroy', function(){
            _.result(vm, 'updateLoanUpload.destroy');
        });

        // Prevents navigation while modal is open
        var allowNavigation = $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });

        $scope.$on('modal.closing', function () {
            allowNavigation();
        });
    })
;