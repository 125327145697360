angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizTemplateService
     * @alias cerberus/core:VizTemplateService
     * @description Provides helper functions to the readout view
     */
    .factory('VizTemplateService', function VizTemplateService(_, kendo, $rootScope, $timeout, $http, $log,
                                                               nimUtilityService, InstancesWindowService, pageObjectsService, VizUtilityService) {
        return {
            setHtml: setHtml,
            buildDataSource: buildDataSource,
            viewRecord: viewRecord,
            requiredFiltersSet: requiredFiltersSet
        };
        ////////////////////
        /**
         * @function getTemplateValue
         * @param {string} data
         * @param {string} viz
         * @return {string} html string
         */
        function setHtml(data, viz) {
            if (!_.isEmpty(viz.settings.template)) {

                var pageSize = _.get(viz, 'settings.dataSource.pageSize');

                var dataItem = pageSize != 1 ? [] : {};
                var user = $rootScope.userData;

                if(!_.isEmpty(data)){
                    if(pageSize != 1){
                        dataItem = _.map(data, defaultPropsToBlank);
                    }
                    else {
                        dataItem = defaultPropsToBlank(data[0]);
                    }
                }

                var compileData = {
                    data: dataItem,
                    nim: nimUtilityService,
                    user: {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        fullName: user.firstName + ' ' + user.lastName
                    }
                };

                try {
                    var templateString = viz.settings.template.replace(/&lt;/g, '<'),
                        escapedString = pageObjectsService.escapePoundSign(templateString);
                    return kendo.template(escapedString)(compileData);
                }
                catch(e){
                    $log.error('Error building HTML:', e);
                }
            }
            // Set Default text
            return angular.copy(viz.settings.defaultText) || '';
        }

        /**
         * Sets null values in dataItem to empty string
         * @param dataItem
         */
        function defaultPropsToBlank(dataItem){
            var newDataItem = {};

            _.forEach(dataItem, function(value, field){
                if(_.isNull(value) || _.isUndefined(value)){
                    newDataItem[field] = '';
                }
                else {
                    newDataItem[field] = value;
                }
            });

            return newDataItem;
        }

        /**
         * Build datasource for template views
         * @function buildDataSource
         * @param {object} pageObject
         * @param {object} scope - angularJs scope
         * @return {object} containing an Array of Columns and a Fields object
         */
        function buildDataSource(pageObject, scope) {
            // var resizeGridsterItem = scope.resizeGridsterItem || angular.noop;
            var dataSourceOptions = {
                type: 'odata',
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                serverAggregates: true,
                serverGrouping: false,
                pageSize: 1,
                transport: {
                    read: read
                },
                change: function(e){
                    var othis = e.sender;
                    scope.vizCtrl.data = othis.data();

                    var defaultText = angular.copy(pageObject.viz.settings.defaultText) || '';

                    if(requiredFiltersSet(pageObject, scope.filters) || _.isEmpty(pageObject.viz.settings.defaultText)) {
                        $timeout(function () {
                            var html = setHtml(scope.vizCtrl.data, pageObject.viz);
                            scope.usingTemplate = html !== defaultText;
                            scope.templateHtml = html;
                            scope.vizCtrl.templateHtml = scope.templateHtml;
                        });
                    }
                    else {
                        scope.templateHtml = defaultText;
                        scope.vizCtrl.templateHtml = scope.templateHtml;
                        scope.usingTemplate = false;
                    }

                    // $timeout(function () {
                    //     resizeGridsterItem();
                    // }, 1);
                },
                requestStart: function (e){
                    if(!requiredFiltersSet(pageObject, scope.filters)){
                        e.preventDefault();

                        $timeout(function(){
                            e.sender.success({d:{results: [], __count: 0}});
                        });

                        return false;
                    }
                }
            };

            var sortOptions = {
                sort: VizUtilityService.createSortArray(pageObject.viz.settings.dataSource)
            };

            var baseFilter = angular.copy(pageObject.viz.settings.dataSource.filter) || {
                    filters: [],
                    logic: 'and'
                };

            var filterOptions = {
                filter: {
                    filters: [baseFilter],
                    logic: pageObject.params.filterLogic || 'and'
                }
            };

            // Extend any user-defined dataSource settings
            angular.extend(dataSourceOptions, pageObject.viz.settings.dataSource, sortOptions, filterOptions);

            // Adds parse function to make sure date fields are correct
            _.forEach(dataSourceOptions.schema.model.fields, function (field) {
                if (field.type === 'date') {
                    field.parse = VizUtilityService.parseDateField;
                }
            });

            // Return new Datasource
            return new kendo.data.DataSource(dataSourceOptions);

            function read(options) {
                var httpOptions = VizUtilityService.buildBaseHttpOptions(pageObject, scope.isPreview);
                httpOptions.params = VizUtilityService.buildHttpParams(scope, dataSourceOptions, options);

                $http(httpOptions).then(function (result) {
                    options.success(result.data);
                }, function (result) {
                    options.error(result);
                });
            }
        }

        /**
         * Opens selected record in instance window
         * @function viewRecord
         * @param id
         */
        function viewRecord(id) {
            if(id) {
                InstancesWindowService.openWindow({
                    action: 'read',
                    instanceId: id
                });
            }
        }

        function requiredFiltersSet(pageObject, filters){
            var filtersSet = true;

            if(!_.isEmpty(pageObject.params.requiredFilters)){
                _.forEach(pageObject.params.requiredFilters, function(required, filter){
                    if(required && VizUtilityService.isFilterEmpty(filters[filter])){
                        filtersSet = false;
                    }
                });
            }

            return filtersSet;
        }
    })
;