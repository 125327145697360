angular.module('cerberus.admin')
    .factory('ImportService', function ImportService($http, $q, apiPath, toaster, ImportListService) {
            /** @public */
            return {
                create: function(id,data){
                    var deferred = $q.defer();
                    $http.post(apiPath + 'widgets/' + id + '/import/settings',data)
                        .success(function (value) {
                            toaster.pop('success', 'Import settings has been created.');
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                getAllSettings: function(id, exclusivePermissions){
                    var findDisplay = function(typesArray,type){
                        return typesArray.map(function(e) { return e.value; }).indexOf(type);
                    };

                    var deferred = $q.defer();
                    var params = {
                        $exclPermissions: exclusivePermissions || false
                    };
                    $http.get(apiPath + 'widgets/' + id + '/import/settings',{params:params})
                        .success(function (value) {
                            var types = ImportListService.getConfigTypes();
                            var d = value.DATA;
                            for(var i = 0; i < d.length; i++){
                                var idx = findDisplay(types,d[i].type);
                                d[i].typeDisplay = types[idx].display;
                            }
                            deferred.resolve(d);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                getAllJobs: function(id, exclusivePermissions){
                    var deferred = $q.defer();
                    var params = {
                        $exclPermissions: exclusivePermissions || false
                    };
                    $http.get(apiPath + 'widgets/' + id + '/import/jobs',{params:params})
                        .success(function (value) {
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                getSetting: function(id){
                    var deferred = $q.defer();
                    $http.get(apiPath + 'import/settings/' + id)
                        .success(function (value) {
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                update: function(id,data){
                    var deferred = $q.defer();
                    $http.put(apiPath + 'import/settings/' + id,data)
                        .success(function (value) {
                            toaster.pop('success', 'Import settings has been updated.');
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                startImport: function(id,name,exclusivePermissions){
                    var deferred = $q.defer();
                    var params = {
                        $exclPermissions: exclusivePermissions || false
                    };
                    $http.put(apiPath + 'import/jobs/' + id,{$exclPermissions:params.$exclPermissions})
                        .success(function (value) {
                            toaster.pop('success', 'Import job: ' + name + ' has been started.');
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                deleteJob: function (id, exclusivePermissions) {
                    var deferred = $q.defer();
                    var params = {
                        $exclPermissions: exclusivePermissions || false
                    };
                    $http({method: 'DELETE', url: apiPath + 'import/jobs/' + id, params: params})
                        .success(function(value){
                            toaster.pop('info', 'Import job has been deleted.');
                            deferred.resolve(value.DATA);
                        })
                        .error(function(reason){
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                validateImport: function(id, exclusivePermissions){
                    var deferred = $q.defer();
                    var params = {
                        $exclPermissions: exclusivePermissions || false
                    };
                    $http.put(apiPath + 'import/jobs/' + id + '/validate',{$exclPermissions:params.$exclPermissions})
                        .success(function (value) {
                            toaster.pop('success', 'Import job has been validated.');
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                rollback: function(id, exclusivePermissions){
                    var deferred = $q.defer();
                    var params = {
                        $exclPermissions: exclusivePermissions || false
                    };
                    $http.put(apiPath + 'imports/' + id +'/rollback',{$exclPermissions:params.$exclPermissions})
                        .success(function (value) {
                            toaster.pop('success', 'Import job has been rolled back.');
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                deleteConfig: function (id) {
                    var deferred = $q.defer();
                    $http({method: 'DELETE', url: apiPath + 'import/settings/' + id})
                        .success(function(value){
                            toaster.pop('info', 'Import configuration has been deleted.');
                            deferred.resolve(value.DATA);
                        })
                        .error(function(reason){
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                createImportJob: function(id,data){
                    var deferred = $q.defer();
                    $http.post(apiPath + 'widgets/' + id + '/import/jobs',data)
                        .success(function (value) {
                            toaster.pop('success', 'Import Job has been created.');
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                }
            };
        })
;
