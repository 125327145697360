angular.module('cerberus.core')
    .controller('nimVizChartDonutCtrl', function nimVizChartDonutCtrl(_, $scope, VizChartService, VizChartDonutService, VizUtilityService, $firebaseObject, fbUtil, $timeout) {
        var vm = this;
        $scope.mainPageObject.vizCtrl = vm;
        //Firebase
        var initializing = true,
            debouncedRefresh = _.debounce(function () { _.result(vm, 'dataSource.read'); }, 5000, { leading: true, trailing: true }),
            unwatchFb = $firebaseObject(fbUtil.ref('queries/' + $scope.pageObject.viz.settings.dataSource.nim_viewId)).$watch(function () {
                if (initializing) {
                    //Stops first firing of the event
                    $timeout(function () { initializing = false; });
                } else {
                    debouncedRefresh();
                }
            });

        init();

        // Event Bindings
        var removeChartSizeWatcher = angular.noop;
        $scope.$on('kendoWidgetCreated', function (event, widget) {
            if (widget === $scope.chart) {
                event.preventDefault();
                event.stopPropagation();

                var debouncedResizeFunc = _.debounce(function () {
                    widget.resize();
                }, 400, { leading: true, trailing: true });
                removeChartSizeWatcher = $scope.$watch(function(){
                    return widget.wrapper.width();
                }, debouncedResizeFunc);
            }
        });

        $scope.$on('nim-viz-reload', function (event) {
            event.preventDefault();
            debouncedRefresh();
        });

        $scope.$on('$destroy', function () {
            unwatchFb();     // Remove this watcher so it stops firing after the user has left the page
        });

        $scope.$on('nim-remove-page-objects', function () {
            if ($scope.grid) {
                $scope.grid.destroy();
                $scope.grid = null;
            }

            removeChartSizeWatcher();
        });

        function init() {
            var options = angular.copy($scope.pageObject.viz.settings);
            
            // Fix any misconfigurations
            VizChartService.fixChartOptions(options);

            // Ensure that autoBind is set to false to prevent multiple server calls
            // We want to manually bind the data because some options will depend on the server's response
            // See the dataSource.fetch() callback function
            options.autoBind = false;

            reorderSeries(options);

            VizChartService.setSeriesColors(options, $scope.pageObject.params);

            if (options.series.length > 1) {
                _.forEach(options.series, function (series) {
                    series.labels = series.labels || {};
                    series.labels.template = ' ';
                });
            }

            //vm.dataSource = VizChartService.buildDataSource($scope.pageObject, $scope, options, VizChartDonutService.configDonut($scope.pageObject.params));
            vm.dataSource = VizChartService.buildDataSource($scope.pageObject, $scope, options, VizChartDonutService.configDonut($scope.pageObject.params));
            options.dataSource = vm.dataSource;

            // Exposes options to view model
            vm.options = options;

            // Watch Filters
            VizUtilityService.subscribeToPageFilters($scope.pageObject.filterMap, $scope, vm.dataSource);
        }

        function reorderSeries(options){
            var sortField = '';
            if (_.isArray(options.dataSource.sort)) {
                // TODO: this only works if the first sort field matches a series
                // Update this to handle multiple sort conditions
                sortField = _.get(options.dataSource, 'sort[0].field', '');
            }
            else if (_.isObject(options.dataSource.sort)) {
                sortField = _.get(options.dataSource, 'sort.field', '');
            }
            
            if(sortField){
                // var index = options.series.map(function(e){ return e.field; }).indexOf(sortField); /* NON LODASH OPTION */
                /* LODASH Version 4 ONLY */
                //var index = _.findIndex(options.series, ['field', sortField]);
                /* LODASH Version 3 & 4 Compatibility Mode */
                var index = _.findIndex(options.series, function(f){return f.field === sortField;});
                if(index >= 0){
                    var top = options.series.splice(index, 1);
                    options.series.push(top[0]);
                }
            }
        }
    })
;