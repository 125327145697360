angular.module('cerberus.admin')
/**
 * @ngdoc service
 * @name WorkflowDiagramService
 * @alias cerberus/workflows-designer:WorkflowDiagramService
 * @description
 *  Helper functions for building Workflow Diagram
 */
    .factory('WorkflowDiagramService', function WorkflowDiagramService(_, kendo){

        var diameter = 13;
        var connectorConfig = {
            vertical: {
                decision: [
                    {name: 'left',description: 'output',width: diameter,height: diameter},
                    {name: 'right',description: 'output',width: diameter,height: diameter},
                    {name: 'top',description: 'input',width: diameter,height: diameter}
                ],
                document: [
                    {name: 'top',description: 'input',width: diameter,height: diameter},
                    {name: 'bottom',description: 'output',width: diameter,height: diameter}
                ],
                endState: [
                    {name: 'top',description: 'input',width: diameter,height: diameter}
                ],
                process: [
                    {name: 'top',description: 'input',width: diameter,height: diameter},
                    {name: 'bottom',description: 'output',width: diameter,height: diameter}
                ]
            },
            horizontal: {
                decision: [
                    {name: 'top',description: 'output',width: diameter,height: diameter},
                    {name: 'bottom',description: 'output',width: diameter,height: diameter},
                    {name: 'left',description: 'input',width: diameter,height: diameter}
                ],
                document: [
                    {name: 'left',description: 'input',width: diameter,height: diameter},
                    {name: 'right',description: 'output',width: diameter,height: diameter}
                ],
                endState: [
                    {name: 'left',description: 'input',width: diameter,height: diameter}
                ],
                process: [
                    {name: 'left',description: 'input',width: diameter,height: diameter},
                    {name: 'right',description: 'output',width: diameter,height: diameter}
                ]
            }
        };

        var shapeConfig = {
            decision: {
                width: 120,
                height: 120,
                type: 'decision',
                stroke: {
                    color: '#feb24c'
                },
                fill: '#ffeda0',
                path: 'M0 60 L60 0 120 60 60 120Z',
                connectors: connectorConfig.vertical.decision
            },
            document: {
                width: 120,
                height: 54,
                type: 'document',
                stroke: {
                    color: '#9ecae1',
                    width: 3
                },
                fill: '#deebf7',
                path: 'M0 0 L120 0 120 57 Q90 43 60 56 30 69 0 55Z',
                connectors: connectorConfig.vertical.document
            },
            endState: {
                width: 120,
                height: 54,
                type: 'document',
                stroke: {
                    color: '#bdbdbd'
                },
                fill: '#f0f0f0',
                path: 'M34 54 C 0 54 0 0 34 0 L 86 0 C 120 0 120 54 86 54Z',
                connectors: connectorConfig.vertical.endState
            },
            process: {
                width: 120,
                height: 70,
                type: 'process',
                stroke: {
                    color: '#a1d99b',
                    width: 1
                },
                fill: '#e5f5e0',
                path: 'M0 0 L60 0 60 40 0 40Z',
                connectors: connectorConfig.vertical.process
            }
        };

        var defaultDiagramOptions = {
            theme: 'default',
            zoomRate: 0,
            shapeDefaults: {
                fill: '#e8eff7',
                stroke: {
                    color: '#586477',
                    width: 2
                },
                editable: {
                    connect: true,
                    drag: true
                },
                content: {
                    color: '#000000',
                    //color: '#ffffff',
                    fontSize: 14
                }
            },
            connectionDefaults: {
                endCap: {
                    type: 'ArrowEnd',
                    fill: '#586477',
                    stroke: '#586477'
                },
                type: 'cascading',
                stroke: {
                    color: '#586477',
                    width: 2
                }
            },
            editable: {
                resize: false,
                rotate: false
            }
        };

        var Shape = kendo.dataviz.diagram.Shape,
            Connection = kendo.dataviz.diagram.Connection;
            //Connector = kendo.dataviz.diagram.Connector;

        return {
            connectorConfig: _.constant(connectorConfig),
            shapeConfig: _.constant(shapeConfig),
            buildWFDiagram: buildWFDiagram,
            workflowModelToShapes: workflowModelToShapes
        };

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        /**
         * Builds options for kendo diagram
         *
         * @param scope
         * @param path - path to workflow model in scope
         * @param readonly - determines if diagram is editable
         * @returns {object}
         */
        function buildWFDiagram(scope, path, readonly){
            var workflow = _.get(scope, path);
            var options = workflowModelToShapes(workflow, readonly);

            _.assign(options, defaultDiagramOptions);

            if(readonly) {
                options.editable = false;
                options.selectable = false;
            }
            else {
                var handlers = {
                    select: diagramSelectHandler,
                    change: diagramChangeHandler(scope, path),
                    itemBoundsChange: diagramItemMovedHandler(scope, path)/*,
                     dragEnd: diagramDragEndHandler(scope, path),
                     remove: diagramItemRemoveHandler()*/
                };
                _.assign(options, handlers);
            }

            return options;
        }

        /**
         * Translates workflow model to shapes and connections configuration
         *
         * @param workflow - workflow model
         * @param readonly - determines if shapes and connections should be editable
         * @returns {{shapes: Array, connections: Array}}
         */
        function workflowModelToShapes(workflow, readonly){
            var shapes = [];
            var connections = [];

            _.forEach(workflow.objects, function(state, id){
                var shape = {
                    id: id,
                    content: {
                        text: state.model.name
                    },
                    x: _.get(state, 'display.x', 0),
                    y: _.get(state, 'display.y', 0)
                };

                if(readonly){
                    shape.editable = false;
                    shape.selectable = false;
                }

                if(state.model.isFinal){
                    _.assign(shape, shapeConfig['endState']);
                }
                else {
                    _.assign(shape, shapeConfig[state.model.type]);
                }

                shapes.push(shape);

                if(shape.type == 'decision'){
                    var connector = 'left';
                    _.forEach(state.model.to, function(t, to){
                        var connection = {
                            from: {
                                shapeId: id,
                                connector: 'left'
                            },
                            to: {
                                shapeId: to,
                                connector: 'top'
                            }
                        };

                        if(readonly){
                            connection.editable = false;
                            connection.selectable = false;
                        }
                        connections.push(connection);

                        connector = 'right';
                    });
                }
                else {
                    _.forEach(state.model.to, function(to){
                        var connection = {
                            from: {
                                shapeId: id,
                                connector: 'bottom'
                            },
                            to: {
                                shapeId: to,
                                connector: 'top'
                            }
                        };

                        if(readonly){
                            connection.editable = false;
                            connection.selectable = false;
                        }
                        connections.push(connection);
                    });
                }
            });

            return {shapes: shapes, connections: connections};
        }

        function diagramSelectHandler(e){
            if (e.selected.length) {
                var selectedItems = e.sender.select();
                var element = e.selected[0];
                if(selectedItems.length > 1){
                    e.sender.select(false);
                    e.sender.select(element);
                }
            }
        }

        function diagramChangeHandler(scope, path){
            return function(e){
                var workflow = _.get(scope, path);
                var diagram = e.sender;

                // Handles removed shapes
                if(e.removed.length){
                    for (var r = 0; r < e.removed.length; r++) {
                        var removedShape = e.removed[r];
                        if (removedShape instanceof Shape) {
                            delete workflow.objects[removedShape.id];
                            for (var id in workflow.objects) {
                                if (workflow.objects.hasOwnProperty(id)) {
                                    var obj = workflow.objects[id];
                                    if (obj.model.type == 'decision' && obj.model.to[removedShape.id]) {
                                        delete obj.model.to[removedShape.id];
                                    } else if (obj.model.type != 'decision' && obj.model.to[0] == removedShape.id) {
                                        obj.model.to.pop();
                                    }
                                }
                            }
                        }
                        else if (removedShape instanceof Connection){
                            if(_.has(removedShape, 'from.shape.id') && _.has(removedShape, 'to.shape.id')){
                                var fromId = removedShape.from.shape.id;
                                var toId = removedShape.to.shape.id;
                                var fromObject = workflow.objects[fromId];
                                if(fromObject.model.type == 'decision'){
                                    delete fromObject.model.to[toId];
                                }
                                else {
                                    fromObject.model.to.pop();
                                }
                            }
                        }
                    }

                    for (var c = 0; c < diagram.connections.length; c++) {
                        var connection = diagram.connections[c];
                        if (!connection.source().shape || !connection.target().shape) {
                            diagram.remove(connection, false);
                            c--;
                        }
                    }
                }
            };
        }

        function diagramItemMovedHandler(scope, path){
            return function(e){
                var workflow = _.get(scope, path);
                var obj = workflow.objects[e.item.id];
                if(obj){
                    _.assign(obj.display, _.pick(e.bounds, ['x', 'y']));
                }
            };
        }

        // TODO: Refactor logic from checkConnections and move it here
        //function diagramDragEndHandler(scope, path){
        //    return function(e){
        //        var workflow = _.get(scope, path);
        //        if(_.has(e, 'connections[0].to')){
        //            var to = e.connections[0].to;
        //            if(!(to instanceof Connector)){
        //                e.preventDefault();
        //            }
        //        }
        //    };
        //}

        // TODO: Move item-removal logic from change handler to this function
        //function diagramItemRemoveHandler(){
        //    return function(e){
        //    };
        //}
    })
;