angular.module('cerberus.les')
    .controller('LESQuickApplicationEligibilityCtrl', function(_, $scope, $location, $timeout, $q, quickApplicationId, lesLoanApplicationsService, lesQuickApplicationService, OdataUtilityService){
        var vm = this;

        vm.quickApplicationId = quickApplicationId;
        vm.quickApplication = {};
        vm.mainBorrower = '';
        vm.breadcrumbDisplay = 'Loading...';
        vm.lenders = [];
        vm.docTypes = [];
        vm.amortizationTypes = [];
        vm.loadingProducts = false;
        vm.notFound = false;
        vm.tabActive = {};
        vm.eligibilityError = '';
        vm.eligibilityType = '';
        vm.eligibilityTypeCounts = {
            all: 0,
            eligible: 0,
            ineligible: 0,
            nearmiss: 0
        };
        vm.termsFilter = {
            field: 'loanAmortizationType',
            operator: 'eq',
            value: ''
        };
        vm.doctypeFilter = {
            field: 'docType',
            operator: 'eq',
            value: ''
        };
        vm.rateFilter = {
            field: 'rate',
            operator: 'lte',
            value: ''
        };
        vm.rateFilterOptions = {
            step: 0.25,
            format: 'n2',
            min: 0,
            decimals: 2
        };

        vm.productRateDetailTableOptions = lesQuickApplicationService.productRateDetailTableOptions;
        vm.operatorSymbols = OdataUtilityService.operatorSymbols();
        vm.operators = OdataUtilityService.operators();

        vm.updateEligibilityFilter = updateEligibilityFilter;        
        vm.applyFilters = applyFilters;
        vm.clearFilter = clearFilter;
        vm.refineSearch = refineSearch;
        vm.navToLoanPage = navToLoanPage;
        vm.openApplication = openApplication;
        
        init();

        function init(){
            vm.quickApplication = {};
            vm.mainBorrower = '';
            vm.lenders = [];
            vm.docTypes = [];
            vm.amortizationTypes = [];
            vm.tabActive = {};
            vm.eligibilityError = '';
            vm.eligibilityType = '';
            vm.eligibilityTypeCounts = {
                all: 0,
                eligible: 0,
                ineligible: 0,
                nearmiss: 0
            };
            vm.termsFilter = {
                field: 'loanAmortizationType',
                operator: 'eq',
                value: ''
            };
            vm.doctypeFilter = {
                field: 'docType',
                operator: 'eq',
                value: ''
            };
            vm.rateFilter = {
                field: 'rate',
                operator: 'lte',
                value: ''
            };
            vm.loadingProducts = true;
            
            lesLoanApplicationsService.getLoanApplication(quickApplicationId).then(function(loanApplication){
                if(loanApplication.recordData.findings){
                    vm.quickApplication = loanApplication.recordData.loanApp;
                }
                else{
                    vm.quickApplication = loanApplication.recordData;
                }

                var mainBorrowerName = _.get(vm.quickApplication, 'mainBorrower.fullName');
                
                vm.mainBorrower = mainBorrowerName;
                vm.breadcrumbDisplay = (mainBorrowerName || 'No Primary Borrower') + ' (#' + _.last(quickApplicationId.split('-')) + ')';

                if (_.isEmpty($location.search())) {
                    setDefaultQueryParams();
                }
                
                lesLoanApplicationsService.getLoanPrograms(quickApplicationId).then(function (programData) {
                    vm.lenders = programData.lenders;

                    var eligibilityPromises = [];

                    _.forEach(vm.lenders, function (l) {
                        _.forEach(l.programs, function (p) {
                            var prom = lesLoanApplicationsService.getLoanProgramEligibility(quickApplicationId, p.id, l.conduit, $location.search()).then(function (eligibilityData) {
                                _.assign(p, eligibilityData);

                                return true;
                            }, function (err) {
                                vm.eligibilityError = err;

                                return true;
                            });

                            eligibilityPromises.push(prom);
                        });
                    });

                    $q.all(eligibilityPromises).then(function () {
                        vm.productEligibilityTableOptions = lesQuickApplicationService.productEligibilityTableOptions(vm.eligibilityType, vm.lenders);

                        var eligibilityTypeCounts = _.countBy(vm.productEligibilityTableOptions.dataSource.data, 'eligibility');

                        if (eligibilityTypeCounts.eligible) {
                            vm.tabActive['eligible'] = true;
                            vm.eligibilityType = 'eligible';
                            vm.productEligibilityTableOptions.dataSource.filter.filters.push({
                                field: 'eligibility',
                                operator: 'eq',
                                value: 'eligible'
                            });
                        }
                        else {
                            vm.tabActive['all'] = true;
                        }

                        eligibilityTypeCounts.all = vm.productEligibilityTableOptions.dataSource.data.length;
                        _.assign(vm.eligibilityTypeCounts, eligibilityTypeCounts);

                        var filterData = lesLoanApplicationsService.getFilterOptions(vm.lenders);
                        
                        vm.amortizationTypes = filterData.amortizationTypes;
                        vm.termsLookupOptions = lesLoanApplicationsService.eligibilityFilterLookupOptions(filterData.amortizationTypes);
                        
                        vm.docTypes = filterData.docTypes;
                        vm.docTypeLookupOptions = lesLoanApplicationsService.eligibilityFilterLookupOptions(filterData.docTypes);

                        vm.loadingProducts = false;

                        if (eligibilityTypeCounts.all === 0) {
                            vm.eligibilityError = 'No products found. Please refine your search.';
                        }
                    }, function () {
                        vm.loadingProducts = false;
                        vm.productEligibilityTableOptions = lesQuickApplicationService.productEligibilityTableOptions(vm.eligibilityType, []);
                    });
                }, function () {
                    vm.loadingProducts = false;
                    vm.productEligibilityTableOptions = lesQuickApplicationService.productEligibilityTableOptions(vm.eligibilityType, []);
                });
            }, function (reason) {
                if (reason == "Not Found.") {
                    vm.loadingProducts = false;
                    vm.notFound = true;
                }
            });
        }

        function setDefaultQueryParams() {
            var docTypes = [];

            if (vm.quickApplication.isSelfEmployed) {
                docTypes.push('12M Bank Statements');
                docTypes.push('24M Bank Statements');
                }
            // else {
                docTypes.push('Full Document'); // Now unconditional
            // }

            var baseLoanAmount = vm.quickApplication.BaseLoanAmount || 0;
            var totalAssets = vm.quickApplication.totalAssets || 0;

            if ((totalAssets * 2) >= baseLoanAmount) {
                docTypes.push('Asset Utilization');
            }

            if (vm.quickApplication.PropertyUsagetype.display == 'Investor') {
                docTypes.push('Debt Service Coverage');
            }

            $location.replace().search({
                amortizations: 'arm,fixed',
                ppp: 'no',
                docTypes: docTypes.join(',')
            });
        }

        function updateEligibilityFilter(eligibilityType) {
            if (eligibilityType != vm.eligibilityType) {
                vm.eligibilityType = eligibilityType;
                applyFilters();
            }
        }

        function applyFilters() {
            var filter = {
                logic: 'and',
                filters: []
            };

            if (vm.eligibilityType) {
                filter.filters.push({
                    field: 'eligibility',
                    operator: 'eq',
                    value: vm.eligibilityType
                });
            }

            if (vm.termsFilter.value && vm.termsFilter.value !== 'Choose...') {
                filter.filters.push(vm.termsFilter);
            }

            // if (vm.doctypeFilter.value && vm.doctypeFilter.value !== 'Choose...') {
            //     filter.filters.push(vm.doctypeFilter);
            // }

            if (vm.rateFilter.value || vm.rateFilter.value === 0) {
                filter.filters.push(vm.rateFilter);
            }

            $timeout(function () {
                if (vm.productEligibilityTable) {
                    vm.productEligibilityTable.dataSource.filter(filter);
                }
            });
        }

        function clearFilter(filter) {
            filter.value = '';
            applyFilters();
        }

        function refineSearch() {
            var defaultQueryParams = lesLoanApplicationsService.parseProductsQueryParams($location.search());
            lesLoanApplicationsService.openFindProductsModal(defaultQueryParams).then(function (result) {
                $location.replace().search(result);
            });
        }

        function navToLoanPage() {
            $location.url('0AC3913C5D696B2A869CFCF478F49283-177');
        }

        function openApplication() {
            $location.url('quick-application/' + quickApplicationId);
        }

        $scope.$on('$locationChangeStart', function (e, newUrl) {
            newUrl = newUrl || '';

            var path = '/quick-application/' + quickApplicationId + '/eligibility';
            if (newUrl.indexOf(path) < 0 && !e.defaultPrevented) {
                if (vm.productEligibilityTable) {
                    vm.productEligibilityTable.destroy();
                    vm.productEligibilityTable = null;
                }
                
                if (vm.termsLookup) {
                    vm.termsLookup.destroy();
                    vm.termsLookup = null;
                }
            }
        });
    })
;