angular.module('cerberus.account')
/**
 * @ngdoc config
 * @description router config
 */
    .config(function ($routeProvider) {
        $routeProvider.when('/users/drafts', {
            templateUrl: 'account/drafts/drafts.tpl.html',
            controller: 'DraftsCtrl',
            controllerAs: 'vm',
            title: 'Drafts',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
    })
;