angular.module('cerberus.admin')
    /**
     * @ngdoc service
     * @name PageObjectsDesignerService
     * @alias cerberus/pages-objects:PageObjectsDesignerService
     * @description Utilities for the Page Object Designer
     */
    .factory('PageObjectsDesignerService', function PageObjectsDesignerService($http, $q, apiPath, toaster){
        return {
            updatePageObject: updatePageObject,
            deletePageObject: deletePageObject
        };

        function updatePageObject(id, data){
            data.subType = data.subType || '';

            var deferred = $q.defer();
            $http.put(apiPath + 'page/objects/' + id, { pageObjectData: data })
                .success(function(value){
                    toaster.pop('success', 'Page Object Saved');
                    deferred.resolve(value);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function deletePageObject(id){
            var deferred = $q.defer();
            $http.delete(apiPath + 'page/objects/' + id)
                .success(function(value){
                    toaster.pop('success', 'Page Object Deleted');
                    deferred.resolve(value);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
    })
;