angular.module('cerberus')
    .config(function($routeProvider, $httpProvider, $provide, localStorageServiceProvider, gridsterConfig) {

        /**
         *  ROUTES
         */
        // Define page routes last so that all app module routes are enforced
        $routeProvider.when('/:pageId', {
            templateUrl: 'core/pages/pages.tpl.html',
            controller: 'PagesCtrl',
            resolve: {
                _wait: 'WaitForUserData',
                pageId: function($route){
                    return $route.current.params.pageId;
                }
            },
            reloadOnSearch: false
        });
        $routeProvider.when('/', {
            templateUrl: 'core/pages/pages.tpl.html',
            controller: 'PagesCtrl',
            resolve: {
                _wait: 'WaitForUserData',
                pageId: function(){
                    return null;
                }
            },
            reloadOnSearch: false
        });

        // Set Default Route
        $routeProvider.otherwise({redirectTo: '/'});

        /**
         *  HTTP
         */
        // Configure $http service to combine processing of multiple http responses
        // received at around the same time via $rootScope.$applyAsync.
        $httpProvider.useApplyAsync(true);

        // Add request header to protect against CSRF
        $provide.factory('csrfHttpIntercepter', function(){
            return {
                request: function(config){
                    config.headers['X-Requested-With'] = 'XMLHttpRequest';

                    return config;
                }
            };
        });
        $httpProvider.interceptors.push('csrfHttpIntercepter');

        /**
         *  Local Storage
         */
        localStorageServiceProvider.setPrefix('nim');

        /**
         *  Gridster
         */
        gridsterConfig.sparse                               =   true;
        gridsterConfig.pushing                              =   false;
        gridsterConfig.floating                             =   false;
        gridsterConfig.swapping                             =   false;
        gridsterConfig.width                                =   'auto';
        gridsterConfig.columns                              =   24;
        gridsterConfig.colWidth                             =   'auto';
        gridsterConfig.minColumns                           =   1;
        gridsterConfig.rowHeight                            =   8;
        gridsterConfig.minRows                              =   7;
        gridsterConfig.maxRows                              =   1000;
        gridsterConfig.defaultSizeX                         =   6;
        gridsterConfig.defaultSizeY                         =   7;
        gridsterConfig.margins                              =   [0,0];
        gridsterConfig.outerMargin                          =   true;
        gridsterConfig.mobileBreakPoint                     =   698; // will trigger on screen widths >= 768px
        gridsterConfig.mobileModeEnabled                    =   true;
        gridsterConfig.saveGridItemCalculatedHeightInMobile =   true;
        gridsterConfig.resizable.enabled                    =   false;
        gridsterConfig.resizable.handles                    =   ['s', 'e', 'n', 'w', 'se', 'ne', 'sw', 'nw'];
        gridsterConfig.draggable.enabled                    =   false;
        gridsterConfig.draggable.scrollSensitivity          =   20;
        gridsterConfig.draggable.scrollSpeed                =   20;
    })
;