angular.module('cerberus.admin')
    .controller('WorkspacesCreateModalCtrl', function WorkspacesCreateModalCtrl($scope, $uibModalInstance, workspacesService, isSubWorkspace, $rootScope) {
        $scope.workspaceObject = {};
        $scope.isSubWorkspace = isSubWorkspace;
        $scope.currentWorkspace = $rootScope.userData.currentWorkspace.id;
        $scope.orgSubDomain = $rootScope.userData.currentOrg.sub_domain;

        $scope.updatePath = function(){
            var name = $scope.workspaceObject.name || '';
            $scope.workspaceObject.path = name.replace(/[^A-Z0-9a-z]/g,'').toLowerCase();
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.ok = function () {
            if(isSubWorkspace > 0){
                $scope.workspaceObject.parent = $scope.currentWorkspace;
            }
            workspacesService.create($rootScope.userData.currentOrg.id,$scope.workspaceObject).then(function(data){
                $uibModalInstance.close(data);
            });
        };

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;
