angular.module('cerberus.account')
    .factory('UsersService', function UsersService($http, $q, apiPath) {
            return {
                updateMemberOf: function(id, data) {
                    var deferred = $q.defer();
                    $http.put(apiPath + 'users/' + id + '/memberOf',data)
                        .success(function (value) {
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                }
            };
        })
;
