angular.module('cerberus.account')
    .controller('OrganizationsUsersDetailsCtrl', function OrganizationsUsersDetailsCtrl($scope, kendo, $location, orgId, userId,
                                                                                        OrganizationsService, organizationGroupsService,
                                                                                        organizationUsersService, UsersService, usersProfileService) {
        var vm = this;
        $scope.activeRoute = 'users';
        $scope.orgId = orgId;
        $scope.orgData = {};

        vm.userInfo = null;
        vm.userId = userId;
        vm.tempGroupModel = {
            details: null,
            members: {current:[]},
            memberOf:{
                current:[],
                add:[],
                remove:[],
                input:[]
            }
        };
        vm.origGroupModel = angular.copy(vm.tempGroupModel);
        vm.selectedMemberOf = {};
        vm.availableMemberOfDs = null;
        vm.memberOfSelectOptions = {
            placeholder: 'Enter group(s) here...',
            dataTextField: 'name'
        };

        vm.add = organizationGroupsService.addItem;
        vm.remove = organizationGroupsService.removeItem;
        vm.isUnchanged = function(){
            return organizationGroupsService.isUnchanged(vm.tempGroupModel,vm.origGroupModel);
        };
        vm.submit = function(isValid,data){
            if(isValid){
                if(!angular.equals(data.memberOf.current, vm.origGroupModel.memberOf.current)){
                    UsersService.updateMemberOf(vm.userId,{add:data.memberOf.add, remove:data.memberOf.remove}).then(function(){
                        $location.url('organizations/' + $scope.orgId + '/settings/users');
                    });
                }
            }
        };

        vm.cancel = function(){
            $location.url('organizations/' + $scope.orgId + '/settings/users');
        };

        init();

        function init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });

            usersProfileService.getDefaultUserProfile(userId).then(function(data){
               vm.userInfo = data[0].data;
            });

            organizationUsersService.getMemberOf(orgId, userId).then(function(data){
                vm.tempGroupModel.memberOf.current = data;
                vm.origGroupModel = angular.copy(vm.tempGroupModel);
            });

            organizationUsersService.getAvailableMemberOf(orgId, userId).then(function(data){
                vm.availableMemberOfDs = new kendo.data.ObservableArray(data);
            });
        }
    })
;