angular.module('cerberus.ui')
    /**
     * @ngdoc service
     * @name nimSignatureService
     * @alias cerberus/ui:nimSignatureService
     * @description Provides functionality to nimSignaturePad directive
     */
    .factory('nimSignatureService', function ($http, $q, $uibModal, apiPath) {
        var service = {
            openModal: openModal,
            saveImage: saveImage,
            getSignatureWithAccount: getSignatureWithAccount
        };
        return service;
        ////////////////////
        function openModal(isInitial, restrictTo, penColor, isTouchpadSign) {
            return $uibModal.open({
                backdropClass: 'verify-sig',
                windowClass: 'modal-verify-sig',
                backdrop: 'static',
                size: 'lg',
                templateUrl: 'ui/nim-signature/nim-signature-modal.tpl.html',
                controller: 'nimSignatureModalCtrl',
                controllerAs: 'sig',
                resolve: {
                    isInitial: function () {
                        return isInitial || false;
                    },
                    restrictTo: function () {
                        return restrictTo || 'any';
                    },
                    penColor: function () {
                        return penColor;
                    },
                    isTouchpadSign: function () {
                        return isTouchpadSign || false;
                    }
                }
            });
        }

        function saveImage (base64) {
            var deferred = $q.defer();
            $http.post(apiPath + 'utilities/image', {base64String: base64})
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getSignatureWithAccount(){
            var deferred = $q.defer();
            $http.get(apiPath + 'users/verify/signature')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
    })
;