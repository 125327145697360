angular.module('cerberus.admin')
    .controller('FormsDesigner_SectionCtrl', function FormsDesigner_SectionCtrl(_, $scope, $uibModalInstance, section, sectionArray, formOriginId, fields, DesignerUtilityService){
        var vm = this;
        vm.orig = angular.copy(section);
        vm.temp = angular.copy(section);
        vm.fields = [];
        vm.fieldType = '';
        vm.condition = {};
        vm.operators = DesignerUtilityService.conditionOperators();
        vm.conditionActionValues = DesignerUtilityService.conditionActionValues();
        vm.boolValues = [
            {value: true, display: 'True'},
            {value: false, display: 'False'}
        ];

        vm.save = save;
        vm.cancel = cancel;
        vm.isUnchanged = isUnchanged;
        vm.validateName = validateName;
        vm.addCondition = addCondition;
        vm.removeCondition = removeCondition;
        vm.checkFieldType = checkFieldType;

        init();

        function init() {
            vm.fields = [{
                model: {
                    config: {
                        modelId: '__nimRecordAction'
                    },
                    label: {
                        text: '(Record Action)'
                    }
                }
            }].concat(_.sortBy(fields, function (f) {
                return _.get(f, 'model.config.modelId');
            }));
        }

        function save(){
            if(!$scope.configForm.$invalid && !vm.isUnchanged()) {
                $uibModalInstance.close(vm.temp);
            }
        }

        function cancel(){
            $uibModalInstance.dismiss();
        }

        function isUnchanged(){
            return angular.equals(vm.temp, vm.orig);
        }

        function validateName(input, name){
            input.$setValidity('hasName', _.get(name, 'length', 0) > 0);
            input.$setValidity('notTaken', sectionArray.indexOf(name) < 0);
        }

        function addCondition(section, condition){
            condition.formOriginId = formOriginId;
            section.condition.push(angular.copy(condition));
            _.assign(condition, {modelId: null, op: null, val: null, isFormObj: false});
        }

        function removeCondition(section, index){
            _.pullAt(section.condition, index);
        }

        function checkFieldType(condition){
            var field = _.find(fields, 'model.config.modelId', condition.modelId);
            var fieldType = '',
                type = _.get(field, 'model.config.type'),
                dataType = _.get(field, 'model.config.dataType'),
                lookUpType = _.get(field, 'model.option.type');

            if(type === 'checkbox'){
                fieldType = 'boolean';
            }
            else if(dataType === 'string'){
                fieldType = 'string';
            }
            else if(dataType === 'number'){
                fieldType = 'number';
            }
            else if(lookUpType === 'custom' || lookUpType === 'views'){
                fieldType = 'lookup';
            }
            else if (condition.modelId == '__nimRecordAction') {
                fieldType = 'action';
            }

            if(fieldType !== vm.fieldType){
                condition.op = '';

                if(fieldType === 'boolean'){
                    condition.op = 'eq';
                    condition.val = true;
                }

                if(vm.fieldType === 'boolean'){
                    condition.val = null;
                }
            }

            vm.fieldType = fieldType;
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;