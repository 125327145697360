angular.module('cerberus.les')
    .controller('LESLoanApplicationExportModalCtrl', function($scope, $window, $uibModalInstance, loanApplicationId, InstancesService, lesLoanApplicationsService){
        var vm = this;

        vm.exportType = '';
        vm.exporting = false;

        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            if (vm.exportType) {
                vm.exporting = true;

                lesLoanApplicationsService.exportLoanApplication(loanApplicationId, vm.exportType).then(function (exportAttachment) {
                    // Save exported file
                    InstancesService.getDownloadKey(exportAttachment.id).then(function (d) {
                        InstancesService.getBlob(d).then(function (blob) {
                            $window.saveAs(blob, exportAttachment.name);

                            finishExport();
                        }, finishExport);
                    }, finishExport);
                }, function () {
                    vm.exporting = false;
                });
            }
        }

        function cancel(){
            $uibModalInstance.dismiss('cancel');
        }

        function finishExport() {
            vm.exporting = false;
            $uibModalInstance.close();
        }

        // Prevents navigation while modal is open
        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;