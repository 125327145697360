angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name PagesService
     * @alias cerberus/core:PagesService
     * @description REST service for "pages" resource
     */
    .factory('PagesService', function PagesService(_, $http, $q, $rootScope, apiPath, toaster, DesignerUtilityService, PagesClassService) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';
        return {
            getPage: getPage,
            getPages: getPages,
            getPagePermissions: getPagePermissions,
            getDefault: getDefault,
            getUserInfo: getUserInfo,
            buildUrlParams: buildUrlParams,
            readUrlParams: readUrlParams,
            filterObjectToString: filterObjectToString,
            filterStringToObject: filterStringToObject
        };
        ////////////////////////
        /**
         * Given a valid response for the REST api this function
         * constructs classes for the page objects and sort them for display
         * @function processPageData
         * @param {object} data - this is the return from the HTTP call
         * @return {object} pageModel
         */
        function processPageData(data) {
            var pageModel = data;
            if(pageModel.items && pageModel.items.length > 0){
                for(var i = 0; i < pageModel.items.length; i++){
                    if(pageModel.items[i].pageObject){
                        var pageObj = PagesClassService.newPageObject();
                        pageObj.setData(angular.fromJson(pageModel.items[i].pageObject));
                        pageModel.items[i].pageObject = pageObj;
                    }

                    delete pageModel.items[i].imported;
                }

                pageModel.items.sort(
                    DesignerUtilityService.firstBy(function(v1,v2){return v1.row - v2.row;})
                        .thenBy(function(v1,v2){return v1.col - v2.col;})
                );
            }
            if(pageModel.options){
                pageModel.options.filters = pageModel.options.filters || [];
            }
            return pageModel;
        }

        /**
         * Makes a get call REST api "pages" resource by id
         * @function getPage
         * @param {string} id
         * @return {promise}
         */
        function getPage(id) {
            var deferred = $q.defer();
            $http.get(apiPath + 'pages/'+ id)
                .success(function(value){
                    deferred.resolve(processPageData(value.DATA));
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Makes a get call REST api "pages" resource
         * @function getDefault
         * @return {promise}
         */
        function getDefault() {
            var deferred = $q.defer();
            $http.get(apiPath + 'pages/default')
                .success(function(value){
                    deferred.resolve(processPageData(value.DATA));
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function getPages(){
            var deferred = $q.defer();
            $http.get(apiPath + 'pages')
                .success(function(value){
                    deferred.resolve(processPageData(value.DATA));
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function getPagePermissions(id){
            var deferred = $q.defer();
            $http.get(apiPath + 'pages/' + id + '/permissions')
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function getUserInfo(){
            return {
                firstName: $rootScope.userData.firstName,
                lastName: $rootScope.userData.lastName,
                fullName: $rootScope.userData.firstName + ' ' + $rootScope.userData.lastName
            };
        }

        function buildUrlParams(filters) {
            var params = {},
                filterDisplays = {};
            
            _.forEach(filters, function (filterObj, filter) {
                var filterString = filterObjectToString(filterObj, filterDisplays);
                if (filterString) {
                    params[filter] = filterString;

                    if (!_.isEmpty(filterObj.filters)) {
                        _.forEach(filterObj.filters, function (f) {
                            if (f.lookupDisplay) {
                                filterDisplays[filter] = f.lookupDisplay;
                            }
                        });
                    }
                }
            });

            if (!_.isEmpty(filterDisplays)) {
                var displayPairs = [];
                _.forEach(filterDisplays, function (displayValue, filter) {
                    displayPairs.push(filter + ',' + displayValue);
                });
                params.__nimFilterDisplay = displayPairs.join('|');
            }    
            
            return params;
        }

        function readUrlParams(params) {
            var filters = {},
                filterDisplays = {};

            if (params.__nimFilterDisplay) {
                var displayPairs = params.__nimFilterDisplay.split('|');
                _.forEach(displayPairs, function (pairString) {
                    var pair = pairString.split(','),
                        filter = _.head(pair),
                        displayValue = _.tail(pair).join(',');
                    
                    filterDisplays[filter] = displayValue;
                });
            }

            _.forEach(params, function (filterString, filter) {
                if (filter == '__nimFilterDisplay') {
                    return false;
                }

                var filterObj = filterStringToObject(filterString, filterDisplays);
                if (filterDisplays[filter] && !_.isEmpty(filterObj.filters)) {
                    filterObj.filters[0].lookupDisplay = filterDisplays[filter];
                }
                filters[filter] = filterObj;
            });

            return filters;
        }

        function filterObjectToString(filterObj){
            if(!filterObj){
                return;
            }

            if(!_.isEmpty(filterObj.filters)){
                var logic = filterObj.logic || 'and';
                var filters = _.map(filterObj.filters, function(f){
                    return filterObjectToString(f);
                });

                filters = _.compact(filters);

                return '(' + _.compact(filters).join(' ' + logic + ' ') + ')';
            }
            else {
                var field = filterObj.field;
                var value = filterObj.value;
                if(_.isNull(value) || _.isUndefined(value)){
                    return;
                }
                else if(value instanceof Date){
                    var dateValue = new Date(value);
                    value = dateValue.toISOString();
                }
                else if(typeof value === 'string'){
                    value = "'" + escapeOperators(value) + "'";
                }

                switch(filterObj.operator){
                    case 'contains':
                        return 'substringof(' + value + ',' + field + ')';
                    case 'doesnotcontain':
                        return 'substringof(' + value + ',' + field + ') eq false';
                    case 'startswith':
                        return 'startswith(' + field + ',' + value + ')';
                    case 'endswith':
                        return 'endswith(' + field + ',' + value + ')';
                    case 'eq':
                        return field + ' eq ' + value;
                    case 'neq':
                        return field + ' ne ' + value;
                    case 'gt':
                        return field + ' gt ' + value;
                    case 'gte':
                        return field + ' ge ' + value;
                    case 'lt':
                        return field + ' lt ' + value;
                    case 'lte':
                        return field + ' le ' + value;
                    default:
                        return '';
                }
            }
        }

        function filterStringToObject(filterStr){
            if(!filterStr){
                return;
            }

            var filter = {};
            if(_.startsWith(filterStr, '(') && _.endsWith(filterStr, ')')){
                var innerStr = filterStr.substr(1, filterStr.length - 2);
                var logic = 'and';

                if(innerStr.search(' or ') >= 0){
                    logic = 'or';
                }

                var filters = innerStr.split(' ' + logic + ' ');

                filter.logic = logic;
                filter.filters = _.compact(_.map(filters, filterStringToObject));
            }
            else {
                var field = null;
                var value = null;
                if(filterStr.search(/^substringof\(.*\)$/) >= 0){
                    filter.operator = 'contains';
                    filterStr = filterStr.replace(/^substringof\(/, '').replace(/\)$/, '');
                    var containsSubStr = filterStr.split(',');
                    field = containsSubStr.pop();
                    value = containsSubStr.join(',');
                }
                else if(filterStr.search(/^substringof\(.*\) eq false$/) >= 0){
                    filter.operator = 'doesnotcontain';
                    filterStr = filterStr.replace(/^substringof\(/, '').replace(/\) eq false$/, '');
                    var doesNotContainSubStr = filterStr.split(',');
                    field = doesNotContainSubStr.pop();
                    value = doesNotContainSubStr.join(',');
                }
                else if(filterStr.search(/^startswith\(.*\)$/) >= 0){
                    filter.operator = 'startswith';
                    filterStr = filterStr.replace(/^startswith\(/, '').replace(/\)$/, '');
                    var startsWithSubStr = filterStr.split(',');
                    field = startsWithSubStr.pop();
                    value = startsWithSubStr.join(',');
                }
                else if(filterStr.search(/^endswith\(.*\)$/) >= 0){
                    filter.operator = 'endswith';
                    filterStr = filterStr.replace(/^endswith\(/, '').replace(/\)$/, '');
                    var endsWithSubStr = filterStr.split(',');
                    field = endsWithSubStr.pop();
                    value = endsWithSubStr.join(',');
                }
                else if(filterStr.search(/ eq /) >= 0){
                    filter.operator = 'eq';
                    var eqSubStr = filterStr.split(' eq ');
                    field = eqSubStr[0];
                    value = eqSubStr[1];
                }
                else if(filterStr.search(/ ne /) >= 0){
                    filter.operator = 'neq';
                    var neSubStr = filterStr.split(' ne ');
                    field = neSubStr[0];
                    value = neSubStr[1];
                }
                else if(filterStr.search(/ gt /) >= 0){
                    filter.operator = 'gt';
                    var gtSubStr = filterStr.split(' gt ');
                    field = gtSubStr[0];
                    value = gtSubStr[1];
                }
                else if(filterStr.search(/ ge /) >= 0){
                    filter.operator = 'gte';
                    var geSubStr = filterStr.split(' ge ');
                    field = geSubStr[0];
                    value = geSubStr[1];
                }
                else if(filterStr.search(/ lt /) >= 0){
                    filter.operator = 'lt';
                    var ltSubStr = filterStr.split(' lt ');
                    field = ltSubStr[0];
                    value = ltSubStr[1];
                }
                else if(filterStr.search(/ le /) >= 0){
                    filter.operator = 'lte';
                    var leSubStr = filterStr.split(' le ');
                    field = leSubStr[0];
                    value = leSubStr[1];
                }

                filter.field = field;
                filter.value = parseFilterValue(value);
            }

            return filter;
        }

        function parseFilterValue(rawString){
            if(_.isNull(rawString)){
                return;
            }
            if(_.startsWith(rawString, '\'') && _.endsWith(rawString, '\'')){
                return removeEscaping(rawString).replace(/^'/, '').replace(/'$/, '');
            }
            else if(rawString.search(/^[0-9]{4}-[0-9]{2}-[0-9]{2}[Tt][0-9]{2}:[0-9]{2}:[0-9]{2}(.[0-9]{1,4})?[Zz]?$/) >= 0){
                return new Date(rawString);
            }
            else {
                var numberValue = parseFloat(rawString);
                if(!isNaN(numberValue)){
                    return numberValue;
                }
            }
        }

        function escapeOperators(value){
            return value
                .replace(/ and /g, ' \\and ')
                .replace(/ or /g, ' \\or ')
                .replace(/ eq /g, ' \\eq ')
                .replace(/ ne /g, ' \\ne ')
                .replace(/ gt /g, ' \\gt ')
                .replace(/ ge /g, ' \\ge ')
                .replace(/ lt /g, ' \\lt ')
                .replace(/ le /g, ' \\le ');
        }

        function removeEscaping(value){
            return value
                .replace(/ \\and /g, ' and ')
                .replace(/ \\or /g, ' or ')
                .replace(/ \\eq /g, ' eq ')
                .replace(/ \\ne /g, ' ne ')
                .replace(/ \\gt /g, ' gt ')
                .replace(/ \\ge /g, ' ge ')
                .replace(/ \\lt /g, ' lt ')
                .replace(/ \\le /g, ' le ');
        }
    })
;