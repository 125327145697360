angular.module('cerberus.core')
    .factory('SearchService',
        function ($http, $q, apiPath) {
            var service = {
                search: search,
                getInstanceIdViaAttachmentId: getInstanceIdViaAttachmentId,
                formatPagination: formatPagination
            };
            return service;
            ////////////////////
            function search(searchTxt,size, current){
                var start = (current - 1) * size;
                if(start < 1){
                    start = 1;
                }
                var top = size;
                var deferred = $q.defer();
                $http.get(apiPath + 'search?q=' + searchTxt + '&$top=' + top + '&$start=' + start)
                    .success(function (value) {
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(reason);
                    });
                return deferred.promise;
            }
            function getInstanceIdViaAttachmentId(id){
                var deferred = $q.defer();
                $http.get(apiPath + 'attachments/' + id + '/get/instance')
                    .success(function (value) {
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(reason);
                    });
                return deferred.promise;
            }

            /**
             *
             * @param total
             * @param size
             * @param current
             * @param paginationSize
             * @returns {Array}
             */
            function formatPagination(total,size,current,paginationSize){
                var rSize = size || 10;
                var pSize = paginationSize || 10;
                var pageArray = [];
                var pages = Math.ceil(total / rSize);
                if(current <= pages) {
                    var startingPage = 0;
                    if(current > pSize/2 && pages - pSize > -1){
                        startingPage = current - (pSize/2);
                    }
                    var pagesToShow = Math.min(pages,pSize);
                    if(startingPage > pages - pSize && startingPage > 0){
                        startingPage = pages - pSize;
                    }
                    for (var i = 0; i < pagesToShow; i++) {
                        pageArray.push({
                            num: startingPage + i + 1
                        });
                    }
                }
                return pageArray;
            }
        }
    )
;