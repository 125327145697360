angular.module('cerberus.account')
    .controller('OrganizationsProfileCtrl', function OrganizationsProfileCtrl($scope, $window, orgId, OrganizationsService) {
        $scope.activeRoute = 'profile';
        $scope.orgId = orgId;
        $scope.orgData = {};
        $scope.tempData = {};
        $scope.origData = {};

        $scope.clearLogo = function(){
            $scope.tempData.logo = '';
            $scope.imageSrc = null;
        };
        $scope.submit = function(isValid, data){
            if(isValid){
                OrganizationsService.update($scope.orgId, data).then(function(){
                    _init();
                });
            }
        };
        $scope.hasChanges = function () {
            return !angular.equals($scope.origData, $scope.tempData);
        };
        $scope.cancel = function(){
            $window.history.back();
        };

        _init();

        function _init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
                $scope.tempData = angular.copy(orgData);
                $scope.origData = angular.copy(orgData);
            });
        }
    })
;