angular.module('cerberus.admin')
    .controller('PagesDesignerCtrl', function PagesDesignerCtrl(_, $scope, $rootScope, $uibModal, $location, $timeout, $q, toaster, pageId,
                                                                ConfirmModalService, DesignerUtilityService, PagesService, PagesClassService, pageObjectsService,
                                                                PageDesignerService, PagesDesignerConvenienceService, PageObjectsDesignerService, WorkspacesGroupsService) {
        $scope.designerWidth = 'auto';
        $scope.filters = {};
        $scope.settingsCollapse = true;
        $scope.importPageObjectId = null;
        $scope.gridsterOpts = {
            swapping: false,
            resizable: {
                enabled: true,
                stop: pageObjectMove
            },
            draggable: {
                enabled: true,
                stop: pageObjectMove
            },
            height: 'auto',
            margins: _.get($scope.temp, 'options.gridsterConfig.margins', [15, 15]),
            outerMargin: _.get($scope.temp, 'options.gridsterConfig.outerMargin', true)
        };

        // CRUD Arrays
        $scope.createArray = [];
        $scope.updateArray = [];
        $scope.deleteArray = [];

        $scope.isPreviewObj = isPreviewObj;

        function pageObjectMove(event, element, widget){
            if($scope.createArray.indexOf(widget) < 0 && $scope.updateArray.indexOf(widget) < 0){
                $scope.updateArray.push(widget);
            }
        }

        function formatName(name){
            var formattedName = '';
            if(name && name.length > 0){
                formattedName += name.charAt(0).toUpperCase();
                if(name.length > 1){
                    formattedName += name.substr(1, name.length - 1).toLowerCase();
                }
            }
            return formattedName;
        }

        $scope.hasChanges = function () {
            return !(angular.equals($scope.orig, $scope.temp) && angular.equals($scope.permissions, $scope.oldPermissions));
        };

        DesignerUtilityService.cancelRouteNav($scope, $scope.hasChanges);

        // Page Item Commands
        $scope.deleteBox = function(index){
            var cin = $scope.createArray.indexOf($scope.temp.items[index]);
            var uin = $scope.updateArray.indexOf($scope.temp.items[index]);
            if(cin >= 0){
                $scope.createArray.splice(cin, 1);
            }
            else{
                if(uin >= 0){
                    $scope.updateArray.splice(uin, 1);
                }
                $scope.deleteArray.push($scope.temp.items[index].id);
            }
            $scope.temp.items.splice(index, 1);
        };

        $scope.deletePage = function(data){
            ConfirmModalService.showModal(null, {
                headerText: 'Confirm Deletion',
                bodyText: 'Are you sure you want to delete this page?',
                instructionText: 'Type "DELETE ' + data.name + '" to confirm.',
                actionButtonText: 'Delete Page Forever',
                closeButtonText: 'Cancel',
                check: function(input){
                    return angular.equals(input, 'DELETE ' + data.name);
                },
                confirm: function(){
                    return PageDesignerService.deletePage(data.id);
                },
                callback: function(){
                    $location.url('/settings/pages');
                }
            });
        };

        $scope.deletePageObject = function(pageObject, index){
            ConfirmModalService.showModal(null, {
                headerText: 'Confirm Deletion',
                bodyText: 'Are you sure you want to delete this page object and remove it from each page that uses it?',
                instructionText: 'Type "DELETE ' + pageObject.name + '" to confirm.',
                actionButtonText: 'Delete Page Object',
                closeButtonText: 'Cancel',
                check: function(input){
                    return angular.equals(input, 'DELETE ' + pageObject.name);
                },
                confirm: function(){
                    return PageObjectsDesignerService.deletePageObject(pageObject.id);
                },
                callback: function(){
                    var uin = $scope.updateArray.indexOf(pageObject);
                    if(uin >= 0){
                        $scope.updateArray.splice(uin, 1);
                    }

                    $scope.temp.items.splice(index, 1);
                }
            });
        };

        $scope.copyPageObject = function(item){
            var newObj = angular.copy(item);
            newObj.id = null;
            delete newObj.imported;
            $scope.createArray.push(newObj);
            $scope.temp.items.push(newObj);
        };

        $scope.openPageObjectConfigModal = function(item, index){
            var controller;
            if(item.viz.subType){
                controller = formatName(item.viz.subType) + 'ConfigCtrl';
            }
            else if(item.viz.type){
                controller = formatName(item.viz.type) + 'ConfigCtrl';
            }
            else{
                controller = formatName(item.type) + 'ConfigCtrl';
            }

            if(item.wrapper.header === true){
                item.wrapper.header = {style:{}};
            }
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/pages/designer/common/config-modal.tpl.html',
                controller: controller,
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    pageObject: function (){
                        return item;
                    },
                    filters: function () {
                        //return $scope.temp.options.filters;
                        return DesignerUtilityService.getFilters($scope.temp.items, item);
                    }
                }
            });
            modalInstance.result.then(function (data) {
                    if(!data.hasOwnProperty('subType')){
                        data.subType = null;
                    }

                    data.viz.settings.batchEdit = data.tools.edit || data.tools.delete;

                    if(index < 0){
                        $scope.temp.items.push(data);
                        $scope.createArray.push(data);
                    }
                    else{
                        var cin = $scope.createArray.indexOf($scope.temp.items[index]);
                        var uin = $scope.updateArray.indexOf($scope.temp.items[index]);
                        $scope.temp.items[index] = data;
                        if(cin >= 0){
                            $scope.createArray[cin] = data;
                        }
                        else if(uin >= 0){
                            $scope.updateArray[uin] = data;
                        }
                        else{
                            $scope.updateArray.push(data);
                        }
                    }

                    if(data.id){
                        var deleteIndex = $scope.deleteArray.indexOf(data.id);
                        if(deleteIndex >= 0){
                            $scope.deleteArray.splice(deleteIndex, 1);
                        }
                    }
                },
                function(reason){
                    if(reason === 'delete'){
                        $scope.deletePageObject(item, index);
                    }
                });
        };

        $scope.createPageObject = function(type, vizCode){
            var item = PagesClassService.newPageObject();
            if (vizCode && PagesDesignerConvenienceService[vizCode]) {
                item.viz = PagesDesignerConvenienceService[vizCode]();
                item.type = type;
                _.defaults(item.tools, {
                    showToolbar: false,
                    create: true,
                    delete: false,
                    refresh: true
                });

                if(item.viz.type === 'template'){
                    _.defaults(item.tools, {
                        print: true
                    });
                }
                else if(item.viz.type === 'kanban'){
                    _.defaults(item.params, {
                        editable: true
                    });
                }
            }
            else if(type && PagesClassService[type]){
                item = PagesClassService[type]();
            }

            if(vizCode === 'table' || vizCode === 'listview' || vizCode === 'scheduleGantt' || vizCode === 'schedule'){
                item.wrapper = PagesDesignerConvenienceService.portlet();
            }
            else if(type === 'tabset'){
                item.wrapper = PagesDesignerConvenienceService.tile();
                item.wrapper.shadow = false;
            }
            else {
                item.wrapper = PagesDesignerConvenienceService.tile();
            }

            $scope.openPageObjectConfigModal(item, -1);
        };

        $scope.importPageObject = function(importPageObjectId, duplicate){
            var index = $scope.temp.items.map(function(obj){ return obj.id; }).indexOf(importPageObjectId);

            if(index < 0 || duplicate){ // Doesn't import an object already on the page
                pageObjectsService.getPageObject(importPageObjectId).then(function(pageObject){
                    $timeout(function(){
                        $scope.importPageObjectId = null;
                        $scope.importShow = false;
                        delete pageObject.col;
                        delete pageObject.row;
                        pageObject.sizeX = 6;
                        pageObject.sizeY = 7;
                        pageObject.subType = null;
                        if(duplicate){
                            pageObject.id = null;
                            $scope.createArray.push(pageObject);
                        }
                        else {
                            pageObject.imported = true;
                            $scope.updateArray.push(pageObject);
                        }

                        $scope.temp.items.push(pageObject);

                        var deleteIndex = $scope.deleteArray.indexOf(pageObject.id);
                        if(deleteIndex >= 0){
                            $scope.deleteArray.splice(deleteIndex, 1);
                        }
                    });
                });
            }
        };

        $scope.isUnchanged = function () {
            return angular.equals($scope.orig, $scope.temp) && angular.equals($scope.permissions, $scope.oldPermissions);
        };

        $scope.save = function (close) {
            var promises = [];

            if($scope.createArray.length > 0) {
                promises.push(
                    PageDesignerService.createObjects($scope.temp.id, $scope.createArray).then(function(){
                        $scope.createArray = [];
                    })
                );
            }
            if($scope.updateArray.length > 0) {
                promises.push(
                    PageDesignerService.updateObjects($scope.temp.id, $scope.updateArray).then(function(){
                        $scope.updateArray = [];
                    })
                );
            }
            if($scope.deleteArray.length > 0) {
                promises.push(
                    PageDesignerService.removeObjects($scope.temp.id, $scope.deleteArray).then(function(){
                        $scope.deleteArray = [];
                    })
                );
            }
            if(optionsChanged($scope.temp, $scope.orig)){
                promises.push(
                    PageDesignerService.update($scope.temp.id, $scope.temp.options, $scope.temp.name, $scope.temp.description, $scope.temp.icon)
                );
            }
            if(!angular.equals($scope.permissions, $scope.oldPermissions)){
                promises.push(
                    PageDesignerService.updatePagePermissions($scope.temp.id, {permissions:$scope.permissions})
                );
            }

            $q.all(promises).then(function(){
                toaster.pop('success', 'Page Saved');
                if(close){
                    $scope.orig = angular.copy($scope.temp);
                    $scope.close();
                }
                else {
                    PagesService.getPage($scope.temp.id).then(function(data){
                        $scope.temp = angular.copy(data);
                        $scope.orig = angular.copy(data);
                    });
                    $scope.oldPermissions = angular.copy($scope.permissions);
                }
            });
        };

        $scope.saveAndClose = function () {
            $scope.save(true);
        };

        $scope.close = function () {
            $location.url('/settings/pages');
        };

        $scope.$on('nim-page-object-list-selected', function(e, pageObject){
            e.stopPropagation();
            $timeout(function(){
                $scope.importPageObjectId = pageObject.id;
            });
        });

        $scope.updateGridsterMargins = updateGridsterMargins;

        function optionsChanged(temp, orig){
            return !(angular.equals(temp.options, orig.options) && temp.name === orig.name && temp.description === orig.description && temp.icon === orig.icon);
        }

        _init();

        $scope.$on('$locationChangeStart', function (e) {
            if (!e.defaultPrevented) {
                $scope.$broadcast('nim-remove-page-objects');
            }
        });

        function _init(){
            PagesService.getPage(pageId).then(function(pageModel){
                _.defaults(pageModel.options, { gridsterConfig:{ margins: [15, 15], outerMargin: true } });

                var gridsterConfig = pageModel.options.gridsterConfig;

                // Protects against potential issue with gridster margins being saved as an object instead of an array
                if(!_.isArray(gridsterConfig.margins)){
                    gridsterConfig.margins = [
                        gridsterConfig.margins[0],
                        gridsterConfig.margins[1]
                    ];
                }

                $scope.gridsterOpts.margins = _.get(pageModel.options, 'gridsterConfig.margins');
                $scope.gridsterOpts.outerMargin = _.get(pageModel.options, 'gridsterConfig.outerMargin');

                $scope.orig = angular.copy(pageModel);
                $scope.temp = angular.copy(pageModel);
            });

            PagesService.getPagePermissions(pageId).then(function(permissions){
                $scope.oldPermissions = angular.copy(permissions);
                $scope.permissions = angular.copy(permissions);
            });

            WorkspacesGroupsService.getGroups($rootScope.userData.currentWorkspace.id).then(function(groups){
                $scope.groups = _.filter(groups, function(g) {
                    return g.type === 'Custom' || g.type === 'User';
                });
            });
        }

        function updateGridsterMargins(pageObjectGridsterConfig, gridsterOptions){
            gridsterOptions.margins = pageObjectGridsterConfig.margins;
        }

        function isPreviewObj(pageObject) {
            return !!(!pageObject.id || _.find($scope.updateArray, 'id', pageObject.id));
        }
    })
;