angular.module('cerberus.admin')
    .directive('nimModelIdAvailable', function($http, apiPath) {
        return {
            require : 'ngModel',
            link : function($scope, element, attrs, ngModel) {
                ngModel.$asyncValidators.nimModelIdAvailable = function(name) {
                    valid(false);
                    loading(true);
                    return $http.get(apiPath + 'forms/model/id/available?id='+ name).then(
                        function(value) {
                            valid(value.data.DATA.isAvailable);
                            loading(false);
                        }
                    );
                };
                ////////////////////
                function valid(bool) {
                    ngModel.$setValidity('record-unavailable', bool);
                }

                function loading(bool) {
                    ngModel.$setValidity('record-loading', !bool);
                }
            }
        };
    })
;