angular.module('cerberus.admin')
    .controller('BulletConfigCtrl', function BulletConfigCtrl(_, $scope, $uibModalInstance, pageObject, filters,
                                                              DesignerUtilityService, PagesDesignerListService,
                                                              pageObjectConfigService, ViewsService, vizListService, WidgetsService, $timeout) {
        var vm = this;
        
        vm.temp = angular.copy(pageObject);
        vm.orig = angular.copy(pageObject);
        vm.filters = filters;
        vm.margin = DesignerUtilityService.cssToArray(pageObject.wrapper.style.margin) || [0,0,0,0];
        vm.padding = DesignerUtilityService.cssToArray(pageObject.wrapper.style.padding) || [0,0,0,0];
        vm.wrapperTypes = PagesDesignerListService.wrapperTypes();
        vm.wrapperConfig = PagesDesignerListService.wrapperConfig();
        vm.filterOperators = pageObjectConfigService.filterOperators();
        vm.textEditorOptions = pageObjectConfigService.textEditorOptions($scope);
        vm.vizDict = vizListService.vizDictionary();
        vm.views = [];
        vm.widgets = [];
        vm.viewColumns = [];
        vm.editView =  vm.temp.viz.settings.dataSource['value'].nim_viewId;
        vm.editDataSource = 'value';
        vm.paletteOptions = {
            palette: PagesDesignerListService.palette(),
            columns: 31,
            tileSize: 18
        };
        vm.filterMap = {};
        vm.tempFilter = {};
        vm.filterType = null;
        vm.sortObj = {
            field: '',
            dir: 'desc'
        };
        vm.formFieldsArray = [];
        vm.labelFormats = pageObjectConfigService.chartLabelFormats();

        vm.tabTemplates = [
            {name: "General", url: "admin/pages/designer/common/general-tab.tpl.html"},
            {name: "Wrapper", url: "admin/pages/designer/common/wrapper-tab.tpl.html"},
            {name: "Data", url: "admin/pages/designer/viz/bullet/bullet-data-tab.tpl.html"},
            {name: "Record Actions", url: "admin/pages/designer/viz/bullet/records-tab.tpl.html"},
            {name: "Filters", url: "admin/pages/designer/common/filter-multiple-tab.tpl.html"},
            {name: "Tools", url: "admin/pages/designer/common/tools-tab.tpl.html"}
        ];

        vm.sortableOptions = pageObjectConfigService.createSortableOptions(vm.temp.viz.settings.columns, vm);

        vm.addColorToSeries = pageObjectConfigService.addColorToSeries;

        vm.dataVizThemes = PagesDesignerListService.dataVizThemes();
        vm.getFilterType = pageObjectConfigService.getFilterType;
        vm.addDSFilter = pageObjectConfigService.addDSFilter;

        vm.notPropertyColumn = pageObjectConfigService.notPropertyColumnFilter;

        // Helper functions
        vm.deleteProperty = pageObjectConfigService.deleteProperty;
        vm.capitalize = _.capitalize;
        vm.removeItemFromArray = _.pullAt;
        vm.fillCSSArray = pageObjectConfigService.fillCSSArray;

        // View functions
        vm.loadColumns = _.partial(pageObjectConfigService.loadColumns, vm);
        vm.onViewChange = _.partial(pageObjectConfigService.onViewChange, vm, onColumnRemoved);

        // Widget functions
        vm.loadFormFields = _.partial(pageObjectConfigService.loadFormFields, vm);
        vm.setBaseWidget = setBaseWidget;
        vm.resetRecordFields = resetRecordFields;

        // Column Selection
        vm.removeColumn = pageObjectConfigService.removeColumn;
        vm.selectColumn = pageObjectConfigService.selectColumn(onColumnAdded, onColumnRemoved);
        vm.selectAllColumns = pageObjectConfigService.selectAllColumns(onColumnAdded, onColumnRemoved);
        vm.removeAllColumns = pageObjectConfigService.removeAllColumns;
        vm.colIsSelected = pageObjectConfigService.colIsSelected;
        vm.allColSelected = pageObjectConfigService.allColSelected;

        vm.onColumnAdded = onColumnAdded;
        vm.onColumnRemoved = onColumnRemoved;

        // DataSource Commands
        vm.setEditDataSource = function(id){
            vm.editDataSource = id;
            vm.editView =  vm.temp.viz.settings.dataSource[id].nim_viewId;
            vm.tempFilter = {};
            vm.filterType = null;
            vm.loadColumns(vm.editView);
        };

        // Filter Commands
        vm.addToFilterMap = pageObjectConfigService.addToFilterMap;
        vm.removeFromFilterMap = pageObjectConfigService.removeFromFilterMap;

        // Sort Commands
        vm.addSortItem = pageObjectConfigService.addSortItem;

        // Modal Commands
        vm.save = pageObjectConfigService.savePageObjConfig($uibModalInstance, vm.margin, vm.padding);

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.deletePageObject = function(){
            $uibModalInstance.dismiss('delete');
        };

        //Init
        init();

        function init(){
            ViewsService.get(false).then(function(views){
                vm.views = views;
            });

            WidgetsService.getWidgets().then(function(widgets){
                vm.widgets = widgets;
            });

            vm.loadColumns(vm.editView);
            vm.loadFormFields(pageObject.viz.widgetId);

            _.defaults(vm.temp.params, {
                defaultValues: {},
                requiredFilters: {
                    value: {},
                    target: {},
                    plot: {}
                }
            });

            _.forEach(vm.temp.viz.settings.dataSource, function(ds, id){
                pageObjectConfigService.updateSortConfig(ds);
                vm.temp.filterMap[id] = vm.temp.filterMap[id] || {};
            });

            vm.sortBySortableOptions = {
                ignore: "input,select",
                change: function (e) {
                    $timeout(function () {
                        pageObjectConfigService.reorderItem(vm.temp.viz.settings.dataSource[vm.editDataSource].sort, e.oldIndex, e.newIndex);
                    },0);
                }
            };
        }

        // Controller-specific functions
        function onColumnAdded(colField){ // Fills in fields by default when column is selected
            var source = vm.temp.viz.settings.dataSource[vm.editDataSource];
            var schemaFields = source.schema.model.fields;
            var type = schemaFields[colField].type;
            var series = vm.temp.viz.settings.series[0];

            if(vm.editDataSource === 'value'){ // Value DataSource
                if(type === 'number' && !series.currentField){ // Current Value Field
                    series.currentField = colField;
                }
                else if(!series.categoryField){ // Category Field
                    series.categoryField = colField;
                }
            }
            else if(vm.editDataSource === 'target'){ // Target DataSource
                if(type === 'number' && !schemaFields.target.from){ // Target Value Field
                    schemaFields.target.from = colField;
                }
                else if(!schemaFields.category.from){ // Category Field (to match up to Current if more than one value)
                    schemaFields.category.from = colField;
                }
            }
            else if(vm.editDataSource === 'plot'){ // Plotband DataSource
                if(type === 'number' && !series.toField){ // Plotband "To" Field
                    series.toField = colField;
                }
            }
        }

        function onColumnRemoved(colField){ // Clears fields bound to the removed column
            var source = vm.temp.viz.settings.dataSource[vm.editDataSource];
            var schemaFields = source.schema.model.fields;
            var series = vm.temp.viz.settings.series[0];

            if(vm.editDataSource === 'value'){ // Value DataSource
                // Current Value Field
                if(series.currentField === colField){
                    delete series.currentField;
                }

                // Category Field
                if(series.categoryField === colField){
                    delete series.categoryField;
                }
            }
            else if(vm.editDataSource === 'target'){ // Target DataSource
                // Target Value Field
                if(schemaFields.target.from === colField){
                    delete schemaFields.target.from;
                }

                // Category Field
                if(schemaFields.category.from === colField){
                    delete schemaFields.category.from;
                }
            }
            else if(vm.editDataSource === 'plot'){ // Plotband DataSource
                // Plotband "To" Field
                if(series.toField === colField){
                    delete series.toField;
                }
            }

            // Sort By
            _.remove(source.sort, 'field', colField);

            // Group By
            if(_.get(source, 'group.field') === colField){
                delete source.group;
            }

            // ID Override
            if(_.get(source, 'schema.model.id') === colField){
                delete source.schema.model.id;
            }
        }

        // Bullet-specific implementation for setBaseWidget
        function setBaseWidget(){
            if(vm.editDataSource === 'value') {
                var selectedView = _.find(vm.views, 'id', vm.editView);
                vm.temp.viz.widgetId = _.get(selectedView, 'base_widget_id');
                vm.loadFormFields = (vm.temp.viz.widgetId);
            }
        }

        function resetRecordFields(){
            vm.temp.params.defaultValues = {};
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;