angular.module('cerberus.admin')
    .controller('ScheduleConfigCtrl', function ScheduleConfigCtrl(_, $scope, $timeout, $uibModalInstance, pageObject, filters,
                                                                  DesignerUtilityService, PagesDesignerListService, pageObjectConfigService,
                                                                  ViewsService, vizListService, WidgetsService, WidgetsSettingsService) {
        var vm = this;
        
        vm.temp = angular.copy(pageObject);
        vm.orig = angular.copy(pageObject);
        vm.filters = filters;
        vm.views = [];
        vm.widgets = [];
        vm.wrapperTypes = PagesDesignerListService.wrapperTypes();
        vm.wrapperConfig = PagesDesignerListService.wrapperConfig();
        vm.paletteOptions = {
            palette: PagesDesignerListService.palette(),
            columns: 31,
            tileSize: 18
        };
        vm.margin = DesignerUtilityService.cssToArray(pageObject.wrapper.style.margin) || [0,0,0,0];
        vm.padding = DesignerUtilityService.cssToArray(pageObject.wrapper.style.padding) || [0,0,0,0];
        vm.viewColumns = [];
        vm.editView =  null;
        vm.editDataSource =  null;
        vm.filterMap = {};
        vm.repeatable = true;
        vm.tempFilter = {};
        vm.filterType = null;
        vm.idArray = [];
        vm.sortObj = {
            field: '',
            dir: 'desc'
        };
        vm.formFieldsArray = [];
        vm.secondaryFieldsArray = [];

        vm.filterOperators = pageObjectConfigService.filterOperators();
        vm.textEditorOptions = pageObjectConfigService.textEditorOptions($scope);

        vm.vizDict = vizListService.vizDictionary();
        vm.defaultColorOptions = vizListService.colorbrewer().qualitative.Paired[12];

        vm.tabTemplates = [
            {name: "General", url: "admin/pages/designer/common/general-tab.tpl.html"},
            {name: "Wrapper", url: "admin/pages/designer/common/wrapper-tab.tpl.html"},
            {name: "Data", url: "admin/pages/designer/viz/schedule/data-tab.tpl.html"},
            {name: "Record Actions", url: "admin/pages/designer/viz/schedule/records-tab.tpl.html"},
            {name: "Filters", url: "admin/pages/designer/common/filter-multiple-tab.tpl.html"},
            {name: "Tools", url: "admin/pages/designer/common/tools-tab.tpl.html"}
        ];

        vm.calendarViews = [
            { value: 'day', display: 'Day' },
            { value: 'workWeek', display: 'Work Week' },
            { value: 'week', display: 'Week' },
            { value: 'month', display: 'Month' }
        ];

        vm.icsTimeUnitTypes = [
            {value: 'S', display: 'Seconds'},
            {value: 'M', display: 'Minutes'},
            {value: 'H', display: 'Hours'},
            {value: 'D', display: 'Days'},
            {value: 'W', display: 'Weeks'}
        ];

        vm.sortableOptions = pageObjectConfigService.createSortableOptions(vm.temp.viz.settings.columns, vm);

        vm.getFilterType = pageObjectConfigService.getFilterType;
        vm.addDSFilter = pageObjectConfigService.addDSFilter;

        vm.notPropertyColumn = pageObjectConfigService.notPropertyColumnFilter;

        // Helper functions
        vm.deleteProperty = pageObjectConfigService.deleteProperty;
        vm.capitalize = _.capitalize;
        vm.removeItemFromArray = _.pullAt;
        vm.fillCSSArray = pageObjectConfigService.fillCSSArray;

        // View functions
        vm.loadColumns = _.partial(pageObjectConfigService.loadColumns, vm);
        vm.onViewChange = _.partial(pageObjectConfigService.onViewChange, vm, onColumnRemoved);
        vm.dataSourceViewSelected = dataSourceViewSelected;

        // Widget functions
        vm.loadFormFields = _.partial(pageObjectConfigService.loadFormFields, vm);
        vm.setBaseWidget = setBaseWidget;
        vm.resetRecordFields = resetRecordFields;

        vm.loadSecondaryWidget = loadSecondaryWidget;

        // Column selection
        vm.removeColumn = pageObjectConfigService.removeColumn;
        vm.selectColumn = pageObjectConfigService.selectColumn(onColumnAdded, onColumnRemoved);
        vm.selectAllColumns = pageObjectConfigService.selectAllColumns(onColumnAdded, onColumnRemoved);
        vm.removeAllColumns = pageObjectConfigService.removeAllColumns;
        vm.colIsSelected = pageObjectConfigService.colIsSelected;
        vm.allColSelected = pageObjectConfigService.allColSelected;

        vm.onColumnAdded = onColumnAdded;
        vm.onColumnRemoved = onColumnRemoved;

        // Filter Commands
        vm.addToFilterMap = pageObjectConfigService.addToFilterMap;
        vm.removeFromFilterMap = pageObjectConfigService.removeFromFilterMap;

        // Sort Commands
        vm.addSortItem = pageObjectConfigService.addSortItem;

        vm.selectedCalendarView = selectedCalendarView;

        // DataSource Commands
        vm.addDataSource = function(dataSources, columns){
            var ids = Object.keys(dataSources);
            var max = 0;
            for(var i = 0; i < ids.length; i++){
                var id = parseInt(ids[i].replace(/^ds/, ''));
                if(id > max){
                    max = id;
                }
            }
            var newId = 'ds' + (max + 1);
            dataSources[newId] = {
                aggregate: [],
                schema: {
                    model: {
                        id: "id",
                        fields: {
                            taskId: { from: "TaskID", type: "number" },
                            title: { defaultValue: "No title", validation: { required: true } },
                            start: { type: "date" },
                            end: { type: "date" },
                            startTimezone: { from: "StartTimezone" },
                            endTimezone: { from: "EndTimezone" },
                            description: { from: "Description" },
                            recurrenceId: { from: "__nimRRuleId" },
                            recurrenceRule: { from: "__nimRRule" },
                            recurrenceException: { from: "__nimRRuleEx" },
                            ownerId: { from: "OwnerID", defaultValue: 1 },
                            isAllDay: { type: "boolean", from: "IsAllDay" }
                        }
                    }
                },
                filter: {
                    filters: [],
                    logic: 'and'
                }
            };
            columns[newId] = [];
            vm.temp.viz.settings.resources[newId] = {field: '', defaultColor: '#005683', dataSource: []};
            vm.temp.filterMap[newId] = {};
        };

        vm.setEditDataSource = function(id){
            vm.editDataSource = id;
            vm.editView =  vm.temp.viz.settings.dataSource[id].nim_viewId;
            vm.tempFilter = {};
            vm.filterType = null;
            vm.loadColumns(vm.editView);
        };

        vm.removeDataSource = function(id){
            if(vm.editDataSource === id){
                vm.editDataSource = null;
                vm.editView = null;
            }

            delete vm.temp.viz.settings.columns[id];
            delete vm.temp.viz.settings.dataSource[id];
            delete vm.temp.viz.settings.resources[id];
            delete vm.temp.filterMap[id];
        };

        vm.addColorCategory = function(resources){
            var color = vm.defaultColorOptions[11];
            if(resources.dataSource.length < 12){
                color = vm.defaultColorOptions[resources.dataSource.length];
            }

            resources.dataSource.push({
                color: color,
                value: ''
            });
        };

        // Modal Commands
        vm.save = pageObjectConfigService.savePageObjConfig($uibModalInstance, vm.margin, vm.padding);

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.deletePageObject = function(){
            $uibModalInstance.dismiss('delete');
        };

        //Init
        init();

        function init(){
            ViewsService.get(false).then(function(views){
                vm.views = views;
            });

            vm.loadFormFields(pageObject.viz.widgetId);

            WidgetsService.getWidgets().then(function(widgets){
                vm.widgets = widgets;
            });

            for(var i = 0; i < filters.length; i++){
                if(filters[i].id){
                    vm.idArray.push(filters[i].id);
                }
            }

            _.defaults(vm.temp.params, {
                openOnSelect: true,
                allowMove: false,
                createOnClick: true,
                eventTemplates: {},
                defaultValues: {},
                requiredFilters: {},
                reminderBeforeAmount: 15,
                reminderBeforeUnit: 'M'
            });

            if(_.isString(vm.temp.viz.settings.workDayStart)){
                vm.temp.viz.settings.workDayStart = new Date(vm.temp.viz.settings.workDayStart);
            }
            if(_.isString(vm.temp.viz.settings.workDayEnd)){
                vm.temp.viz.settings.workDayEnd = new Date(vm.temp.viz.settings.workDayEnd);
            }

            // Fixes old configuration by making all views objects which can then be configured
            _.forEach(vm.temp.viz.settings.views, function(view, index, viewArray){
                if(_.isString(view)){
                    viewArray[index] = { type: view, selected: false };
                }
            });

            if(!_.has(vm.temp, 'viz.settings.resources')){
                vm.temp.viz.settings.resources = {};

                _.forEach(vm.temp.viz.settings.dataSource, function(ds, id){
                    vm.temp.viz.settings.resources[id] = {field: '', defaultColor: '#005683', dataSource: []};
                    vm.temp.filterMap[id] = vm.temp.filterMap[id] || {};
                    vm.temp.params.requiredFilters[id] = vm.temp.params.requiredFilters[id] || {};

                    pageObjectConfigService.updateSortConfig(ds);
                });
            }
            else {
                _.forEach(vm.temp.viz.settings.dataSource, function(ds, id){
                    pageObjectConfigService.updateSortConfig(ds);
                    vm.temp.filterMap[id] = vm.temp.filterMap[id] || {};
                    vm.temp.params.requiredFilters[id] = vm.temp.params.requiredFilters[id] || {};
                });
            }

            vm.sortBySortableOptions = {
                ignore: "input,select",
                change: function (e) {
                    $timeout(function () {
                        pageObjectConfigService.reorderItem(vm.temp.viz.settings.dataSource[vm.editDataSource].sort, e.oldIndex, e.newIndex);
                    },0);
                }
            };
        }

        // Controller-specific functions
        function onColumnAdded(colField){ // Fills in fields by default when column is selected
            var source = vm.temp.viz.settings.dataSource[vm.editDataSource];
            var schemaFields = source.schema.model.fields;

            // Date Fields
            if(schemaFields[colField].type === 'date'){
                if(!schemaFields.start.from){ // Start Date
                    schemaFields.start.from = colField;
                }
                else if(!schemaFields.end.from){ // End Date
                    schemaFields.end.from = colField;
                }
            }
            else if(!schemaFields.title.from){ // Title
                schemaFields.title.from = colField;
            }
        }

        function onColumnRemoved(colField){ // Clears fields bound to the removed column
            var source = vm.temp.viz.settings.dataSource[vm.editDataSource];
            var schemaFields = source.schema.model.fields;

            // Start Date
            if(schemaFields.start.from === colField){
                delete schemaFields.start.from;
            }

            // End Date
            if(schemaFields.end.from === colField){
                delete schemaFields.end.from;
            }

            // Title
            if(schemaFields.title.from === colField){
                delete schemaFields.title.from;
            }

            // Sort By
            _.remove(source.sort, 'field', colField);

            // Group By
            if(_.get(source, 'group.field') === colField){
                delete source.group;
            }

            // ID Override
            if(_.get(source, 'schema.model.id') === colField){
                delete source.schema.model.id;
            }
        }

        function dataSourceViewSelected() {
            vm.onViewChange(vm.temp.viz.settings.dataSource[vm.editDataSource], vm.temp.viz.settings.columns[vm.editDataSource], vm.editView);
            vm.setBaseWidget(vm.editView, true);
            vm.temp.viz.settings.dataSource[vm.editDataSource].sort = [];
            vm.temp.viz.settings.dataSource[vm.editDataSource].secondaryFieldMap = {};
            vm.temp.viz.settings.dataSource[vm.editDataSource].nim_widgetId = '';
        }

        // Scheduler-specific implementation for setBaseWidget
        function setBaseWidget(){
            // Only automatically set base widget if not already set.
            // This is important since this page object has multiple data sources.
            if(!vm.temp.viz.widgetId) {
                var selectedView = _.find(vm.views, 'id', vm.editView);
                vm.temp.viz.widgetId = _.get(selectedView, 'base_widget_id');
                vm.loadFormFields = (vm.temp.viz.widgetId);
            }
        }

        function resetRecordFields(){
            vm.temp.params.startDateField = null;
            vm.temp.params.endDateField = null;
            vm.temp.params.defaultValues = {};
        }

        // Getter/Setter for selected calendar view
        function selectedCalendarView(type){
            var views = vm.temp.viz.settings.views;
            if(arguments.length){
                _.forEach(views, function(view){
                    view.selected = view.type === type;
                });

                return type;
            }
            else {
                var selectedView = _.find(views, 'selected', true);
                return selectedView.type;
            }
        }

        function loadSecondaryWidget(dsId) {
            var ds = vm.temp.viz.settings.dataSource[dsId];

            if (ds.nim_widgetId) {
                WidgetsSettingsService.getFormFields(ds.nim_widgetId).then(function (data) {
                    vm.secondaryFieldsArray = WidgetsSettingsService.parseFormObjects(data.display);
                });
            }
            else {
                vm.secondaryFieldsArray = [];
            }
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;