angular.module('cerberus.account')
    .controller('OrganizationsFileDetailsCtrl', function(kendo, $scope, $location, fileId, orgId, OrganizationsService){
        $scope.pageTitle = 'File Details';
        $scope.activeRoute = 'media-library';
        $scope.fileId = fileId;
        $scope.orgId = orgId;
        $scope.orgData = {};
        $scope.temp = {};
        $scope.orig = {};
        $scope.uploadDate = '';
        $scope.url = '';

        $scope.isUnchanged = angular.equals;
        $scope.submit = _submit;
        $scope.cancel = _cancel;

        _init();

        function _init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });

            OrganizationsService.getAsset(orgId, fileId).then(function(file){
                var fileData = file.data;
                var path = fileData.path;
                delete fileData.path;

                $scope.temp = angular.copy(fileData);
                $scope.orig = angular.copy(fileData);

                $scope.uploadDate = _parseDate(fileData);
                $scope.path = path;
                $scope.url = $location.host() + path;
            });
        }

        function _submit(valid, fileData){
            if(valid && !angular.equals(fileData, $scope.orig)){
                var data = {
                    data: fileData,
                    type: "File"
                };

                OrganizationsService.updateAsset(orgId, fileId, data).then(_cancel);
            }
        }

        function _cancel(){
            $location.url('/organizations/' + $scope.orgId + '/settings/media-library');
        }

        function _parseDate(file){
            //var iso = file.uploadDate.replace(' ', 'T') + 'Z';
			var iso = file.uploadDate;
            var date = new Date(iso);

            return kendo.format('{0:G}', date);
        }
    })
;