angular.module('cerberus.les')
    .controller('LESLoanApplicationCreditPullModalCtrl', function(_, $rootScope, $scope, $uibModalInstance, loanApplicationId, lesLoanApplicationsService){
        var vm = this;

        vm.loanApplicationId = loanApplicationId;
        vm.borrowers = [];
        vm.tempBorrowers = [];
        vm.updatingPermissions = false;
        vm.pullingCredit = false;
        vm.errorAlert = '';
        vm.creditPull = {
            creditProvider: null,
            // overrideLiabilities: null,
            referenceNumber: ''
        };
        vm.creditProviderLookupOptions = {
            dataTextField: 'display',
            dataValueField: 'id',
            filter: 'contains',
            optionLabel: {
                display: 'Choose...',
                id: -1
            },
            dataSource: {
                type: 'odata',
                transport: {
                    read: {
                        url: '/server/rest/v1/views/E693092AA6FF4C37362265BC06B0C79D-221/odata.svc',
                        dataType: 'json',
                        beforeSend: function(xhr){
                            xhr.setRequestHeader('Authentication','Bearer ' + $rootScope.authToken);
                            xhr.setRequestHeader('WSID', $rootScope.userData.currentWorkspace.id);
                        },
                        data: {
                            $lookupDisplay: 'provider_name'
                        }
                    }
                }
            }
        };

        vm.ok = ok;
        vm.cancel = cancel;
        vm.updateBorrowerCreditPullPermission = updateBorrowerCreditPullPermission;
        vm.permissionToPullCredit = permissionToPullCredit;

        init();

        function init(){
            var loanRecordId = _.last(loanApplicationId.split('-'));
            lesLoanApplicationsService.getLoanApplicants(loanRecordId).then(function(borrowers){
                vm.borrowers = _.map(borrowers, mapBorrowerPermissionData);
                vm.tempBorrowers = angular.copy(vm.borrowers);
            });
        }

        function ok(form){
            vm.pullingCredit = true;

            if (!validateForm(form)) {
                vm.pullingCredit = false;
                return;
            }

            lesLoanApplicationsService.pullCredit(vm.loanApplicationId, vm.creditPull).then(function(){
                vm.pullingCredit = false;
                $uibModalInstance.close();
            }, function(reason){
                vm.pullingCredit = false;
                
                if (reason.CODE >= 400 && reason.CODE < 500) {
                    vm.errorAlert = reason.DATA;
                }
            });
        }

        function cancel(){
            $uibModalInstance.dismiss('cancel');
        }

        function validateForm(form) {
            var valid = true;
            
            // Credit Provider
            if (!vm.creditPull.creditProvider) {
                valid = false;
                form['credit-provider-lookup'].$setValidity('required', false);
            }
            else {
                form['credit-provider-lookup'].$setValidity('required', null);
            }

            // Override Liabilities
            // if (!_.isBoolean(vm.creditPull.overrideLiabilities)) {
            //     valid = false;
            //     form['credit-liabilities-override'].$setValidity('required', false);
            // }
            // else {
            //     form['credit-liabilities-override'].$setValidity('required', null);
            // }

            // Borrowers
            _.forEach(vm.borrowers, function (borrower, index) {
                if (!borrower.permission) {
                    valid = false;
                    form['borrower-' + index + '-permission'].$setValidity('required', false);
                }
                else {
                    form['borrower-' + index + '-permission'].$setValidity('required', null);
                }
            });

            return valid;
        }

        function updateBorrowerCreditPullPermission(index, id, permission){
            vm.updatingPermissions = true;
            lesLoanApplicationsService.updateBorrowerCreditPullPermission(id, permission).then(function(){
                vm.updatingPermissions = false;
                vm.borrowers[index].permission = permission;
            }, function(){
                vm.updatingPermissions = false;
                vm.tempBorrowers[index].permission = vm.borrowers[index].permission;
            });
        }

        function mapBorrowerPermissionData(borrower){
            return {
                id: _.get(borrower, 'Borrowers_instance_id'),
                firstName: _.get(borrower, 'Borrowers_FirstName', ''),
                lastName: _.get(borrower, 'Borrowers_LastName', ''),
                permission: _.get(borrower, 'Borrowers_BorrowerCreditPullPermission_display', false)
            };
        }

        function permissionToPullCredit(){
            return _.every(vm.borrowers, function(borrower){
                return borrower.permission;
            });
        }

        $scope.$on('$destroy', function(){
            _.result(vm, 'creditProviderLookup.destroy');
        });

        // Prevents navigation while modal is open
        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;