angular.module('cerberus.admin')
    .controller('ViewsCtrl', function ViewsCtrl($location, workspaceId, localStorageService, ViewsService) {
        var vm = this;
        vm.viewsArray = [];
        vm.openViewsDetails = _openViewsDetails;
        vm.openViewDesigner = _openViewDesigner;
        vm.viewFilter = _viewFilter;

        _init();

        function _init(){
            ViewsService.get().then(function(viewsArray){
                vm.viewsArray = viewsArray;
            });
        }

        function _openViewsDetails(){
            $location.url('/settings/queries/create');
        }

        function _openViewDesigner(id){
            $location.url('/settings/queries/' + id);
        }

        // Getter-Setter function for filter
        function _viewFilter(filterText){
            // Checks if value was passed in
            if(arguments.length){
                localStorageService.set('viewFilter.' + workspaceId, filterText);
                return filterText || '';
            }
            else {
                return localStorageService.get('viewFilter.' + workspaceId) || '';
            }
        }
    })
;