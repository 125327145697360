angular.module('cerberus.les')
    .controller('LESLoanApplicationCtrl', function(_, moment, $scope, $rootScope, $timeout, $firebaseObject, fbUtil, loanApplicationId, lesLoanApplicationsService, pageObjectsService){
        var vm = this;

        $scope.filters = {};
        
        vm.loanApplicationId = loanApplicationId;
        vm.simpleLoanId = _.last(loanApplicationId.split('-'));
        vm.loanApplication = {};
        vm.status = {};
        vm.statuses = [];
        vm.primaryBorrower = '';
        vm.breadcrumbDisplay = 'Loading...';
        vm.borrowers = [];
        vm.creditScores = {};
        vm.applicationDataLoaded = false;
        vm.auditLogInitialized = false;
        vm.savedForLaterInitialized = false;
        vm.showValidityAlert = false;
        vm.validityErrors = [];
        vm.productSelected = false;
        vm.findings = {};

        vm.toYear = toYear;

        var instanceWindowCallback = {},
            deregisterAuditLog = angular.noop,
            deregisterSavedForLater = angular.noop;

        init();

        function init(){
            lesLoanApplicationsService.getLoanApplication(loanApplicationId).then(function(loanApplication){
                if(loanApplication.recordData.findings){
                    vm.productSelected = true;
                    vm.loanApplication = loanApplication.recordData.loanApp;
                    vm.findings = loanApplication.recordData.findings;
                }
                else{
                    vm.productSelected = false;
                    vm.loanApplication = loanApplication.recordData;
                    vm.findings = {};
                }

                var loanRecordId = _.last(loanApplicationId.split('-')),
                    mainBorrowerName = _.get(vm.loanApplication, 'mainBorrower.fullName');
                
                vm.status = _.get(vm.loanApplication, 'LoanAppStatus.id', 1735);
                vm.primaryBorrower = mainBorrowerName || 'No Primary Borrower';
                vm.actionsAvailable = lesLoanApplicationsService.actionAvailabilityByLoanStatus();
                vm.applicationDataLoaded = true;
                vm.borrowers = [];
                vm.creditScores = {};
                vm.breadcrumbDisplay = vm.primaryBorrower + ' (#' + loanRecordId + ')';

                // Modal/Routing functions are not set until the loan application is verified

                vm.openApplicationRecord = _.partial(lesLoanApplicationsService.openApplicationRecord, loanRecordId, _, instanceWindowCallback);
                //vm.openUpdateModal = _.partial(lesLoanApplicationsService.openUpdateModal, loanApplicationId);
                vm.openExportModal = _.partial(lesLoanApplicationsService.openExportModal, loanApplicationId);
                // vm.openEligibilityPage = _.partial(lesLoanApplicationsService.openEligibilityPage, loanApplicationId);
                vm.openEligibilityPage = openEligibilityPage;
                vm.openCreditPullModal = openCreditPullModal;
                vm.openCreditManualEntryModal = openCreditManualEntryModal;
                vm.openWithdrawalModal = openWithdrawalModal;
                vm.openUnregistrationModal = openUnregistrationModal;
                vm.openCloseAndFundModal = openCloseAndFundModal;
                vm.openReopenModal = openReopenModal;
                vm.openSelectedProduct = openSelectedProduct;

                instanceWindowCallback.callback = function () { $rootScope.$broadcast('nim-loan-update', loanApplicationId); };

                $scope.filters.LoanApplicationID = {
                    filters: [{
                        field: '__nimColumn__',
                        operator: 'eq',
                        value: loanRecordId
                    }],
                    logic: 'or'
                };

                // Set Kendo Grid options
                vm.auditLogOptions = lesLoanApplicationsService.loanAuditLogOptions(loanRecordId);
                vm.savedForLaterOptions = lesLoanApplicationsService.loanSavedForLaterOptions(loanRecordId);
                // vm.attachmentGridOptions = lesLoanApplicationsService.attachmentGridOptions;
                // vm.documentGridOptions = lesLoanApplicationsService.loanDocumentVaultOptions(loanRecordId);
                vm.addDocumentToVault = _.partial(lesLoanApplicationsService.addDocumentToVault, loanRecordId);

                // Watches firebase to reload audit log                
                deregisterAuditLog = $firebaseObject(fbUtil.ref('queries/291C8BED3D8503A658F3C1E4ABC99FD1-176')).$watch(function () {
                    if (!vm.auditLogInitialized) {
                        //Stops first firing of the event
                        $timeout(function () { vm.auditLogInitialized = true; });
                    } else {
                        _.result(vm, 'auditLog.dataSource.read');
                    }
                });

                // Watches firebase to reload saved for later table                
                deregisterSavedForLater = $firebaseObject(fbUtil.ref('queries/3437A61E95CA10A66DA643259BCA80C5-183')).$watch(function () {
                    if (!vm.savedForLaterInitialized) {
                        //Stops first firing of the event
                        $timeout(function () { vm.savedForLaterInitialized = true; });
                    } else {
                        _.result(vm, 'savedForLater.dataSource.read');
                    }
                });

                lesLoanApplicationsService.getLoanAppStatuses().then(function(statuses){
                    vm.statuses = statuses;
                });

                lesLoanApplicationsService.getLoanApplicants(loanRecordId).then(function (borrowers) {
                    vm.borrowers = borrowers;
                });

                lesLoanApplicationsService.getCreditData(loanRecordId).then(function (data) {
                    vm.creditScores = _.groupBy(data.scores, 'borrowerId');
                });
            });

            pageObjectsService.getPageObject('22DACED3D22F2B95DABF06125C9DD278-151').then(function (pageObj) {
                vm.documentVault = pageObj;
            });

            pageObjectsService.getPageObject('1BBBC0190C979291712632271018D4FA-170').then(function (pageObj) {
                vm.creditReport = pageObj;
            });
        }

        function openCreditPullModal(){
            lesLoanApplicationsService.openCreditPullModal(loanApplicationId).then(refreshStatusAndAuditLog);
        }

        function openCreditManualEntryModal(){
            lesLoanApplicationsService.openCreditManualEntryModal(loanApplicationId).then(refreshStatusAndAuditLog);
        }

        function openWithdrawalModal(){
            lesLoanApplicationsService.openWithdrawalModal(loanApplicationId).then(refreshStatusAndAuditLog);
        }

        function openUnregistrationModal(){
            lesLoanApplicationsService.openUnregistrationModal(loanApplicationId).then(refreshStatusAndAuditLog);
        }

        function openCloseAndFundModal(){
            lesLoanApplicationsService.openCloseAndFundModal(loanApplicationId, vm.loanApplication.BaseLoanAmount, vm.findings.noteRate).then(refreshStatusAndAuditLog);
        }

        function openReopenModal(){
            lesLoanApplicationsService.openReopenModal(loanApplicationId).then(refreshStatusAndAuditLog);
        }

        function openEligibilityPage() {
            vm.showValidityAlert = false;
            vm.validityErrors = [];
            
            lesLoanApplicationsService.validateLoanApplication(loanApplicationId).then(function (result) {
                if (!result.isValid) {
                    vm.showValidityAlert = true;
                    /* LODASH Version 4 ONLY */
                    //vm.validityErrors = _.filter(result.messages, ['type', 'error']);
                    /* LODASH Version 3 & 4 Compatibility Mode */
                    vm.validityErrors = _.filter(result.messages, function(c){return c.type === 'error';});
                }

                var defaultQueryParams = {};

                if (vm.loanApplication.isSelfEmployed) {
                    defaultQueryParams['12monthBankStatements'] = true;
                    defaultQueryParams['24monthBankStatements'] = true;
                    }
                // else {
                    defaultQueryParams.fullDocumentation = true; // Now unconditional
                // }
                var baseLoanAmount = vm.loanApplication.BaseLoanAmount || 0;
                var totalAssets = vm.loanApplication.totalAssets || 0;

                if ((totalAssets * 2) >= baseLoanAmount) {
                    defaultQueryParams.assetUtilization = true;
                }

                if (vm.loanApplication.PropertyUsagetype.display == 'Investor') {
                    defaultQueryParams.debtServiceCoverage = true;
                }

                lesLoanApplicationsService.openFindProductsModal(defaultQueryParams, result).then(function (params) {
                    lesLoanApplicationsService.openEligibilityPage(loanApplicationId, params);
                });
            });
        }

        function openSelectedProduct(){
            lesLoanApplicationsService.openFindingsReportModal(loanApplicationId, vm.findings.productId, vm.findings, -1);
        }

        function refreshStatusAndAuditLog(){
            vm.borrowers = [];
            vm.creditScores = {};
            
            lesLoanApplicationsService.getLoanApplication(loanApplicationId).then(function(loanApplication){
                if(loanApplication.recordData.findings){
                    vm.productSelected = true;
                    vm.loanApplication = loanApplication.recordData.loanApp;
                    vm.findings = loanApplication.recordData.findings;
                }
                else{
                    vm.productSelected = false;
                    vm.loanApplication = loanApplication.recordData;
                    vm.findings = {};
                }

                vm.status = _.get(vm.loanApplication, 'LoanAppStatus.id', 1735);
                vm.primaryBorrower = _.get(vm.loanApplication, 'mainBorrower.fullName') || 'No Primary Borrower';

                var lenderCaseId = vm.loanApplication.LenderCaseIdentifier || 'No Case Number';
                vm.breadcrumbDisplay = vm.primaryBorrower + ' (' + lenderCaseId + ')';
            });

            var loanRecordId = _.last(loanApplicationId.split('-'));            
            lesLoanApplicationsService.getLoanApplicants(loanRecordId).then(function (borrowers) {
                vm.borrowers = borrowers;
            });

            lesLoanApplicationsService.getCreditData(loanRecordId).then(function (data) {
                vm.creditScores = _.groupBy(data.scores, 'borrowerId');
            });
        }

        function toYear(value) {
            if (value) {
                if (_.isDate(value)) {
                    return moment.utc(value).local().year();
                }
                else if (_.isString(value)) {
                    return moment.utc(value, 'MMMM, DD YYYY HH:mm:ss').local().year();
                }
            }    

            return '';
        }

        $scope.$on('nim-loan-update', function (e, id) {
            e.preventDefault();

            if (id == loanApplicationId) {
                vm.showValidityAlert = false;
                vm.validityErrors = [];
                refreshStatusAndAuditLog();
            }    
        });

        $scope.$on('$locationChangeStart', function (e, newUrl, oldUrl) {
            newUrl = newUrl || '';
            oldUrl = oldUrl || '';

            if (!e.defaultPrevented) {
                _.result(vm, 'auditLog.destroy');
                _.result(vm, 'savedForLater.destroy');

                // Remove firebase watchers
                deregisterAuditLog();
                deregisterSavedForLater();
            }
        });
    })
;