angular.module('cerberus.util')
    .controller('nimPrintBuilderCtrl', function(_, $scope, $timeout, FormsService, InstancesService){
        var vm = this;

        vm.states = [];
        vm.dataSet = {};
        vm.filters = {};
        vm.staticGridsterOptions = {
            pushing: true
        };
        vm.instanceForm = {};
        vm.doneLoading = {};
        vm.sectionsShown = {};
        vm.fieldsShown = {};
        vm.dataSeriesRows = 0;
        vm.gridsterItems = [];

        vm.sectionFilter = FormsService.sectionFilter;
        vm.autoFillData = $scope.autoFillData;
        vm.gridsterItemOptions = gridsterItemOptions;

        if(_.isEmpty($scope.instance)) {
            InstancesService.getInstance($scope.instanceId).then(function (data) {
                vm.instanceData = data;
                vm.states = angular.copy(data.workflowPath).reverse();

                _.forEach(vm.states, function (state) {
                    vm.sectionsShown[state] = {};
                    vm.fieldsShown[state] = {};

                    if (data.workflowObjects[state]) {
                        vm.doneLoading[state] = false;

                        var displayForm = data.workflowObjects[state].model.forms[0],
                            formOptions = [displayForm];

                        InstancesService.getFormData($scope.instanceId, formOptions).then(function (result) {
                            var form = result.formData[0];
                            vm.sectionsShown[state][form.originatorId] = {};

                            vm.instanceForm[state] = form;
                            _.assign(vm.dataSet, form.defaultDataSet);
                            vm.filters = {};
                            vm.doneLoading[state] = true;


                            if (_.every(vm.doneLoading)) {
                                checkConditions();
                                finishAfterResize();
                            }
                        });
                    }
                });
            });
        }
        else {
            var data = $scope.instance;

            if(!_.isEmpty($scope.dataSet)){
                vm.dataSet = $scope.dataSet;
            }

            if(!_.isEmpty($scope.autoFillData)){
                vm.autoFillData = $scope.autoFillData;
            }

            vm.instanceData = data;
            vm.states = angular.copy(data.workflowPath).reverse();

            _.forEach(vm.states, function (state) {
                vm.sectionsShown[state] = {};
                vm.fieldsShown[state] = {};

                if (data.workflowObjects[state]) {
                    var form = data.workflowObjects[state].model.forms[0];
                    vm.sectionsShown[state][form.originatorId] = {};
                    vm.instanceForm[state] = form;
                }
            });

            checkConditions();
            finishAfterResize();
        }

        function finishAfterResize() {
            $timeout(function () {
                var removeGridsterWatcher = angular.noop;
                var removeInitWatcher = angular.noop;
                var timeout = 1000 + (1000 * vm.dataSeriesRows);
                var done = _.debounce(function () {
                    removeGridsterWatcher();
                    removeInitWatcher();
                    $timeout($scope.done);
                }, timeout);

                removeGridsterWatcher = $scope.$watch('vm.gridsterItems', function () { 
                    done();
                }, true);
                
                done();
            });
        }

        function checkConditions(){
            _.forEach(vm.instanceForm, function(form, state){
                var formOriginId = form.originatorId;

                // Section Conditions
                _.forEach(form.form.sections, function(section){
                    if (!_.isEmpty(section.condition)) {
                        vm.sectionsShown[state][formOriginId][section.name] = FormsService.sectionCondition(section.condition, vm.dataSet, section.logic, 'read');
                    }
                    else {
                        vm.sectionsShown[state][formOriginId][section.name] = true;
                    }
                });

                _.forEach(form.form.objects, function(formObj){
                    var modelId = formObj.model.config.modelId,
                        section = formObj.model.display.section,
                        params = formObj.model.param;

                    // Form Object Conditions
                    var sectionShown = !section || section == '__nimHiddenSection' || vm.sectionsShown[state][formOriginId][section];
                    if(sectionShown && !_.isEmpty(params.condition) && (params.conditionalAction === 'show' || params.conditionalAction === 'hide')){
                        var result = FormsService.sectionCondition(params.condition, vm.dataSet, params.conditionalLogic, 'read');

                        if(params.conditionalAction === 'hide'){
                            vm.fieldsShown[state][modelId] = !result;
                        }
                        else {
                            vm.fieldsShown[state][modelId] = result;
                        }
                    }
                    else {
                        vm.fieldsShown[state][modelId] = true;
                    }

                    // Rendering Dataseries
                    if (vm.fieldsShown[state][modelId] && sectionShown && formObj.model.config.type == 'table') {
                        var rows = vm.instanceForm[state].defaultDataSet[modelId] || [];
                        vm.dataSeriesRows += rows.length;
                    }
                });
            });
        }

        function gridsterItemOptions(formObjectModel) {
            var options = _.pick(formObjectModel, ['sizeX', 'sizeY', 'row', 'col']);

            vm.gridsterItems.push(options);

            return options;
        }
    })
    .directive('nimPrintBuilder', function(){
        return {
            restrict: 'AE',
            templateUrl: 'util/nim-print-normal/nim-print-builder.tpl.html',
            controller: 'nimPrintBuilderCtrl',
            controllerAs: 'vm',
            link: function(scope, element){
                element.css('height', scope.paperOptions.paperSize[1])
                    .css('width', scope.paperOptions.paperSize[0]);

                scope.paperOptions.paperSize[0] = element.css('width');
                scope.paperOptions.paperSize[1] = element.css('height');

                element.css('width', '-=' + scope.paperOptions.margin.left + 'px')
                    .css('width', '-=' + scope.paperOptions.margin.right + 'px')
                    .css('height', '');
            }
        };
    })
;