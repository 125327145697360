angular.module('cerberus')
    .factory('appSettingsService', function appSettingsService($http) {
        var defaults = {
            platform: { //TODO: Use organization values from database and if null then use these values
                // This will be displayed in various areas
                name: '',
                // This will be used for logo anchor tags
                url: 'https://crownpaperconverting.com',
                // This will be used for meta tags and copyrights
                publisher: 'Crown Paper Converting Software, Inc.'
            },
            //TODO: Move to Database
            login: {
                // Whether or not to show the create account; note this does not disable or enable the function
                createAccountVisible: true,
                // Text in the create account button
                createAccountLabel: 'Create Free Account',
                // This switch between using square logo with name or simply a rectangular logo
                logoStyle: 'rectangular', // 'square' | 'rectangular'
                // Subtitle html
                subtitleVisible: false
            },
            //TODO: Move to Database
            topNav: {
                // This switch between using square logo with name or simply a rectangular logo
                logoStyle: 'rectangular' // 'square' | 'rectangular'
            }
        };

        //init
        _getOverrides();

        var service = {
            branding: branding
        };

        return service;
        ////////////////////
        function branding() {
            return defaults;
        }
        function _getOverrides() {
            $http.get('/assets/platform/client-config-overrides.json').then(function (response) {
                angular.merge(defaults, response.data);
            });
        }

    })
;