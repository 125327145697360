angular.module('cerberus.core')
    .controller('MassimportUploadModalController', function (_, moment, $uibModalInstance, listPageObject, ImportService, $timeout, authToken, workspaceId, ImportExchangeService, usersProfileService) {
        var vm = this;

        vm.newJobCreationSelected = false;
        vm.widgetId = listPageObject.viz.widgetId;
        vm.ok = ok;
        vm.cancel = cancel;
        vm.$exclPermissions = true; //Allow non-admin exclusive permissions
        vm.rollbackAllowed = false; //Disable permissions for rollback operations (prevent operatord mass-delete data). If needed add a slider with Yes/No selector.

        /* Functions related to 'new import job settings' (getAllsettings, upload) */

        vm.getListOfAllImportSettings = function(){
            ImportService.getAllSettings(vm.widgetId, vm.$exclPermissions).then(function(d){
                vm.importSettingsArray = d;
            });
        };

        vm.importSettingsArray = [];
        vm.importJobModel = {
            jsonData: {},
            $exclPermissions: vm.$exclPermissions,
            validate: true
        };
        vm.importSource = null;
        vm.mailServers = [];
        vm.errors = null;

        vm.changeSource = function(){
            for(var i = 0; i < vm.importSettingsArray.length; i++){
                if(vm.importJobModel.importSettingId == vm.importSettingsArray[i].id){
                    vm.importSource = vm.importSettingsArray[i].type;
                }
            }
            if(vm.importSource.substring(0,8) === 'exchange'){
                usersProfileService.getAll('Mail Server').then(function(data){
                   vm.mailServers = data;
                });
            }
        };

        vm.getExchangeData = function(id,importSource){
            if(id){
                ImportExchangeService.getExchangeData(id,importSource).then(function(data){
                    vm.importJobModel.jsonData.exchangeData = data;
                });
            }
            else{
                vm.importJobModel.jsonData.exchangeData = [];
            }
        };

        vm.submit = function(isValid){
            if(isValid){
                ImportService.createImportJob(vm.widgetId,vm.importJobModel).then(function(){
                    $timeout(function(){
                        vm.getListOfAllJobs(); //update list
                    });
                });
            }
        };

        vm.asyncOptions = {
            saveUrl: '/server/rest/v1/widgets/' + vm.widgetId + '/import/jobs',
            removeUrl: 'remove',
            autoUpload: false
        };

        vm.localizationOptions = {
            uploadSelectedFiles: 'Submit to upload'
        };

        vm.onUpload = function(e) {
            delete vm.importJobModel.jsonData;
            e.data = vm.importJobModel;
            var xhr = e.XMLHttpRequest;
            if (xhr) {
                xhr.addEventListener("readystatechange", function () {
                    if (xhr.readyState == 1 /* OPENED */) {
                        xhr.setRequestHeader('Authentication','Bearer ' + authToken);
                        xhr.setRequestHeader('WSID', workspaceId);
                        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest ');
                    }
                });
            }
        };

        vm.onError = function(e){
            $timeout(function(){
                var resp = angular.fromJson(e.XMLHttpRequest.response);
                vm.errors = _.get(resp, 'DATA.message', '');
            });
        };

        vm.onSuccess = function (){
            $timeout(function(){
                vm.errors = null;
                vm.getListOfAllJobs(); //update list
            });
        };

        /* Functions related to 'import jobs list table' (getAll, create, validate, submit and delete) */

        vm.startImportJob = function(id, idx){
            vm.importJobs[idx].status = 'Importing';
            ImportService.startImport(id, vm.importJobs[idx].name, vm.$exclPermissions).then(function(){
                $timeout(function(){
                    vm.getListOfAllJobs();
                });
            });
        };

        vm.deleteJob = function(id, idx){
            ImportService.deleteJob(id, vm.$exclPermissions)
            .then(function(){
                vm.importJobs.splice(idx, 1);
            });
        };

        vm.validateImport = function(id){
            ImportService.validateImport(id, vm.$exclPermissions).then(function(){
                vm.getListOfAllJobs();
            });
        };
        
        // vm.validateImport = function(id){
            // ImportService.validateImport(id, vm.$exclPermissions).then(function(){
                // ImportService.getAllJobs(vm.widgetId, vm.$exclPermissions).then(function(importJobs){
                    // vm.importJobs = angular.copy(importJobs);
                    // _.forEach(vm.importJobs, function(impJob){
                        // impJob.date = moment.utc(impJob.date, 'MMMM, DD YYYY HH:mm:ss').toDate();
                        // impJob.validateDate = moment.utc(impJob.validateDate, 'MMMM, DD YYYY HH:mm:ss').toDate();
                    // });
                // });
            // });
        // };

        vm.rollback = function(item, idx){
            ImportService.rollback(item.id, vm.$exclPermissions)
            .then(function () {
                vm.importJobs.splice(idx, 1);
            });
        };

        vm.getListOfAllJobs = function (){
            ImportService.getAllJobs(vm.widgetId, vm.$exclPermissions).then(function(importJobs){
                vm.importJobs = angular.copy(importJobs);
                _.forEach(vm.importJobs, function(impJob){
                    impJob.date = moment.utc(impJob.date, 'MMMM, DD YYYY HH:mm:ss').toDate();
                    impJob.validateDate = moment.utc(impJob.validateDate, 'MMMM, DD YYYY HH:mm:ss').toDate();
                });
            });
        };

        vm.refreshAll = function (){
            vm.getListOfAllJobs();
        };

        vm.goToNewJobCreation = function (){
            vm.newJobCreationSelected = true;
        };

        //Init function        
        init();
        ////////////////////
        /**
         * Init controller data and server calls
         */
        function init() {
            //get all jop settings
            vm.getListOfAllImportSettings();
            //get all job history list
            vm.getListOfAllJobs();
        }

        function ok(){
            $uibModalInstance.close();
        }

        function cancel(){
            $uibModalInstance.dismiss();
        }
    })
;