angular.module('cerberus.core')
    .controller('MenusSideCtrl', function MenusSideCtrl(_, $scope, $location, $routeParams, MenusService, OrganizationsService) {
        var vm = this;

        // Menu Containers
        vm.menu = [];

        // Workspaces
        vm.openWorkspacesModal = openWorkspacesModal;
        vm.isPageActive = MenusService.isPageActive;

        // Edit Page
        vm.isPageRoute = MenusService.isPageRoute;
        vm.editCurrentPage = MenusService.editCurrentPage;
        vm.editCurrentMenu = MenusService.editCurrentWorkspaceMenu;

        init();

        ////////////////////

        function init() {
            // Get the Menu
            getMenu();

            // Subscribe to Events
            $scope.$on('nim-menu-updated', getMenu);
            $scope.$on('auth:loginConfirmed', getMenu);
            $scope.$on('auth:loginCancelled', function () {
                vm.menu = [];
                MenusService.clear();
            });
        }

        function getMenu() {
            MenusService.getWorkspaceMenu().then(
                function (defaultMenu) {
                    vm.menu = defaultMenu.items;

                    // Checks which menu item, if any, should be open
                    // Only runs until current page is found
                    _.some(defaultMenu.items, checkIfOpen);
                }
            );
        }

        // Checks if menu item should be open
        function checkIfOpen(item){
            if($routeParams.pageId === item.pageId){
                return true;
            }
            else if(!_.isEmpty(item.items)){
                // This item should be open if one of its nested items is the current page
                return item.open = _.some(item.items, checkIfOpen);
            }
            return false;
        }

        function openWorkspacesModal() {
            OrganizationsService.getUserOrganizations().then(function (orgs) {
                if (orgs.length == 1 && orgs[0].workspaces.length == 1) {
                    $location.url('');
                    return;
                }

                MenusService.openWorkspacesModal(orgs);
            });    
        }
    })
;