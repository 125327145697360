angular.module('cerberus.core')
    .controller('nimVizTemplateCtrl', function (_, kendo, $scope, $timeout, $window, VizUtilityService, VizTemplateService, $firebaseObject, fbUtil) {
        var vm = this;
        
        // Instantiate Variables
        $scope.vizCtrl = {};
        $scope.usingTemplate = false;
        $scope.mainPageObject.vizCtrl = $scope.vizCtrl;

        $scope.viewRecord = VizTemplateService.viewRecord;
        //Firebase
        var initializing = true,
            debouncedRefresh = _.debounce(function () { _.result(vm, 'dataSource.read'); }, 5000, { leading: true, trailing: true }),
            unwatchFb = $firebaseObject(fbUtil.ref('queries/' + $scope.pageObject.viz.settings.dataSource.nim_viewId)).$watch(function(){
                if (initializing) {
                    //Stops first firing of the event
                    $timeout(function() { initializing = false; });
                } else {
                    debouncedRefresh();
                }
            });

        // Init
        init();

        // Event Bindings
        $scope.$on('nim-viz-reload', function (event) {
            event.preventDefault();
        });

        $scope.$on('$destroy', function(){
            unwatchFb();     // Remove this watcher so it stops firing after the user has left the page
        });

        function init() {
            var dataSource = new kendo.data.DataSource({
                requestStart: function (e) {
                    if (!VizTemplateService.requiredFiltersSet($scope.pageObject, $scope.filters)) {
                        e.preventDefault();

                        $timeout(function () {
                            e.sender.success({ d: { results: [], __count: 0 } });
                        });

                        return false;
                    }
                },
                requestEnd: function () {
                    var defaultText = angular.copy($scope.pageObject.viz.settings.defaultText) || '';

                    if (VizTemplateService.requiredFiltersSet($scope.pageObject, $scope.filters) || _.isEmpty($scope.pageObject.viz.settings.defaultText)) {
                        $timeout(function () {
                            var data = dataSource.data();

                            $scope.vizCtrl.data = data;
                            var html = VizTemplateService.setHtml(data, $scope.pageObject.viz);
                            $scope.usingTemplate = html !== defaultText;
                            $scope.templateHtml = html;
                            $scope.vizCtrl.templateHtml = $scope.templateHtml;
                        });
                    }
                    else {
                        $scope.usingTemplate = false;
                        $scope.templateHtml = defaultText;
                        $scope.vizCtrl.templateHtml = $scope.templateHtml;
                    }
                }
            });
            if ($scope.pageObject.viz.settings.columns.length > 0) {
                dataSource = VizTemplateService.buildDataSource($scope.pageObject, $scope);
            }

            vm.dataSource = dataSource;

            $scope.templateHtml = $scope.pageObject.viz.settings.defaultText || '';
            $scope.vizCtrl.templateHtml = $scope.templateHtml;

        
            VizUtilityService.subscribeToPageFilters($scope.pageObject.filterMap, $scope, dataSource);
        }
    })
    .directive('nimVizTemplate', function($compile) {
        return {
            restrict: 'AE',
            templateUrl: 'core/pages/viz/template/template.tpl.html',
            scope: true,
            require: '^nimPageObject',
            controller: 'nimVizTemplateCtrl',
            link: function(scope, element){
                scope.$watch('templateHtml', function(html){
                    var templateDiv = element.find('.nim-template-html');
                    templateDiv.empty().append(html);
                    $compile(templateDiv[0])(scope);
                });
            }
        };
    })
;