angular.module('cerberus.util')
    .controller('nimCheckBuilderCtrl', function(_, kendo, moment, $scope, $timeout, $q, FormsService, InstancesService){
        var vm = this;

        // Each page is an array of instanceIds with a length matching the specified checksPerPage
        vm.pages = _.chunk($scope.instanceIds, $scope.checksPerPage);

        vm.checkForms = {};
        vm.dataSet = {};
        vm.filters = {};
        vm.doneLoading = {};
        vm.sectionsShown = {};
        vm.fieldsShown = {};
        vm.autoFillData = {};
        vm.staticGridsterOptions = {
            pushing: true
        };

        vm.sectionFilter = FormsService.sectionFilter;

        init();

        function init() {
            if ($scope.autoFillData) {
                vm.autoFillData = $scope.autoFillData;
            }

            // If carbon copy is desired, double up each page
            if($scope.carbonCopy) {
                var pages = [];
                _.forEach(vm.pages, function(page) {
                    pages.push(page);
                    pages.push(page);
                });
                vm.pages = pages;
            }

            var promises = [],
                promiseIds = [],
                updateArray = [];

            _.forEach($scope.instanceIds, function(id) {
                vm.doneLoading[id] = false;
                vm.dataSet[id] = {};
                vm.filters[id] = {};
                vm.sectionsShown[id] = {};
                vm.fieldsShown[id] = {};

                InstancesService.getInstance(id).then(function (data) {
                    vm.doneLoading[id] = true;
                    vm.autoFillData[id] = vm.autoFillData[id] || {};
                    
                    var stateId = _.findKey(data.instanceWorkflow, $scope.formOriginId);
                    //var workflowState = data.instanceWorkflow[stateId];
                    //var formId = workflowState[$scope.formOriginId];

                    // Check each check if record was previously made, and record 'Check No' only if no record is found
                    if ($scope.checkNumExists && $scope.checkNumExists[id] == false) {
                        var updateData = {};
                        updateData[$scope.pageCounter] = $scope.checkNumbers[id];
                        updateArray.push({
                            id: id,
                            currentStateId: stateId,
                            data: updateData,
                            ignoreSections: []
                        });
                    }
                    if ($scope.formData[id]) {
                        var form = $scope.formData[id];

                        vm.checkForms[id] = form;
                        _.assign(vm.dataSet[id], form.defaultDataSet);
                        vm.filters[id] = {};

                        checkSectionConditions(id, form.form.sections);
                        checkFieldConditions(id, form.form.objects);
                    }
                    else {
                        var workflowState = _.find(data.instanceWorkflow, $scope.formOriginId),
                            formArray = [];

                        _.forEach(workflowState, function(formId, key){
                            if(!_.startsWith(key, '__')) {
                                formArray.push({
                                    formId: formId,
                                    isPk: true,
                                    displayOrder: '1'
                                });
                            }
                        });

                        var formDataPromise = InstancesService.getFormData(id, formArray);

                        promises.push(formDataPromise);
                        promiseIds.push(id);

                        formDataPromise.then(function (result) {
                            vm.checkForms[id] = _.find(result.formData, 'originatorId', parseInt($scope.formOriginId));

                            _.forEach(result.formData, function(form){
                                _.defaults(vm.checkForms[id].defaultDataSet, form.defaultDataSet);
                            });
                            
                            _.assign(vm.dataSet[id], vm.checkForms[id].defaultDataSet);
                            vm.filters[id] = {};

                            _.forEach(result.formData, function (form) {
                                checkSectionConditions(id, form.form.sections);
                                checkFieldConditions(id, form.form.objects);
                            });
                        });
                    }

                    if(_.every(vm.doneLoading)) {
                        // When data has been updated and all forms have loaded,
                        // trigger the print function
                        $q.all(promises).then(function (formDataResults) {
                            var autoFillPromises = [];

                            _.forEach(formDataResults, function (result, index) {
                                var IDx = promiseIds[index];

                                if (_.isEmpty(vm.autoFillData[IDx])) {
                                    autoFillPromises = autoFillPromises.concat(
                                        loadAutoFillData(IDx, result.formData, vm.checkForms[IDx].defaultDataSet)
                                    );
                                }
                            });                            

                            $q.all(autoFillPromises).then(function () {
                                // Update FormObj 'CheckNo' column in the Instance(s) only if needed.
                                var updateArrayPromise = [];
                                if (!_.isEmpty(updateArray)) {
                                    updateArrayPromise.push(InstancesService.batchUpdate(updateArray));
                                }

                                $q.all(updateArrayPromise).then(function () {
                                    $timeout(function() {
                                        $scope.done();
                                    }, 250 + 150 * vm.pages.length /* *$scope.instanceIds.length */); // Page size varies if 1 or 3 check(s) per page.
                                });
                            });
                        });
                    }
                });
            });
        }
        
/* // OLD ORIGINAL CODE - UNABLE TO BATCH PRINT MULTIPLE CHECKS - WORKED ONLY WITH SINGLE CHECKS - 
   // ISSUES WITH autoFillData AND There is a promises.push(recordDataPromise); not used in new style code. Need to check again what is this "recordData"...
   // Transition to new code style 2021-12-18 - By HD -
   
        function init() {

            if ($scope.autoFillData) {
                vm.autoFillData = $scope.autoFillData;
            }

            if($scope.carbonCopy){
                var pages = [];

                // If carbon copy is desired, double up each page
                _.forEach(vm.pages, function(page){
                    pages.push(page);
                    pages.push(page);
                });

                vm.pages = pages;
            }

            var updateArray = [],
                promises = [];

            _.forEach($scope.instanceIds, function(id){
                vm.doneLoading[id] = false;
                vm.dataSet[id] = {};
                vm.filters[id] = {};
                vm.sectionsShown[id] = {};
                vm.fieldsShown[id] = {};

                InstancesService.getInstance(id).then(function(data){
                    vm.doneLoading[id] = true;
                    
                    var stateId = _.findKey(data.instanceWorkflow, $scope.formOriginId),
                        workflowState = data.instanceWorkflow[stateId],
                        formId = workflowState[$scope.formOriginId],
                        updateData = {};

                    updateData[$scope.pageCounter] = $scope.checkNumbers[id];

                    updateArray.push({
                        id: id,
                        currentStateId: stateId,
                        data: updateData,
                        ignoreSections: []
                    });

                    if($scope.formData[id]){
                        var form = $scope.formData[id];

                        vm.checkForms[id] = form;
                        form.defaultDataSet[$scope.pageCounter] = $scope.checkNumbers[id];
                        _.assign(vm.dataSet[id], form.defaultDataSet);
                        vm.filters[id] = {};
                        checkSectionConditions(id, form.form.sections);
                        checkFieldConditions(id, form.form.objects);
                    }
                    else {
                        var displayOrder = data.workflowObjects[stateId].display.order,
                            trimmedFormId = parseInt(_.last(formId.split('-'))),
                            checkForm = _.find(data.workflowObjects[stateId].model.forms, 'formId', trimmedFormId),
                            forms = [];
                        
                        _.forEach(data.workflowObjects, function (wfo) {
                            if (wfo.display.order <= displayOrder) {
                                forms = forms.concat(wfo.model.forms);
                            }
                        });

                        _.remove(forms, 'formId', trimmedFormId);

                        var formDataPromise = InstancesService.getFormData(id, [checkForm]),
                            recordDataPromise = InstancesService.getFormData(id, forms, null, true);
                        
                        promises.push(formDataPromise);
                        promises.push(recordDataPromise);

                        formDataPromise.then(function(result){
                            var form = result.formData[0];

                            vm.checkForms[id] = form;
                            form.defaultDataSet[$scope.pageCounter] = $scope.checkNumbers[id];

                            recordDataPromise.then(function (recordResult) {
                                _.defaults(form.defaultDataSet, recordResult.recordData);
                                _.assign(vm.dataSet[id], form.defaultDataSet);
                                vm.filters[id] = {};
                                checkSectionConditions(id, form.form.sections);
                                checkFieldConditions(id, form.form.objects);
                            });
                        });
                    }

                    if(_.every(vm.doneLoading)){
                        promises.push(
                            InstancesService.batchUpdate(updateArray)
                        );

                        // When data has been updated and all forms have loaded,
                        // trigger the print function
                        $q.all(promises).then(function(){
                            $timeout(function(){
                                $scope.done();
                            }, 400);
                        });
                    }
                });
            });
        }
*/

        function checkSectionConditions(id, sections) {
            _.forEach(sections, function(section) {
                if(!_.isEmpty(section.condition)) {
                    vm.sectionsShown[id][section.name] = FormsService.sectionCondition(section.condition, vm.dataSet[id], section.logic, 'read');
                }
                else {
                    vm.sectionsShown[id][section.name] = true;
                }
            });
        }

        function checkFieldConditions(id, formObjects) {
            _.forEach(formObjects, function (formObj) {
                var modelId = formObj.model.config.modelId,
                    section = formObj.model.display.section,
                    params = formObj.model.param;

                var sectionShown = !section || section == '__nimHiddenSection' || vm.sectionsShown[id][section];
                if (sectionShown && !_.isEmpty(params.condition) && (params.conditionalAction === 'show' || params.conditionalAction === 'hide')) {
                    var result = FormsService.sectionCondition(params.condition, vm.dataSet[id], params.conditionalLogic, 'read');

                    if (params.conditionalAction === 'hide') {
                        vm.fieldsShown[id][modelId] = !result;
                    }
                    else {
                        vm.fieldsShown[id][modelId] = result;
                    }
                }
                else {
                    vm.fieldsShown[id][modelId] = true;
                }
            });
        }

        function loadAutoFillData(id, formData, dataSet) {
            var promises = [];

            _.forEach(formData, function (form) {
                _.forEach(form.form.objects, function (f) {
                    var option = f.model.option,
                        modelId = f.model.config.modelId,
                        fieldValue = dataSet[modelId];

                    if (f.model.config.type == 'datetime' && fieldValue && _.isString(fieldValue)) {
                      //replaced by HD 2019-07-03
                      //dataSet[modelId] = kendo.parseDate(fieldValue, ['yyyy-MM-ddTHH:mm:ss', 'yyyy-MM-ddTHH:mm:ss.fffZ']);
                        dataSet[modelId] = kendo.parseDate(fieldValue, ['yyyy-MM-ddTHH:mm:ssz', 'yyyy-MM-ddTHH:mm:ss.fffz'], 'yyyy-MM-ddTHH:mm:sszzz');
                    }

                    if (!vm.autoFillData[id][modelId] && option.type !== 'custom' && !_.isEmpty(option.autoFill)) {
                        vm.autoFillData[id][modelId] = {};

                        var sectionShown = !f.model.display.section || f.model.display.section == '__nimHiddenSection' || vm.sectionsShown[id][f.model.display.section];

                        if (!_.isEmpty(dataSet[modelId]) && sectionShown && vm.fieldsShown[id][modelId]) {
                            var autoFillPromise = FormsService.getAutoFillValues(dataSet[modelId].id, option);

                            promises.push(autoFillPromise);

                            autoFillPromise.then(function (data) { 
                                if (data.length > 0) {
                                    $timeout(function () {
                                        _.forEach(data, function (targetField, index) {
                                            var autoFillMap = option.autoFill[index],
                                                propertyId = autoFillMap.value,
                                                value = targetField.value;

                                            if (targetField.type === 'timestamp') {
                                                value = moment.utc(value, 'MMMM, DD YYYY HH:mm:ss').toDate();
                                            }

                                            if (typeof value === 'object') {
                                                vm.autoFillData[id][modelId][propertyId] = _.get(value, 'display', '');
                                            }
                                            else {
                                                vm.autoFillData[id][modelId][propertyId] = value;
                                            }
                                        });
                                    });
                                }
                            });
                        }
                    }
                });
            });
            return promises;
        }
    })
    .directive('nimCheckBuilder', function() {
        return {
            restrict: 'AE',
            templateUrl: 'util/nim-print-check/nim-check-builder.tpl.html',
            controller: 'nimCheckBuilderCtrl',
            controllerAs: 'vm',
            link: function(scope, element) {
                element.css('height', scope.paperOptions.paperSize[1] + 'in')
                    .css('width', scope.paperOptions.paperSize[0] + 'in');

                scope.paperOptions.paperSize[0] = element.css('width');
                scope.paperOptions.paperSize[1] = element.css('height');

                element.css('width', '-=' + scope.paperOptions.margin.left + 'px')
                    .css('width', '-=' + scope.paperOptions.margin.right + 'px')
                    .css('height', '');
                
                scope.checkWidth = element.css('width');

                element.css('paddingLeft', scope.paperOptions.margin.left + 'px')
                    .css('paddingRight', scope.paperOptions.margin.right + 'px');
            }
        };
    })
;