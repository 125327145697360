angular.module('cerberus.admin')
    .config(function ($routeProvider) {
        $routeProvider.when('/settings/widgets', {
            templateUrl: 'admin/widgets/widgets.tpl.html',
            controller: 'WidgetsCtrl',
            title: 'Widgets',
            resolve: {
                _wait: 'WaitForUserData',
                workspaceId: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function(){
                        return $rootScope.userData.currentWorkspace.id;
                    });
                }
            }
        });
        $routeProvider.when('/settings/widgets/create', {
            templateUrl: 'admin/widgets/widgets-create.tpl.html',
            controller: 'WidgetsCreateCtrl',
            controllerAs: 'vm',
            title: 'Create Widget',
            resolve: {
                _wait: 'WaitForUserData'
            }
        });
        $routeProvider.when('/settings/widgets/:id', {
            templateUrl: 'admin/widgets/details/widgets-details.tpl.html',
            controller: 'WidgetsDetailsCtrl',
            title: 'Widget Details',
            resolve: {
                _wait: 'WaitForUserData',
                widgetId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/settings/widgets/:id/forms', {
            templateUrl: 'admin/widgets/forms/forms.tpl.html',
            controller: 'WidgetsFormsCtrl',
            title: 'Widget Forms',
            resolve: {
                _wait: 'WaitForUserData',
                widgetId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/settings/widgets/:wid/forms/:id', {
            templateUrl: 'admin/widgets/forms/designer/designer.tpl.html',
            controller: 'FormsDesignerCtrl',
            title: 'Form Designer',
            resolve: {
                _wait: 'WaitForUserData',
                formOriginId: function($route){
                    return $route.current.params.id;
                },
                widgetId: function($route){
                    return $route.current.params.wid;
                }
            }
        });
        $routeProvider.when('/settings/widgets/:wid/forms/:id/template', {
            templateUrl: 'admin/widgets/forms/templates/forms-templates-create.tpl.html',
            controller: 'FormsTemplatesCreateCtrl',
            title: 'Create Form Template',
            resolve: {
                _wait: 'WaitForUserData',
                formOriginId: function($route){
                    return $route.current.params.id;
                },
                widgetId: function($route){
                    return $route.current.params.wid;
                }
            }
        });
        $routeProvider.when('/settings/widgets/:id/workflow', {
            templateUrl: 'admin/widgets/workflows/workflow.tpl.html',
            controller: 'WidgetsWorkflowCtrl',
            controllerAs: 'vm',
            title: 'Widget Workflow',
            resolve: {
                _wait: 'WaitForUserData',
                widgetId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/settings/widgets/:wid/workflow/:id', {
            templateUrl: 'admin/widgets/workflows/designer/designer.tpl.html',
            controller: 'WorkflowsDesignerCtrl',
            title: 'Workflow Designer',
            resolve: {
                _wait: 'WaitForUserData',
                originId: function($route){
                    return $route.current.params.id;
                },
                workspaceId: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function(){
                        return $rootScope.userData.currentWorkspace.id;
                    });
                }
            }
        });
        $routeProvider.when('/settings/widgets/:id/data', {
            templateUrl: 'admin/widgets/data/widgets-data.tpl.html',
            controller: 'WidgetsDataCtrl',
            title: 'Widget Data',
            resolve: {
                _wait: 'WaitForUserData',
                widgetId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/settings/widgets/:id/imports', {
            templateUrl: 'admin/widgets/imports/imports.tpl.html',
            controller: 'WidgetsImportsCtrl',
            title: 'Widget Imports',
            resolve: {
                _wait: 'WaitForUserData',
                widgetId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/settings/widgets/:id/imports/jobs', {
            templateUrl: 'admin/widgets/imports/imports-jobs.tpl.html',
            controller: 'WidgetsImportsJobsCtrl',
            controllerAs: 'vm',
            title: 'Import Jobs',
            resolve: {
                _wait: 'WaitForUserData',
                authToken: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function(){
                        return $rootScope.authToken;
                    });
                },
                widgetId: function($route){
                    return $route.current.params.id;
                },
                workspaceId: function(WaitForUserData, $rootScope){
                    return WaitForUserData.then(function(){
                        return $rootScope.userData.currentWorkspace.id;
                    });
                }
            }
        });
        $routeProvider.when('/settings/widgets/:id/imports/rules/create', {
            templateUrl: 'admin/widgets/imports/imports-rules-create.tpl.html',
            controller: 'WidgetsImportsRulesCreateCtrl',
            title: 'Create Import Rule',
            resolve: {
                _wait: 'WaitForUserData',
                widgetId: function($route){
                    return $route.current.params.id;
                }
            }
        });
        $routeProvider.when('/settings/widgets/:id/imports/rules/:id2', {
            templateUrl: 'admin/widgets/imports/imports-rules-details.tpl.html',
            controller: 'WidgetsImportsRulesDetailsCtrl',
            title: 'Import Rule Details',
            resolve: {
                _wait: 'WaitForUserData',
                widgetId: function($route){
                    return $route.current.params.id;
                },
                importRuleId: function($route){
                    return $route.current.params['id2'];
                }
            }
        });
    })
;