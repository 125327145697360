angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizChartService
     * @alias cerberus/core:VizChartService
     * @description Preps and dynamically configures a chart viz based on data
     */
    .factory('VizChartService', function VizChartService(_, kendo, $timeout, $http, VizUtilityService, vizListService) {
        return {
            buildDataSource: buildDataSource,
            setSeriesColors: setSeriesColors,
            fixChartOptions: fixChartOptions
        };
        ////////////////////
        /**
         * Parses grid definition to properly set defines schema
         * @function buildDataSource
         * @param {object} pageObject
         * @param {object} scope - angularJs scope
         * @param {object} chartOptions
         * @param {function} configFunc - contains object-specific function to configure data/options
         * @return {kendo.data.DataSource}
         */
        function buildDataSource(pageObject, scope, chartOptions, configFunc) {
            var usesDateCategory = _.get(pageObject, 'viz.settings.categoryAxis.type') === 'date',
                groupField = _.get(pageObject, 'viz.settings.dataSource.group.field', ''),
                useClientSort = pageObject.viz.subType === 'pie' || pageObject.viz.subType === 'donut' || usesDateCategory,
                origDataSourceOptions = pageObject.viz.settings.dataSource;

            var realSortOptions = VizUtilityService.createSortArray(origDataSourceOptions);

            var dataSourceOptions = {
                type: 'odata',
                transport: {
                    read: read
                },
                serverFiltering: true,
                serverSorting: !useClientSort,
                serverPaging: true,
                serverAggregates: true,
                serverGrouping: false,
                requestStart: function (e){
                    if(!_.isEmpty(pageObject.params.requiredFilters)){
                        _.forEach(pageObject.params.requiredFilters, function(required, filter){
                            if(required && VizUtilityService.isFilterEmpty(scope.filters[filter])){
                                e.preventDefault();

                                $timeout(function(){
                                    e.sender.success({d:{results: [], __count: 0}});
                                });

                                return false;
                            }
                        });
                    }
                },
                change: function (e) {
                    if (groupField) {
                        _.forEach(e.sender.view(), function (group) {
                            var sortedItems = VizUtilityService.clientSort(group.items, realSortOptions);
                            if (!angular.equals(sortedItems, group.items)) {
                                group.items = sortedItems;
                            }
                        });
                    }
                    
                    scope.mainPageObject.vizCtrl.data = e.sender.data();
                }
            };

            var sortOptions = {
                sort: []
            };

            if (!useClientSort) {
                sortOptions.sort = realSortOptions;
            }

            var baseFilter = angular.copy(origDataSourceOptions.filter) || {
                filters: [],
                logic: 'and'
            };

            var filterOptions = {
                filter: {
                    filters: [baseFilter],
                    logic: pageObject.params.filterLogic || 'and'
                }
            };

            // Extend any user-defined dataSource settings
            angular.extend(dataSourceOptions, origDataSourceOptions, sortOptions, filterOptions);

            // Adds parse function to make sure date fields are correct
            _.forEach(dataSourceOptions.schema.model.fields, function (field) {
                if (field.type === 'date') {
                    field.parse = VizUtilityService.parseDateField;
                }
            });

            // Return new Datasource
            return new kendo.data.DataSource(dataSourceOptions);

            function read(options) {
                var httpOptions = VizUtilityService.buildBaseHttpOptions(pageObject, scope.isPreview);
                httpOptions.params = VizUtilityService.buildHttpParams(scope, dataSourceOptions, options);

                $http(httpOptions).then(function (result) {
                    var data = oDataToArray(result.data);
                    var categoryField = _.get(pageObject, 'viz.settings.categoryAxis.field');

                    // Fixes issue with grouping while using a date category
                    if (usesDateCategory && groupField) {
                        fixDateCategoryData(categoryField, groupField, data);
                    }
                    
                    // Client-side sorting
                    if (realSortOptions && useClientSort) {
                        data = VizUtilityService.clientSort(data, realSortOptions);
                    }

                    // Fix pageSize issue
                    var pageSize = _.get(options, 'data.pageSize');
                    if (data.length > pageSize) {
                        data.splice(pageSize, data.length - 1);
                    }
                    
                    // Transform data
                    if (configFunc && chartOptions) {
                        configFunc(data, chartOptions);
                    }

                    options.success(arrayToOData(data));
                }, function (result) {
                    options.error(result);
                });
            }
        }

        function setSeriesColors(chartOptions, params){
            var colorbrewer = vizListService.colorbrewer();
            var pathToPalette = [params.seriesDataType, params.seriesColorPalette, params.seriesClassNum];
            if(_.has(colorbrewer, pathToPalette)){
                chartOptions.seriesColors = _.get(colorbrewer, pathToPalette);
                chartOptions.seriesColors.reverse();
            }
            if(_.isEmpty(chartOptions.seriesColors)){
                delete chartOptions.seriesColors;
            }
        }

        function oDataToArray(oDataResult) {
            var data = [];

            if (_.has(oDataResult, 'd')) {
                if (_.isArray(oDataResult.d.results)) {
                    data = oDataResult.d.results;
                }
                else {
                    data.push(_.omit(oDataResult.d, '__count'));
                }
            }

            return data;
        }

        function arrayToOData(arr) {
            var odata = {
                d: {
                    __count: arr.length
                }
            };

            if (arr.length == 1) {
                _.assign(odata.d, arr[0]);
            }
            else {
                odata.d.results = arr;
            }

            return odata;
        }

        /**
         * Fix issues with incomplete configurations
         * @param {Object} options - Options for the kendo chart
         */
        function fixChartOptions(options) {
            if (options.categoryAxis && options.categoryAxis.type == 'date' && !options.categoryAxis.baseUnitStep) {
                options.categoryAxis.baseUnitStep = 1;
            }
        }

        /**
         * Fixes issue where a chart with grouping and a date category will
         * only render values for the categories present in the first group
         * @param {*} categoryField 
         * @param {*} groupField 
         * @param {*} data 
         */
        function fixDateCategoryData(categoryField, groupField, data) {
            // List of all category values
            var categories = _.compact(_.uniq(_.map(data, categoryField)));

            // Preview of grouped data
            var groups = _.groupBy(data, groupField);
            
            _.forEach(groups, function (groupData, groupValue) {
                _.forEach(categories, function (cat) { 
                    // If category value is not present in this group...
                    if (!_.find(groupData, categoryField, cat)) {
                        // Add new data item that falls in this group with the given category
                        // but with no value so the item doesn't render
                        var newDataItem = {
                            __nimAttachments: []
                        };

                        newDataItem[categoryField] = cat;
                        newDataItem[groupField] = groupValue;

                        data.push(newDataItem);
                    }
                });
            });
        }
    })
;