angular.module('cerberus.ui')
    .controller('NimRibbonCtrl', function ($scope, $element, $document, $window, $timeout) {
        // set local/private vars
        var vm = this;
        var scrollAnimation = null;
        var scrollTimer;

        // set/expose public vars
        vm.$container = $element;
        vm.$currentTab = null;

        // expose methods
        vm.initTab = initTab;
        vm.resize= resize;

        // create arrows
        vm.leftArrowEle = $document[0].createElement('div');
        vm.rightArrowEle = $document[0].createElement('div');
        vm.$leftArrow = angular.element(vm.leftArrowEle);
        vm.$rightArrow = angular.element(vm.rightArrowEle);

        // style arrows
        vm.$leftArrow.addClass('rm-drag-arrow').addClass('left').html('<i class="fa fa-angle-left"></i>');
        vm.$rightArrow.addClass('rm-drag-arrow').addClass('right').html('<i class="fa fa-angle-right"></i>');

        // add arrows
        vm.$container.append(vm.$leftArrow);
        vm.$container.append(vm.$rightArrow);

        // bind events
        vm.$leftArrow.bind('click', scrollSnapLeft);
        vm.$leftArrow.bind('dblclick', scrollFullLeft);
        vm.$leftArrow.bind('touchstart', scrollTouchLeft);
        vm.$leftArrow.bind('touchend', scrollOut);
        vm.$rightArrow.bind('click', scrollSnapRight);
        vm.$rightArrow.bind('dblclick', scrollFullRight);
        vm.$rightArrow.bind('touchstart', scrollTouchRight);
        vm.$rightArrow.bind('touchend', scrollOut);
        vm.$container.resize(resize);
        $scope.$on("$destroy", function() {
            vm.$leftArrow.unbind('click dblclick touchstart touchend').remove();
            vm.$rightArrow.unbind('click dblclick touchstart touchend').remove();
        });

        ////////////////////
        // hide/show arrow
        function displayArrows(x) {
            var pPosition = vm.$container.parent().position() || {top:0, left:0};
            if (vm.$currentTab[0].offsetWidth < vm.$container[0].offsetWidth) {
                vm.$leftArrow.css({display:'none'});
                vm.$rightArrow.css({display:'none'});
                return;
            }
            var right = vm.$currentTab[0].offsetWidth - vm.$container[0].offsetWidth + x;
            if (x > -1 && x < 1 ) {
                vm.$leftArrow.css({display:'none'});
            } else {
                vm.$leftArrow.css({display:'block'});
            }
            if(right < 1) {
                vm.$rightArrow.css({display:'none'});
            } else {
                vm.$rightArrow.css({display:'block', left:vm.$container[0].offsetWidth - pPosition.left - vm.$rightArrow.outerWidth() + 'px'});
            }
        }
        function displayProxy(self) {
            $window.Draggable.get(vm.$currentTab).update();
            displayArrows(self.vars.css.x);
        }

        // scroll functions
        function calcHelper() {
            var frameX = $window.Draggable.get(vm.$currentTab).x;
            var frameWidth = vm.$currentTab[0].offsetWidth;
            var containerWidth = vm.$container[0].offsetWidth;
            var maxX = (frameWidth - containerWidth) * -1;
            var nextX = ((frameX * -1) + containerWidth) * -1;
            var previousX = Math.min(0, (frameX + containerWidth));
            nextX = Math.max(nextX, maxX);
            return {
                maxX: maxX,
                nextX: nextX,
                previousX: previousX
            };
        }

        function scrollFullLeft() {
            var animation = new $window.TweenLite(vm.$currentTab, 0.3, {x: 0});
            animation.eventCallback("onComplete", displayProxy, ["{self}"]);
        }
        function scrollFullRight() {
            var animation = new $window.TweenLite(vm.$currentTab, 0.3, {x: calcHelper().maxX});
            animation.eventCallback("onComplete", displayProxy, ["{self}"]);
        }
        function scrollSnapLeft() {
            var animation = new $window.TweenLite(vm.$currentTab, 0.3, {x: calcHelper().previousX});
            animation.eventCallback("onComplete", displayProxy, ["{self}"]);
        }
        function scrollSnapRight() {
            var animation = new $window.TweenLite(vm.$currentTab, 0.3, {x: calcHelper().nextX});
            animation.eventCallback("onComplete", displayProxy, ["{self}"]);
        }
        function scrollTouchLeft(event) {
            event.preventDefault();
            var animation = new $window.TweenLite(vm.$currentTab, 0.3, {x: calcHelper().previousX});
            animation.eventCallback("onComplete", displayProxy, ["{self}"]);
        }
        function scrollTouchRight(event) {
            event.preventDefault();
            var animation = new $window.TweenLite(vm.$currentTab, 0.3, {x: calcHelper().nextX});
            animation.eventCallback("onComplete", displayProxy, ["{self}"]);
        }
        function scrollOut(event) {
            var e = event.toElement || event.relatedTarget;
            if (e.parentNode == this || e == this) {
                return;
            }
            scrollAnimation.kill();
            $timeout.cancel(scrollTimer);
            $window.Draggable.get(vm.$currentTab).update();
            scrollAnimation = null;
            displayArrows($window.Draggable.get(vm.$currentTab).x);
        }

        function resize() {
            var width = 0;
            var li = vm.$currentTab[0].children;
            var pPosition = vm.$container.position() || {top:0, left:0};
            for (var i = 0; i < li.length; i++) {
                width = width + Math.ceil(angular.element(li[i]).outerWidth()) + 10;
            }
            vm.$currentTab.css({width:width + pPosition.left + 'px'});
            if (vm.$container.outerWidth() > width + pPosition.left) {
                vm.$rightArrow.css({display:'none'});
            }
            vm.$leftArrow.css({display:'none'});
            $window.Draggable.get(vm.$currentTab).applyBounds({
                top: vm.$container.position().top,
                left: vm.$container.position().left,
                height: vm.$container.height(),
                width: vm.$container.innerWidth()
            });
            displayArrows($window.Draggable.get(vm.$currentTab).x);
        }

        function initTab(element) {
            $window.Draggable.create(element, {
                type:"x",
                bounds: vm.$container,
                edgeResistance:0.8,
                throwProps: true,
                maxDuration: 5,
                throwResistance: 3000,
                onDragEnd: function () {
                    displayArrows(this.x);
                },
                onThrowComplete: function () {
                    displayArrows(this.x);
                }
            });
        }

    })
    .directive('nimRibbon', function() {
        return {
            restrict: 'AE',
            controller: 'NimRibbonCtrl',
            transclude: true,
            template: '<div class="ribbon-wrapper"><div class="tabs-content" ng-transclude></div></div>',
            link: function (scope, element) {
                element.addClass('nim-ribbon');
            }
        };
    })
    .directive('nimRibbonTab', function() {
        return {
            restrict: 'AE',
            require: '^nimRibbon',
            link: function(scope, element, attrs, ctrl) {
                element.addClass('tab-panel');

                // Tell the controller to use this tab in its function closures
                ctrl.$currentTab = element;

                // Init the Controller
                ctrl.initTab(element);

                // Watch for resize items
                scope.$watchGroup([
                        function () { return element.children().length; },
                        function () { return ctrl.$container.innerWidth(); }
                    ],
                    function () { ctrl.resize(); }
                );

            }
        };
    })
;