angular.module('cerberus.core')
    .factory('MenusService', function MenusService(_, $http, $q, $rootScope, $route, $uibModal, $location, toaster, apiPath) {
        var currentDefaultPageId;
        var currentWorkspaceMenu = [];
        var currentWorkspaceMenuId;
        var currentWorkspaceMenuDict = {};
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';

        var service = {
            createMenu: createMenu,
            updateMenu: updateMenu,
            deleteMenu: deleteMenu,
            getMenu: getMenu,
            getWorkspaceMenu: getWorkspaceMenu,
            getMenus: getMenus,
            setDefaultPage: setDefaultPage,
            workspaceMenu: workspaceMenu,
            workspaceMenuDict:workspaceMenuDict,
            editCurrentPage: editCurrentPage,
            editCurrentWorkspaceMenu: editCurrentWorkspaceMenu,
            isPageRoute: isPageRoute,
            isPageActive: isPageActive,
            openWorkspacesModal: openWorkspacesModal,
            clear: clear
        };
        return service;
        ////////////////////

        /**
         * @function createMenu
         * @description Posts data to create new menu in current workspace
         * @param data
         * @returns {promise}
         */
        function createMenu(data){
            var deferred = $q.defer();
            $http.post(apiPath + 'menus', data)
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * @function updateMenu
         * @description Updates menu
         * @param id
         * @param data
         * @returns {promise}
         */
        function updateMenu(id, data) {
            var deferred = $q.defer();
            var d = {
                name: data.name,
                description: data.description,
                is_default: data.is_default,
                items: data.items
            };
            $http.put(apiPath + 'menus/' + id, d)
                .success(function(value){
                    $rootScope.$broadcast('nim-menu-updated');
                    toaster.pop('success', 'Menu Updated.');
                    deferred.resolve(value);
                })
                .error(function(reason){
                    toaster.pop('error', 'Error', 'Menu not updated');
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * @function deleteMenu
         * @description Deletes menu by id
         * @param id
         * @returns {promise}
         */
        function deleteMenu(id){
            var deferred = $q.defer();
            $http.delete(apiPath + 'menus/' + id)
                .success(function(value){
                    deferred.resolve(value);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * @function getMenu
         * @description Gets menu items
         * @param [id]
         * @returns {promise}
         */
        function getMenu(id) {
            if(id) {
                var deferred = $q.defer();
                $http.get(apiPath + 'menus/' + id)
                    .success(function (value) {
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
                return deferred.promise;
            }
            else {
                return getWorkspaceMenu();
            }
        }

        /**
         * @function getDefaultMenu
         * @description Gets current workspace's default menu
         */
        function getWorkspaceMenu(){
            var deferred = $q.defer();
            $http.get(apiPath + 'menus/default')
                .success(function(value){
                    currentWorkspaceMenu = value.DATA.items;
                    currentWorkspaceMenuId = value.DATA.id;
                    processMenu(value.DATA.items, currentWorkspaceMenuDict);
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * @function getMenus
         * @description Gets all menus in current workspace
         * @returns {promise}
         */
        function getMenus(){
            var deferred = $q.defer();
            $http.get(apiPath + 'menus')
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * @function setDefaultPage
         * @description Sets page with matching id as default
         * @param id
         * @param menuArray
         */
        function setDefaultPage(id, menuArray){
            var arr = menuArray;
            var setDefault = function(array){
                for(var i = 0;i < array.length; i++){
                    if(array[i].id != id){
                        delete array[i].isDefault;
                    }
                    setDefault(array[i].items);
                }
            };
            setDefault(arr);
        }

        /**
         * Caches the default page and flattens hierarchical structure to a dictionary
         * @param {Array} arr
         * @param {object} dict
         * @param {Array} [idPath]
         * @param {Array} [titlePath]
         */
        function processMenu(arr, dict, idPath, titlePath) {
            idPath = idPath || [];
            titlePath = titlePath || [];
            for (var i = 0, len = arr.length; i < len; i++) {
                dict[arr[i].pageId] = {
                    id: idPath.concat([arr[i].pageId]),
                    title: titlePath.concat([arr[i].title])
                };
                if (arr[i].isDefault === true) {
                    currentDefaultPageId = arr[i].pageId;
                    dict.default = {
                        id: idPath.concat([arr[i].pageId]),
                        title: titlePath.concat([arr[i].title])
                    };
                }
                if(arr[i].items && arr[i].items.length) {
                    var id = idPath.concat([arr[i].pageId]);
                    var title = titlePath.concat([arr[i].title]);
                    processMenu(arr[i].items, dict, id, title);
                }
            }
        }

        /**
         * Finds the default page in a menu array
         * @param {Array} arr
         * @returns {*}
         */
        //function findDefaultPage(arr) {
        //    for (var i = 0, len = arr.length; i < len; i++) {
        //        if (arr[i].isDefault === true) {
        //            return arr[i].pageId;
        //        }
        //        if(arr[i].items && arr[i].items.length) {
        //            return findDefaultPage(arr[i].items);
        //        }
        //    }
        //}

        /**
         * Workspace menu
         * @returns {Array}
         */
        function workspaceMenu() {
            return currentWorkspaceMenu;
        }

        /**
         * Getter setter for workspace menu
         * @returns {object}
         */
        function workspaceMenuDict() {
            return currentWorkspaceMenuDict;
        }

        /**
         * Edit the current page
         */
        function editCurrentPage() {
            var pageId = $route.current.params.pageId;
            if ($route.current.$$route && $route.current.$$route.controller === 'PagesCtrl' && !pageId) {
                pageId = currentDefaultPageId;
            }
            $location.url('/settings/pages/' + pageId);
        }

        /**
         * Edit the current workspace menu (side menu)
         */
        function editCurrentWorkspaceMenu() {
            $location.url('/settings/menus/' + currentWorkspaceMenuId);
        }

        /**
         * Test whether the current route is a page
         * @returns {boolean}
         */
        function isPageRoute() {
            if ($route.current.$$route) {
                return $route.current.$$route.controller === 'PagesCtrl';
            }
            
            return false;
        }

        /**
         * Test whether the current location matches parameters
         * @param {string} viewLocation
         * @param {boolean|*} isDefault
         * @returns {boolean}
         */
        function isPageActive(viewLocation, isDefault) {
            var location = $location.path();
            if ('/' + viewLocation === location || '/settings/pages/' + viewLocation === location) {
                return true;
            }
            else {
                return (isDefault && '/' === location);
            }
        }

        /**
         * Opens the workspace selection modal
         */
        function openWorkspacesModal(orgs) {
            if(!$rootScope.isWorkspaceModalOpen){
                $rootScope.isWorkspaceModalOpen = true;

                var modalInstance = $uibModal.open({
                    templateUrl: 'core/menus/menus-workspaces-modal.tpl.html',
                    controller: 'MenusWorkspacesCtrl',
                    backdropClass: 'darkest',
                    windowClass: 'transparent',
                    size: 'lg',
                    backdrop: 'static',
                    resolve: {
                        userOrgs: function () {
                            if(orgs){
                                // Some functions will check organizations before opening this modal
                                return orgs;
                            }
                            else {
                                return null;
                            }
                        }
                    }
                });

                modalInstance.result.then(function(){
                    $rootScope.isWorkspaceModalOpen = false;
                }, function(){
                    $rootScope.isWorkspaceModalOpen = false;
                });
            }
        }

        /**
         * Cleans up the stored variables; useful for session invalidation.
         */
        function clear() {
            currentDefaultPageId = null;
            currentWorkspaceMenuId = null;
            currentWorkspaceMenu = [];
        }
    })
;
