angular.module('cerberus.admin')
    .controller('FormsDesigner_datetimeCtrl', function FormsDesigner_datetimeCtrl(_, moment, kendo, $scope, $uibModalInstance, autoTabIndex, currentFormObjects,
                                                                                  editFlag, formObj, formOriginId, idArray, sections, widgetId,
                                                                                  FormsDesignerListService, FormsClassService,
                                                                                  DesignerUtilityService, WidgetsSettingsService) {
        // Populate Scope
        $scope.temp = angular.copy(formObj);
        $scope.orig = angular.copy(formObj);
        $scope.editFlag = editFlag;
        $scope.autoTabIndex = autoTabIndex;
        $scope.tabsTemplate = FormsDesignerListService.formObjects();
        $scope.defaultValType = null;
        $scope.customDefaultVal = null;
        $scope.valueTypes = FormsDesignerListService.defaultDateValues();
        $scope.formFields = [];
        $scope.formFieldObjects = [];
        $scope.conditionOptions = [];
        $scope.sections = sections;
        $scope.isTimeFormat = $scope.temp.model.display.format.match(/[HhfmstgG]/g);
        $scope.customParseFormat = {};
        $scope.conditionalActions = FormsDesignerListService.conditionalActions();
        $scope.conditionType = '';
        $scope.conditionOperators = DesignerUtilityService.conditionOperators();
        $scope.conditionBooleanValues = DesignerUtilityService.conditionBooleanValues();
        $scope.conditionActionValues = DesignerUtilityService.conditionActionValues();
        $scope.conditionFilter = DesignerUtilityService.conditionFilter(formOriginId, formObj.model.config.modelId);
        $scope.getConditionType = DesignerUtilityService.getConditionType;
        $scope.addCondition = DesignerUtilityService.addCondition($scope.temp, formOriginId);
        $scope.addCustomParseFormat = addCustomParseFormat;
        $scope.previewCustomParseFormat = previewCustomParseFormat;

        $scope.updateDefaultValue = function(defaultVal, customVal){
            if(defaultVal === '@nim_today'){
                $scope.temp.model.config.defaultValue = '@nim_today';
            }
            else{
                $scope.temp.model.config.defaultValue = customVal;
            }
        };

        // UI Helper Functions
        $scope.preventSpace = DesignerUtilityService.preventSpace;
        $scope.labelToUniqueId = function (item){
            if (!$scope.orig.model.config.modelId) {
                item.model.config.modelId = DesignerUtilityService.labelToUniqueId(item.model.label.text, idArray);
            }
        };

        // Form Object Specific Logic
        $scope.formatList = [
            { display: 'Short Date', value: 'd' },
            { display: 'Long Date', value: 'D' },
            { display: '24-Hour Time', value: 'HH:mm:ss' },
            { display: '12-Hour Time', value: 'h:mm:ss tt' },
            { display: 'Short Date-Time', value: 'g' },
            { display: 'Long Date-Time', value: 'G' }
        ];

        $scope.formatChanged = function(){
            $scope.isTimeFormat = $scope.temp.model.display.format.match(/[HhfmstgG]/g);
            $scope.formattedDate = getFormatDate();
        };

        $scope.deleteArrayObject = function(array, index){
            array.splice(index, 1);
        };

        // Modal Actions
        $scope.ok = function (){
            $uibModalInstance.close($scope.temp);
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        // Init
        init();

        function init(){
            WidgetsSettingsService.getFormFields(widgetId).then(function(formFieldArray){
                DesignerUtilityService.mergeFieldsWithCurrentForm(formFieldArray, currentFormObjects);

                $scope.formFields = formFieldArray;
                $scope.formFieldObjects = getFormFieldObjects(formFieldArray);

                var conditionOptions = _.sortBy(_.filter($scope.formFieldObjects, $scope.conditionFilter), 'label');
                $scope.conditionOptions = [{ label: '(Record Action)', modelId: '__nimRecordAction' }].concat(conditionOptions);

                // Codemirror
                $scope.codemirrorLoaded = DesignerUtilityService.codemirrorLoaded(formFieldArray.operands);
                $scope.mainGridOptions = DesignerUtilityService.formGrid(formFieldArray.display);
                $scope.detailGridOptions = DesignerUtilityService.formDetailGrid($scope);
            });

            if(formObj.model.config.defaultValue === '@nim_today'){
                $scope.defaultValType = '@nim_today';
            }
            else{
                $scope.defaultValType = 'custom';
                $scope.customDefaultVal = formObj.model.config.defaultValue;
            }

            $scope.formattedDate = getFormatDate();

            // Hack
            DesignerUtilityService.focusFirstElement('configForm');
        }

        function getFormFieldObjects(fieldArray){
            var objects = [];
            for(var f = 0; f < fieldArray.display.length; f++){
                var form = fieldArray.display[f];
                for(var o = 0; o < form.objects.length; o++){
                    var object = form.objects[o];
                    object.formId = form.originId;
                    object.realFormId = form.formId;
                    objects.push(object);
                }
            }
            return objects;
        }

        function getFormatDate(){
            var now = moment(new Date()),
                interval = $scope.temp.model.tag.attrs['k-interval'];

            if(interval && now.round && $scope.isTimeFormat){
                now.round(interval, 'minutes');
            }

            return kendo.toString(now.toDate(), $scope.temp.model.display.format);
        }

        function addCustomParseFormat(format) {
            if (!$scope.temp.model.param.parseFormats){
                $scope.temp.model.param.parseFormats = [];
            }

            $scope.temp.model.param.parseFormats.push(format.text);
            format.text = '';
        }

        function previewCustomParseFormat(format) {
            return kendo.toString(new Date(), format);
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;