angular.module('cerberus.account')
    .controller('OrganizationsCreateCtrl', function OrganizationsCreateCtrl($scope, $location, defaultDomain, DesignerUtilityService, OrganizationsService) {
    $scope.defaultDomain = defaultDomain;
    $scope.origData = {};
    $scope.tempData = {};
    $scope.workspaceObject = {};

    $scope.clearLogo = _clearLogo;
    $scope.nameToPath = _nameToPath;
    $scope.hasChanges = _hasChanges;
    $scope.submit = _submit;
    $scope.cancel = _cancel;

    _init();

    function _init(){
        _clearLogo();
    }

    function _clearLogo(){
        $scope.tempData.logo = '';
        $scope.imageSrc = null;
    }
    function _nameToPath(name){
        if(name){
            $scope.workspaceObject.path = DesignerUtilityService.labelToUniqueId(name, []);
        }
    }

    function _hasChanges() {
        return !angular.equals($scope.origData, $scope.tempData);
    }

    function _submit(isValid, data, workspaceData){
        if(isValid){
            data.workspaceData = workspaceData;
            OrganizationsService.create(data).then(function(){
                $location.url('users/settings/organizations');
            });
        }
    }

    function _cancel(){
        $location.url('users/settings/organizations');
    }
});