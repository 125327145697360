angular.module('cerberus.admin')
    .controller('WorkspacesCtrl', function WorkspacesCtrl($scope, $uibModal, $rootScope, $window) {
        $scope.workspaces = [];
        $scope.$watch('$rootScope.userData', function (newValue) {
            $scope.workspaces = newValue.workspaces;
        }, true);
        $scope.goToWorkspace = function(url){
            $window.location.href = url + '/#/';
        };
        $scope.openCreateBusinessWorkspaceModal = function(){
            var modalInstance = $uibModal.open({
                templateUrl: 'admin/workspaces/workspaces-create-modal.tpl.html',
                controller: 'WorkspacesCreateModalCtrl',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    isSubWorkspace: function(){
                        return 0;
                    }
                }
            });
            modalInstance.result.then(function (workspaceUrl) {
                $scope.goToWorkspace(workspaceUrl);
            });
        };
    })
;
