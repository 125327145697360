angular.module('cerberus.core')
    .controller('nimVizTableCtrl',function(_, kendo, $scope, $timeout, $firebaseObject, fbUtil,
                                           VizTableService, VizUtilityService) {
        var vm = this;
        var dataSource = VizTableService.buildDataSource($scope.pageObject, $scope);
        var dataBound = false;
        var navFunctions = {
            getNext: _.partialRight(VizTableService.viewNextRecord, dataSource),
            getPrevious: _.partialRight(VizTableService.viewPreviousRecord, dataSource)
        };
        var debouncedRefresh = _.debounce(refreshTable, 5000, { leading: true, trailing: true });

        vm.selectedRows = [];
        vm.selectedIds = [];
        vm.expandedRows = [];
        vm.detailPageObjects = [];
        vm.allRowsSelected = false;
        vm.data = [];
        vm.aggregates = {};

        $scope.grid = null;
        $scope.colFilters = {};
        $scope.checkedForDefaultFilter = false;
        $scope.pageChanging = false;

        vm.notesGridOptions = VizTableService.notesGridOptions;
        vm.attachmentGridOptions = VizTableService.attachmentGridOptions;
        vm.exportXLS = exportXLS;
        vm.exportAllAsXLS = exportAllAsXLS;
        vm.selectRow = selectRow;
        vm.selectAllRows = selectAllRows;
        vm.rowSelected = rowSelected;
        vm.detailTabChanged = detailTabChanged;

        init();

        function init() {
            //Firebase
            var initializing = true,
                unwatchFb = $firebaseObject(fbUtil.ref('queries/' + $scope.pageObject.viz.settings.dataSource.nim_viewId)).$watch(function(){
                    if (initializing) {
                        //Stops first firing of the event
                        $timeout(function () {
                            initializing = false;
                        });
                    } else {
                        debouncedRefresh();
                    }
                });
            
            vm.options = buildOptions($scope.pageObject);

            VizUtilityService.subscribeToPageFilters($scope.pageObject.filterMap, $scope, dataSource);
            $scope.mainPageObject.vizCtrl = vm;

            if ($scope.nimIsAdvSelObject) {
                $scope.$watchCollection('vm.selectedRows', function (selectedRows, oldRows) {
                    if (!angular.equals(selectedRows, oldRows)) {
                        $scope.$emit('nim-advanced-selection-updated', selectedRows);
                    }
                });
            }

            // Event Bindings
            $scope.$on('nim-viz-reload', function (event) {
                event.preventDefault();
                debouncedRefresh();
            });

            var removeGridSizeWatcher = angular.noop;
            $scope.$on("kendoWidgetCreated", function (e, widget) {
                e.preventDefault();
                e.stopPropagation();

                if (widget == $scope.grid) {
                    var debouncedResizeFunc = _.debounce(function () {
                        if ($scope.resizeGridsterItem) {
                            $timeout(function () {
                                $scope.resizeGridsterItem();
                            }, 1);
                        }
                    }, 400, { leading: true, trailing: true });

                    removeGridSizeWatcher = $scope.$watchGroup([
                        function () {
                            return _.result(widget, 'tbody.height');
                        },
                        function () {
                            return _.result(widget, 'pager.element.height');
                        }
                    ], debouncedResizeFunc);
                    
                    if (widget.options.detailTemplate && !_.get($scope.pageObject.params, 'routeSettings.enabled') && !$scope.pageObject.params.openOnSelect) {
                        widget.tbody.on('click', '.k-master-row', function (event) {
                            var target = angular.element(event.target);
                        
                            if (!target.is('a') && !target.is('input')) {
                                target.closest('.k-master-row').find('.k-hierarchy-cell a.k-icon').trigger('click');
                            }
                        });
                    }
                }
            });

            $scope.$on('nim-viz-update-data', function (e, action, id/*, data*/) {
                //var oldData = dataSource.data();
                if (action === 'create') {
                    // Nothing here, for now...
                }
                else if (action === 'update') {
                    // Nothing here, for now...
                }
                else if (action === 'delete') {
                    _.forEach(id, function (deletedId) {
                        var dataItem = dataSource.get(deletedId);

                        if (dataItem) {
                            dataSource.remove(dataItem);
                        }
                    });

                    vm.selectedIds = [];
                    vm.selectedRows = [];
                    debouncedRefresh();
                }
            });

            $scope.$on('$destroy', function () {
                delete navFunctions.getNext;
                delete navFunctions.getPrevious;

                unwatchFb();     // Remove this watcher so it stops firing after the user has left the page
            });

            $scope.$on('nim-remove-page-objects', function () {
                if ($scope.grid) {
                    $scope.grid.destroy();
                    $scope.grid = null;
                }

                removeGridSizeWatcher();
            });
        }

        function buildOptions(pageObject) {
            var options = {
                autoBind: false,
                columns: [],
                dataSource: dataSource,
                columnMenu: false,
                editable: false,
                filterable: _.get(pageObject, "viz.settings.filterable", true),
                mobile: false,
                navigatable: true,
                pageable: false,    // By default, paging is disabled in favor of virtual scrolling
                reorderable: true,
                resizable: true,
                noRecords: true,
                scrollable: {
                    virtual: true   // Enabled by default
                },
                selectable: "row",
                sortable: false,
                toolbar: false,
                groupable: _.get(pageObject, "viz.settings.groupable", false),
                change: rowSelectHandler,
                dataBinding: dataBindingHandler,
                dataBound: dataBoundHandler,
                filterMenuInit: filterMenuInitHandler,
                detailExpand: detailExpandHandler,
                detailCollapse: detailCollapseHandler,
                expand: resizeTable,
                collapse: resizeTable
            };

            var params = pageObject.params;

            VizTableService.addCheckColumns(options, pageObject);
            VizTableService.addMainColumns(options, pageObject);
            var hasDetails =
            VizTableService.addDetailColumnsAndTemplates(vm, options, pageObject, navFunctions);

            // If virtual scrolling has been disabled, allow use of paging configuration
            if (_.get(params, "disableVirtualScrolling", true)) {
                options.pageable = _.get(pageObject, "viz.settings.pageable", true);
                options.scrollable.virtual = false; // Turn off virtual scrolling
            }

            if (_.get(pageObject, "viz.settings.sortable", true)) {
                options.sortable = true;

                // If doing a groupby, we want to be able to keep the records sorted by group
                // in addition to other sorting options
                if (_.has(pageObject, 'viz.settings.dataSource.group.field')) {
                    options.sortable = {
                        mode: 'multiple'
                    };
                }
            }    

            if (params.useRowColors && !params.useRowIcons) {
                options.rowTemplate = _.partial(VizTableService.buildRow, params.rowColors, options.columns, hasDetails);
            }
            else if (params.useCustomTemplate) {
                var row = buildCustomRowTemplate(hasDetails, pageObject.viz.settings.showAttaches, pageObject.viz.settings.showNotes);
                options.rowTemplate = rowTemplate(row);
                options.altRowTemplate = altRowTemplate(row);
            }

            return options;
        }

        function dataBindingHandler(e) {
            var dataSource = e.sender.dataSource;
            
            // Makes sure grouping does not mess up the sort order
            if (!_.isEmpty(dataSource.group())) {
                sortView(dataSource.sort(), dataSource.view());
            }
        }

        function dataBoundHandler(e) {
            var grid = e.sender,
                dataSource = grid.dataSource;
            var params = $scope.pageObject.params;
                    
            vm.data = dataSource.data();
            vm.aggregates = dataSource.aggregates();
            setKeyUpEvent(grid);

            _.forEach(vm.selectedIds, function (id, index) {
                var updatedRow = dataSource.get(id);
                    
                if (updatedRow) {
                    vm.selectedRows[index] = updatedRow;
                }
            });

            if (vm.expandedRows.length > 0) {
                $timeout(function () {
                    grid.expandRow(grid.tbody.children().filter(function (idx, row) {
                        var uid = angular.element(row).data('uid'),
                            dataItem = dataSource.getByUid(uid);

                        if (dataItem) {
                            return _.includes(vm.expandedRows, dataItem.id);
                        }

                        return false;
                    }));
                });
            }

            if (!$scope.nimIsAdvSelObject && !$scope.checkedForDefaultFilter) {
                // Makes sure row is selected after loading table filter from URL
                var filterId = params.filterId,
                    filter = $scope.filters[filterId],
                    asFilter = params.asFilter;

                if (asFilter && filter) {
                    if (!_.isEmpty(filter.filters)) {
                        _.forEach(filter.filters, function (f) {
                            if (f.value) {
                                var dataItem = dataSource.get(f.value);

                                if (dataItem) {
                                    // Makes sure tables which use this table as a
                                    // filter can access the lookup display value
                                    if (params.filterDisplay) {
                                        f.lookupDisplay = dataItem[params.filterDisplay];
                                    }
                                    else {
                                        f.dataItem = dataItem;
                                    }

                                    toggleSelectRow(f.value);

                                    // Has grid select row
                                    if (!params.allowMultiSelect && dataItem) {
                                        var rowObject = grid.table.find('tr[data-uid=' + dataItem.uid + ']');
                                        grid.select(rowObject);
                                    }
                                }
                            }
                        });
                    }
                }
            }

            $scope.checkedForDefaultFilter = true;

            if (grid && vm.selectedIds.length > 0) {
                var selectedPageRows = [];
                var pageData = dataSource.data();

                _.forEach(pageData, function (dataItem) {
                    var selector = "tr[data-uid=" + dataItem.uid + "]";
                    if (vm.selectedIds.indexOf(dataItem.id) >= 0) {
                        selectedPageRows.push(selector);
                    }
                });

                if (selectedPageRows.length > 0) {
                    $timeout(function () {
                        if (grid.table) {
                            $scope.pageChanging = true;

                            var selectedPageRowSelector = selectedPageRows.join(', ');
                            var selectedPageRowJQuery = grid.table.find(selectedPageRowSelector);
                                
                            grid.select(selectedPageRowJQuery);

                            $scope.pageChanging = false;
                        }
                    }, 1);
                }
            }

            applyTableFilter(e);
            resizeTable();
        }
            
        function filterMenuInitHandler(e) {
            // If column displays string value, change filter operator to "contains" by default
            // Taken from Kendo demo
            var field = $scope.pageObject.viz.settings.dataSource.schema.model.fields[e.field];
            if (_.get(field, 'type') === 'string') {
                var firstValueDropDown = e.container.find("select:eq(0)").data("kendoDropDownList");
                firstValueDropDown.value("contains");
                firstValueDropDown.trigger("change");

                var secondValueDropDown = e.container.find("select:eq(2)").data("kendoDropDownList");
                secondValueDropDown.value("contains");
                secondValueDropDown.trigger("change");
            }
        }

        function exportXLS() {
            _.result($scope, 'grid.saveAsExcel');
        }

        function exportAllAsXLS() {
            var filters = dataSource.filter();
            var sort = dataSource.sort();
            VizTableService.exportRecordsAsXLS($scope.pageObject.id, filters, sort);
        }

        function toggleSelectRow(rowId) {
            var index = rowIndex(rowId);
            $timeout(function () {
                if (index < 0) {
                    vm.selectedRows.push(dataSource.get(rowId));
                    vm.selectedIds.push(rowId);
                }
                else {
                    _.pullAt(vm.selectedRows, index);
                    _.pullAt(vm.selectedIds, index);
                }
            });
        }

        function selectRow(rowId) {
            toggleSelectRow(rowId);

            // Apply Filter
            applyTableFilter();
        }

        function selectAllRows(selected) {
            var rows = $scope.grid.dataSource.data();

            _.forEach(rows, function (row) {
                var id = row.id;
                var index = rowIndex(id);
                if(selected && index < 0){
                    vm.selectedRows.push(row);
                    vm.selectedIds.push(id);
                }
                else if(!selected && index >= 0){
                    _.pullAt(vm.selectedRows, index);
                    _.pullAt(vm.selectedIds, index);
                }
            });

            // Apply Filter
            applyTableFilter();
        }

        function checkRowSelection() {
            $timeout(function () {
                var rows = dataSource.data();
                var page = _.map(rows, 'id');

                vm.allRowsSelected = _.every(page, rowSelected);
            });    
        }

        function rowSelected(id) {
            return rowIndex(id) >= 0;
        }

        function refreshTable(){
            if ($scope.grid) {
                dataSource.read();
                applyTableFilter();
            }
        }

        function rowIndex(id){
            return vm.selectedIds.indexOf(id);
        }

        function applyTableFilter() {
            checkRowSelection();

            var params = $scope.pageObject.params;
            if ($scope.grid && params.asFilter && params.filterId) {
                if (!dataBound) {
                    dataBound = true;
                }
                else {
                    $timeout(function () {
                        var filters = _.map(vm.selectedRows, function (row, i) {
                            var id = vm.selectedIds[i],
                                dataItem = angular.copy(row),
                                filterObj = {
                                    field: '__nimColumn__',
                                    operator: 'eq',
                                    value: id
                                };

                            if (params.filterDisplay && dataItem) {
                                filterObj.lookupDisplay = dataItem[params.filterDisplay];
                            }
                            else if(dataItem) {
                                filterObj.dataItem = dataItem; // For objects that will use the filter for default data
                            }

                            return filterObj;
                        });

                        $scope.filters[params.filterId] = {
                            logic: 'or',
                            filters: filters
                        };
                    });
                }
            }
        }

        // ADDED BY HD - 2018-09-25
        var click_selection_object_id= null, click_selection_times = 0;    //used for double click selection
        // END ADDED BY HD - 2018-09-25
        
        function rowSelectHandler(e){
            if(!$scope.pageChanging) {
                var selected = e.sender.select();
                if (selected && selected.length > 0) {
                    var dataItem = e.sender.dataItem(selected[0]);

                    $timeout(function () {
                        // Opens Instance Window on Single Click Selection
                        if ($scope.pageObject.params.openOnSelect) {
                            VizTableService.viewRecord(dataItem.id, navFunctions);
                        }

                        // ADDED BY HD - 2018-09-25
                        // OPENS INSTANCE WINDOW ON DOUBLE CLICK SELECTION
                        // Uses 2 external variables: click_selection_object_id = null, click_selection_times = 0;
                        if ($scope.pageObject.params.openButton) {
                        var clkTimer = null, delay = 300;
                        click_selection_times++;        //count click_selection_times
                        if(click_selection_times == 1) {
                          clkTimer = setTimeout(function() {
                            $scope.$apply(function () {
                            click_selection_object_id = dataItem.id;
                            click_selection_times = 0;  //after action performed, reset counter
                            }); 
                          }, delay);
                          } else {
                            if (click_selection_object_id === dataItem.id) {
                                VizTableService.viewRecord(dataItem.id, navFunctions);
                            }
                            clearTimeout(clkTimer);     //prevent single-click action
                            click_selection_times = 0;  //after action performed, reset counter
                          }
                        }
                        // END ADDED BY HD - 2018-09-25
                        
                        var routeSettings = _.get($scope.pageObject.params, 'routeSettings', {});
                        if (routeSettings.enabled && routeSettings.template) {
                            var target = routeSettings.newTab ? '_blank' : '_self';
                            VizUtilityService.openPageObjectRoute(routeSettings.template, dataItem, target);
                        }

                        // Filters by row
                        if (!$scope.pageObject.params.allowMultiSelect) {
                            vm.selectedRows = [dataItem];
                            vm.selectedIds = [dataItem.id];
                            if ($scope.pageObject.params.asFilter) {
                                applyTableFilter();
                            }
                        }
                    });
                }
            }
        }

        /**
         *
         * @param e - kendo event data
         * @param e.detailRow {jQuery}
         * @param e.masterRow {jQuery}
         * @param e.sender {kendo.ui.Grid}
         */
        function detailExpandHandler(e){
            resizeTable();

            var uid = e.masterRow.data('uid'),
                dataItem = e.sender.dataSource.getByUid(uid);

            if(dataItem && dataItem.id) {
                vm.expandedRows.push(dataItem.id);
            }
        }

        /**
         *
         * @param e - kendo event data
         * @param e.detailRow {jQuery}
         * @param e.masterRow {jQuery}
         * @param e.sender {kendo.ui.Grid}
         */
        function detailCollapseHandler(e){
            resizeTable();

            var uid = e.masterRow.data('uid'),
                dataItem = e.sender.dataSource.getByUid(uid);

            if(dataItem.id) {
                _.pull(vm.expandedRows, dataItem.id);
            }
        }

        /**
         * Sets keyup event to handle filtering the selected [cell's] column
         * @param grid
         */
        function setKeyUpEvent(grid) {
            // Used to keep track of full typed string
            var typedString = '',
                timeout = null,
                selectedCell = null,
                filterOp = $scope.pageObject.params.colFilterOp || 'startswith';

            // Detects key up on grid table
            grid.table.on('keyup', function (event) {
                // Gets currently selected cell
                selectedCell = grid.current();

                // If holding ALT or CTRL or if no table
                // cell is selected, ignore key press
                if (event.altKey || event.ctrlKey || !selectedCell) {
                    return;
                }

                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();

                var keyCode = event.keyCode;

                // If using number pad, change to number keys
                if (keyCode >= 96 && keyCode <= 105) {
                    keyCode -= 48;
                }

                // Checks that keyCode matches characters 0-9, A-Z
                if (keyCode >= 48 && keyCode <= 90) {
                    // Converts key code to character and appends to string
                    typedString += String.fromCharCode(keyCode);

                    // Used for debouncing - previous timeout will not run
                    if (timeout) {
                        $timeout.cancel(timeout);
                    }

                    timeout = $timeout(function () {
                        if (selectedCell) {
                            // The many steps to find the right column to filter
                            var describedBy = selectedCell.attr('aria-describedby');    // Contains ID of column element

                            if (describedBy) {
                                var columnId = describedBy.split(' ')[0],                   // Parses ID
                                    columnElement = grid.thead.find('#' + columnId),        // Finds column element
                                    columnIndex = columnElement.data('index'),              // Gets index of column (in column array)
                                    column = grid.columns[columnIndex];                     // Finally found it

                                // This filtering is specifically for string values
                                if (_.get(column, 'type') === 'string') {
                                    var filterConfig = grid.dataSource.filter() || {
                                        filters: [],
                                        logic: 'and'
                                    };

                                    if ($scope.colFilters[column.field]) {
                                        var index = filterConfig.filters.indexOf($scope.colFilters[column.field]);

                                        if (index >= 0) {
                                            filterConfig.filters.splice(index, 1);
                                        }
                                    }

                                    $scope.colFilters[column.field] = {
                                        field: column.field,
                                        operator: filterOp,
                                        value: typedString
                                    };

                                    filterConfig.filters.push($scope.colFilters[column.field]);

                                    grid.dataSource.filter(filterConfig);
                                }
                            }
                        }

                        // Reset variables
                        typedString = '';
                        selectedCell = null;
                        timeout = null;

                    }, 300); // 300 millisecond debounce
                }
                else if (keyCode === 8 || keyCode === 46) { // Key codes for Backspace and Delete
                    if (timeout) {
                        $timeout.cancel(timeout);
                    }

                    // The many steps to find the right column to NOT filter
                    var describedBy = selectedCell.attr('aria-describedby');    // Contains ID of column element

                    if (describedBy) {
                        var columnId = describedBy.split(' ')[0],                   // Parses ID
                            columnElement = grid.thead.find('#' + columnId),        // Finds column element
                            columnIndex = columnElement.data('index'),              // Gets index of column (in column array)
                            column = grid.columns[columnIndex];                     // Finally found it

                        if (_.get(column, 'type') === 'string' && $scope.colFilters[column.field]) {
                            var filterConfig = grid.dataSource.filter() || {
                                filters: [],
                                logic: 'and'
                            };

                            if (filterConfig) {
                                var index = filterConfig.filters.indexOf($scope.colFilters[column.field]);

                                if (index >= 0) {
                                    filterConfig.filters.splice(index, 1);
                                }
                            }

                            delete $scope.colFilters[column.field];
                            grid.dataSource.filter(filterConfig);
                        }
                    }
                }
            }).on('keydown', function (event) {
                if (event.keyCode === 8) {
                    event.preventDefault();
                    event.stopPropagation();
                    event.stopImmediatePropagation();
                }
            });
        }

        function rowTemplate(row){
            var template = '';

            row.filter('tr').each(function(index, element){
                template += element.outerHTML;
            });

            return kendo.template(template);
        }

        function altRowTemplate(row){
            var template = '';

            row.addClass('k-alt').filter('tr').each(function(index, element){
                template += element.outerHTML;
            });

            return kendo.template(template);
        }

        function buildCustomRowTemplate(hasDetails, showAttach, showNotes){
            var row = angular.element($scope.pageObject.params.customTemplate);

            if(!row.is('tr')){
                row = angular.element('<tr>' + $scope.pageObject.params.customTemplate + '</tr>');
            }

            row.attr('data-uid', '#: uid #');

            if($scope.pageObject.params.allowMultiSelect){
                row.first().prepend('<td rowspan="' + row.filter('tr').length + '"><input class="form-control" type="checkbox" ng-checked="vm.rowSelected(#: id #)" ng-model="selected" ng-click="vm.selectRow(#: id #)" /></td>');
            }

            if(hasDetails){
                row.addClass('k-master-row')
                    .prepend('<td class="k-hierarchy-cell"><a class="k-icon k-plus" tabindex="-1"></a></td>');
            }

            if(showAttach){
                row.append('<td>#:attachment_count#</td>');
            }

            if(showNotes){
                row.append('<td>#:note_count#</td>');
            }

            return row;
        }

        function sortView(sortArray, viewArray) {
            // Iterates over each group
            _.forEach(viewArray, function(view){
                    /* LODASH Version 4 ONLY */
                    //var sorts = _.reject(sortArray, ['field', view.field]),
                    /* LODASH Version 3 & 4 Compatibility Mode */
                    var sorts = _.reject(sortArray, function(r){return r.field === view.field;}),
                    sortFields = _.map(sorts, 'field'),
                    sortDirs = _.map(sorts, 'dir');
                // Group's data items
                var data = view.items;
                if (!_.isEmpty(data)) {
                    if (view.hasSubgroups) {
                        sortView(sorts, data);
                    }
                    else {
                        /* LODASH MIGRATION HELPER FUNCTION */
                        /* LODASH Version 3 & 4 Compatibility Mode */
                        if ((_.VERSION).charAt(0) <= 3){ //Detect LODASH version 3 or 4. Function for V4 was renamed from 'sortByOrder' into 'orderBy'
                            //V3 or lower
                            console.log('Upgrade LODASH from V3 to V4 ASAP - ERRCode00DHLD3.1');
                            data = _.sortByOrder(data, sortFields, sortDirs);
                        } else {
                            //V4 or Higher
                            /* LODASH Version 4 ONLY */
                            data = _.orderBy(data, sortFields, sortDirs); // ++ AFTER MIGRATION TO V4 remove helper function with all V3 detect options ++
                        }
                    }

                    // Assigns sorted data to view
                    _.assign(view.items, data);
                }    
            });
        }

        function resizeTable(){
            if ($scope.resizeGridsterItem) {
                $timeout(function () {
                    $scope.resizeGridsterItem();
                }, 1);
            }
        }

        function detailTabChanged() {
            resizeTable();
        }
    })
    .directive('nimVizTable', function() {
            return {
                restrict: 'AE',
                templateUrl: 'core/pages/viz/table/table.tpl.html',
                scope: true,
                require: '^nimPageObject',
                controller: 'nimVizTableCtrl',
                controllerAs: 'vm'
            };
        })
;