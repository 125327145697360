angular.module('cerberus.admin')
    .factory('WorkspacesUserService', function WorkspacesUserService(_, $http, $q, apiPath, toaster) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';

        return {
            getUsers: getUsers,
            getAvailableMemberOf: getAvailableMemberOf,
            getMemberOf: getMemberOf,
            addUsers: addUsers,
            removeUsers: removeUsers,
            getOrgUserList: getOrgUserList,
            enableUsers: enableUsers,
            disableUsers: disableUsers
        };
        ////////////////////

        function disableUsers(data) {
            var deferred = $q.defer();
            $http.put(apiPath + 'workspaces/users/disable', data)
                .success(function (value) {
                    toaster.pop('info', 'Selected user(s) has been disabled.');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function enableUsers(data) {
            var deferred = $q.defer();
            $http.put(apiPath + 'workspaces/users/enable', data)
                .success(function (value) {
                    toaster.pop('success', 'Selected user(s) has been enabled.');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function removeUsers(id){
            var deferred = $q.defer();
            $http({method: 'DELETE', url: apiPath + 'workspaces/users/' + id})
                .success(function(value){
                    toaster.pop('info', 'User(s) removed.');
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getOrgUserList(){
            var deferred = $q.defer();
            $http.get(apiPath + 'workspaces/org/user/list')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function addUsers(data) {
            var deferred = $q.defer();
            $http.post(apiPath + 'workspaces/users', data)
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getMemberOf(userId) {
            var deferred = $q.defer();
            $http.get(apiPath + 'workspaces/users/' + userId + '/memberOf')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getAvailableMemberOf(userId) {
            var deferred = $q.defer();
            $http.get(apiPath + 'workspaces/users/' + userId + '/available/memberOf')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function getUsers(){
            var deferred = $q.defer();
            $http.get(apiPath + 'workspaces/users')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
    })
;