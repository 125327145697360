angular.module('cerberus.admin')
    .controller('CalendarConfigCtrl', function CalendarConfigCtrl($scope, $timeout, $uibModalInstance, toaster, pageObject, icons,
                                                                  filters, views, pages, PagesDesignerListService, pageObjectConfigService,
                                                                  DesignerUtilityService, ViewsService, vizListService) {
        var vm = this;
        
        vm.temp = angular.copy(pageObject);
        vm.orig = angular.copy(pageObject);
        vm.icons = icons;
        vm.filters = filters;
        vm.views = views;
        vm.pages = pages;
        vm.wrapperTypes = PagesDesignerListService.wrapperTypes();
        vm.wrapperConfig = PagesDesignerListService.wrapperConfig();
        vm.paletteOptions = {
            palette: PagesDesignerListService.palette(),
            columns: 31,
            tileSize: 18
        };
        vm.margin = DesignerUtilityService.cssToArray(pageObject.wrapper.style.margin) || [0,0,0,0];
        vm.padding = DesignerUtilityService.cssToArray(pageObject.wrapper.style.padding) || [0,0,0,0];
        vm.jsonEdit = angular.toJson(vm.temp.viz.settings);
        vm.viewColumns = [];
        vm.viewDetails = {};
        vm.indexLookup = {};
        vm.editView =  null;
        vm.filterMap = {};

        vm.colSelected = {};
        vm.advCollapse = true;

        vm.filterOperators = pageObjectConfigService.filterOperators();
        vm.textEditorOptions = pageObjectConfigService.textEditorOptions($scope);

        vm.vizDict = vizListService.vizDictionary();

        vm.tabTemplates = [
            {name: "General", url: "admin/pages/designer/common/general-tab.tpl.html"},
            {name: "Wrapper", url: "admin/pages/designer/common/wrapper-tab.tpl.html"},
            {name: "Data", url: "admin/pages/designer/viz/calendar/calendar-data-tab.tpl.html"},
            {name: "Filters", url: "admin/pages/designer/common/filter-tab.tpl.html"},
            {name: "Tools", url: "admin/pages/designer/common/tools-tab.tpl.html"}
        ];

        vm.deleteProperty = pageObjectConfigService.deleteProperty;

        vm.onViewChange = function(viewId){
            for(var i = 0; i < vm.views.length; i++){
                if(vm.views[i].id === viewId){
                    vm.temp.query.create = vm.views[i].base_widget_id;
                    break;
                }
            }
        };
        // Helper functions
        vm.upper = function(s){
            return s.substr(0, 1).toUpperCase() + s.substr(1, s.length - 1);
        };

        vm.stripQuotes = function(s){
            return s.replace(/"/g, '');
        };

        // Load functions
        vm.loadColumns = function(vid){
            if(vid){
                ViewsService.getColumns(vid).then(function(columns){
                    vm.viewColumns[vid] = columns;
                });
            }
        };

        // Column selection
        vm.allSelected = function(view){
            if(vm.viewColumns[view]) {
                for (var vc = 0; vc < vm.viewColumns[view].length; vc++) {
                    if (!vm.isSelected(view, vm.viewColumns[view][vc])) {
                        return false;
                    }
                }
                return true;
            }
            else {
                return false;
            }
        };

        vm.isSelected = function(view, column){
            return vm.indexLookup[vm.stripQuotes(column.value)] >= 0;
        };

        vm.selectColumn = function(view, column){
            var value = vm.stripQuotes(column.value);
            if(column.type === 'property'){
                value = column.name;
            }
            vm.temp.query.from[view] = vm.temp.query.from[view] || {
                id: view,
                select: []
            };
            vm.temp.viz.settings.columns = vm.temp.viz.settings.columns || [];
            if(vm.isSelected(view, column)) {
                var index = vm.indexLookup[vm.stripQuotes(column.value)];

                if(index != null && index >= 0) {
                    vm.temp.viz.settings.columns.splice(index, 1);
                    delete vm.temp.viz.settings.dataSource.schema.model.fields[value];
                    vm.temp.query.from[view].select.splice(index, 1);
                    if(vm.temp.query.from[view].select.length < 1){
                        delete vm.temp.query.from[view];
                    }
                    delete vm.indexLookup[value];
                }
            }
            else {
                var fromColumn = {
                    type: column.type,
                    name: column.name,
                    value: column.value,
                    asLabel: column.name
                };
                var settingsColumn = {
                    field: value,
                    title: fromColumn.asLabel,
                    type: column.castType,
                    attributes: {},
                    format: column.format || '',
                    aggregates: [],
                    footerTemplate: ''
                };
                vm.temp.query.from[view].select.push(fromColumn);
                vm.temp.viz.settings.columns.push(settingsColumn);
                vm.temp.viz.settings.dataSource.schema.model.fields[value] = {
                    type: column.castType,
                    from: value
                };
            }
            $timeout(function(){
                vm.tableColumns = angular.copy(vm.temp.viz.settings.columns);
            });
            updateIndices(view, vm.temp.query, vm.indexLookup);
        };

        vm.selectAll = function(view, remove){
            if(vm.allSelected(view)) {
                var columns = vm.viewColumns[view];
                for(var col in columns){
                    if(columns.hasOwnProperty(col)){
                        vm.selectColumn(view, columns[col], remove);
                    }
                }
                delete vm.temp.query.from[view];
            }
            else {
                for(var c = 0; c < vm.viewColumns[view].length; c++){
                    if(!vm.isSelected(view, vm.viewColumns[view][c])){
                        vm.selectColumn(view, vm.viewColumns[view][c], remove);
                    }
                }
            }
        };

        // Change callbacks
        vm.updateLabel = function(view, col){
            var index = vm.indexLookup[vm.stripQuotes(col.value)];
            if(index != null && index >= 0 && vm.temp.viz.settings.columns[index]) {
                vm.temp.viz.settings.columns[index].title = angular.copy(vm.temp.query.from[view].select[index].asLabel);
                vm.tableColumns = angular.copy(vm.temp.viz.settings.columns);
            }
        };

        vm.resetValue = function(series, col){
            var value = vm.stripQuotes(col.value);
            var fields = [
                'categoryField',
                'field',
                'xField',
                'yField',
                'sizeField'
            ];
            for(var f = 0; f < fields.length; f++){
                var field = fields[f];
                if(series[0].hasOwnProperty(field) && series[0][field] == value){
                    series[0][field] = "";
                }
            }
        };

        vm.hasValue = function(series, col){
            for(var s = 0; s < series.length; s++){
                if(series[s].field == col.field){
                    return true;
                }
            }
            return false;
        };

        // View Commands
        vm.selectView = function(view, item){
            item.viewInfo = {id:view.id};
            item.id = view.originId;
            item.viewObject = view.viewObject;
        };

        vm.setEditView = function(query){
            if(query.from && Object.keys(query.from).length > 0 ){
                vm.editView = query.from[Object.keys(query.from)[0]].id;
            }
        };

        // Filter Commands
        vm.addFilterMap = function (item) {
            if(item.key) {
                vm.temp.filterMap = vm.temp.filterMap || {};
                vm.temp.filterMap[item.key] = item.value;
                item.key = null;
                item.value = null;
            }
        };

        vm.removeFilterMap = function (key) {
            delete vm.temp.filterMap[key];
        };

        // Custom Buttons
        vm.addCustomButton = function(pageObj){
            pageObj.tools.customButtons = pageObj.tools.customButtons || [];
            pageObj.tools.customButtons.push({});
        };

        vm.removeCustomButton = function(pageObj, index){
            if(pageObj.tools.customButtons && index < pageObj.tools.customButtons.length) {
                pageObj.tools.customButtons.splice(index, 1);
            }
        };

        // Modal Commands
        vm.save = function () {
            if($scope.form.$invalid){
                toaster.pop('warning', 'Form invalid', '');
            }
            else {
                if (vm.temp.wrapper.type === 'circletile') {
                    vm.temp.wrapper.header.style.background = vm.temp.wrapper.body.style.background;
                }
                vm.temp.wrapper.style.margin = DesignerUtilityService.arrayToCss(vm.margin);
                vm.temp.wrapper.style.padding = DesignerUtilityService.arrayToCss(vm.padding);
                $uibModalInstance.close(vm.temp);
            }
        };

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.deletePageObject = function(){
            $uibModalInstance.dismiss('delete');
        };

        // JSON Editor
        vm.editorOptions = function (_editor) {
            // Editor part
            var _doc = _editor.getDoc();
            // Options
            _editor.setOption('lineWrapping', true);
            _editor.setOption('lineNumbers', true);
            _editor.setOption('matchBrackets', true);
            _editor.setOption('autoCloseBrackets', true);
            _editor.setOption('mode', "application/ld+json");
            //Prep
            _doc.markClean();
//            _editor.focus();
            //Open
            _editor.setSize("100%", "300px");
            _editor.on("keyup", function (cm, event) {
                var keyCode = event.keyCode || event.which;
                if (keyCode === 192 && event.ctrlKey) {
                    var formatted_json = vm.formatJson(_editor.getValue());
                    _editor.setValue(formatted_json);
                }
                $timeout(function(){
                    vm.jsonEdit = _editor.getValue();
                    vm.temp.viz.settings = angular.fromJson(vm.jsonEdit);
                });
            });
        };

        vm.formatJson = function (json) {
            var i = 0,
                il = 0,
                tab = "  ",
                newJson = "",
                indentLevel = 0,
                inString = false,
                currentChar = null;

            for (i = 0, il = json.length; i < il; i += 1) {
                currentChar = json.charAt(i);

                switch (currentChar) {
                    case '{':
                    case '[':
                        if (!inString) {
                            newJson += currentChar + "\n" + repeat(tab, indentLevel + 1);
                            indentLevel += 1;
                        } else {
                            newJson += currentChar;
                        }
                        break;
                    case '}':
                    case ']':
                        if (!inString) {
                            indentLevel -= 1;
                            newJson += "\n" + repeat(tab, indentLevel) + currentChar;
                        } else {
                            newJson += currentChar;
                        }
                        break;
                    case ',':
                        if (!inString) {
                            newJson += ",\n" + repeat(tab, indentLevel);
                        } else {
                            newJson += currentChar;
                        }
                        break;
                    case ':':
                        if (!inString) {
                            newJson += ": ";
                        } else {
                            newJson += currentChar;
                        }
                        break;
                    case ' ':
                    case "\n":
                    case "\t":
                        if (inString) {
                            newJson += currentChar;
                        }
                        break;
                    case '"':
                        if (i > 0 && json.charAt(i - 1) !== '\\') {
                            inString = !inString;
                        }
                        newJson += currentChar;
                        break;
                    default:
                        newJson += currentChar;
                        break;
                }
            }
            return newJson;
        };


        //Init
        for (var queryView in vm.temp.query.from) {
            if (vm.temp.query.from.hasOwnProperty(queryView)) {
                updateIndices(queryView, vm.temp.query, vm.indexLookup);
            }
        }

        for(var v = 0; v < views.length; v++){
            vm.loadColumns(angular.copy(views[v].id));
        }

        vm.setEditView(vm.temp.query);
        vm.onViewChange(vm.editView);

        function repeat(s, count) {
            return new Array(count + 1).join(s);
        }

        function updateIndices(view, query, lookup){
            if(!query.from[view]){
                return;
            }
            var select = query.from[view].select;
            for(var vCol = 0; vCol < select.length; vCol++){
                lookup[vm.stripQuotes(select[vCol].value)] = vCol;
            }
        }

        /*
        function footerTemplateString(aggregate){
            return '<div>' + aggregate.nimLabel + ': #=' + aggregate.aggregate + '#</div>';
        }
        */
    })
;