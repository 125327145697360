angular.module('cerberus.core')
    .controller('PagesCtrl', function PagesCtrl(_, $scope, $location, $rootScope, $window, pageId, PagesService, MenusService, nimTitleService, $timeout) {
        // Filters
        $scope.filters = PagesService.readUrlParams($location.search());

        $scope.$watch('filters', function(filter){
            if(filter) {
                $location.replace().search(PagesService.buildUrlParams(filter));
            }
            else {
                $location.replace().search({});
            }
        }, true);

        $window.Pace.on('done', paceDone);
        $window.Pace.on('restart', paceRestart);

        $scope.gridsterContainerStyle = {};

        $scope.$watch(function () {
            return MenusService.workspaceMenuDict()[pageId || 'default'];
        }, function (newValue) {
            if (newValue) {
                $scope.crumbTrail = newValue;
                nimTitleService.set(newValue.title[newValue.id.length - 1]);
            }
        });

        $scope.$on('$destroy', function(){
            $window.Pace.off('done', paceDone);
            $window.Pace.off('restart', paceRestart);
        });
        
        $scope.$on('$locationChangeStart', function (e, newUrl, oldUrl) {
            newUrl = newUrl || '';
            oldUrl = oldUrl || '';

            if (!e.defaultPrevented) {
                var path = $location.path();
                // Ignore this event if path did not change
                if (newUrl.indexOf(path) < 0 || oldUrl.indexOf(path) < 0) {
                    $scope.$broadcast('nim-remove-page-objects');
                }
            }
        });

        _init();
        ////////////////////
        function _init(){
            if(pageId){
                PagesService.getPage(pageId).then(setPageModel);
            }
            else {
                PagesService.getDefault().then(setPageModel);
            }

            $scope.userInfo = PagesService.getUserInfo();
        }

        function setPageModel(pageModel){
            $scope.pageModel = pageModel;
            $scope.gridsterOptions = {
                mobileBreakPoint: 768,
                margins: _.get($scope.pageModel, 'options.gridsterConfig.margins', [15, 15]),
                outerMargin: _.get($scope.pageModel, 'options.gridsterConfig.outerMargin', true),
                pushing: true,
                floating: true
            };

            // Protects against potential issue with gridster margins being saved as an object instead of an array
            if(!_.isArray($scope.gridsterOptions.margins)){
                $scope.gridsterOptions.margins = [
                    $scope.gridsterOptions.margins[0],
                    $scope.gridsterOptions.margins[1]
                ];
            }

            $scope.$watch('printingPage', function (isPrinting, wasPrinting) {
                if (isPrinting && !wasPrinting) {
                    var width = 816 + (2 * $scope.gridsterOptions.margins[0]);
                    $scope.gridsterContainerStyle.width = width + 'px';
                }
                else if (wasPrinting && !isPrinting) {
                    $scope.gridsterContainerStyle.width = 'auto';
                }
            });
        }

        function paceDone(){
            $timeout(function(){
                $window.status = "Complete";
            });
        }

        function paceRestart(){
            $timeout(function(){
                $window.status = "";
            });
        }
    })
;