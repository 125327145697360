angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name InstancesService
     * @alias cerberus/core:InstancesService
     * @description Provides CRUD functionality for instances
     */
    .factory('InstancesService', function InstancesService(_, $http, $q, apiPath, toaster, FormsClassService, DesignerUtilityService) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';
        var service = {
            getInstance: getInstance,
            createInstance: createInstance,
            update: update,
            batchUpdate: batchUpdate,
            //newRevision: newRevision,
            updateInstanceObject: updateInstanceObject,
            updateAllInstanceObject: updateAllInstanceObject,
            updateSchema: updateSchema,
            updateAllSchema: updateAllSchema,
            updateCalculations: updateCalculations,
            updateAllCalculations: updateAllCalculations,
            updateRelationships: updateRelationships,
            getRelationships: getRelationships,
            getDownloadKey: getDownloadKey,
            getFormData: getFormData,
            getRevision: getRevision,
            softDelete: softDelete,
            hardDelete: hardDelete,
            deleteAttachment: deleteAttachment,
            getDraft: getDraft,
            getInstanceParticipants: getInstanceParticipants,
            getBlob: getBlob
        };
        return service;
        ////////////////////

        function getRelationships(id){
            var deferred = $q.defer();
            $http.get(apiPath + 'instances/' + id + '/relationships')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * getDownloadKey
         * @param id
         * @returns {*|r.promise|Function|promise|Document.promise}
         */
        function getDownloadKey(id){
            var deferred = $q.defer();
            $http.get(apiPath + 'attachments/' + id + '/download/key')
                .success(function (value) {
                    deferred.resolve(apiPath + 'download/' + value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         revId
         * @param id
         * @returns {*|r.promise|Function|promise|Document.promise}
         */
        function getRevision(id){
            var deferred = $q.defer();
            $http.get(apiPath + 'instances/revision/' + id)
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * getFormData
         * @param instanceId
         * @param formArray
         * @param revisionId
         * @param dataOnly
         * @returns {*|r.promise|Function|promise|Document.promise}
         */

        function getFormData(instanceId, formArray, revisionId, dataOnly){
            var fby = function (v1, v2) {
                return v1.row - v2.row;
            };
            var tby = function (v1, v2) {
                return v1.col - v2.col;
            };
            var deferred = $q.defer(),
                url = apiPath + 'instances/' + instanceId + '/form/data?dataOnly=' + (!!dataOnly);

            if(revisionId){
                url += '&revision=' + revisionId;
            }

            $http.put(url,{formArray:formArray})
                .success(function (value) {
                    var newData = value.DATA.formData;
                    for(var i = 0; i < newData.length; i++){
                        if(!_.isEmpty(newData[i])) {
                            newData[i].form.objects.sort(
                                DesignerUtilityService.firstBy(fby)
                                    .thenBy(tby)
                            );
                            for (var j = 0; j < newData[i].form.objects.length; j++) {
                                var newField = FormsClassService.newField();
                                newField.setData(newData[i].form.objects[j].model);
                                newData[i].form.objects[j].model = newField;
                                //Generate tab index
                                if (newData[i].form.settings.autoTabIndex.enabled && newData[i].form.objects[j].model.config.dataType != 'static') {
                                    newData[i].form.objects[j].model.tag.attrs.tabindex = j + 2;
                                }
                            }
                        }
                    }

                    value.DATA.formData = newData;

                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }


        /**
         * Gets and instance by its ID
         * @param {string} instanceId
         * @param {string} state - (Optional) Limits instance data to this state
         * @returns {deferred.promise|{then}}
         */
        function getInstance(instanceId, state) {
            var deferred = $q.defer();
            var queryParam = '';

            if(state){
                queryParam = '?state=' + state;
            }

            $http.get(apiPath + 'instances/' + instanceId + queryParam)
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Creates a new instance
         * @param data
         * @returns {deferred.promise|{then}}
         */
        function createInstance(data) {
            var deferred = $q.defer();
            $http.post(apiPath + 'instances', data)
                .success(function (value) {
                    toaster.pop('success', 'Record Created');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    if(_.has(reason, 'DATA.errMsg')) {
                        toaster.pop({
                            type: 'warning',
                            bodyOutputType: 'trustedHtml',
                            body: parseError(reason.DATA.errMsg)
                        });
                    }
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function parseError (errArr){
            var str = '';
            //Parsing error into readable string
            for(var i = 0; i < errArr.length; i++ ){
                str = str + '<strong>' + errArr[i].modelId + '</strong>: ';
                for(var j = 0; j < errArr[i].invalidArr.length; j++){
                    str = str + errArr[i].invalidArr[j] + ' ';
                }
                str = str + '<br>';
            }
            return str;
        }

        /**
         * Updates an instances; more of a submit.  Will progress the instance in its workflow
         * @param {string} id
         * @param data
         * @returns {deferred.promise|{then}}
         */
        function update(id, data) {
            var deferred = $q.defer();
            $http.put(apiPath + 'instances/' + id, data)
                .success(function (value) {
                    toaster.pop('success', 'Success');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    if(_.has(reason, 'DATA.errMsg')) {
                        toaster.pop({
                            type: 'warning',
                            bodyOutputType: 'trustedHtml',
                            body: parseError(reason.DATA.errMsg)
                        });
                    }
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        function batchUpdate(recordDataArray){
            var deferred = $q.defer();
            $http.put(apiPath + 'instances/', {recordData: recordDataArray})
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Updates the form Object Data of an instance
         * @param {struct} data
         * @returns {deferred.promise|{then}}
         */
        function updateInstanceObject(data) {
            var deferred = $q.defer();
            $http.put(apiPath + 'instance/update/object', data)
                .success(function (value) {
                    toaster.pop('success', 'Object Data Updated');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Batch operation for updating form Object Data of instances from entire widget
         * @param {string} widgetId
         * @param {struct} updateOptions
         * @returns {deferred.promise|{then}}
         */
        function updateAllInstanceObject(widgetId, updateOptions) {
            var deferred = $q.defer();
            $http.put(apiPath + 'widgets/' + widgetId + '/instances/update/object/all', {updateSettings:updateOptions})
                .success(function (value) {
                    toaster.pop('success', 'Object Data Updated');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Updates the form schemas of an instance to the latest published version
         * @param data
         * @returns {deferred.promise|{then}}
         */
        function updateSchema(data) {
            var deferred = $q.defer();
            $http.put(apiPath + 'instance/update/schema', data)
                .success(function (value) {
                    toaster.pop('success', 'Schema Updated');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Batch operation for updating form schemas of instances from entire widget
         * @param {string} widgetId
         * @param {struct} updateOptions
         * @returns {deferred.promise|{then}}
         */
        function updateAllSchema(widgetId, updateOptions) {
            var deferred = $q.defer();
            $http.put(apiPath + 'widgets/' + widgetId + '/instances/update/schema/all', {updateSettings:updateOptions})
                .success(function (value) {
                    toaster.pop('success', 'Schema Updated');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Updates the form calculations of an instance
         * @param data
         * @returns {deferred.promise|{then}}
         */
        function updateCalculations(data) {
            var deferred = $q.defer();
            $http.put(apiPath + 'instance/update/calcs', data)
                .success(function (value) {
                    toaster.pop('success', 'Calculations Updated');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Batch operation for updating form calculations of instances from entire widget
         * @param {string} widgetId
         * @returns {deferred.promise|{then}}
         */
        function updateAllCalculations(widgetId) {
            var deferred = $q.defer();
            $http.put(apiPath + 'widgets/' + widgetId + '/instances/update/calcs/all')
                .success(function (value) {
                    toaster.pop('success', 'Calculations Updated');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Updates the relationships of an instance
         * @param {string} id
         * @param relationships
         * @returns {deferred.promise|{then}}
         */
        function updateRelationships(id, relationships) {
            var d = {relation: relationships};
            var deferred = $q.defer();
            $http.put(apiPath + 'instances/' + id + '/relationships', d)
                .success(function (value) {
                    deferred.resolve(value);
                    toaster.pop('success', 'Record relationships established.');
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Requests a soft delete of an instance
         * @param {string} id
         * @returns {deferred.promise|{then}}
         */
        function softDelete(id) {
            var deferred = $q.defer();
            $http({method: 'DELETE', url: apiPath + 'instances/soft/delete/' + id})
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Requests a hard delete of an instance
         * @param {Array} id
         * @returns {deferred.promise|{then}}
         */
        function hardDelete(id) {
            var deferred = $q.defer();
            $http.put(apiPath + 'instances/hard/delete', {ids: id})
                .success(function (value) {
                    toaster.pop('success', 'Records deleted.');
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Deletes an attachment
         * @param {string} id
         * @returns {deferred.promise|{then}}
         */
        function deleteAttachment(id) {
            var deferred = $q.defer();
            $http({method: 'DELETE', url: apiPath + 'attachment/' + id})
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Extends data objects with retrieved draft data
         * @param {object} draftData
         * @param {object} dataSet
         * @param {object} form
         */
        function getDraft(draftData, dataSet, form) {
            for(var mId in dataSet){
                if(draftData.data[mId]){
                    dataSet[mId] = draftData.data[mId];
                }
            }
            angular.extend(form, {fileAttachments: draftData.attachments});
        }
        /**
         * Gets list of users participating in instance
         * @param instanceId
         * @returns {*}
         */
        function getInstanceParticipants(instanceId){
            var deferred = $q.defer();
            $http.get(apiPath + 'instances/' + instanceId + '/participants')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

        /**
         * Get file as a blob
         * @param url
         * @returns {Promise}
         */
        function getBlob(url){
            var deferred = $q.defer();
            $http.get(url, {responseType: 'blob'})
                .success(function(data){
                    deferred.resolve(data);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }
    })
;
