angular.module('cerberus.ui')
    /**
     * @ngdoc service
     * @name dropdownFormService
     * @alias cerberus/ui:dropdownFormService
     * @description Provides functionality to dropdownForm directive
     */
    .service('dropdownFormService', function($document, dropdownFormConfig) {
        var openScope = null;

        this.open = function( dropdownScope ) {
            if ( !openScope ) {
                $document.bind('click', closeDropdown);
                $document.bind('keydown', escapeKeyBind);
            }

            if ( openScope && openScope !== dropdownScope ) {
                openScope.isOpen = false;
            }

            openScope = dropdownScope;
        };

        this.close = function( dropdownScope ) {
            if ( openScope === dropdownScope ) {
                openScope = null;
                $document.unbind('click', closeDropdown);
                $document.unbind('keydown', escapeKeyBind);
            }
        };

        var closeDropdown = function( evt ) {
            // This method may still be called during the same mouse event that
            // unbound this event handler. So check openScope before proceeding.
            if (!openScope) { return; }

            // If the click happened in a UI Library that appends widget to body
            if($(evt.target).closest('.'+ dropdownFormConfig.uiLibraryClass).length > 0) {
                return;
            }

            // If the click event was within the dropdown
            if ($(evt.target).closest('.' + dropdownFormConfig.containerClass).length > 0) {
                return;
            }

            var toggleElement = openScope.getToggleElement();
            if ( evt && toggleElement && toggleElement[0].contains(evt.target)) {
                return;
            }

            openScope.$apply(function() {
                openScope.isOpen = false;
            });
        };

        var escapeKeyBind = function( evt ) {
            if ( evt.which === 27 ) {
                openScope.focusToggleElement();
                closeDropdown();
            }
        };
    })
;