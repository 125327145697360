angular.module('cerberus.util')
    .factory('_', function _($window) {
        return $window._;
    })
    .factory('$', function $($window) {
        return $window.$;
    })
    .factory('CodeMirror', function CodeMirror($window) {
        return $window.CodeMirror;
    })
    .factory('kendo', function kendo($window) {
        return $window.kendo;
    })
    .factory('moment', function moment($window) {
        return $window.moment;
    })
    .factory('ics', function ics($window) {
        return $window.ics;
    })
    .factory('RRule', function RRule($window) {
        return $window.RRule;
    })
    .factory('L', function L($window) {
        return $window.L;
    })
    .factory('SignaturePad', function SignaturePad($window) {
        return $window.SignaturePad;
    })
    .factory('Firebase', function Firebase($window) {
        return $window.Firebase;
    })
    .factory('fbUtil', function fbUtil(fbUrl, Firebase) {
        return{
            ref: firebaseRef
        };

        function pathRef(args) {
            for (var i = 0; i < args.length; i++) {
                if (angular.isArray(args[i])) {
                    args[i] = pathRef(args[i]);
                }
                else if( typeof args[i] !== 'string' ) {
                    throw new Error('Argument ' + i + ' to firebaseRef is not a string: ' + args[i]);
                }
            }
            return args.join('/');
        }

        function firebaseRef() {
            var ref = new Firebase(fbUrl);
            var args = Array.prototype.slice.call(arguments);
            if( args.length ) {
                ref = ref.child(pathRef(args));
            }
            return ref;
        }
    })
    .factory('writtenNumber', function writtenNumber($window){
        return $window.writtenNumber;
    })
;