angular.module('cerberus.admin')
/**
 * @ngdoc service
 * @name FormsDesignerListService
 * @alias cerberus/forms-designer:FormsDesignerListService
 * @description Returns options for User Interface
 */
    .factory('FormsDesignerListService', function FormsDesignerListService() {
        return {
            formObjects: formObjects,
            patterns: patterns,
            masks: masks,
            separators: separators,
            defaultDateValues: defaultDateValues,
            addressFields: addressFields,
            conditionalActions: conditionalActions
        };
        /////////////////////
        function defaultDateValues(){
            return [
                {
                    label: 'Today',
                    value: '@nim_today'
                },
                {
                    label: 'Custom',
                    value: 'custom'
                }
            ];
        }
        /**
         * @function formObjects
         * @returns {{text: {tabTemplates: {name: string, url: string}[], dataType: string, display: string, defaultSizeX: number, defaultSizeY: number}, textarea: {tabTemplates: {name: string, url: string}[], dataType: string, display: string, defaultSizeX: number, defaultSizeY: number}, autocomplete: {tabTemplates: {name: string, url: string}[], dataType: string, display: string, defaultSizeX: number, defaultSizeY: number}, number: {tabTemplates: {name: string, url: string}[], dataType: string, display: string, defaultSizeX: number, defaultSizeY: number}, datetime: {tabTemplates: {name: string, url: string}[], dataType: string, display: string, defaultSizeX: number, defaultSizeY: number}, select: {tabTemplates: {name: string, url: string}[], dataType: string, display: string, defaultSizeX: number, defaultSizeY: number}, multiselect: {tabTemplates: {name: string, url: string}[], dataType: string, display: string, defaultSizeX: number, defaultSizeY: number}, radio: {tabTemplates: {name: string, url: string}[], dataType: string, display: string, defaultSizeX: number, defaultSizeY: number}, checkbox: {tabTemplates: {name: string, url: string}[], dataType: string, display: string, defaultSizeX: number, defaultSizeY: number}, signature: {tabTemplates: {name: string, url: string}[], dataType: string, display: string, defaultSizeX: number, defaultSizeY: number}, table: {tabTemplates: {name: string, url: string}[], dataType: string, display: string, defaultSizeX: number, defaultSizeY: number}, hr: {tabTemplates: {name: string, url: string}[], dataType: string, display: string, defaultSizeX: number, defaultSizeY: number}, statictext: {tabTemplates: {name: string, url: string}[], dataType: string, display: string, defaultSizeX: number, defaultSizeY: number}, readonly: {tabTemplates: {name: string, url: string}[], dataType: string, display: string, defaultSizeX: number, defaultSizeY: number}}}
         */
        function formObjects() {
            return {
                text:{
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/string/advanced-tab.tpl.html'
                        },
                        {
                            name: 'Calculation',
                            url: 'admin/widgets/forms/designer/string/calculation-tab.tpl.html'
                        },
                        {
                            name: 'Condition',
                            url: 'admin/widgets/forms/designer/common/condition-tab.tpl.html'
                        }
                    ],
                    dataType: 'string',
                    display: 'Text',
                    defaultSizeX: 6,
                    defaultSizeY: 7
                },
                textarea: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/string/advanced-textarea-tab.tpl.html'
                        },
                        {
                            name: 'Calculation',
                            url: 'admin/widgets/forms/designer/string/calculation-tab.tpl.html'
                        },
                        {
                            name: 'Condition',
                            url: 'admin/widgets/forms/designer/common/condition-tab.tpl.html'
                        }
                    ],
                    dataType: 'string',
                    display: 'Paragraph Text',
                    defaultSizeX: 12,
                    defaultSizeY: 35
                },
                autocomplete: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/string/advanced-autocomplete-tab.tpl.html'
                        },
                        {
                            name: 'Calculation',
                            url: 'admin/widgets/forms/designer/string/calculation-tab.tpl.html'
                        },
                        {
                            name: 'Condition',
                            url: 'admin/widgets/forms/designer/common/condition-tab.tpl.html'
                        }
                    ],
                    dataType: 'string',
                    display: 'Auto Complete',
                    defaultSizeX: 6,
                    defaultSizeY: 7
                },
                location: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/string/advanced-location-tab.tpl.html'
                        },
                        {
                            name: 'Calculation',
                            url: 'admin/widgets/forms/designer/string/calculation-tab.tpl.html'
                        },
                        {
                            name: 'Condition',
                            url: 'admin/widgets/forms/designer/common/condition-tab.tpl.html'
                        }
                    ],
                    dataType: 'string',
                    display: 'Geolocation',
                    defaultSizeX: 6,
                    defaultSizeY: 7
                },
                map: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/map/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/map/advanced-tab.tpl.html'
                        }
                    ],
                    dataType: 'map',
                    display: 'Map',
                    defaultSizeX: 24,
                    defaultSizeY: 85
                },
                number: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/number/advanced-tab.tpl.html'
                        },
                        {
                            name: 'Calculation',
                            url: 'admin/widgets/forms/designer/number/calculation-tab.tpl.html'
                        },
                        {
                            name: 'Condition',
                            url: 'admin/widgets/forms/designer/common/condition-tab.tpl.html'
                        }
                    ],
                    dataType: 'number',
                    display: 'Number',
                    defaultSizeX: 6,
                    defaultSizeY: 7
                },
                datetime: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/datetime/advanced-tab.tpl.html'
                        },
                        {
                            name: 'Calculation',
                            url: 'admin/widgets/forms/designer/datetime/calculation-tab.tpl.html'
                        },
                        {
                            name: 'Condition',
                            url: 'admin/widgets/forms/designer/common/condition-tab.tpl.html'
                        }
                    ],
                    dataType: 'datetime',
                    display: 'Date/Time',
                    defaultSizeX: 6,
                    defaultSizeY: 7
                },
                select: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/selection/advanced-tab.tpl.html'
                        },
                        //{
                        //    name: 'Calculation',
                        //    url: 'admin/widgets/forms/designer/selection/calculation-tab.tpl.html'
                        //},
                        {
                            name: 'Condition',
                            url: 'admin/widgets/forms/designer/common/condition-tab.tpl.html'
                        }
                    ],
                    dataType: 'selection',
                    display: 'Drop Down',
                    defaultSizeX: 6,
                    defaultSizeY: 7
                },
                multiselect: {
                    tabTemplates: [
                        //{
                        //    name: 'General',
                        //    url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        //},
                        //{
                        //    name: 'Advanced',
                        //    url: 'admin/widgets/forms/designer/selection/advanced-tab.tpl.html'
                        //},
                        //{
                        //    name: 'Calculation',
                        //    url: 'admin/widgets/forms/designer/selection/calculation-tab.tpl.html'
                        //},
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/dataseries/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/dataseries/advanced-table-tab.tpl.html'
                        },
                        {
                            name: 'Condition',
                            url: 'admin/widgets/forms/designer/common/condition-tab.tpl.html'
                        }
                    ],
                    dataType: 'dataseries',
                    display: 'Multiple Select',
                    defaultSizeX: 6,
                    defaultSizeY: 7
                },
                radio: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/selection/advanced-tab.tpl.html'
                        },
                        //{
                        //    name: 'Calculation',
                        //    url: 'admin/widgets/forms/designer/selection/calculation-tab.tpl.html'
                        //},
                        {
                            name: 'Condition',
                            url: 'admin/widgets/forms/designer/common/condition-tab.tpl.html'
                        }
                    ],
                    dataType: 'selection',
                    display: 'Radio',
                    defaultSizeX: 6,
                    defaultSizeY: 7
                },
                checkbox: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        },
                        {
                            name: 'Condition',
                            url: 'admin/widgets/forms/designer/common/condition-tab.tpl.html'
                        }
                    ],
                    dataType: 'selection',
                    display: 'Checkbox',
                    defaultSizeX: 6,
                    defaultSizeY: 7
                },
                signature: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/signature/advanced-tab.tpl.html'
                        },
                        {
                            name: 'Condition',
                            url: 'admin/widgets/forms/designer/common/condition-tab.tpl.html'
                        }
                    ],
                    dataType: 'signature',
                    display: 'Signature',
                    defaultSizeX: 8,
                    defaultSizeY: 10
                },
                table: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/dataseries/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/dataseries/advanced-table-tab.tpl.html'
                        }
                    ],
                    dataType: 'dataseries',
                    display: 'Table',
                    defaultSizeX: 12,
                    defaultSizeY: 60
                },
                hr: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/static/general-hr-tab.tpl.html'
                        }
                    ],
                    dataType: 'static',
                    display: 'Divider',
                    defaultSizeX: 12,
                    defaultSizeY: 6
                },
                statictext: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/static/general-statictext-tab.tpl.html'
                        },
                        {
                            name: 'Condition',
                            url: 'admin/widgets/forms/designer/common/condition-tab.tpl.html'
                        }
                    ],
                    dataType: 'static',
                    display: 'Static Text',
                    defaultSizeX: 12,
                    defaultSizeY: 7
                },
                barcode:{
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/readonly/barcode-config-tab.tpl.html'
                        },
                        {
                            name: 'Condition',
                            url: 'admin/widgets/forms/designer/common/condition-tab.tpl.html'
                        }
                    ],
                    dataType: 'barcode',
                    display: 'Barcode',
                    defaultSizeX: 6,
                    defaultSizeY: 9
                },
                readonly: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/readonly/advanced-tab.tpl.html'
                        },
                        {
                            name: 'Calculation',
                            url: 'admin/widgets/forms/designer/readonly/calculation-tab.tpl.html'
                        }
                    ],
                    dataType: 'readonly',
                    display: 'Read Only',
                    defaultSizeX: 6,
                    defaultSizeY: 7
                },
                timer: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/readonly/timer-config-tab.tpl.html'
                        }
                    ],
                    dataType: 'timer',
                    display: 'Timer',
                    defaultSizeX: 6,
                    defaultSizeY: 7
                },
                image: {
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/image/image-config-tab.tpl.html'
                        }
                    ],
                    dataType: 'image',
                    display: 'Image',
                    defaultSizeX: 6,
                    defaultSizeY: 7
                },
                rule:{
                    tabTemplates: [
                        {
                            name: 'General',
                            url: 'admin/widgets/forms/designer/common/general-tab.tpl.html'
                        },
                        {
                            name: 'Advanced',
                            url: 'admin/widgets/forms/designer/rule/advanced-tab.tpl.html'
                        }
                    ],
                    dataType: 'rule',
                    display: 'Rule',
                    defaultSizeX: 6,
                    defaultSizeY: 7
                }
            };
        }

        /**
         * @function patterns
         * @returns {{id: string, display: string, pattern: string}[]}
         */
        function patterns() {
            return [
                {
                    id: 'email',
                    display: 'Email',
                    pattern: '^\w[\w\.\-]*\w@\w+[\w\.\-]*\.[a-zA-Z]{2,}$'
                },
                {
                    id: 'url',
                    display: 'URL',
                    pattern: '^(https?:\/\/)?([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([\/\\w \\.-]*)*\/?$'
                }
            ];
        }

        /**
         * @function masks
         * @returns {{display: string, mask: string}[]}
         */
        function masks() {
            return [
                {
                    display: 'U.S. Phone Number',
                    mask: '(000) 000-0000'
                }
            ];
        }

        /**
         * @function separators
         * @returns {string[]}
         */
        function separators() {
            return [
                'None',
                ', ',
                '| ',
                '; '
            ];
        }

        function addressFields(){
            return {
                formatted_address: 'Full Address',
				postal_address: 'Postal Address',
                street_address: 'Street Address',
                street_number_long_name: 'Street Number',
                route_long_name: 'Street',
                establishment: 'Establishment',
                locality_long_name: 'City',
                administrative_area_level_1_short_name: 'State',
                administrative_area_level_2_long_name: 'County',
                postal_code_short_name: 'ZIP Code',
                postal_code_suffix_long_name: 'ZIP +4',
                country_long_name: 'Country',
                lat: 'Latitude',
                lng: 'Longitude'
            };
        }

        /**
         * @function conditionalActions
         * @param isStaticField
         * @returns {{display: string, value: string}[]}
         */
        function conditionalActions(isStaticField){
            if(isStaticField){
                return [
                    {
                        display: 'Show',
                        value: 'show'
                    },
                    {
                        display: 'Hide',
                        value: 'hide'
                    }
                ];
            }

            return [
                {
                    display: 'Show',
                    value: 'show'
                },
                {
                    display: 'Hide',
                    value: 'hide'
                },
                {
                    display: 'Active',
                    value: 'active'
                },
                {
                    display: 'Readonly',
                    value: 'readonly'
                },
                {
                    display: 'Required',
                    value: 'required'
                },
                {
                    display: 'Optional',
                    value: 'optional'
                }
            ];
        }
    })
;