angular.module('cerberus.ui')
    /**
     * @ngdoc service
     * @name pushActionService
     * @alias cerberus/ui:pushActionService
     * @description Provides functions for handling push actions (Execute actions).
     */
    .factory('pushActionService', function pushActionService(_, $http, apiPath, $q, toaster, $timeout, normalPrintingService) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';
        var service = {
            execute: execute,
            placeNote: placeNote
        };
        return service;
        ////////////////////
        
        /**
         * Execute 'push-action' related to specific instance; Will process action from instance action menu based on its workflow
         * @param {string} id
         * @param {struct} structData
         * @returns {deferred.promise|{then}}
         */
         
         //TODO - FIX LOOP TO ALLOW MULTIPLE FILES TO BE ATTACHED FROM ONE SINGLE SHOT...
        function execute(id, structData) {
            var deferred = $q.defer(),
                attachPromises = [],
                stateId = _.get(structData, 'instance_stateId', ''),
                defaultFileName = _.get(structData, 'instance_defaultFileName', null);
                
            //  var qq = {};

            //_.forEach(instanceIds, function(id) {
            //  qq.attachmentsReady[id] = false;

                generateInstancePDF(id, stateId, defaultFileName).then(function(result) {
            //      qq.attachmentsReady[id] = true;
                    var attachStruct = {attachmentInfo: result};
                    attachPromises.push(attachStruct);
            //        console.log(attachStruct); //get filename here...

                //  if(_.every(qq.attachmentsReady)) {
                    //when all attachments are ready. Continue with action
                    structData.attachments = attachPromises;
                    $http.put(apiPath + 'utilities/pushaction/' + id, {actionData: structData})
                        .success(function(value) {
                            toaster.pop('success', 'Success');
                            deferred.resolve(value.DATA);
                        })
                        .error(function(reason) {
                            if(_.has(reason, 'DATA.errMsg')) {
                                toaster.pop('warning', reason.DATA.errMsg);
                                // toaster.pop({
                                    // type: 'warning',
                                    // bodyOutputType: 'trustedHtml',
                                    // body: reason.DATA.errMsg //parseError(reason.DATA.errMsg)
                                // });
                            }
                            else {
                                toaster.pop('warning', _.get(reason, 'MESSAGE', connectionError));
                            }
                            deferred.reject(
                                _.get(reason, 'MESSAGE', connectionError)
                            );
                        });

                        $q.all(attachPromises).then(function () {
                            $timeout(function() {
                                //timeout exec.
                            }, 250 + 150 * attachPromises.length);
                        });
                    //}
                });
            //});
            return deferred.promise;
        }

     /* function parseError(errArr) {
            var str = '';
            //Parsing error into readable string
            for(var i = 0; i < errArr.length; i++ ) {
                str = str + '<strong>' + errArr[i].modelId + '</strong>: ';
                for(var j = 0; j < errArr[i].invalidArr.length; j++) {
                    str = str + errArr[i].invalidArr[j] + ' ';
                }
                str = str + '<br>';
            }
            return str;
        } */

        /**
         * Generate PDF for the given instance
         * @param instanceId
         * @param stateId
         * @param defaultFileName
         */
        function generateInstancePDF(instanceId, stateId, defaultFileName) {
        //------------------------------------------------
        //development - NOTE...
        //add stateId to be able to print a certain form from entire instance / widget container (Useful when widget contains multiple states and forms...
        //------------------------------------------------
            var deferred = $q.defer(),
                //instanceScope = $rootScope.$new(true),
                defaultName = defaultFileName || null,
                normalOptions = {
                    "landscape": false,
                    "paperSize": "letter",
                    "margin": {
                        "bottom": 0,
                        "left": 0,
                        "right": 0,
                        "top": 0
                        }
                },
                instance = {},
                dataSet = {},
                autoFillData = {},
                processPdf = 'write';

            //instanceScope.id = instanceId;
            //instanceScope.TestTempPaperOptions = normalOptions;

            // Call PDF generator function and return filename details
            normalPrintingService.printInstance(instanceId, defaultName, normalOptions, instance, dataSet, autoFillData, processPdf).then(function (value) {
                deferred.resolve(value);
            });

            // instanceScope.done = function() {
                // instanceScope.$destroy();
            // };

            return deferred.promise;
        }
        
        /**
         * Places a note for the given instance
         * @param instanceId
         * @param noteData
         */
        function placeNote(instanceId, noteData) {

            var deferred = $q.defer(),
                noteTitle =  _.get(noteData, 'title', 'Auto'),
                noteBody =  _.get(noteData, 'body', 'Auto'),
                noteIsPublic = _.get(noteData, 'isPublic', 'YES'),
                noteType = _.get(noteData, 'type', 'Note'); 

            var apiUrl = apiPath + 'instances/' + instanceId + '/metadata';
            $http.post(apiUrl, {dataStruct: {title: noteTitle, body: noteBody}, isPublic: noteIsPublic, type: noteType})
                    .success(function(value) {
                        toaster.pop('success', 'Success');
                        deferred.resolve(value.DATA);
                    })
                    .error(function(reason) {
                        if(_.has(reason, 'DATA.errMsg')) {
                            toaster.pop('warning', reason.DATA.errMsg);
                        }
                        else {
                            toaster.pop('warning', _.get(reason, 'MESSAGE', connectionError));
                        }
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
            return deferred.promise;
        }
    })
;