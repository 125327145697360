angular.module('cerberus.admin')
/**
 * @ngdoc service
 * @name PagesDesignerListService
 * @alias cerberus/pages-designer:PagesDesignerListService
 * @description Lists of options and features for User Interface
 */
    .factory('PagesDesignerListService', function PagesDesignerListService() {
        var wrapperSettingsPath = 'admin/pages/designer/wrapper-settings/';
        return {
            markerColors: markerColors,
            filters: filters,
            wrapperConfig: wrapperConfig,
            wrapperTypes: wrapperTypes,
            palette: palette,
            listViewTemplatePresets: listViewTemplatePresets,
            kanbanTemplatePresets: kanbanTemplatePresets,
            dataVizThemes: dataVizThemes
        };
        ////////////////////

        /**
         * markerColors
         * @returns {{text: string, color: string}[]}
         */
        function markerColors() {
            return [
                {
                    text: 'Red',
                    color: 'orange-dark'
                },
                {
                    text: 'Orange',
                    color: 'orange'
                },
                {
                    text: 'Yellow',
                    color: 'yellow'
                },
                {
                    text: 'Green',
                    color: 'green-light'
                },
                {
                    text: 'Blue',
                    color: 'cyan'
                },
                {
                    text: 'Purple',
                    color: 'violet'
                },
                {
                    text: 'Black',
                    color: 'black'
                }
            ];
        }

        /**
         * wrapperTypes
         * @returns {{value: string, display: string}[]}
         */
        function wrapperTypes() {
            return [
                {value: 'portlet', display: 'Portlet'},
                {value: 'tile', display: 'Tile'},
                {value: 'circle-tile', display: 'Circle Tile'}
            ];
        }

        /**
         * @function filters
         * @return {object} of filters for UI
         */
        function filters() {
            return {
                date: 'Date',
                dateRange: 'Date Range',
                number: 'Number',
                string: 'Text',
                lookup: 'Look Up'
            };
        }

        /**
         * @function wrappers
         * @return {object} of page wrappers for UI
         */
        function wrapperConfig() {
            return {
                tile: [wrapperSettingsPath + 'padding.tpl.html',wrapperSettingsPath + 'margin.tpl.html',wrapperSettingsPath + 'header.tpl.html',wrapperSettingsPath + 'body.tpl.html',wrapperSettingsPath + 'footer.tpl.html'],
                portlet: [wrapperSettingsPath + 'padding.tpl.html',wrapperSettingsPath + 'margin.tpl.html',wrapperSettingsPath + 'header.tpl.html',wrapperSettingsPath + 'body.tpl.html',wrapperSettingsPath + 'footer.tpl.html'],
                'circle-tile': [wrapperSettingsPath + 'header.tpl.html',wrapperSettingsPath + 'body.tpl.html']
            };
        }

        /**
         * @function palettes
         * @return {Array} of application colors for UI
         */
        function palette() {
            return [
                "#ffffe5","#ffffd9","#f7fcf0","#f7fcfd","#fff7fb","#fff7fb","#f7fcfd","#fff7f3","#f7f4f9","#fff7ec","#ffffcc","#ffffe5","#fcfbfd","#f7fbff","#f7fcf5","#fff5eb","#fff5f0","#ffffff","#b35806","#8c510a","#762a83","#c51b7d","#b2182b","#b2182b","#d73027","#d53e4f","#d73027","#a6cee3","#fbb4ae","#e41a1c","#8dd3c7",
                "#f7fcb9","#edf8b1","#e0f3db","#e5f5f9","#ece2f0","#ece7f2","#e0ecf4","#fde0dd","#e7e1ef","#fee8c8","#ffeda0","#fff7bc","#efedf5","#deebf7","#e5f5e0","#fee6ce","#fee0d2","#f0f0f0","#e08214","#bf812d","#9970ab","#de77ae","#d6604d","#d6604d","#f46d43","#f46d43","#f46d43","#1f78b4","#b3cde3","#377eb8","#ffffb3",
                "#d9f0a3","#c7e9b4","#ccebc5","#ccece6","#d0d1e6","#d0d1e6","#bfd3e6","#fcc5c0","#d4b9da","#fdd49e","#fed976","#fee391","#dadaeb","#c6dbef","#c7e9c0","#fdd0a2","#fcbba1","#d9d9d9","#fdb863","#dfc27d","#c2a5cf","#f1b6da","#f4a582","#f4a582","#fdae61","#fdae61","#fdae61","#b2df8a","#ccebc5","#4daf4a","#bebada",
                "#addd8e","#7fcdbb","#a8ddb5","#99d8c9","#a6bddb","#a6bddb","#9ebcda","#fa9fb5","#c994c7","#fdbb84","#feb24c","#fec44f","#bcbddc","#9ecae1","#a1d99b","#fdae6b","#fc9272","#bdbdbd","#fee0b6","#f6e8c3","#e7d4e8","#fde0ef","#fddbc7","#fddbc7","#fee090","#fee08b","#fee08b","#33a02c","#decbe4","#984ea3","#fb8072",
                "#78c679","#41b6c4","#7bccc4","#66c2a4","#67a9cf","#74a9cf","#8c96c6","#f768a1","#df65b0","#fc8d59","#fd8d3c","#fe9929","#9e9ac8","#6baed6","#74c476","#fd8d3c","#fb6a4a","#969696","#f7f7f7","#f5f5f5","#f7f7f7","#f7f7f7","#f7f7f7","#ffffff","#ffffbf","#ffffbf","#ffffbf","#fb9a99","#fed9a6","#ff7f00","#80b1d3",
                "#41ab5d","#1d91c0","#4eb3d3","#41ae76","#3690c0","#3690c0","#8c6bb1","#dd3497","#e7298a","#ef6548","#fc4e2a","#ec7014","#807dba","#4292c6","#41ab5d","#f16913","#ef3b2c","#737373","#d8daeb","#c7eae5","#d9f0d3","#e6f5d0","#d1e5f0","#e0e0e0","#e0f3f8","#e6f598","#d9ef8b","#e31a1c","#ffffcc","#ffff33","#fdb462",
                "#238443","#225ea8","#2b8cbe","#238b45","#02818a","#0570b0","#88419d","#ae017e","#ce1256","#d7301f","#e31a1c","#cc4c02","#6a51a3","#2171b5","#238b45","#d94801","#cb181d","#525252","#b2abd2","#80cdc1","#a6dba0","#b8e186","#92c5de","#bababa","#abd9e9","#abdda4","#a6d96a","#fdbf6f","#e5d8bd","#a65628","#b3de69",
                "#006837","#253494","#0868ac","#006d2c","#016c59","#045a8d","#810f7c","#7a0177","#980043","#b30000","#bd0026","#993404","#54278f","#08519c","#006d2c","#a63603","#a50f15","#252525","#8073ac","#35978f","#5aae61","#7fbc41","#4393c3","#878787","#74add1","#66c2a5","#66bd63","#ff7f00","#fddaec","#f781bf","#fccde5",
                "#004529","#081d58","#084081","#00441b","#014636","#023858","#4d004b","#49006a","#67001f","#7f0000","#800026","#662506","#3f007d","#08306b","#00441b","#7f2704","#67000d","#000000","#542788","#01665e","#1b7837","#4d9221","#2166ac","#4d4d4d","#4575b4","#3288bd","#1a9850","#cab2d6","#f2f2f2","#999999","#d9d9d9"
            ];
        }

        /**
         * @function listViewTemplatePresets
         * @returns {Object}
         */
        function listViewTemplatePresets(){
            return {
                photos:
                '<div data-uid="#: data.uid #" class="nim-list-photos-item">' +
                    '<img class="nim-list-photos-image" src="#: data.file.loc #" />' +
                    '#: ui.checkbox #' +
                    '<div class="nim-list-photos-caption">' +
                        '<div class="nim-list-caption-text">' +
                            '<label>[CAPTION GOES HERE]</label>' +
                        '</div>' +
                        '<div class="nim-list-photos-info">' +
                            '#: ui.infoButton #' +
                        '</div>' +
                    '</div>' +
                '</div>',

                files:
                '<div data-uid="#: data.uid #" class="nim-list-files-item" #: onClick.selectSingle #>' +
                    '<img class="nim-list-files-image" src="#: data.file.loc #" />' +
                    '<div class="nim-list-files-caption">' +
                        '<div class="nim-list-caption-text">' +
                            '<label title="#: data.file.name #">#: data.file.name #</label>' +
                        '</div>' +
                        '<div class="nim-list-files-info">' +
                            '#: ui.infoButton #' +
                        '</div>' +
                    '</div>' +
                '</div>',

                contacts:
                '<div data-uid="#: data.uid #" class="nim-list-contacts-item" #: onClick.viewAndSelect #>' +
                    '<div class="nim-list-contacts-image">' +
                        '<img src="#: data.file.loc #" />' +
                    '</div>' +
                    '<div class="nim-list-contacts-caption">' +
                        '<label class="control-label">[CAPTION GOES HERE]</label>' +
                        '<div class="nim-list-contacts-info">' +
                            '[CONTACT INFO]' +
                        '</div>' +
                    '</div>' +
                '</div>',

                'card-large':
                '<div data-uid="#: data.uid #" class="nim-list-card-large-item" #: onClick.viewAndSelect #>' +
                    '<img class="nim-list-card-image" src="#: data.file.loc #" />' +
                    '<div class="nim-list-card-caption">' +
                        '<label class="control-label">[CAPTION GOES HERE]</label>' +
                        '<div class="nim-list-card-description">' +
                            '[DESCRIPTION]' +
                        '</div>' +
                    '</div>' +
                '</div>',

                'card-medium':
                '<div data-uid="#: data.uid #" class="nim-list-card-medium-item" #: onClick.viewAndSelect #>' +
                    '<img style="left:0;" class="nim-list-card-image" src="#: data.file.loc #" />' +
                    '<div style="border-left:1px solid rgba(0,0,0,0.26); left:160px; right:0;" class="nim-list-card-caption">' +
                        '<label class="control-label">[CAPTION GOES HERE]</label>' +
                        '<div class="nim-list-card-description">' +
                            '[DESCRIPTION]' +
                        '</div>' +
                    '</div>' +
                '</div>',

                'card-small':
                '<div data-uid="#: data.uid #" class="nim-list-card-small-item" #: onClick.viewAndSelect #>' +
                    '<img class="nim-list-card-image" src="#: data.file.loc #" />' +
                    '<div class="nim-list-card-caption" style="border-top: 1px solid rgba(0,0,0,0.26); top: 80px">' +
                        '<label class="control-label">[CAPTION GOES HERE]</label>' +
                        '<div class="nim-list-card-description">' +
                            '[DESCRIPTION]' +
                        '</div>' +
                    '</div>' +
                '</div>'
            };
        }

        function kanbanTemplatePresets(){
            return {
                default: '<div data-uid="#: data.uid #" class="nim-text-overflow-ellipsis">(Label Value Here) <span>(Order Value Here)</span></div>',
                photos:
                '<div data-uid="#: data.uid #" class="nim-list-photos-item">' +
                '<img class="nim-list-photos-image" src="#: data.file.loc #" />' +
                //'#: ui.checkbox #' +
                '<div class="nim-list-photos-caption">' +
                '<div class="nim-list-caption-text">' +
                '<label class="nim-text-overflow-ellipsis">[CAPTION GOES HERE]</label>' +
                '</div>' +
                '<div class="nim-list-photos-info">' +
                //'#: ui.infoButton #' +
                '</div>' +
                '</div>' +
                '</div>',

                files:
                //'<div data-uid="#: data.uid #" class="nim-list-files-item" #: onClick.selectSingle #>' +
                '<div data-uid="#: data.uid #" class="nim-list-files-item">' +
                '<img class="nim-list-files-image" src="#: data.file.loc #" />' +
                '<div class="nim-list-files-caption">' +
                '<div class="nim-list-caption-text">' +
                '<label title="#: data.file.name #" class="nim-text-overflow-ellipsis">#: data.file.name #</label>' +
                '</div>' +
                '<div class="nim-list-files-info">' +
                //'#: ui.infoButton #' +
                '</div>' +
                '</div>' +
                '</div>',

                contacts:
                //'<div data-uid="#: data.uid #" class="nim-list-contacts-item" #: onClick.viewAndSelect #>' +
                '<div data-uid="#: data.uid #" class="nim-list-contacts-item">' +
                '<div class="nim-list-contacts-image">' +
                '<img src="#: data.file.loc #" />' +
                '</div>' +
                '<div class="nim-list-contacts-caption">' +
                '<label class="control-label nim-text-overflow-ellipsis">[CAPTION GOES HERE]</label>' +
                '<div class="nim-list-contacts-info nim-text-overflow-ellipsis">' +
                '[CONTACT INFO]' +
                '</div>' +
                '</div>' +
                '</div>',

                'card-large':
                //'<div data-uid="#: data.uid #" class="nim-list-card-large-item" #: onClick.viewAndSelect #>' +
                '<div data-uid="#: data.uid #" class="nim-list-card-large-item nim-text-overflow-ellipsis">' +
                '<img class="nim-list-card-image" src="#: data.file.loc #" />' +
                '<div class="nim-list-card-caption">' +
                '<label class="control-label nim-text-overflow-ellipsis">[CAPTION GOES HERE]</label>' +
                '<div class="nim-list-card-description nim-text-overflow-ellipsis">' +
                '[DESCRIPTION]' +
                '</div>' +
                '</div>' +
                '</div>',

                'card-medium':
                //'<div data-uid="#: data.uid #" class="nim-list-card-medium-item" #: onClick.viewAndSelect #>' +
                '<div data-uid="#: data.uid #" class="nim-list-card-medium-item">' +
                '<img style="left:0;" class="nim-list-card-image" src="#: data.file.loc #" />' +
                '<div style="border-left:1px solid rgba(0,0,0,0.26); left:160px; right:0;" class="nim-list-card-caption">' +
                '<label class="control-label nim-text-overflow-ellipsis">[CAPTION GOES HERE]</label>' +
                '<div class="nim-list-card-description nim-text-overflow-ellipsis">' +
                '[DESCRIPTION]' +
                '</div>' +
                '</div>' +
                '</div>',

                'card-small':
                //'<div data-uid="#: data.uid #" class="nim-list-card-small-item" #: onClick.viewAndSelect #>' +
                '<div data-uid="#: data.uid #" class="nim-list-card-small-item">' +
                '<img class="nim-list-card-image" src="#: data.file.loc #" />' +
                '<div class="nim-list-card-caption" style="border-top: 1px solid rgba(0,0,0,0.26); top: 80px">' +
                '<label class="control-label nim-text-overflow-ellipsis">[CAPTION GOES HERE]</label>' +
                '<div class="nim-list-card-description nim-text-overflow-ellipsis">' +
                '[DESCRIPTION]' +
                '</div>' +
                '</div>' +
                '</div>'
            };
        }

        function dataVizThemes(){
            return [
                { display: 'Black', value: 'black' },
                { display: 'Blue Opal', value: 'blueopal' },
                { display: 'Bootstrap', value: 'bootstrap' },
                { display: 'Flat', value: 'flat' },
                { display: 'High Contrast', value: 'highcontrast' },
                { display: 'ERP Majestic', value: 'erp_majestic_theme' },
                { display: 'Kendo', value: 'default' },
                { display: 'Material', value: 'material' },
                { display: 'Material Black', value: 'materialblack' },
                { display: 'Metro', value: 'metro' },
                { display: 'Metro Black', value: 'metroblack' },
                { display: 'Moonlight', value: 'moonlight' },
                { display: 'Silver', value: 'silver' },
                { display: 'Uniform', value: 'uniform' },
                { display: 'Fiori', value: 'fiori' },
                { display: 'Nova', value: 'nova' }
            ];
        }
    })
;
