angular.module('cerberus.core')
    .directive('nimWrapper', function () {
        return {
            restrict: 'AE',
            transclude: true,
            templateUrl: function(element, attributes){
                return 'core/pages/wrappers/' + attributes.nimWrapper + '.tpl.html';
            }
        };
    })
;