angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizChartBarService
     * @alias cerberus/core:VizChartBarService
     * @description Provides functions for configuring bar charts
     */
    .factory('VizChartBarService', function VizChartBarService(){
        return {
            //configBar: configBar
        };

        /**
         * This function dynamically adjusts certain parameters for the chart settings
         * @function configBar
         * @param {object} data
         * @param {object} options
         * function configBar(data, options){}
         */

    })
;