angular.module('cerberus.account')
    .controller('UsersSettingsCtrl', function UsersSettingsCtrl($scope, coreAppVersion, buildEnvironment) {
        $scope.coreAppVersion = coreAppVersion;
		$scope.buildEnvironment = buildEnvironment;
		
		// DECLARE FUNCTIONS HERE IF NEEDED.
        //_init();
		//
        //function _init(){
        //    _mainInitFunction();
        //}
		//
        //function _mainInitFunction(){
		////something to run when page is accessed.
		//}
		// END SAMPLE FOR DECLARE FUNCTIONS .
	
    })
;