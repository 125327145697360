angular.module('cerberus.account')
    .controller('OrganizationsWorkspacesCtrl', function OrganizationsWorkspacesCtrl($scope, $location, orgId, OrganizationsService) {
        $scope.activeRoute = 'workspaces';
        $scope.orgId = orgId;
        $scope.orgData = {};
        $scope.workspaces = [];
        $scope.goToWorkspacesDetails = function(id){
            if(id){
                $location.url('organizations/' + orgId + '/settings/workspaces/' + id);
            }
            else{
                $location.url('organizations/' + orgId + '/settings/workspaces/create');
            }
        };

        init();

        function init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });

            OrganizationsService.getOrganizationWorkspaces(orgId).then(function(workspaces){
                $scope.workspaces = workspaces;
            });
        }
    })
    .controller('OrganizationsWorkspacesCreateCtrl', function OrganizationsWorkspacesCreateCtrl($scope, $location, orgId, OrganizationsService, TemplatesServices, workspacesService) {
        $scope.templatesArray = [];
        $scope.activeRoute = 'workspaces';
        $scope.orgData = {};
        $scope.orgId = orgId;
        $scope.tempData = {
            logo: ''
        };
        $scope.origData = angular.copy($scope.tempData);
        $scope.cropStep = 1;
        $scope.clearLogo = function(){
            $scope.tempData.logo = '';
            $scope.imageSrc = null;
        };
        $scope.submit = function(isValid, data){
            if(isValid){
                workspacesService.create(orgId, data).then(function(){
                    $location.url('organizations/' + orgId + '/settings/workspaces');
                });
            }
        };
        $scope.cancel = function(){
            $location.url('organizations/' + orgId + '/settings/workspaces');
        };
        $scope.hasChanges = function () {
            return !angular.equals($scope.origData, $scope.tempData);
        };

        init();

        function init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });

            TemplatesServices.get('Workspace').then(function(templates){
                $scope.templatesArray = templates;
            });
        }
    })
    .controller('OrganizationsWorkspacesDetailsCtrl', function OrganizationsWorkspacesDetailsCtrl(_, $scope, $location, orgId, workspaceId, OrganizationsService, workspacesService) {
        $scope.activeRoute = 'workspaces';
        $scope.orgData = {};
        $scope.orgId = orgId;
        $scope.tempData = {};
        $scope.origData = {};
        $scope.workspaceId = workspaceId;
        $scope.cropStep = 1;
        $scope.usePath = false;
        $scope.clearLogo = function(){
            $scope.tempData.logo = '';
            $scope.imageSrc = null;
        };
        $scope.submit = function(isValid, data){
            if(isValid){
                workspacesService.update(workspaceId, data).then(function(){
                    $location.url('organizations/' + orgId + '/settings/workspaces');
                });
            }
        };
        $scope.cancel = function(){
            $location.url('organizations/' + orgId + '/settings/workspaces');
        };
        $scope.hasChanges = function () {
            return !angular.equals($scope.origData, $scope.tempData);
        };

        init();

        function init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });

            workspacesService.get(workspaceId).then(function(workspaceData){
                _.defaults(workspaceData, { logo: '' });

                $scope.tempData = angular.copy(workspaceData);
                $scope.origData = angular.copy(workspaceData);

                $scope.usePath = workspaceData.path || workspaceData.path !== "";
            });
        }
    })
    .controller('OrganizationsCurrentWorkspaceDetailsCtrl', function OrganizationsCurrentWorkspaceDetailsCtrl($scope, $location, orgId, workspaceData, OrganizationsService, workspacesService) {
        $scope.activeRoute = 'workspaces';
        $scope.orgData = {};
        $scope.orgId = orgId;
        $scope.tempData = angular.copy(workspaceData);
        $scope.tempData.logo = $scope.tempData.logo || '';
        $scope.origData = angular.copy(workspaceData);
        $scope.workspaceId = workspaceData.id;
        $scope.cropStep = 1;
        $scope.usePath = workspaceData.path || workspaceData.path !== "";
        $scope.clearLogo = function(){
            $scope.tempData.logo = '';
            $scope.imageSrc = null;
        };
        $scope.submit = function(isValid, data){
            if(isValid){
                workspacesService.update($scope.workspaceId, data).then(function(){
                    $location.url('organizations/' + orgId + '/settings/workspaces');
                });
            }
        };
        $scope.cancel = function(){
            $location.url('organizations/' + orgId + '/settings/workspaces');
        };
        $scope.hasChanges = function () {
            return !angular.equals($scope.origData, $scope.tempData);
        };

        init();

        function init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });
        }
    })
;