angular.module('cerberus.cpc')
    .controller('InventoryCtrl', function InventoryCtrl(_, $scope, inventoryService) {
        var vm = this;

        vm.updateInventoryRowPositionsOpenModal = inventoryService.updateInventoryRowPositionsOpenModal;
        vm.updateInventoryLocationOpenModal = inventoryService.updateInventoryLocationOpenModal;
        vm.updateFinishedProductLocationOpenModal = inventoryService.updateFinishedProductLocationOpenModal;
        vm.initiateWarehouseAuditOpenModal = inventoryService.initiateWarehouseAuditOpenModal;
        vm.initiateEDI_RollInputOpenModal = inventoryService.initiateEDI_RollInputOpenModal;
        vm.updateRollPositions = inventoryService.updateRollPositions;
        vm.init = _init;

        $scope.$on('$destroy', function(){
            delete vm.navFunctions.callback;
        });

        _init();

        function _init() {
        // if needed to load something on page init, add it here...
        //    vm.selectedIds = [];
        //    vm.isAllSelected = false;
        //    inventoryService.getSomeData().then(function(drafts){
        //       vm.drafts = drafts;
        //    });
        }
    })
;