angular.module('cerberus.account')
    .factory('usersApiKeysService', function usersApiKeysService($http, $q, apiPath, toaster) {
            return {
                get: function () {
                    var deferred = $q.defer();
                    $http.get(apiPath + 'users/apiKey')
                        .success(function (value) {
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                create: function (data) {
                    var deferred = $q.defer();
                    $http.post(apiPath + 'users/apiKey', data)
                        .success(function (value) {
                            toaster.pop('success', 'Created');
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                update: function (id,data) {
                    var deferred = $q.defer();
                    $http.put(apiPath + 'users/apiKey/' + id, data)
                        .success(function (value) {
                            toaster.pop('success', 'Updated');
                            deferred.resolve(value);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                remove: function (id) {
                    var deferred = $q.defer();
                    $http({method: 'DELETE', url: apiPath + 'users/apiKey/' + id})
                        .success(function(value){
                            deferred.resolve(value.DATA);
                        })
                        .error(function(reason){
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                }
            };
        })
;
