angular.module('cerberus.admin')
    .controller('FormsTemplatesCreateCtrl', function FormsTemplatesCreateCtrl(_, $scope, $location, formOriginId, widgetId, FormsService, TemplatesServices) {
        $scope.tempData = {
            type:'Form',
            originId: formOriginId
        };
        $scope.origData = angular.copy($scope.tempData);
        $scope.formModel = {};
        $scope.filters = {};
        $scope.dataSet = {};
        $scope.securityTypes = TemplatesServices.getSecurityLevels();

        $scope.hasChanges = _hasChanges;
        $scope.submit = _submit;
        $scope.cancel = _navToForm;

        _init();

        function _init(){
            FormsService.getForm(formOriginId).then(function(formModel){
                $scope.formModel = formModel;
            });
        }

        function _hasChanges(){
            return !angular.equals($scope.origData, $scope.tempData);
        }

        function _submit(isValid, data){
            if(isValid){
                data.template = $scope.formModel.form;
                TemplatesServices.create(data).then(function(){
                    _navToForm();
                });
            }
        }

        function _navToForm(){
            $location.url('/settings/widgets/' + widgetId + '/forms/' + formOriginId);
        }
    })
;