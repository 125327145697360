angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizChartBulletService
     * @alias cerberus/core:VizChartBulletService
     * @description Provides functions for configuring bullet charts
     */
    .factory('VizChartBulletService', function VizChartBulletService(_, kendo, $rootScope, $http, $timeout, apiPath, VizUtilityService){
        return {
            configBullet: configBullet,
            buildBulletDataSource: buildBulletDataSource,
            valueFetchCallback: valueFetchCallback,
            plotBandFetchCallback: plotBandFetchCallback,
            targetFetchCallback: targetFetchCallback
        };

        ///////////////////////////

        /**
         * This function dynamically adjusts certain parameters for the chart settings
         * @function configBullet
         * @param {object} data
         * @param {object} options
         */
        function configBullet(data, options) {
            if(data) {
                var fieldsChecked = {};
                var max = options.valueAxis.max || 0;
                var min = options.valueAxis.min || 0;

                _.forEach(options.series, function (series) {
                    var field = series.currentField;

                    if(!fieldsChecked[field]){
                        fieldsChecked[field] = true;

                        _.forEach(data, function (dataItem) {
                            var value = dataItem[field];
                            var target = dataItem.target;

                            if(typeof value == "number"){
                                if(value > max){
                                    max = value;
                                }
                                else if(value < min){
                                    min = value;
                                }
                            }

                            if(target && typeof target == "number"){
                                if(target > max){
                                    max = target;
                                }
                                else if(target < min){
                                    min = target;
                                }
                            }
                        });
                    }

                    series.data = data;
                });

                if(options.valueAxis.plotBands && options.valueAxis.plotBands.length && options.valueAxis.plotBands[0].to > max){
                    max = options.valueAxis.plotBands[0].to;
                }

                options.valueAxis.max = max;
                options.valueAxis.min = min;
            }
        }

        /**
         * Joins target data and value data on category values
         * @param valueData
         * @param valueCatField
         * @param targetData
         */
        function joinValueAndTarget(valueData, valueCatField, targetData) {
            if(valueData && targetData && targetData.length == 1 && !targetData[0].category){
                //If only 1 target with no category, apply that target to all values
                var targetValue = targetData[0].target;
                _.forEach(valueData, function (valueItem) {
                    valueItem.target = targetValue;
                });
            }
            else if(valueData && valueCatField && valueCatField !== '' && targetData){
                // Joins data by category
                _.forEach(targetData, function (targetItem) {
                    var category = targetItem.category;
                    var target = targetItem.target;

                    _.forEach(valueData, function (valueItem) {
                        // joins data if value's category matches target's category
                        if(valueItem[valueCatField] === category){
                            valueItem.target = target;
                        }
                    });
                });
            }
        }

        /**
         * Builds simple dataSource for bullet chart
         * @param pageObject
         * @param source
         * @param options
         * @param scope
         * @param id
         * @param cb
         * @returns {kendo.data.DataSource}
         */
        function buildBulletDataSource(pageObject, source, options, scope, id, cb){
            if(source) {
                var dataSourceOptions = {
                    type: 'odata',
                    transport: {
                        read: read
                    },
                    serverFiltering: true,
                    serverSorting: true,
                    serverPaging: true,
                    serverAggregates: true,
                    serverGrouping: false,
                    requestStart: function (e) {
                        var requiredFilters = _.get(pageObject.params, ['requiredFilters', id], {});
                        if(!_.isEmpty(requiredFilters)){
                            _.forEach(requiredFilters, function(required, filter){
                                if(required && VizUtilityService.isFilterEmpty(scope.filters[filter])){
                                    e.preventDefault();

                                    $timeout(function(){
                                        e.sender.success({d:{results: [], __count: 0}});
                                    });

                                    return false;
                                }
                            });
                        }
                    },
                    change: function (e) {
                        $timeout(function(){
                            scope[id] = e.sender.data();
                            cb(e.sender);
                            configBullet(scope.value, options);
                            if(scope.chart) {
                                scope.chart.setOptions(options);

                                scope.chart.refresh();
                            }
                        }, 1);
                    }
                };

                var sortOptions = {
                    sort: VizUtilityService.createSortArray(source)
                };

                var baseFilter = angular.copy(source.filter) || {
                    filters: [],
                    logic: 'and'
                };

                var filterOptions = {
                    filter: {
                        filters: [baseFilter],
                        logic: pageObject.params.filterLogic || 'and'
                    }
                };

                angular.extend(dataSourceOptions, source, sortOptions, filterOptions);

                // Adds parse function to make sure date fields are correct
                _.forEach(dataSourceOptions.schema.model.fields, function (field) {
                    if (field.type === 'date') {
                        field.parse = VizUtilityService.parseDateField;
                    }
                });

                return new kendo.data.DataSource(dataSourceOptions);
            }

            function read(options) {
                var httpOptions = VizUtilityService.buildBaseHttpOptions(pageObject, scope.isPreview);
                httpOptions.params = VizUtilityService.buildHttpParams(scope, dataSourceOptions, options);
                httpOptions.params.$ds = id;

                if (pageObject.params) {
                    var recurrence = VizUtilityService.buildRecurrenceData(pageObject, scope, options.data);
                    if (recurrence) {
                        httpOptions.params.$recurrence = recurrence;
                    }
                }

                $http(httpOptions).then(function (result) {
                    options.success(result.data);
                }, function (result) {
                    options.error(result);
                });
            }
        }

        /**
         * Builds callback function for fetching value data
         * @param scope
         * @param options
         * @returns {Function}
         */
        function valueFetchCallback(scope, options){
            return function (valueSource) {
                var data = valueSource.data();
                scope.currentField = options.series[0].currentField;

                // if target data has been retrieved, join the data
                if(scope.target){
                    joinValueAndTarget(data, options.series[0].categoryField, scope.target);
                }

                if (options.seriesColors && options.seriesColors.length < 1) {
                    options.seriesColors = null;
                }

                scope.mainPageObject.vizCtrl.data.value = data;
                scope.mainPageObject.vizCtrl.data.currentField = scope.currentField;
            };
        }

        /**
         * Builds callback function for fetching plotband data
         * @param scope
         * @param options
         * @returns {Function}
         */
        function plotBandFetchCallback(scope, options) {
            return function(source){
                // Extracts plotband 'to' values from returned data and sorts it
                var rawPlotData = source.data();
                setPlotBands(scope, options, rawPlotData);

                if(scope.chart) {
                    scope.chart.setOptions(options);
                }

                scope.mainPageObject.vizCtrl.data.plot = scope.plot;
                scope.mainPageObject.vizCtrl.data.plotField = scope.plotField;
            };
        }

        function setPlotBands(scope, options, data){
            scope.plotField = options.series[0].toField;
            var plotToValues = _.map(data, scope.plotField).sort().reverse();

            // Sets plotbands' colors and adds them to the chart
            var plotBands = [];
            for(var pv = 0; pv < plotToValues.length && pv < scope.plotColors.length; pv++){
                plotBands.push({
                    from: 0,
                    to: plotToValues[pv],
                    color: scope.plotColors[pv]
                });
            }

            options.valueAxis.plotBands = plotBands;
        }

        /**
         * Builds callback function for fetching target data
         * @param scope
         * @param options
         * @returns {Function}
         */
        function targetFetchCallback(scope, options){
            return function(targetSource){
                // joins value and target data if both have been fetched
                if(scope.chart && scope.chart.dataSource && scope.value){
                    var data = scope.value;
                    joinValueAndTarget(data, options.series[0].categoryField, targetSource.data());
                    scope.chart.dataSource.data(data);
                }

                scope.mainPageObject.vizCtrl.data.target = scope.target;
                scope.mainPageObject.vizCtrl.data.targetField = 'target';
            };
        }
    })
;