angular.module('cerberus.account')
    .factory('OrganizationsService', function OrganizationsService(_, $http, $q, apiPath, toaster) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';
        return {
            getUserOrganizations: function () {
                var deferred = $q.defer();
                $http.get(apiPath + 'users/organizations')
                    .success(function (value) {
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
                return deferred.promise;
            },
            get: function () {
                var deferred = $q.defer();
                $http.get(apiPath + 'users/organizations')
                    .success(function (value) {
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
                return deferred.promise;
            },
            create: function (data) {
                var deferred = $q.defer();
                $http.post(apiPath + 'organizations', data)
                    .success(function (value) {
                        toaster.pop('success', 'Organzation created.');
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
                return deferred.promise;
            },
            getDetails: function (id) {
                var deferred = $q.defer();
                $http.get(apiPath + 'organizations/' + id)
                    .success(function (value) {
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
                return deferred.promise;
            },
            update: function (id, data) {
                var deferred = $q.defer();
                $http.put(apiPath + 'organizations/' + id, data)
                    .success(function (value) {
                        toaster.pop('success', 'Organzation updated.');
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
                return deferred.promise;
            },
            getOrganizationWorkspaces: function (id) {
                var deferred = $q.defer();
                $http.get(apiPath + 'users/organizations/' + id + '/workspaces')
                    .success(function (value) {
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
                return deferred.promise;
            },
            disableUsers: function (orgId, userIds) {
                var deferred = $q.defer();
                $http.put(apiPath + 'organizations/' + orgId + '/users/disable', userIds)
                    .success(function (value) {
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
                return deferred.promise;
            },
            enableUsers: function (orgId, userIds) {
                var deferred = $q.defer();
                $http.put(apiPath + 'organizations/' + orgId + '/users/' + userIds + '/enable')
                    .success(function (value) {
                        toaster.pop('success', 'User Enabled');
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
                return deferred.promise;
            },
            remove: function (orgId, userIds) {
                var deferred = $q.defer();
                $http({ method: 'DELETE', url: apiPath + 'organizations/' + orgId + '/users/' + userIds })
                    .success(function (value) {
                        toaster.pop('info', 'User Removed');
                        deferred.resolve(value);
                    })
                    .error(function (reason) {
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
                return deferred.promise;
            },
            createAsset: function (id, data) {
                var deferred = $q.defer();
                $http.post(apiPath + 'organizations/' + id + '/assets', data)
                    .success(function (value) {
                        toaster.pop('success', 'Created.');
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
                return deferred.promise;
            },
            getAllAssets: function (id, type) {
                var deferred = $q.defer();
                $http.get(apiPath + 'organizations/' + id + '/assets?type=' + type)
                    .success(function (value) {
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
                return deferred.promise;
            },
            getAsset: function (orgId, id) {
                var deferred = $q.defer();
                $http.get(apiPath + 'organizations/' + orgId + '/assets/' + id)
                    .success(function (value) {
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
                return deferred.promise;
            },
            updateAsset: function (orgId, id, data) {
                var deferred = $q.defer();
                $http.put(apiPath + 'organizations/' + orgId + '/assets/' + id, data)
                    .success(function (value) {
                        toaster.pop('success', 'Updated.');
                        deferred.resolve(value.DATA);
                    })
                    .error(function (reason) {
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
                return deferred.promise;
            },
            deleteAsset: function (orgId, id) {
                var deferred = $q.defer();
                $http({ method: 'DELETE', url: apiPath + 'organizations/' + orgId + '/assets/' + id })
                    .success(function (value) {
                        toaster.pop('info', 'Deleted');
                        deferred.resolve(value);
                    })
                    .error(function (reason) {
                        deferred.reject(
                            _.get(reason, 'MESSAGE', connectionError)
                        );
                    });
                return deferred.promise;
            }
        };
    })
;
