angular.module('cerberus.les')
    .controller('LESLoanApplicationReopenModalCtrl', function(_, $scope, $uibModalInstance, loanApplicationId, lesLoanApplicationsService){
        var vm = this;

        vm.loanApplicationId = loanApplicationId;
        vm.reopening = false;
        vm.fundingData = {};

        vm.ok = ok;
        vm.cancel = cancel;

        function ok(){
            vm.reopening = true;

            lesLoanApplicationsService.updateLoan(vm.loanApplicationId,{action:'reopen',data:{}}).then(function(){
                vm.reopening = false;
                $uibModalInstance.close();
            }, function(){
                vm.reopening = false;
            });
        }

        function cancel(){
            $uibModalInstance.dismiss('cancel');
        }

        // Prevents navigation while modal is open
        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;