angular.module('cerberus.core')
    .factory('WidgetsService', function WidgetsService(_, $http, $q, $window, apiPath, toaster, FormsService) {
        var widgetComponents;
        var widgets = [];
        return {
            widgets: function () {
                return widgets;
            },
            widgetComponents: function () {
                return widgetComponents;
            },
            getWidgets: getWidgets,
            getWidget: getWidget,
            getFirstState: getFirstState,
            getAllForms: getAllForms,
            getMax: getMax
        };

        function getWidgets() {
            var deferred = $q.defer();
            $http.get(apiPath + 'widgets')
                .success(function (value) {
                    widgets = value.DATA;
                    deferred.resolve(widgets);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getWidget(id) {
            var deferred = $q.defer();
            $http.get(apiPath + 'widgets/' + id)
                .success(function (value) {
                    widgetComponents = value.DATA;
                    deferred.resolve(widgetComponents);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getFirstState(id) {
            var deferred = $q.defer();
            $http.get(apiPath + 'widgets/' + id + '/workflows/start/forms')
                .success(function (value) {
                    var newData = value.DATA;
                    _.forEach(newData.workflowObjects, function (wfObj) {
                        wfObj.model.forms = _.map(wfObj.model.forms, function (formModel) {
                            if (formModel.form) {
                                formModel = FormsService.processFormData(formModel, formModel.defaultDataSet);
                            }
                            else {
                                formModel.form = { objects: [] };
                            }

                            return formModel;
                        });
                    });
                    
                    deferred.resolve(newData);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getAllForms(id, printType) {
            var deferred = $q.defer(),
                params = {};

            if (printType) {
                params.printType = printType;
            }
            $http.get(apiPath + 'widgets/' + id + '/forms', { params: params })
                .success(function (value) {
                    deferred.resolve(value.DATA.forms);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });
            
            return deferred.promise;
        }

        function getMax(widgetId, modelId) {
            var deferred = $q.defer();

            $http.get(apiPath + 'widgets/' + widgetId + '/modelId/' + modelId + '/max')
                .success(function (value) {
                    deferred.resolve(value.DATA);
                })
                .error(function (reason) {
                    deferred.reject(reason);
                });

            return deferred.promise;
        }
    })
;