angular.module('cerberus.util')
    .factory('normalPrintingService', function(_, kendo, $compile, $http, $q, $rootScope, $location, $timeout, $window, DeviceSniffService, ExportFileWriteService){
        var service = {
            printElement: printElement,
            printInstance: printInstance
        };

        return service;

        // function printElement(elem, filename, normalOptions) {
        //     var printSection = angular.element('#print-build-section'),
        //         paperOptions = kendo.pdf.getPaperOptions(normalOptions),
        //         deferred = $q.defer();
            
        //     printSection.empty()
        //         .append(elem[0].outerHTML);
            
        //     var element = printSection.children();
            
        //     // Convert paperSize from px (at 72ppi) to in.
        //     paperOptions.paperSize = _.map(paperOptions.paperSize, function(px){
        //         var inches = px / 72;
        //         return inches + 'in';
        //     });

        //     element.css('height', paperOptions.paperSize[1])
        //         .css('width', paperOptions.paperSize[0]);

        //     var options = {
        //             paperSize: [
        //                 pixelsToInteger(element.css('width')),
        //                 pixelsToInteger(element.css('height'))
        //             ],
        //             margin: normalOptions.margin,
        //             multiPage: true
        //         };

        //     kendo.drawing.drawDOM(element, options).then(function (group) {
        //         return kendo.drawing.exportPDF(group, options);
        //     }).done(function (dataURI) {
        //         printSection.empty();

        //         var browser = DeviceSniffService.deviceBrowser();
        //         if (_.startsWith(browser, 'IE')) {
        //             kendo.saveAs({
        //                 dataURI: dataURI,
        //                 fileName: filename + '.pdf'
        //             });
        //         }
        //         else {
        //             $window.open(dataURI);
        //         }

        //         deferred.resolve();
        //     });

        //     return deferred.promise;
        // }

        /**
         * Prints the given element
         * @param elem - Element identifier
         * @param defaultFileName - Filename of PDF when it's ready to be generated
         * @param normalOptions - Options set by user in the print configuration modal
         */
        function printElement(elem, defaultFileName, normalOptions) {
            var printSection = angular.element('#print-build-section'),
                paperOptions = kendo.pdf.getPaperOptions(normalOptions),
                element = elem.clone(),
                browser = DeviceSniffService.deviceBrowser(),
                isIE = _.startsWith(browser, 'IE'),
                printWindow = null,
                deferred = $q.defer(),
                filename = 'Download';

            if(defaultFileName){
                filename = defaultFileName;
            }

            element.append('<div class="row text-center"><div class="col-xs-12"><div class="padding-sm">Powered by <a href="https://crownpaperconverting.com">crownpaperconverting.com</a></div></div></div>');
            
            printSection.empty()
                .append(element);
            
            element.css('width', '816px');
            
            if (!isIE) {
                printWindow = $window.open();
            }
            
            // If Internet Explorer, download pdf
            if (isIE || !printWindow) {
                exportPDF(element, paperOptions).done(function (dataURI) {
                    printSection.empty();

                    kendo.saveAs({
                        dataURI: dataURI,
                        fileName: filename + '.pdf'
                    });

                    deferred.resolve();
                });
            }
            else {
                deferred.resolve();

                // Load CSS
                var cssLink = angular.element($window.document).find('link[rel=stylesheet]');
                var cssPromise = null;
                if (cssLink[0]) {
                    cssPromise = $http.get(cssLink[0].href);
                }

                // Else, open in new window
                printInWindow(element, printWindow, filename, cssPromise);
                printSection.empty();
            }

            return deferred.promise;
        }

        /**
         * Prints the given instance
         * @param instanceId
         * @param defaultFileName - Filename of PDF when it's ready to be generated
         * @param normalOptions - Options set by user in the print configuration modal
         * @param [instance]
         * @param [dataSet]
         * @param [autoFillData] - Loaded autofill data passed in from an open instance
         * @param [processPdf] - (Optional) : Available commands used for PDF Processing invoked after generating is complete: 
         *            (1) 'save'  - Force PDF file to be directly downloaded without any extra print window preview
         *            (2) 'write' - Force PDF file to be internally stored locally on server
         *            (3) 'false' - Default, None or Null - Go to printing process
         */
        function printInstance(instanceId, defaultFileName, normalOptions, instance, dataSet, autoFillData, processPdf){
            var element,
                deferred = $q.defer(),    
                printSection = angular.element('#print-build-section'),
                paperOptions = kendo.pdf.getPaperOptions(normalOptions),
                instanceScope = $rootScope.$new(true),
                template = '<div nim-print-builder></div>',
                filename = 'Download';

            if(defaultFileName){
                filename = defaultFileName;
            }
            // Convert paperSize from px (at 72ppi) to in.
            paperOptions.paperSize = _.map(paperOptions.paperSize, function(px){
                var inches = px / 72;
                return inches + 'in';
            });

            processPdf = processPdf || false;
            instanceScope.instanceId = instanceId;
            instanceScope.paperOptions = paperOptions;
            instanceScope.instance = {};
            instanceScope.dataSet = {};
            instanceScope.autoFillData = {};

            if(!_.isEmpty(instance)){
                instanceScope.instance = instance;
            }
            if(!_.isEmpty(dataSet)){
                instanceScope.dataSet = dataSet;
            }
            if(!_.isEmpty(autoFillData)){
                instanceScope.autoFillData = autoFillData;
            }

            // Load CSS
            var cssLink = angular.element($window.document).find('link[rel=stylesheet]');
            var cssPromise = null;
            if (cssLink[0]) {
                cssPromise = $http.get(cssLink[0].href);
            }

            instanceScope.done = function(){
                instanceScope.$destroy();

                var browser = DeviceSniffService.deviceBrowser(),
                    isIE = _.startsWith(browser, 'IE'),
                    printWindow = null;
                
                if (!isIE && !processPdf) {
                    printWindow = $window.open();
                }

                // If Internet Explorer or if PDF is forced, download pdf
                if (isIE || !printWindow || processPdf) {
                    exportPDF(element, paperOptions).done(function (dataURI) {
                        printSection.empty();
                        if (processPdf === 'write') {
                            filename = null; //Let system to generate a unique name if empty
                            if(defaultFileName){
                                filename = defaultFileName + '.pdf';
                            }
                            ExportFileWriteService.writeFile(dataURI, {filename: filename}).then(function (result) {
                                deferred.resolve(result);
                            });
                        }
                        else {
                            kendo.saveAs({
                                dataURI: dataURI,
                                fileName: filename + '.pdf'
                            });
                            deferred.resolve();
                        }
                    });
                }
                else {
                    deferred.resolve();

                    // Else, open in new window
                    printInWindow(element, printWindow, filename, cssPromise);
                    printSection.empty();
                }

            };

            element = $compile(template)(instanceScope);

            printSection.empty()
                .append(element);

            return deferred.promise;
        }

        /**
         * Exports the rendered element to a PDF
         * @param element {jQuery}
         * @param paperOptions {Object}
         */
        function exportPDF(element, paperOptions) {
            var elemWidth = element.css('width'),
                elemHeight = element.css('height'),
                options = {
                    paperSize: [
                        pixelsToInteger(elemWidth),
                        pixelsToInteger(elemHeight)
                    ],
                    margin: paperOptions.margin,
                    multiPage: true
                };

            element.css('height', elemHeight)
                .css('width', elemWidth);

            return kendo.drawing.drawDOM(element, options).then(function (group) {
                return kendo.drawing.exportPDF(group, options);
            });
        }

        /**
         * Opens new window to print rendered element
         * @param element {jQuery}
         */
        function printInWindow(element, printWindow, filename, cssPromise) {
            var printContent = element.clone(),
                base = $location.protocol() + '://' + $location.host(),
                windowHead = angular.element(printWindow.document.head),
                windowBody = angular.element(printWindow.document.body);
            
            windowHead
                .append('<title>' + filename + '</title><base href="' + base + '" />');
            
            windowBody
                .addClass('nim-print-window-body')
                .css('padding', '0');
            
            // Print these elements in color:
            var printInColor = [
                '.k-event',
                '.k-event div',
                '.k-scheduler .k-header .k-state-selected',
                '.k-scheduler .k-header .k-state-selected .k-link',
                '.k-grid .k-state-selected',
                '.k-grid .k-state-selected *',
                '.k-gantt .k-task-wrap *',
                '.k-gantt .k-gantt-views *',
                '.leaflet-map-pane *',
                '.kanban .column-title',
                '.kanban .column-title *'
            ];

            printContent
                .find(printInColor.join(', '))
                .addClass('print-in-color');
            
            windowBody.append(printContent);

            if (cssPromise) {
                cssPromise.then(function (result) {
                    var style = angular.element('<style type="text/css">' + result.data + '</style>');
                    style[0].onload = function () {
                        executePrint();
                    };
                    windowHead.append(style);
                });
            }
            else {
                executePrint();
            }
            
            function executePrint() {
                $timeout(function () {
                    printWindow.document.close();
                    printWindow.focus();
                    printWindow.print();
                }, 100);
            }
        }

        /**
         * Takes string of format "0px", removes "px" and returns the number
         * @param pixelString
         * @returns {*|number}
         */
        function pixelsToInteger(pixelString){
            var valueString = pixelString.replace('px', '');
            return parseInt(valueString);
        }
    })
;