angular.module('cerberus.core')
    .controller('MenusTopCtrl', function MenusTopCtrl($scope, $document, $window, $rootScope, $location, $timeout, $firebaseObject, toaster, fbUtil, appSettingsService, AuthService, DeviceSniffService, MenusService, nimTitleService, normalPrintingService, NotificationsService) {
        $scope.branding = appSettingsService.branding();
        $scope.workspacePath = $rootScope.userData.currentWorkspace.path;
        $scope.searchDropdownMenuSettings = {
            'auto-close': 'outsideClick'
        };
        $scope.notifications = [];
        $scope.totalUnread = 0;
        $scope.markingAsRead = {};
        $rootScope.printingPage = false;

        $scope.isPageRoute = MenusService.isPageRoute;
        $scope.markAsRead = markAsRead;
        
        var unwatchFb = $firebaseObject(fbUtil.ref('notifications/' + $rootScope.userData.id)).$watch(function () {
            $timeout(function () {
                refreshNotifications();
				if ($scope.totalUnread > 0) {
					toaster.pop('info', 'You have notifications.');
				}
            }, 1000);
        });

        //User Functions
        $scope.logout = function () {
            AuthService.logout();
        };

        //Search
        $scope.goToSearch = function(txt){
            if(txt){
                $location.url('/search').search({q: txt});
            }
            else{
                $location.url('/search');
            }
        };
        //Download Pdf
        $scope.printCurrentPage = function(){
            if(!$scope.printingPage){
                $rootScope.printingPage = true;
                var title = nimTitleService.get(),
                    options = {
                        landscape: false,
                        margin: 0
                    };
                
                var pageContent = $document.find('#page-content > #content-wrap > .gridster-container');

                $timeout(function () {
                    normalPrintingService.printElement(pageContent, title, options).then(function () {
                        $rootScope.printingPage = false;
                    });
                }, 1000);
            }
        };

        //Override for top menu navigation
        $scope.navigate = function(appUI, path){
            appUI.isTopBarCollapsed = true;

            // Handle navigation on mobile
            if(DeviceSniffService.isMobile()) {
                $location.url(path);
            }
        };

        $scope.focusMe = function(e){
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();

            angular.element(e.target).trigger('focus');
        };

        // If user clicks on top menu on mobile device, close side menu
        $scope.closeSideMenu = function(appUI){
            if (DeviceSniffService.isMobile()){
                appUI.isLeftBarCollapsed = true;
            }
        };

        $scope.$on('destroy', function () {
            unwatchFb();
        });

        function refreshNotifications() {
            NotificationsService.getNotifications(5, 0).then(function (result) {
                $scope.notifications = result.notifications;
                $scope.totalUnread = result.totalUnread;
            });
        }

        function markAsRead(notification) {
            if (!notification.isRead && !$scope.markingAsRead[notification.id]) {
                $scope.markingAsRead[notification.id] = true;

                NotificationsService.markAsRead(notification.id).then(function () {
                    $scope.markingAsRead[notification.id] = false;
                    notification.isRead = true;
                }, function () {
                    $scope.markingAsRead[notification.id] = false;
                });
            }    
        }
    })
;