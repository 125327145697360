angular.module('cerberus.les')
    .controller('LESEditQuickApplicationPageCtrl', function (_, $scope, $window, quickApplicationId, lesQuickApplicationService, lesLoanApplicationsService) {
        var vm = this;

        vm.quickApplication = {};
        vm.breadcrumbDisplay = 'Loading...';

        init();
        
        function init() {
            lesLoanApplicationsService.getLoanApplication(quickApplicationId).then(function (loanApplication) { 
                if(loanApplication.recordData.findings){
                    vm.quickApplication = loanApplication.recordData.loanApp;
                }
                else{
                    vm.quickApplication = loanApplication.recordData;
                }
                
                var mainBorrowerName = _.get(vm.quickApplication, 'mainBorrower.fullName') || 'No Primary Borrower';
                vm.breadcrumbDisplay = mainBorrowerName + ' (#' + _.last(quickApplicationId.split('-')) + ')';
            });

            lesQuickApplicationService.openNewQuickAppModal(quickApplicationId).then(function () {
                lesQuickApplicationService.openQuickAppEligibilityPage(quickApplicationId);
            }, function () {
                cancel();
            });
        }

        function cancel() {
            $window.history.back();
        }
    })
;