angular.module('cerberus.account')
    .controller('DraftsCtrl', function DraftsCtrl(_, $scope, $window, draftsService, InstancesService) {
        var vm = this;

        vm.drafts = [];
        vm.selectedIds = [];
        vm.isAllSelected = false;
        vm.navFunctions = {
            callback: _init
        };

        vm.modifySelectArray = draftsService.modifySelectArray;
        vm.selectAll = draftsService.selectAll;
        vm.deleteDraft = draftsService.deleteDraft;
        vm.openDraft = _.partialRight(draftsService.openDraft, vm.navFunctions);
        vm.init = _init;

        $scope.$on('$destroy', function(){
            delete vm.navFunctions.callback;
        });

        vm.getDownloadKey = function(attach){
            InstancesService.getDownloadKey(attach.id).then(function(d){
                InstancesService.getBlob(d).then(function(pdfBlob){
                    $window.saveAs(pdfBlob, attach.name);
                });
            });
        };

        _init();

        function _init(){
            vm.selectedIds = [];
            vm.isAllSelected = false;

            draftsService.getUserDrafts().then(function(drafts){
                vm.drafts = drafts;
            });
        }
    })
;