angular.module('cerberus.admin')
/**
 * @ngdoc service
 * @name ImportExchangeService
 * @alias cerberus/widgets:ImportExchangeService
 * @description Provides REST service for "import exchange server" resource
 */
    .factory('ImportExchangeService', function ImportExchangeService(_, $http, $q, apiPath) {
        var connectionError = 'Looks like the Connection failed. Try the action again or refresh the page.';
        return {
            getExchangeData: getExchangeData
        };
        ////////////////////

        /**
         * Makes a get call REST api "views/{id}/details" resource
         * @function getExchangeData
         * @param {string} id - exchange server id
         * @param {string} type - type of data
         * @return {promise}
         */
        function getExchangeData(id,type) {
            var deferred = $q.defer();
            $http.get(apiPath + 'exchange/server/' + id + '?type=' + type)
                .success(function(value){
                    deferred.resolve(value.DATA);
                })
                .error(function(reason){
                    deferred.reject(
                        _.get(reason, 'MESSAGE', connectionError)
                    );
                });
            return deferred.promise;
        }

    })
;
