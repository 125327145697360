angular.module('cerberus.admin')
    .controller('TableConfigCtrl', function TableConfigCtrl(_, $scope, $uibModalInstance, pageObject, filters, DesignerUtilityService,
                                                            PagesDesignerListService, pageObjectConfigService,
                                                            pageObjectsService, ViewsService, vizListService, WidgetsService) {
        var vm = this;

        vm.temp = angular.copy(pageObject);
        vm.orig = angular.copy(pageObject);
        vm.filters = filters;
        vm.views = [];
        vm.widgets = [];
        vm.wrapperTypes = PagesDesignerListService.wrapperTypes();
        vm.wrapperConfig = PagesDesignerListService.wrapperConfig();
        vm.paletteOptions = {
            palette: PagesDesignerListService.palette(),
            columns: 31,
            tileSize: 18
        };
        vm.margin = DesignerUtilityService.cssToArray(pageObject.wrapper.style.margin) || [0,0,0,0];
        vm.padding = DesignerUtilityService.cssToArray(pageObject.wrapper.style.padding) || [0,0,0,0];
        vm.jsonEdit = angular.toJson(vm.temp.viz.settings);
        vm.viewColumns = [];
        vm.detailColumns = [];
        vm.editView = _.get(pageObject,'viz.settings.dataSource.nim_viewId');
        vm.filterMap = {};
        vm.tempFilter = {};
        vm.repeatable = true;
        vm.useColAsFilterVal = false;
        vm.tempDefaultData = {};
        vm.formFieldsArray = [];
        vm.filterType = '';
        vm.idArray = [];
        vm.detailObject = {
            column: 'id'
        };
        vm.sortObj = {
            field: '',
            dir: 'desc'
        };

        vm.condition = [];
        vm.conditionType = [];
        vm.isColumnValue = [];

        vm.aggregate = {
            field: null,
            aggregate: null,
            nimLabel: ''
        };

        vm.aggregateFunctions = [
            {value:'sum', display:'Sum'},
            {value:'count', display:'Count'},
            {value:'avg', display:'Average'}
        ];

        vm.geoProperties = [
            { value: 'formatted_address', display: 'Full Address' },
            { value: 'postal_address', display: 'Postal Address' },
            { value: 'country_long_name', display: 'Country (Long)' },
            { value: 'country_short_name', display: 'Country (Short)' },
            { value: 'administrative_area_level_1_long_name', display: 'State (Long)' },
            { value: 'administrative_area_level_1_short_name', display: 'State (Short)' },
            { value: 'administrative_area_level_2_long_name', display: 'County (Long)' },
            { value: 'administrative_area_level_2_short_name', display: 'County (Short)' },
            { value: 'locality_long_name', display: 'City (Long)' },
            { value: 'locality_short_name', display: 'City (Short)' },
            { value: 'postal_code_long_name', display: 'ZIP (Long)' },
            { value: 'postal_code_short_name', display: 'ZIP (Short)' },
            { value: 'street_address', display: 'Street Address' }
        ];

        vm.columnFilterOperators = [
            {value: 'contains', display: 'Contains'},
            {value: 'startswith', display: 'Starts with'}
        ];

        vm.filterOperators = pageObjectConfigService.filterOperators();
        vm.textEditorOptions = pageObjectConfigService.textEditorOptions($scope);

        vm.tabTemplates = [
            {name: "General", url: "admin/pages/designer/common/general-tab.tpl.html"},
            {name: "Wrapper", url: "admin/pages/designer/common/wrapper-tab.tpl.html"},
            {name: "Data", url: "admin/pages/designer/viz/table/data-tab.tpl.html"},
            {name: "Record Actions", url: "admin/pages/designer/viz/table/records-tab.tpl.html"},
            {name: "Filters", url: "admin/pages/designer/common/filter-tab.tpl.html"},
            {name: "Tools", url: "admin/pages/designer/common/tools-tab.tpl.html"}
        ];

        vm.pageObjects = [];

        vm.vizDict = vizListService.vizDictionary();

        vm.sortableOptions = pageObjectConfigService.createSortableOptions(vm.temp.viz.settings.columns);

        vm.getFilterType = pageObjectConfigService.getFilterType;
        vm.addDSFilter = pageObjectConfigService.addDSFilter;

        vm.notPropertyColumn = pageObjectConfigService.notPropertyColumnFilter;

        // Helper functions
        vm.deleteProperty = pageObjectConfigService.deleteProperty;
        vm.capitalize = _.capitalize;
        vm.removeItemFromArray = _.pullAt;
        vm.fillCSSArray = pageObjectConfigService.fillCSSArray;
        vm.labelToUniqueId = DesignerUtilityService.labelToUniqueId;
        vm.preventSpace = DesignerUtilityService.preventSpace;

        // View functions
        vm.loadColumns = _.partial(pageObjectConfigService.loadColumns, vm);
        vm.onViewChange = _.partial(pageObjectConfigService.onViewChange, vm, onColumnRemoved);

        // Widget functions
        vm.loadFormFields = _.partial(pageObjectConfigService.loadFormFields, vm);
        vm.setBaseWidget = _.partial(pageObjectConfigService.setBaseWidget, vm, vm.temp);
        vm.resetRecordFields = resetRecordFields;

        // Column selection
        vm.removeColumn = pageObjectConfigService.removeColumn;
        vm.selectColumn = pageObjectConfigService.selectColumn(onColumnAdded, onColumnRemoved);
        vm.selectAllColumns = pageObjectConfigService.selectAllColumns(onColumnAdded, onColumnRemoved);
        vm.removeAllColumns = pageObjectConfigService.removeAllColumns;
        vm.colIsSelected = pageObjectConfigService.colIsSelected;
        vm.allColSelected = pageObjectConfigService.allColSelected;

        vm.onColumnAdded = onColumnAdded;
        vm.onColumnRemoved = onColumnRemoved;

        // Row Color
        vm.addRowColor = addRowColor;
        vm.removeRowColor = removeRowColor;
        vm.getConditionType = getConditionType;
        vm.conditionStringValue = conditionStringValue;
        vm.matchingConditionType = matchingConditionType;
        vm.addCondition = addCondition;

        vm.checkParentSettings = function(){
            var model = vm.temp.viz.settings.dataSource.schema.model;
            model.id = 'id';
            model.parentId = 'parentId';
            model.fields.parentId = model.fields.parentId || {
                type: 'number',
                nullable: true
            };
        };

        vm.setParentData = function(parentField){
            if(parentField) {
                vm.temp.viz.settings.dataSource.schema.model.fields.parentId.from = parentField;
            }
        };

        $scope.$on('nim-column-sorted', function(e, args){
            // Reorders columns in dataSource when they are sorted in table preview
            var oldI = args.oldIndex;
            var newI = args.newIndex;

            var colObj = vm.temp.viz.settings.columns.splice(oldI, 1)[0];

            vm.temp.viz.settings.columns.splice(newI, 0, colObj);
        });

        // Aggregates
        vm.addAggregate = function(aggregate){
            vm.temp.viz.settings.dataSource.schema.aggregates = 'nim_aggregate';
            vm.temp.viz.settings.dataSource.aggregate = vm.temp.viz.settings.dataSource.aggregate || [];
            vm.temp.viz.settings.dataSource.aggregate.push(angular.copy(aggregate));

            for(var c = 0; c < vm.temp.viz.settings.columns.length; c++){
                var column = vm.temp.viz.settings.columns[c];
                if(column.field == aggregate.field){
                    if(column.aggregates.indexOf(aggregate.aggregate) == -1) {
                        column.aggregates.push(aggregate.aggregate);
                    }
                    break;
                }
            }

            aggregate.field = null;
            aggregate.format = null;
            aggregate.aggregate = null;
            aggregate.nimLabel = '';
        };

        vm.removeAggregate = function(aggregate, index){
            vm.temp.viz.settings.dataSource.aggregate.splice(index, 1);

            for(var c = 0; c < vm.temp.viz.settings.columns.length; c++){
                var column = vm.temp.viz.settings.columns[c];
                if(column.field == aggregate.field){
                    column.aggregates.splice(column.aggregates.indexOf(aggregate.aggregate), 1);
                    break;
                }
            }
        };

        vm.updateDrillDownViz = function(id){
            /* LODASH Version 4 ONLY */
            //var pageObject = _.find(vm.pageObjects, ['id', id]);
            /* LODASH Version 3 & 4 Compatibility Mode */
            var pageObject = _.find(vm.pageObjects, function(f){return f.id === id;});
            vm.detailObject.title = pageObject.name;
            delete vm.detailObject.field;

            pageObjectsService.getPageObject(id).then(function(pageObjectData){
                var vId = _.get(pageObjectData, 'viz.settings.dataSource.nim_viewId');
                if(vId){
                    vm.detailObject.viewId = vId;
                    ViewsService.getColumns(vId).then(function(columns){
                        vm.detailColumns = [];
                        _.forEach(columns, function(c){
                            var split = c.colName.split('_');
                            if(split.length > 2 && _.last(split) === 'display'){
                                vm.detailColumns.push({
                                    field: c.colName.replace(/_display$/, ''),
                                    name: c.name
                                });
                                vm.detailColumns.push({
                                    field: c.colName,
                                    name: c.name + ' Display'
                                });
                            }
                            else {
                                vm.detailColumns.push({
                                    field: c.colName,
                                    name: c.name
                                });
                            }
                        });
                    });
                }
                else {
                    delete vm.detailObject.viewId;
                    vm.detailColumns = [];
                }
            });
        };

        vm.addDetailPageObject = function(){
            if(!_.isArray(vm.temp.viz.drillDownSettings)){
                vm.temp.viz.drillDownSettings = [];
            }
            vm.temp.viz.drillDownSettings.push(vm.detailObject);
            vm.detailObject = {
                column: 'id'
            };
            vm.detailColumns = [];
        };

        // Filter Commands
        vm.addToFilterMap = pageObjectConfigService.addToFilterMap;
        vm.removeFromFilterMap = pageObjectConfigService.removeFromFilterMap;

        // Sort Commands
        vm.addSortItem = pageObjectConfigService.addSortItem;

        vm.filterByType = function(column){
            return column.castType === vm.filterType && vm.notPropertyColumn(column);
        };

        // Getters/Setters
        vm.tempFilterStringValue = function(newStringValue){
            if(arguments.length){
                vm.tempFilter.value = newStringValue.replace(/^"/, '\\"').replace(/"$/, '\\"');
                return newStringValue;
            }
            else {
                var value = _.get(vm.tempFilter, 'value');
                return vm.removeEscaping(value);
            }
        };

        vm.removeEscaping = function(value){
            if(_.isString(value)){
                return value.replace(/^\\"/, '"').replace(/\\"$/, '"');
            }
            else if(_.isNull(value)){
                return '';
            }

            return value;
        };

        vm.addQuotes = function(text){
            return '"' + text + '"';
        };

        // Modal Commands
        vm.save = pageObjectConfigService.savePageObjConfig($uibModalInstance, vm.margin, vm.padding);

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        };

        vm.deletePageObject = function(){
            $uibModalInstance.dismiss('delete');
        };

        //Init
        init();

        function init(){
            _.defaults(vm.temp.params, {
                disableVirtualScrolling: true,
                defaultData: [],
                openOnSelect: true,
                rowColors: [],
                colFilterOp: 'startswith',
                requiredFilters: {},
                defaultValues: {},
                routeSettings: {
                    enabled: false,
                    newTab: false,
                    template: ''
                }
            });

            _.forEach(vm.temp.params.rowColors, function(){
                vm.condition.push({});
                vm.conditionType.push('');
                vm.isColumnValue.push(false);
            });

            for(var i = 0; i < filters.length; i++){
                if(filters[i].id){
                    vm.idArray.push(filters[i].id);
                }
            }

            ViewsService.get(false).then(function(views){
                vm.views = views;
            });

            vm.loadFormFields(pageObject.viz.widgetId);

            WidgetsService.getWidgets().then(function(widgets){
                vm.widgets = widgets;
            });

            pageObjectsService.getPageObjects().then(function(data){
                vm.pageObjects = data;
            });

            vm.loadColumns(vm.editView);
            pageObjectConfigService.updateSortConfig(vm.temp.viz.settings.dataSource);
            vm.sortBySortableOptions = pageObjectConfigService.createSortableOptions(vm.temp.viz.settings.dataSource.sort);
        }

        // Controller-specific functions
        function onColumnAdded(/*colField*/){
            // Empty, for now
        }

        function onColumnRemoved(colField){
            var source = vm.temp.viz.settings.dataSource;

            // Clears fields bound to the removed column
            // Sort By
            /* LODASH Version 4 ONLY */
            //_.remove(source.sort, ['field', colField]);
            /* LODASH Version 3 & 4 Compatibility Mode */
            _.remove(source.sort, function(rm){return rm.field === colField;});

            // Group By
            if(_.get(source, 'group.field') === colField){
                delete source.group;
            }

            // ID Override
            if(_.get(source, 'schema.model.id') === colField){
                delete source.schema.model.id;
            }

            if(_.get(vm.temp.params, 'uploadFilename.modelId') === colField){
                delete vm.temp.params.uploadFilename;
            }

            if(_.get(vm.temp.params, 'uploadImageField.modelId') === colField){
                delete vm.temp.params.uploadImageField;
            }
        }

        function resetRecordFields(){
            vm.temp.params.defaultData = [];
            vm.temp.params.defaultValues = {};
        }

        function addRowColor(rowColorArray){
            rowColorArray.push({
                name: '',
                color: '#ffffff',
                logic: 'and',
                conditions: []
            });

            vm.condition.push({});
            vm.conditionType.push('');
            vm.isColumnValue.push(false);
        }

        function removeRowColor(rowColorArray, index){
            rowColorArray.splice(index, 1);
            vm.condition.splice(index, 1);
            vm.conditionType.splice(index, 1);
            vm.isColumnValue.splice(index, 1);
        }

        function getConditionType(columns, condition, index){
            var conditionType = '';

            if(condition){
                if(condition.field){
                    /* LODASH Version 4 ONLY */
                    //var colIndex = _.findIndex(columns, ['field', condition.field]);
                    /* LODASH Version 3 & 4 Compatibility Mode */
                    var colIndex = _.findIndex(columns, function(f){return f.field === condition.field;});
                    if(colIndex >= 0){
                        conditionType = columns[colIndex].type;

                        // Don't allow filter to keep value of different type
                        if(conditionType !== vm.conditionType[index]){
                            condition.value = null;
                        }
                    }
                    else {
                        // Remove value if field is invalid
                        condition.value = null;
                    }
                }
                else{
                    _.assign(condition, {
                        operator: null,
                        value: null
                    });
                }
            }

            vm.conditionType[index] = conditionType;
        }

        function conditionStringValue(index){
            return function(newStringValue){
                if(arguments.length){
                    vm.condition[index].value = newStringValue.replace(/^"/, '\\"').replace(/"$/, '\\"');
                    return newStringValue;
                }
                else {
                    var value = _.get(vm.condition[index], 'value');
                    return vm.removeEscaping(value);
                }
            };
        }

        function matchingConditionType(index){
            return function(column){
                return column.type === vm.conditionType[index];
            };
        }

        function addCondition(rowColor, condition, index){
            rowColor.conditions.push(angular.copy(condition));

            condition.value = null;
            condition.op = null;
            condition.field = '';

            vm.conditionType[index] = '';
        }

        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;