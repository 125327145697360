angular.module('cerberus.util')
    .directive('nimStopBackspace', function(){
        return {
            restrict: 'AE',
            link: function (scope, element) {
                // Prevents backspace from triggering navigation
                // while the user is focused on an input field.
                angular.element(element)
                    .keydown(function(e){
                        if (e.keyCode == 8) {
                            var target = $(e.target);
                            var isInput = target.is('input,textarea,select');
                            if(!isInput || e.target.disabled || e.target.readOnly){
                                e.preventDefault();
                            }
                        }
                    })
                ;
            }
        };
    })
;