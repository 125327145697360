angular.module('cerberus.util')
    .controller('nimLabelBuilderCtrl', function(_, kendo, moment, $scope, $timeout, $q, FormsService, InstancesService){
        var vm = this;

        vm.items = {};
        vm.labelForm = {};
        vm.dataSet = {};
        vm.filters = {};
        vm.doneLoading = {};
        vm.sectionsShown = {};
        vm.fieldsShown = {};
        vm.autoFillData = {};
        vm.staticGridsterOptions = {
            pushing: true
        };

        vm.sectionFilter = FormsService.sectionFilter;

        init();

        function init() {
            if ($scope.autoFillData) {
                vm.autoFillData = $scope.autoFillData;
            }

            var promises = [],
                promiseIds = [];

            // console.time('loaddata');
            _.forEach($scope.instanceIds, function (id) {
                vm.items[id] = [];
                vm.doneLoading[id] = false;
                vm.dataSet[id] = {};
                vm.filters[id] = {};
                vm.sectionsShown[id] = {};
                vm.fieldsShown[id] = {}; 

                for(var i = 0; i < $scope.pageTotals[id]; i++){
                    vm.items[id].push(i + 1);
                }

                InstancesService.getInstance(id).then(function (data) {
                    vm.doneLoading[id] = true;
                    vm.autoFillData[id] = vm.autoFillData[id] || {};
                    
                    if ($scope.formData[id]) {
                        var form = $scope.formData[id];

                        vm.labelForm[id] = form;
                        _.assign(vm.dataSet[id], form.defaultDataSet);
                        vm.filters[id] = {};

                        checkSectionConditions(id, form.form.sections);
                        checkFieldConditions(id, form.form.objects);
                    }
                    else {
                        var workflowState = _.find(data.instanceWorkflow, $scope.formOriginId),
                            formArray = [];

                        _.forEach(workflowState, function(formId, key){
                            if(!_.startsWith(key, '__')) {
                                formArray.push({
                                    formId: formId,
                                    isPk: true,
                                    displayOrder: '1'
                                });
                            }
                        });

                        var formDataPromise = InstancesService.getFormData(id, formArray);

                        promises.push(formDataPromise);
                        promiseIds.push(id);

                        formDataPromise.then(function (result) {
                            vm.labelForm[id] = _.find(result.formData, 'originatorId', parseInt($scope.formOriginId));

                            _.forEach(result.formData, function(form){
                                _.defaults(vm.labelForm[id].defaultDataSet, form.defaultDataSet);
                            });
                            
                            _.assign(vm.dataSet[id], vm.labelForm[id].defaultDataSet);
                            vm.filters[id] = {};

                            _.forEach(result.formData, function (form) {
                                checkSectionConditions(id, form.form.sections);
                                checkFieldConditions(id, form.form.objects);
                            });
                        });
                    }

                    if(_.every(vm.doneLoading)) {
                        // When data has been updated and all forms have loaded,
                        // trigger the print function
                        $q.all(promises).then(function (formDataResults) {
                            var autoFillPromises = [];

                            _.forEach(formDataResults, function (result, index) {
                                var IDx = promiseIds[index];

                                if (_.isEmpty(vm.autoFillData[IDx])) {
                                    autoFillPromises = autoFillPromises.concat(
                                        loadAutoFillData(IDx, result.formData, vm.labelForm[IDx].defaultDataSet)
                                    );
                                }
                            });                            

                            $q.all(autoFillPromises).then(function () {
                                var maxPages = 0;
                                _.forEach($scope.pageTotals, function (total) {
                                    if (total > maxPages) {
                                        maxPages = total;
                                    }
                                });

                                // console.time('timeout');
                                $timeout(function() {
                                    // console.timeEnd('timeout');
                                    // console.timeEnd('loaddata');
                                    $scope.done();
                                // }, 0);
                                }, 250 + 150 * maxPages * $scope.instanceIds.length);
                            });
                        });
                    }
                });
            });
        }

        function checkSectionConditions(id, sections) {
            _.forEach(sections, function(section) {
                if(!_.isEmpty(section.condition)) {
                    vm.sectionsShown[id][section.name] = FormsService.sectionCondition(section.condition, vm.dataSet[id], section.logic, 'read');
                }
                else {
                    vm.sectionsShown[id][section.name] = true;
                }
            });
        }

        function checkFieldConditions(id, formObjects) {
            _.forEach(formObjects, function (formObj) {
                var modelId = formObj.model.config.modelId,
                    section = formObj.model.display.section,
                    params = formObj.model.param;

                var sectionShown = !section || section == '__nimHiddenSection' || vm.sectionsShown[id][section];
                if (sectionShown && !_.isEmpty(params.condition) && (params.conditionalAction === 'show' || params.conditionalAction === 'hide')) {
                    var result = FormsService.sectionCondition(params.condition, vm.dataSet[id], params.conditionalLogic, 'read');

                    if (params.conditionalAction === 'hide') {
                        vm.fieldsShown[id][modelId] = !result;
                    }
                    else {
                        vm.fieldsShown[id][modelId] = result;
                    }
                }
                else {
                    vm.fieldsShown[id][modelId] = true;
                }
            });
        }

        function loadAutoFillData(id, formData, dataSet) {
            var promises = [];

            _.forEach(formData, function (form) {
                _.forEach(form.form.objects, function (f) {
                    var option = f.model.option,
                        modelId = f.model.config.modelId,
                        fieldValue = dataSet[modelId];

                    if (f.model.config.type == 'datetime' && fieldValue && _.isString(fieldValue)) {
                      //replaced by HD 2019-07-03
                      //dataSet[modelId] = kendo.parseDate(fieldValue, ['yyyy-MM-ddTHH:mm:ss', 'yyyy-MM-ddTHH:mm:ss.fffZ']);
                        dataSet[modelId] = kendo.parseDate(fieldValue, ['yyyy-MM-ddTHH:mm:ssz', 'yyyy-MM-ddTHH:mm:ss.fffz'], 'yyyy-MM-ddTHH:mm:sszzz');
                    }
                    
                    if (!vm.autoFillData[id][modelId] && option.type !== 'custom' && !_.isEmpty(option.autoFill)) {
                        vm.autoFillData[id][modelId] = {};

                        var sectionShown = !f.model.display.section || f.model.display.section == '__nimHiddenSection' || vm.sectionsShown[id][f.model.display.section];

                        if (!_.isEmpty(dataSet[modelId]) && sectionShown && vm.fieldsShown[id][modelId]) {
                            var autoFillPromise = FormsService.getAutoFillValues(dataSet[modelId].id, option);

                            promises.push(autoFillPromise);

                            autoFillPromise.then(function (data) { 
                                if (data.length > 0) {
                                    $timeout(function () {
                                        _.forEach(data, function (targetField, index) {
                                            var autoFillMap = option.autoFill[index],
                                                propertyId = autoFillMap.value,
                                                value = targetField.value;

                                            if (targetField.type === 'timestamp') {
                                                value = moment.utc(value, 'MMMM, DD YYYY HH:mm:ss').toDate();
                                            }

                                            if (typeof value === 'object') {
                                                vm.autoFillData[id][modelId][propertyId] = _.get(value, 'display', '');
                                            }
                                            else {
                                                vm.autoFillData[id][modelId][propertyId] = value;
                                            }
                                        });
                                    });
                                }
                            });
                        }
                    }
                });
            });
            
            return promises;
        }
    })
    .directive('nimLabelBuilder', function() {
        return {
            restrict: 'AE',
            templateUrl: 'util/nim-print-label/nim-label-builder.tpl.html',
            controller: 'nimLabelBuilderCtrl',
            controllerAs: 'vm',
            link: function(scope, element) {
                element.css('height', scope.paperOptions.paperSize[1] + 'in')
                    .css('width', scope.paperOptions.paperSize[0] + 'in');

                scope.paperOptions.paperSize[0] = element.css('width');
                scope.paperOptions.paperSize[1] = element.css('height');

                element.css('width', '-=' + scope.paperOptions.margin.left + 'px')
                    .css('width', '-=' + scope.paperOptions.margin.right + 'px')
                    .css('height', '');
                
                scope.labelWidth = element.css('width');

                element.css('paddingLeft', scope.paperOptions.margin.left + 'px')
                    .css('paddingRight', scope.paperOptions.margin.right + 'px');
            }
        };
    })
;