angular.module('cerberus.core')
    /**
     * @ngdoc service
     * @name VizClassService
     * @alias cerberus/core:VizClassService
     * @description
     *   Constructs Viz classes
     */
    .factory('VizClassService', function VizClassService() {
        /**
         * Constructs a new Viz
         * @class Viz
         * @classdesc describes a Viz
         * @memberOf VizClassService
         * @param {string} type
         * @param {string} subType
         * @param {string} template
         * @param {Object} settings
         */
        function Viz(type, subType, template, settings) {
            this.type = type;
            this.subType = subType;
            this.template = template;
            this.settings = settings;
        }

        return {
            viz: Viz,
            newViz: newViz
        };
        ////////////////////

        /**
         * Builds a viz
         * @function newViz
         * @returns {Viz}
         */
        function newViz() {
            return new Viz(null, null, null, {});
        }

    })
;