angular.module('cerberus.account')
    .controller('OrganizationsGroupsCtrl', function OrganizationsGroupsCtrl(_, $scope, $location, orgId, OrganizationsService, organizationGroupsService) {
        $scope.activeRoute = 'groups';
        $scope.orgId = orgId;
        $scope.orgData = {};
        $scope.groups = [];
        $scope.records = { 'checked': false, items: {} };
        $scope.deleteGroup = function(){
            var idArray = _.keys($scope.records.items);
            if(idArray.length > 0){
                organizationGroupsService.deleteGroups(idArray).then(init);
            }
        };
        $scope.openGroup = function(id){
            if(id){
                $location.url('organizations/' + $scope.orgId + '/settings/groups/' + id);
            }
            else {
                $location.url('organizations/' + $scope.orgId + '/settings/groups/create');
            }
        };

        init();

        function init(){
            OrganizationsService.getDetails(orgId).then(function(orgData){
                $scope.orgData = orgData;
            });

            organizationGroupsService.getGroups(orgId).then(function(data){
                $scope.groups = data;
            });
        }
    })
;