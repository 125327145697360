angular.module('cerberus.admin')
    .factory('workspacesService', function workspacesService($http, $q, apiPath, toaster) {
            /** @public */
            return {
                create: function(id,data){
                    var deferred = $q.defer();
                    $http.post(apiPath + 'organizations/' + id + '/workspaces', data)
                        .success(function (value) {
                            toaster.pop('success', 'Business Workspace Created.');
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                getChildren: function(){
                    var deferred = $q.defer();
                    $http.get(apiPath + 'children')
                        .success(function (value) {
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                get: function(id){
                    var deferred = $q.defer();
                    $http.get(apiPath + 'workspaces/' + id)
                        .success(function (value) {
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                update: function(id, data){
                    var deferred = $q.defer();
                    $http.put(apiPath + 'workspaces/' + id, data)
                        .success(function (value) {
                            toaster.pop('success', 'Workspace updated.');
                            deferred.resolve(value.DATA);
                        })
                        .error(function (reason) {
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                },
                deleteCurrentWorkspace: function(){
                    var deferred = $q.defer();
                    $http({method: 'DELETE', url: apiPath + 'workspaces/current/delete'})
                        .success(function(value){
                            deferred.resolve(value);
                        })
                        .error(function(reason){
                            deferred.reject(reason);
                        });
                    return deferred.promise;
                }
            };
        })
;
