angular.module('cerberus.core')
    .directive('nimStoreObject', function () {
        return {
            restrict: 'A',
            require: '?ngModel',
            //Because post-link functions are ran in reverse order if no priority is set,
            // it falls back on to alphabetical order, conflict with ngModel
            priority: 1,
            link: function (scope, element, attrs, ctrl) {
                if(attrs.type === 'checkbox'){
                    ctrl.$formatters.push(function(modelValue) {
                        return typeof modelValue === 'object';
                    });
                    ctrl.$parsers.push(function(viewValue) {
                        if(viewValue === true){
                            return angular.fromJson(attrs.true);
                        }
                        else {
                            return angular.fromJson(attrs.false);
                        }
                    });
                }
                else if(attrs.type === 'radio'){
                    ctrl.$formatters.push(function(modelValue) {
                        return angular.toJson(modelValue);
                    });
                    ctrl.$parsers.push(function(viewValue) {
                        return angular.fromJson(viewValue);
                    });
                }
            }
        };
    })
;