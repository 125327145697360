angular.module('cerberus.ui-util')
    .directive('nimFocus', function nimFocus($timeout) {
        return {
            restrict: 'A',
            link: function(scope, element) {
                $timeout(function(){
                    var focusElement = $(element).find('input, textarea, select, .k-dropdown')
                        .not('input[type=hidden],input[type=button],input[type=submit],input[type=reset],input[type=image],button,.k-dropdown[area-readonly=true],.nim-readonly-form-object *')
                        .filter(':enabled:visible:first');

                    if(focusElement.hasClass('k-dropdown')){
                        focusElement.click();
                    }
                    else {
                        focusElement.focus();
                    }
                }, 100);
            }
        };
    })
;