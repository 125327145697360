angular.module('cerberus.admin')
    .controller('WidgetSchemaUpdateModalCtrl', function WidgetSchemaUpdateModalCtrl($scope, $uibModalInstance, widget, updateOptions) {
		
        $scope.widget = angular.copy(widget);
        $scope.updateOptions = updateOptions;
		
        $scope.isNotEqual = function(data){
            return !angular.equals('UPDATE ' + $scope.widget.name, data);
        };
		
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
		
        $scope.finish = function(){
            $uibModalInstance.close($scope.widget);
        };
		
        $scope.$on('$locationChangeStart', function(event){
            event.preventDefault();
        });
    })
;